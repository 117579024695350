import React, { useState, useEffect } from "react";
import SelectCustom from "../../components/utility/SelectCustom";
import { setWorkerStatus } from "../../api/employees";
import { useMutation, useQueryClient } from "react-query";
import useFeedback from "../../hooks/useFeedback";
import ConfirmModal from "../../components/utility/confirmModal/ConfirmModal";
import DeleteBtn from "../../components/utility/Buttons/DeleteBtn";
import CommonBtn from "../../components/utility/Buttons/CommonBtn";
import useLanguage from "../../hooks/useLanguage";

const statuses = {
	"50": "Кандидат принят на работу",
	"80": "Уволен",
};

const statusesEN = {
	"50": "Candidate is hired",
	"80": "Fired",
};

interface IProps {
	workerStatusName: any;
	workerStatusId: any;
	workerId: any;
	setModal: any;
}

function EmployeeChangeStatus({
	workerStatusName,
	workerStatusId,
	workerId,
	setModal,
}: IProps) {
	const { L } = useLanguage();
	const [status, setStatus] = useState(null);
	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();
	const { data, mutate, isSuccess } = useMutation(setWorkerStatus, {
		onSuccess: () => {
			openFeedback(
				L("Стутус успешно изменен", "Status has been changed successfully"),
				"success"
			);
			setModal({
				opened: false,
			});
			setTimeout(() => {
				client.invalidateQueries("employees");
			}, 150);
			setTimeout(() => {
				client.invalidateQueries("employees");
			}, 400);
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	// change status when status changes
	// console.log(status);
	// console.log(workerStatusId);
	useEffect(() => {
		if (status && status != workerStatusId) {
			if (status == 80) {
				openConformationFire();
			}
			if (status == 50) {
				openConformationHire();
			}
		}
	}, [status, workerStatusId]);

	// open conformation modal to FIRE employee
	const openConformationFire = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConformation}
					title={L("Увольнение сотрудника", "Fire an employee")}
					text={L(
						"Увольнение сотрудника - это необратимая операция. Вы уверены, что хотите уволить сотрудника?",
						"Firing an employee is unreversible. Are you sure you want to proceed?"
					)}
				>
					<DeleteBtn
						text={L("УВОЛИТЬ", "FIRE")}
						onClick={() => {
							if (status) {
								mutate({
									workerId: workerId,
									comment: "comment",
									workerStatusId: status,
								});
							}
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={() => {
							setModal({
								opened: false,
							});
							closeConformation();
						}}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	// open conformation modal to HIRE employee
	const openConformationHire = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConformation}
					title={L("Нанять сотрудника", "Hire an employee")}
					text={L(
						"Вы уверены, что хотите нанять сотрудника?",
						"Are you sure you want to hire this employee?"
					)}
				>
					<DeleteBtn
						text={L("Да, уверен", "Yes")}
						onClick={() => {
							if (status) {
								mutate({
									workerId: workerId,
									comment: "comment",
									workerStatusId: status,
								});
							}
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConformation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	// close conformation modal
	const closeConformation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	return (
		<div>
			<SelectCustom
				styles={{ marginTop: "-12px", marginBottom: "20px" }}
				data={L(statuses, statusesEN)}
				setState={setStatus}
				stateName="idk"
				simpleState
				initial={workerStatusName}
				label={L("Статус", "Status")}
			/>
		</div>
	);
}

export default EmployeeChangeStatus;
