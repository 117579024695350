import { getRatesCreationTemplate, postHourlyRates } from "../../../api/rates";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState, useRef } from "react";
import InputAutocomplete from "../../utility/InputAutocomplete";
import SelectCustom from "../../utility/SelectCustom";
import { yearsDataReverse } from "../../../utils/datesData";
import MonthsSelectCustom from "../../utility/MonthsSelectCustom";
import Loading from "../../utility/Loading";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import useFeedback from "../../../hooks/useFeedback";
import MultipleChoice from "../../utility/MultipleChoice";

interface IProps {
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
		}>
	>;
}

function CreateRates({ setModal }: IProps) {
	const [info, setInfo] = useState({
		companyId: null,
		year: null,
		month: null,
		cityId: null,
		contractorIds: [],
		jobTitleId: null,
		hourlyRate: "",
	});

	const { openFeedback, closeFeedback } = useFeedback();
	const client = useQueryClient();

	const { data } = useQuery(
		["getRatesCreationTemplate", info.companyId],
		(q) => getRatesCreationTemplate(q),
		{
			refetchOnWindowFocus: false,
			refetchInterval: false,
		}
	);

	// refs to clear inputs
	const geoRef = useRef(null);
	const contractorRef = useRef(null);
	const jobTitleIdRef = useRef(null);
	// regex to check if number
	const re = /^\d+(\.|,)?\d*$/;

	// validation
	const [yearreq, setyearreq] = useState(false);
	const [monthreq, setmonthreq] = useState(false);
	const [companyreq, setcompanyreq] = useState(false);
	const [georeq, setgeoreq] = useState(false);
	const [contractorreq, setcontractorreq] = useState(false);
	const [jobreq, setjobreq] = useState(false);
	const [ratereq, setratereq] = useState(false);

	// format hourly rate
	const formatHourlyRate = (hourlyRate: string) => {
		if (hourlyRate === "") return null;
		if (hourlyRate.includes(",")) {
			return hourlyRate.replace(",", ".");
		} else {
			return hourlyRate;
		}
	};

	// post
	const { mutate } = useMutation(postHourlyRates, {
		onSuccess: () => {
			openFeedback("Ставка успешно создана", "success");
			setModal({ opened: false });
			setTimeout(() => {
				client.invalidateQueries("hourlyRates");
			}, 150);
		},
	});

	const postRates = () => {
		mutate({
			...info,
			hourlyRate: formatHourlyRate(info.hourlyRate),
		});
		if (!info.year) setyearreq(true);
		if (!info.month) setmonthreq(true);
		if (!info.companyId) setcompanyreq(true);
		if (!info.cityId) setgeoreq(true);
		if (info.contractorIds.length < 1) setcontractorreq(true);
		if (!info.jobTitleId) setjobreq(true);
		if (!info.hourlyRate) setratereq(true);

		if (info.year) setyearreq(false);
		if (info.month) setmonthreq(false);
		if (info.companyId) setcompanyreq(false);
		if (info.cityId) setgeoreq(false);
		if (info.contractorIds.length > 0) setcontractorreq(false);
		if (info.jobTitleId) setjobreq(false);
		if (info.hourlyRate) setratereq(false);
	};

	return (
		<div className="form-inner side-modal-container">
			<form className="center-container-3 ">
				<SelectCustom
					arrowPosition="far-right"
					styles={{ marginBottom: "16px" }}
					data={data?.data?.years ? data?.data.years : { 2022: "2022" }}
					setState={setInfo}
					stateName="year"
					label="Год*"
					requiredState={yearreq}
				/>
				<MonthsSelectCustom
					arrowPosition="far-right"
					styles={{ marginBottom: "16px" }}
					data={data?.data?.months ? data?.data?.months : { 1: "Январь" }}
					setState={setInfo}
					stateName="month"
					label="Месяц"
					requiredState={monthreq}
				/>
				{/* company */}
				<InputAutocomplete
					id={"company"}
					data={data?.data?.companies}
					setState={setInfo}
					stateNamesToNull={["cityId", "contractorId", "jobTitleId"]}
					clearRefs={[geoRef, contractorRef, jobTitleIdRef]}
					stateNames={["companyId"]}
					label={"Компания*"}
					requiredState={companyreq}
				/>
				{/* geo */}
				<InputAutocomplete
					ref={geoRef}
					id={"geo"}
					data={data?.data?.geo}
					setState={setInfo}
					stateNames={["cityId"]}
					disabled={!!!info.companyId}
					label={"Регион и город*"}
					requiredState={georeq}
				/>

				<MultipleChoice
					key={"sfsf"}
					data={data?.data?.contractors}
					stateData={info.contractorIds}
					setState={setInfo}
					stateName="contractorIds"
					arrowPosition="far-right"
					label="Подрядчики"
					requiredState={contractorreq}
				/>

				{/* jobName */}
				<InputAutocomplete
					dropup
					ref={jobTitleIdRef}
					id={"job"}
					data={data?.data?.jobTitles}
					setState={setInfo}
					stateNames={["jobTitleId"]}
					disabled={!!!info.companyId}
					label={"Должность*"}
					requiredState={jobreq}
				/>
				{/* ставка */}
				<div style={{ width: "99%" }} className="mt-2">
					<label className="form-label" htmlFor="outletaddress">
						Ставка*
					</label>
					<input
						style={ratereq ? { border: "1px solid #ff3b30" } : {}}
						// type="number"
						value={info.hourlyRate}
						onChange={(e) => {
							if (e.target.value === "" || re.test(e.target.value)) {
								setInfo((prev: any) => ({
									...prev,
									hourlyRate: e.target.value,
								}));
							}
						}}
						id="hourlyRate"
						className="form-control"
					/>
					{ratereq && (
						<p className="input-validation-text">Поле должно быть заполнено</p>
					)}
				</div>
			</form>
			<div className="bottom-container-3">
				<div className="flex">
					<CommonBtn
						onClick={() => setModal({ opened: false })}
						type="light"
						text="Отменить"
					/>
					<CommonBtn onClick={postRates} text="Создать" />
				</div>
			</div>
		</div>
	);
}

export default CreateRates;
