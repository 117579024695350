import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import CommonBtn from "../utility/Buttons/CommonBtn";
import InputControlled from "../utility/InputControlled";
import { setWorkerStats } from "../../api/employees";
import SelectCustom from "../utility/SelectCustom";
import useLanguage from "../../hooks/useLanguage";

interface ISetTime {
	year: string | null;
	month: string | null;
	day: number | null;
	workerId: string | number;
	currentDayStatus: {
		id: string | number;
		name: string;
		workedHours: string;
		isAvailableToEdit: any;
	} | null;
	refetch: any;
	setModal: any;
	workerStatus: string | undefined;
}

const statuses = {
	"1": "Явка",
	"2": "Неявка",
	"3": "Больничный",
};

const statusesEN = {
	"1": "Turnout",
	"2": "No-show",
	"3": "Disease",
};

function SetTime({
	year,
	month,
	day,
	workerId,
	currentDayStatus,
	refetch,
	setModal,
	workerStatus,
}: ISetTime) {
	const { L } = useLanguage();

	const [statusId, setStatusId] = useState<any>(currentDayStatus); // poor naming
	const [newHours, setNewHours] = useState("");
	const client = useQueryClient();
	const inputRef = useRef<HTMLInputElement>(null);
	const { data, mutate, isSuccess } = useMutation(setWorkerStats, {
		onSuccess: () => {
			//await refetch();
			setModal({
				opened: false,
				secondaryOpened: false,
			});
			setTimeout(() => {
				client.invalidateQueries("employees");
				client.invalidateQueries("timetable");
				client.invalidateQueries("employeeInfo");
			}, 300);
			setTimeout(() => {
				client.invalidateQueries("employees");
				client.invalidateQueries("timetable");
				client.invalidateQueries("employeeInfo");
			}, 120);
		},
	});
	console.log(currentDayStatus?.isAvailableToEdit);
	const dataQuery = client.getQueryData<any>("employees", { exact: false });

	const getWorkerData = () => {
		const dataQueryRaw = client.getQueryData<any>("employees", {
			exact: false,
		});
		const dataineed = dataQuery.data.workers.filter((worker: any) => {
			return worker.key.workerId == workerId;
		})[0];
	};
	// update initial state when a day is chosen
	useEffect(() => {
		setStatusId(currentDayStatus);
		currentDayStatus?.workedHours && setNewHours(currentDayStatus?.workedHours);
		!currentDayStatus?.workedHours && setNewHours("");
	}, [currentDayStatus?.id, currentDayStatus]);

	// some work with input
	const shouldDisabled =
		statusId?.id === "2" ||
		statusId?.id === "3" ||
		statusId == "2" ||
		statusId == "3";

	// a crotch, set 0 hours when statusId is 2 or 3
	useEffect(() => {
		if (statusId == "2" || statusId == "3") {
			setNewHours("0");
		}
	}, [statusId]);

	// change status
	const changeStatus = () => {
		const settedHours = () => {
			if (!currentDayStatus?.id) {
				return newHours;
			}
			if (newHours === "") {
				return currentDayStatus?.workedHours;
			}
			return newHours;
		};

		mutate({
			workerId: workerId.toString(),
			year: year,
			month: month,
			days: [
				{
					day: Number(day),
					workdayStatusId: Number(statusId) || Number(currentDayStatus?.id),
					hours: settedHours(),
				},
			],
		});

		/*setTimeout(() => {
			refetch();
		}, 200);
		setTimeout(async () => {
			await refetch();
			setModal({
				opened: false,
				secondaryOpened: false,
			});
			
		}, 600); */
	};

	const canEdit = () => {
		if (currentDayStatus?.isAvailableToEdit == 1) return true;
		if (currentDayStatus?.isAvailableToEdit === undefined) return true;
		if (currentDayStatus?.isAvailableToEdit === 0) return false;
	};

	if (isSuccess) {
	}
	const isNOTFired = workerStatus != "80";
	return (
		<div className="timesheet-set-container">
			{canEdit() && isNOTFired && (
				<SelectCustom
					styles={{ width: "210px" }}
					stylesInput={{ height: "14px" }}
					data={L(statuses, statusesEN)}
					setState={setStatusId}
					stateName=""
					simpleState
					initial={currentDayStatus?.name || L("Выберите...", "Choose...")}
					label={L("Статус", "Status")}
					position="inverse"
				/>
			)}
			{canEdit() && isNOTFired && (
				<div className="">
					<label style={{ display: "block" }} className="form-label">
						{L("Часы", "Hours")}
					</label>
					<input
						disabled={shouldDisabled}
						ref={inputRef}
						value={newHours}
						placeholder="-"
						onChange={(e) => {
							const value: any = e.target.value.trim();
							if (!isNaN(value)) {
								setNewHours(value);
							}
						}}
						style={{
							// minWidth: "198px",
							width: "186px",
							paddingTop: "11px",
							fontSize: "14px",
						}}
						type="text"
					/>
				</div>
			)}
			{canEdit() && isNOTFired && (
				<CommonBtn
					onClick={() => {
						setModal({
							opened: false,
							secondaryOpened: false,
						});
					}}
					text={L("Отменить", "Close")}
					type="light"
					styles={{ minWidth: "198px", maxHeight: "14px" }}
				/>
			)}
			{canEdit() && isNOTFired && (
				<CommonBtn
					disabled={(Number(day) > 0 && statusId) ? false : true}
					onClick={changeStatus}
					text={L("Сохранить", "Save")}
					styles={{ minWidth: "198px", maxHeight: "14px" }}
				/>
			)}
		</div>
	);
}

export default SetTime;
