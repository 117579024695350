import { useState } from "react";
import { useQuery } from "react-query";
import { getContractorsSupport } from "../../../api/for_support/support_contractors";
import TableTotalCount from "../../utility/TableTotalCount";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import IconInputFilter from "../../utility/IconInputFilter";
import ModalMain from "../../utility/ModalMain/ModalMain";
import EditContractorSupport from "./modals/EditContractorSupport";
import ContractorsSupportInfo from "./modals/ContractorsSupportInfo";
import Loading from "../../utility/Loading";
import TableHeadSort from "../../utility/Table/headings/TableHeadSort";
import CreateContractorSupport from "./modals/CreateContractorSupport";

function ContractorsSupport() {
	const [page, setPage] = useState(1);
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc
	const [filters, setFilters] = useState({
		searchInput: "",
	});

	const { data, isSuccess } = useQuery(
		["contractors", { pageNumber: page }, filters, sort],
		(q) => getContractorsSupport(q)
	);

	// create
	const [modalCreateNew, setModalCreateNew] = useState({
		opened: false,
	});

	// Info
	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		confirmOpened?: boolean;
		secondaryOpened?: boolean;
	}>({
		opened: false,
		payload: { id: 1 },
		confirmOpened: false,
		secondaryOpened: false,
	});

	const headings = [
		// "Компания",
		{ name: "Подрядчик" },
		{ name: "Сейчас работает" },
		{ name: "Принято заявок" },
		{ name: "Всего" },
		{ name: "Нанято" },
		{
			name: "Ср. время закрытия",
		},
		{ name: "Не подошло" },
		{ name: "Подошел другой" },
		{ name: "Не явился" },

		// "Отменено Заявок",
	];

	return (
		<div className="container-fluid">
			<div className="upper-bar">
				<div className="upper-bar-first">
					<IconInputFilter
						setFilters={setFilters}
						placeholder="Искать подрядчика"
					/>
					<TableTotalCount
						name="Подрядчиков"
						headers={data?.headers["x-pagination"]}
					/>
				</div>
				<div className="upper-bar-second">
					<CommonBtn
						onClick={() =>
							setModalCreateNew({
								opened: true,
							})
						}
						text=""
					>
						<img src="../../icons/add.svg" alt="plus" />
						<p>Создать подрядчика</p>
					</CommonBtn>
				</div>
			</div>

			<ModalMain
				setModal={setModalInfo}
				opened={modalInfo.opened}
				title="Информация о подрядчике"
				modal={modalInfo}
				secondaryOpened={modalInfo.secondaryOpened || false}
			>
				<ContractorsSupportInfo
					payload={modalInfo.payload}
					setModal={setModalInfo}
				/>
			</ModalMain>

			<ModalMain
				opened={modalCreateNew.opened}
				setModal={setModalCreateNew}
				title="Создать подрядчика"
			>
				<CreateContractorSupport setModal={setModalCreateNew} />
			</ModalMain>

			{isSuccess ? (
				<>
					<div className="table-container">
						<table cellSpacing={0} className="my-table">
							<TableHeadSort
								headings={headings}
								setSort={setSort}
								sort={sort}
							/>
							<tbody>
								{data?.data?.map((col: any) => {
									return (
										<tr
											onClick={() => {
												setModalInfo({
													opened: true,
													payload: { id: col.contractorId },
												});
											}}
										>
											<td className="size-item">{col.contractorName}</td>
											<td>{col.numOfWorkers}</td>
											<td>{col.numOfAcceptedRequests}</td>
											<td>{col.numOfCandidates}</td>
											<td>{col.candidateHired}</td>
											<td>{col.avgDaysToCloseRequests}</td>
											<td>{col.candidateNotFitted}</td>
											<td>{col.candidateAnotherFitted}</td>
											<td>{col.candidateNotShowedUp}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default ContractorsSupport;
