import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { TNotificationElement, TNotifications } from "../../../api/notifications";
import { RiErrorWarningLine } from "react-icons/ri";
interface INavElementProps {
	link: string;
	name: string;
	isSuccess: boolean;
	notifications: AxiosResponse<TNotifications, any> | undefined;
}

function NavElement({
	link,
	name,
	notifications,
	isSuccess,
}: INavElementProps) {
	const [notification, setNotification] = useState<null | TNotificationElement>(null)

	const withdrawNotification = () => {
		if (isSuccess && notifications) {
			const notificationForThisPage = notifications.data.notifications.filter((n) => n.pageLink === link)
			if (notificationForThisPage.length > 0) {
				setNotification(notificationForThisPage[0])
			}
		}
	}
	useEffect(() => {
		withdrawNotification();
	}, [isSuccess, notifications])


	return (
		<li className="navbar-item">
			<NavLink to={link}
			>
				<span className="nav-item-text">
					{notification &&  <div className="notification-circle"><RiErrorWarningLine color="red"   /></div>}
					{name}
				</span>
			</NavLink>
		</li>
	);
}

export default NavElement;
