import React from "react";
import "../../styles/TimeTable/TimeTable.css";
import { createArrFromNums } from "../../utils/createArrFromNums";
import useLanguage from "../../hooks/useLanguage";

function TimeTableHead() {
	const { L } = useLanguage();

	const dates = createArrFromNums(31);
	console.log(dates);
	return (
		<div className="time-table-head-outer-container">
			<div className="info">
				<p>{L("Информация", "Information")}</p>
			</div>
			<div className="h-table-container">
				{dates.map((date: number) => {
					return (
						<div key={date} className="h-table-date-container">
							<p className="h-table-date">{date}</p>
						</div>
					);
				})}
			</div>
			<div className="statistics-h">
				<p>{L("Статистика", "Statistics")}</p>
			</div>
		</div>
	);
}

export default TimeTableHead;
