import React from "react";

interface IConfirmModalProps {
	title: string;
	text?: string;
	children: any;
	onClose: any;
}

function ConfirmModal({ title, text, children, onClose }: IConfirmModalProps) {
	console.log("got the title: ", title);
	return (
		<div className="confirm-modal">
			<h3 className="title">{title}</h3>
			<p className="text">{text}</p>
			<div className="confirm-buttons">{children}</div>
			<img onClick={onClose} src="../../../icons/del.svg" alt="" />
		</div>
	);
}

export default ConfirmModal;
