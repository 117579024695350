import React, { ReactNode, useState, useRef, useEffect } from "react";
import { GrClose } from "react-icons/gr";
import BlackScreen from "../confirmModal/BlackScreen";

interface IModalProps {
	opened: boolean;
	secondaryOpened?: boolean;
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			secondaryOpened?: boolean;
			confirmOpened?: boolean;
		}>
	>;
	title: string;
	children?: ReactNode;
	secondary?: () => React.ReactElement<any, any>;
	modal?: any;
	confirmComponent?: any;
	shouldNotOpenMain?: boolean;
}

function ModalMain({
	children,
	opened,
	setModal,
	title,
	secondary,
	secondaryOpened,
	modal,
	confirmComponent,
	shouldNotOpenMain,
}: IModalProps) {
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
	const [closeAnimationPlaying, setCloseAnimationPlaying] = useState(false);
	const outerRef = useRef(null);
	const handleModalClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		event.stopPropagation();
	};

	const handleOuterClick = (e: React.MouseEvent, closeBtn: boolean = false) => {
		if (e.target === outerRef.current || closeBtn) {
			setCloseAnimationPlaying(true);
			setTimeout(() => {
				setModal((prev) => ({
					// ...prev,
					payload: null,
					opened: false,
				}));
				setCloseAnimationPlaying(false);
			}, 200);
		}
	};

	const makeModalInvisible = () => {
		if (modal?.confirmOpened) {
			return {
				display: "none",
			};
		}
		return {};
	};

	// close on escape press
	useEffect(() => {
		const closeModal = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				setModal({
					opened: false,
					secondaryOpened: false,
					confirmOpened: false,
					payload: {},
				});
			}
		};
		window.addEventListener("keydown", closeModal);
		return () => {
			window.removeEventListener("keydown", closeModal);
		};
	}, []);

	return (
		<>
			{opened && (
				<div className="modal-first">
					{modal && modal.confirmOpened && (
						<BlackScreen>{modal.confirmComponent}</BlackScreen>
					)}
					<div
						style={makeModalInvisible()}
						ref={outerRef}
						onClick={handleOuterClick}
						className={`main-modal-outer-container ${
							closeAnimationPlaying && "close-animation-outer"
						}`}
					>
						{/* div to prevent clicking on the edge of the modal */}
						{!shouldNotOpenMain && (
							<div className="prevent-click-outside"></div>
						)}
						{!shouldNotOpenMain && (
							<div
								className={`main-modal-inner-container ${
									closeAnimationPlaying && "close-animation-inner"
								}`}
								// onClick={handleModalClick}
							>
								<div className="main-modal-head">
									<h2>{title}</h2>
									<GrClose
										onClick={(e) => handleOuterClick(e, true)}
										size={22}
									/>
								</div>
								<div>{children}</div>
							</div>
						)}
						{/* secondary inner */}
						{secondary && secondaryOpened && (
							<div className="main-modal-inner-container-secondary">
								{secondary()}
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default ModalMain;
