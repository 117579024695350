import dayjs from "dayjs";
import { useEffect } from "react";
import SelectCustom from "../utility/SelectCustom";
import {
	monthsData,
	yearsDataReverse,
	getMonthFromNumber,
	monthKeyType,
	getMonthFromNumberEn,
	monthsDataEng,
} from "../../utils/datesData";
import MonthsSelectCustom from "../utility/MonthsSelectCustom";
import useLanguage from "../../hooks/useLanguage";

interface IProps {
	setYear: React.Dispatch<React.SetStateAction<string | null>>;
	setMonth: React.Dispatch<React.SetStateAction<string | null>>;
	outsideYear?: any; // used to set year outside of this component (TimeTable)
	outsideMonth?: any; // used to set months outside of this component
}

function SelectDateTimeSheet({
	setYear,
	setMonth,
	outsideYear,
	outsideMonth,
}: IProps) {
	const { L } = useLanguage();
	//formatting the date to DD format and setting it as state
	const minTwoDigits = (n: number) => {
		return (n < 10 ? "0" : "") + n.toString();
	};
	const currentYearTwoDigits = minTwoDigits(dayjs().year()).toString();
	const currentMonthTwoDigits = minTwoDigits(dayjs().month() + 1).toString();

	// setting default state as current month & year
	useEffect(() => {
		if (!outsideMonth) {
			setMonth(currentMonthTwoDigits);
		}
		if (!outsideYear) {
			setYear(currentYearTwoDigits);
		}
		if (outsideMonth) {
			setMonth(outsideMonth); // its already 2 digits and string
		}
		if (outsideYear) {
			setYear(outsideYear);
		}
	}, [currentMonthTwoDigits, currentYearTwoDigits]);

	return (
		<div className="choose-year-month">
			<SelectCustom
				styles={{ width: "100%" }}
				data={yearsDataReverse}
				setState={setYear}
				stateName="idk"
				initial={outsideYear ? outsideYear : currentYearTwoDigits.toString()}
				label={L("Год", "Year")}
				simpleState={true}
			/>
			<MonthsSelectCustom
				styles={{ width: "100%" }}
				data={L(monthsData, monthsDataEng)}
				setState={setMonth}
				stateName="idk"
				initial={L(
					outsideMonth
						? getMonthFromNumber(outsideMonth)
						: getMonthFromNumber(currentMonthTwoDigits as monthKeyType),
					outsideMonth
						? getMonthFromNumberEn(outsideMonth)
						: getMonthFromNumberEn(currentMonthTwoDigits as monthKeyType)
				)}
				label={L("Месяц", "Month")}
				simpleState={true}
			/>
		</div>
	);
}

export default SelectDateTimeSheet;
