import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { uploadReqs } from "../../../../../api/for_support/support_worker_requests";
import useFeedback from "../../../../../hooks/useFeedback";

function UploadReqsWidgetS() {
	const [file, setFile] = useState<any>(null);
	const navigate = useNavigate();
	const handleFileChange = (e: any) => {
		console.log(e.target.files);
		setFile(e.target.files[0]);
		console.log(file);
	};

	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();

	const { isSuccess, mutate, isError, data, error } = useMutation<any, any>(
		uploadReqs,
		{
			onSuccess: () => {
				openFeedback("Заявки загружены", "success");
				setTimeout(() => {
					client.invalidateQueries("hourlyRates");
				}, 150);
			},
		}
	);
	const handleUpload = () => {
		mutate(file);
	};

	if (isError) console.log(error.response.data);
	const wrongExcelError = error?.response?.data;
	const axiosErr = error?.response?.data?.errors;

	const niceErrorsBro = () => {
		if (error) {
			if (error.response.data) {
				return error.response.data.map((err: any) => {
					return <div className="text-danger">{err}</div>;
				});
			}
		}
	};

	return (
		<>
			<div className="">
				<div className="">
					<label htmlFor="upload" className="upload-file-label">
						<input type="file" onChange={(e) => handleFileChange(e)} />
					</label>
				</div>
				<div className="col-1">
					<button
						disabled={!!!file}
						className="btn btn-gray-300 btn-modal-right btn-form common-btn"
						style={{ width: "200px", height: "42px" }}
						onClick={handleUpload}
					>
						Загрузить
					</button>
				</div>
			</div>

			{isError && <div className="upload-errors">{niceErrorsBro()}</div>}
		</>
	);
}

export default UploadReqsWidgetS;
