import { useQuery } from "react-query";
import { useState, useEffect, useRef } from "react";
import { Typeahead, ClearButton } from "react-bootstrap-typeahead";
import InputFilter from "../../utility/InputFilter";
import { createArrayOptions } from "../../../utils/createArrayOptionsFromObj";
// import "../styles/filters/filters.css";
import "../../../styles/filters/filters.css";
import { getCandidates } from "../../../api/for_contractors/candidates_contractors";
import FilterAutocomplete from "../../utility/FilterAutocomplete";
import { useSearchParams } from "react-router-dom";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import useLanguage from "../../../hooks/useLanguage";
interface IProps {
	filters?: any;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
}
function CandidatesFiltersContractors({ setFilters, filters }: IProps) {
	const { L } = useLanguage();

	const { data, isSuccess } = useQuery(
		["candidatesRequestsFiltersContractors", { pageNumber: 1 }, filters],
		getCandidates
	);
	const [searchParams] = useSearchParams();

	const regions = createArrayOptions(data?.data?.filterValues?.regions);
	const cities = createArrayOptions(data?.data?.filterValues?.cities);
	const objectNames = createArrayOptions(data?.data?.filterValues?.objectNames);
	const jobTitles = createArrayOptions(data?.data?.filterValues?.jobTitles);
	const workerStatuses = createArrayOptions(
		data?.data?.filterValues?.workerStatuses
	);
	const contractors = createArrayOptions(data?.data?.filterValues?.contractors);

	const regionRef: any = useRef(null);
	const cityRef: any = useRef(null);
	const jobRef: any = useRef(null);
	const statusRef: any = useRef(null);
	const contractorsRef: any = useRef(null);
	const customIdRef: any = useRef();
	const chosenFilters = data?.data?.chosenFilters;

	let checkRegion = regions && localStorage.getItem("role") != "Director";
	let checkCity = cities && localStorage.getItem("role") != "Director";

	// search params fill filter
	const fioRef: any = useRef(null);
	const startRef: any = useRef(null);
	const endRef: any = useRef(null);

	const reqNumberRef: any = useRef();
	useEffect(() => {
		if (searchParams.get("WorkerRequestId")) {
			let wId: any = searchParams.get("WorkerRequestId");
			reqNumberRef.current.value = wId;
		}
	}, [searchParams]);

	useEffect(() => {
		if (data) {
			if (filters.index) customIdRef.current.value = filters.index;
			if (filters.workerRequestId)
				reqNumberRef.current.value = filters.workerRequestId;
			if (filters.CandidateFullName)
				fioRef.current.value = filters.CandidateFullName;
			if (filters.StartVisitDate)
				startRef.current.value = filters.StartVisitDate;
			if (filters.EndVisitDate) endRef.current.value = filters.EndVisitDate;
		}
	}, [data]);

	const clear = () => {
		customIdRef.current.value = "";
		reqNumberRef.current.value = "";
		fioRef.current.value = "";
		startRef.current.value = "";
		endRef.current.value = "";

		setFilters((prev: any) => ({
			...prev,
			index: "",
			region: "",
			city: "",
			outlet: "",
			job: "",
			ContractorId: "",
			WorkerStatusId: "",
			CandidateFullName: "",
			StartVisitDate: "",
			EndVisitDate: "",
			workerRequestId: "",
			companyId: "",
			searchInput: "",
		}));
	};

	return (
		<div className="container-fluid mt-4 mb-4">
			<div style={{ marginBottom: "20px" }}>
				<CommonBtn
					onClick={clear}
					type="light"
					text={L("Очистить фильтры", "Clear filters")}
				/>
			</div>
			<InputFilter
				label={L("№ Заявки", "Request ID")}
				ref={reqNumberRef}
				filterField="workerRequestId"
				setFilters={setFilters}
				placeholder={L("Пример: 231", "Example: 231")}
				type={"text"}
			/>

			<FilterAutocomplete
				label={L("Компания", "Company")}
				id="company"
				options={data?.data?.filterValues?.companies}
				setFilters={setFilters}
				filterName={"companyId"}
				selectedValue={data?.data?.chosenFilters?.companies.value}
				placeholder={L("Пример: Продукты", "Example: Grocery")}
			/>
			<FilterAutocomplete
				label={L("Филиал", "Outlet")}
				id="object"
				options={data?.data?.filterValues?.objectNames}
				setFilters={setFilters}
				filterName={"outlet"}
				selectedValue={data?.data?.chosenFilters?.objectNames.value}
				placeholder={L("Пример: Продукты_245", "Example: Grocery_245")}
			/>

			<InputFilter
				ref={customIdRef}
				filterField="index"
				setFilters={setFilters}
				placeholder={L("Пример: 918267", "Example: 918267")}
				label={L("ID филиала", "Outlet ID")}
				type={"text"}
			/>

			{checkRegion && (
				<FilterAutocomplete
					label={L("Регион", "Region")}
					id="region"
					options={data?.data?.filterValues?.regions}
					setFilters={setFilters}
					filterName={"region"}
					selectedValue={data?.data?.chosenFilters?.regions.value}
					placeholder={L("Пример: Омская область", "Example: Scotland")}
				/>
			)}

			{checkCity && (
				<FilterAutocomplete
					label={L("Город", "City")}
					id="city"
					options={data?.data?.filterValues?.cities}
					setFilters={setFilters}
					filterName={"city"}
					selectedValue={data?.data?.chosenFilters?.cities.value}
					placeholder={L("Пример: Симферополь", "Example: Glasgow")}
				/>
			)}

			{jobTitles && (
				<FilterAutocomplete
					label={L("Должность", "Job title")}
					id="job"
					options={data?.data?.filterValues?.jobTitles}
					setFilters={setFilters}
					filterName={"job"}
					selectedValue={data?.data?.chosenFilters?.jobTitles.value}
					placeholder={L("Пример: Кассир", "Example: Cashier")}
				/>
			)}

			{workerStatuses && (
				<FilterAutocomplete
					label={L("Статус кандидата", "Candidate status")}
					id="WorkerStatusId"
					options={data?.data?.filterValues?.workerStatuses}
					setFilters={setFilters}
					filterName={"WorkerStatusId"}
					selectedValue={data?.data?.chosenFilters?.workerStatuses.value}
					placeholder={L("Пример: Ожидает собеседования", "Example: Hired")}
				/>
			)}

			<InputFilter
				ref={fioRef}
				label={L("ФИО кандидата", "Candidate's full name")}
				filterField="CandidateFullName"
				setFilters={setFilters}
				placeholder={L(
					"Пример: Иванов Иван Иванович",
					"Example: John Williams Smith"
				)}
				type={"text"}
			/>

			<InputFilter
				ref={startRef}
				label={L("Дата визита от", "Visit date from")}
				filterField="StartVisitDate"
				setFilters={setFilters}
				placeholder={"Создан от"}
				type={"date"}
			/>

			<InputFilter
				ref={endRef}
				label={L("Дата визита before", "Visit date before")}
				filterField="EndVisitDate"
				setFilters={setFilters}
				placeholder={"Создан до"}
				type={"date"}
			/>
		</div>
	);
}

export default CandidatesFiltersContractors;
