import React, { useState } from "react";
import { getCandidates } from "../../api/candidates";
import { useQuery } from "react-query";
import Loading from "../../components/utility/Loading";
import useLanguage from "../../hooks/useLanguage";

interface IProps {
	active: "request" | "candidates" | "edit";
	workerRequestId: number | string;
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			confirmOpened?: boolean | undefined;
			secondaryOpened?: boolean | undefined;
		}>
	>;
}

function EmployeeRequestsCandidates({
	active,
	workerRequestId,
	setModal,
}: IProps) {
	const { L } = useLanguage();

	const [activeCandidate, setActiveCandidate] = useState<number | null>(null);
	const filters = {
		workerRequestId: workerRequestId,
		PageSize: 200,
	};
	const { data, isSuccess } = useQuery(
		["candidates", { pageNumber: 1 }, filters],
		(q) => getCandidates(q),
		{
			enabled: (active as string) === "candidates",
		}
	);

	const candidates = data?.data?.candidates;

	// open secondary modal
	const openSecondary = (candidateData: any) => {
		setModal((prev) => ({
			...prev,
			secondaryOpened: true,
			payload: { ...prev.payload, data: candidateData },
		}));
	};

	// candidates list onclick

	const candidateListClickHandler = (
		candidateId: number,
		candidateData: any
	) => {
		setActiveCandidate(candidateId);
		openSecondary(candidateData);
	};

	// candidates list
	const candidatesList = candidates?.map((candidate) => {
		const candidateName = candidate.candidateFullName;
		return (
			<div
				key={candidate.candidateId}
				onClick={() =>
					candidateListClickHandler(candidate.candidateId, candidate)
				}
				className={
					activeCandidate === candidate.candidateId
						? `candidates-list active`
						: `candidates-list`
				}
			>
				<div className="info">
					<p>{L("Подрядчик:", "Contractor:")}</p>
					<p>{candidate.contractorName && `${candidate.contractorName}`}</p>
				</div>
				<div className="info">
					<p>{L("Кандидат:", "Candidate:")}</p>
					<p>{candidateName}</p>
				</div>
				<div className="info">
					<p>{L("Дата визита:", "Visit date")}</p>
					<p>{candidate.visitDate && `${candidate.visitDate.slice(0, 10)}`}</p>
				</div>
				<div className="info">
					<p>{L("Статус:", "Status:")}</p>
					<p>{candidate.candidateStatus}</p>
				</div>
				{/* <div className="info">
					<p>Ставка:</p>
					<p>{candidate.hourlyRate && `${candidate.hourlyRate} руб/ч`}</p>
				</div> */}
			</div>
		);
	});

	// loading
	if (!isSuccess) return <Loading type="inside" />;
	if (isSuccess && data.data.candidates.length === 0) {
		return (
			<p className="color-gray-text">
				{L("Кандидатов нет.", "No candidates.")}
			</p>
		);
	}
	return <div>{candidatesList || ""}</div>;
}

export default EmployeeRequestsCandidates;
