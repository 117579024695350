import React, { useState, useEffect } from "react";
import SelectCustom from "../../../components/utility/SelectCustom";
import CommonBtn from "../../../components/utility/Buttons/CommonBtn";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getEmployeeInfo, editEmployee } from "../../../api/employees";
import Loading from "../../../components/utility/Loading";
import InputControlled from "../../../components/utility/InputControlled";
import useFeedback from "../../../hooks/useFeedback";
import { formatDate } from "../../../utils/formatDate";
import useLanguage from "../../../hooks/useLanguage";

interface IProps {
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			secondaryOpened?: boolean | undefined;
			confirmOpened?: boolean | undefined;
		}>
	>;
	workerId: any;
	startWorkDate: string;
	endWorkDay: string;
}
interface IEdited {
	workerId: number;
	firstname: string;
	lastname: string;
	middlename: string | null;
	birthday: string;
	passport: string;
	sex: true | false | null | undefined;
	phoneNumber: string;
	phoneNumberWithoutCode: string;
	citizenship: string;
}

function EmployeeEdit({
	setModal,
	workerId,
	startWorkDate,
	endWorkDay,
}: IProps) {
	const { L } = useLanguage();

	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();

	const [edited, setEdited] = useState<IEdited>({
		workerId: workerId,
		firstname: "",
		lastname: "",
		middlename: "",
		birthday: "",
		passport: "",
		sex: undefined,
		phoneNumber: "",
		phoneNumberWithoutCode: "",
		citizenship: "",
	});

	// get data
	const { data, isSuccess } = useQuery(
		["employeeInfo", workerId, startWorkDate, endWorkDay],
		(q) => getEmployeeInfo(q)
	);

	// fill state on data received
	useEffect(() => {
		if (isSuccess === true) {
			const worker = data?.data.workerInfo;
			setEdited((prev) => ({
				...prev,
				firstname: worker.firstName,
				lastname: worker.lastName,
				middlename: worker.middleName,
				birthday: formatDate(worker.birthday),
				passport: worker.passport,
				sex: worker.sex,
				citizenship: worker.citizenship,
			}));
			// format phone number
			if (worker.phoneNumber.slice(0, L(2, 3)) === L("+7", "+44")) {
				setEdited((prev) => ({
					...prev,
					phoneNumberWithoutCode: worker.phoneNumber.toString().slice(L(2, 3)),
				}));
			} else {
				setEdited((prev) => ({
					...prev,
					phoneNumberWithoutCode: worker.phoneNumber.toString(),
				}));
			}
		}
	}, [isSuccess, data]);

	// required states
	const [firstnamereq, setfirstnamereq] = useState(false);
	const [lastnamereq, setlastnamereq] = useState(false);
	const [birthdayreq, setbirthdayreq] = useState(false);
	const [passportreq, setpassportreq] = useState(false);
	const [sexreq, setsexreq] = useState(false);
	const [phoneNumberreq, setphoneNumberreq] = useState(false);
	const [citizenshipreq, setcitizenshipreq] = useState(false);
	const [visitDatereq, servisitDatereq] = useState(false);

	// mutation
	const { mutate, error } = useMutation<any, any, any>(editEmployee, {
		onSuccess: () => {
			openFeedback(
				L("Сотрудник успешно изменен", "Employee has been changed"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("employeeInfo");
				client.invalidateQueries("employees");
			}, 150);
			setModal((prev) => ({ ...prev, secondaryOpened: false }));
		},
		onError: () =>
			openFeedback(
				L("Пожалуйста, проверьте поля", "Please, check input fields"),
				"error"
			),
	});

	// POST

	const postEmployee = () => {
		const whatIsSex = (sex: any) => {
			if (sex === "true") {
				return true;
			}
			if (sex === "false") {
				return false;
			}
			if (sex === true) {
				return true;
			}
			if (sex === false) {
				return false;
			} else {
				return null;
			}
		};
		const realSex = whatIsSex(edited.sex);
		if (!edited.firstname) setfirstnamereq(true);
		if (!edited.lastname) setlastnamereq(true);
		if (!edited.birthday) setbirthdayreq(true);
		if (!edited.passport) setpassportreq(true);
		if (edited.sex === null) setsexreq(true);
		if (!edited.phoneNumberWithoutCode) setphoneNumberreq(true);
		if (!edited.citizenship) setcitizenshipreq(true);

		if (edited.firstname) setfirstnamereq(false);
		if (edited.lastname) setlastnamereq(false);
		if (edited.birthday) setbirthdayreq(false);
		if (edited.passport) setpassportreq(false);
		if (edited.sex !== null) setsexreq(false);
		if (edited.phoneNumberWithoutCode) setphoneNumberreq(false);
		if (edited.citizenship) setcitizenshipreq(false);

		if (error) {
			if (error.response.status === 411) setpassportreq(true);
		}

		if (edited.phoneNumberWithoutCode) {
			mutate({
				...edited,
				phoneNumber: L("+7", "+44") + edited.phoneNumberWithoutCode,
				sex: realSex,
			});
		} else {
			mutate({ ...edited, sex: realSex });
		}
	};
	// passport error
	const whichError = () => {
		if (error) {
			if (error.response.status === 411)
				return L(
					"Кандидат с таким паспортом уже был направлен на вакансию",
					"A candidate with this passport has already been sent to a vacancy"
				);
		} else {
			return L("Поле должно быть заполнено", "This field should not be empty");
		}
	};

	// sex options
	const newSexOptions = {
		true: L("Мужчина", "Male"),
		false: L("Женщина", "Female"),
	};

	if (!isSuccess)
		return (
			<div style={{ width: "440px", marginTop: "200px" }}>
				<Loading type="inside" />
			</div>
		);
	return (
		<div style={{ width: "440px" }} className="form-inner side-modal-container">
			<form className="center-container-3" style={{ maxWidth: "100%" }}>
				<InputControlled
					label={L("Имя*", "First name*")}
					setState={setEdited}
					stateName="firstname"
					requiredState={firstnamereq}
					value={edited.firstname}
				/>
				<InputControlled
					label={L("Фамилия*", "Last name*")}
					setState={setEdited}
					stateName="lastname"
					requiredState={lastnamereq}
					value={edited.lastname}
				/>
				<InputControlled
					label={L("Отчество", "Middle name")}
					setState={setEdited}
					stateName="middlename"
					value={edited.middlename}
				/>

				<InputControlled
					label={L("Дата рождения*", "Birth date*")}
					setState={setEdited}
					stateName="birthday"
					value={edited.birthday}
					type="date"
					requiredState={birthdayreq}
				/>

				<InputControlled
					label={L("Паспорт*", "Passport")}
					setState={setEdited}
					stateName="passport"
					value={edited.passport}
					requiredState={passportreq}
					errorMessage={whichError()}
				/>

				<SelectCustom
					styles={{ marginBottom: "16px" }}
					label={L("Пол*", "Sex*")}
					data={newSexOptions}
					setState={setEdited}
					stateName="sex"
					initial={data?.data?.workerInfo.workerSexString}
					arrowPosition="far-right"
					requiredState={sexreq}
				/>

				<div className="mt-2">
					<label className="form-label" htmlFor="phoneNumber">
						{L("Телефон*", "Phone*")}
					</label>
					<div className={L("phonefield-container", "phonefield-container-gb")}>
						<input
							style={phoneNumberreq ? { border: "1px solid #ff3b30" } : {}}
							value={edited.phoneNumberWithoutCode}
							onChange={(e: any) =>
								setEdited((prev: any) => ({
									...prev,
									phoneNumberWithoutCode: e.target.value.toString(),
									phoneNumber: e.target.value.toString(),
								}))
							}
							type="telephone"
							id="phoneNumber"
							className=" phonefield form-control"
						/>
					</div>
					{phoneNumberreq && (
						<p className="input-validation-text">
							{L("Обязательное поле", "The field must not be empty")}
						</p>
					)}
				</div>

				{/* citizenship */}
				<InputControlled
					label={L("Гражданство*", "Citizenship*")}
					setState={setEdited}
					stateName="citizenship"
					value={edited.citizenship}
					requiredState={citizenshipreq}
				/>
			</form>
			<div className="bottom-container-3">
				<div className="flex">
					<CommonBtn
						onClick={() =>
							setModal((prev) => ({ ...prev, secondaryOpened: false }))
						}
						type="light"
						text={L("Отменить", "Cancel")}
					/>
					<CommonBtn onClick={postEmployee} text={L("Сохранить", "Save")} />
				</div>
			</div>
		</div>
	);
}

export default EmployeeEdit;
