export function addThousandSeparator(num: number | string): string {
	if (!num) return "";
	const numString = num.toString();
	const decimalIndex = numString.indexOf(".");
	const integerPart =
		decimalIndex !== -1 ? numString.slice(0, decimalIndex) : numString;
	const decimalPart = decimalIndex !== -1 ? numString.slice(decimalIndex) : "";

	const separatedIntegerPart = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		" "
	);

	return separatedIntegerPart + decimalPart;
}
