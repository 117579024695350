import {
	getRateInfo,
	getRatesCreationTemplate,
	putHourlyRates,
} from "../../../api/rates";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState, useRef, useEffect } from "react";
import InputAutocomplete from "../../utility/InputAutocomplete";
import { Navigate, useNavigate, useParams } from "react-router";
import useFeedback from "../../../hooks/useFeedback";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import SelectCustom from "../../utility/SelectCustom";
import MonthsSelectCustom from "../../utility/MonthsSelectCustom";

interface IProps {
	id: number;
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			confirmOpened?: boolean | undefined;
			secondaryOpened?: boolean | undefined;
		}>
	>;
}

function EditRate({ id, setModal }: IProps) {
	// getting workerRateId from params

	const [info, setInfo] = useState<any>({
		companyId: null,
		year: null,
		month: null,
		cityId: null,
		contractorId: null,
		jobTitleId: null,
		hourlyRate: null,
	});
	const { data } = useQuery(
		["getRatesCreationTemplate", info.companyId],
		(q) => getRatesCreationTemplate(q),
		{
			refetchOnWindowFocus: false,
			refetchInterval: false,
		}
	);

	// refs to clear inputs
	const geoRef = useRef(null);
	const contractorRef = useRef(null);
	const jobTitleIdRef = useRef(null);
	//regex to check if number
	const re = /^\d+(\.|,)?\d*$/;
	//validation

	const [yearreq, setyearreq] = useState(false);
	const [monthreq, setmonthreq] = useState(false);
	const [companyreq, setcompanyreq] = useState(false);
	const [georeq, setgeoreq] = useState(false);
	const [contractorreq, setcontractorreq] = useState(false);
	const [jobreq, setjobreq] = useState(false);
	const [ratereq, setratereq] = useState(false);

	// format hourly rate
	const formatHourlyRate = (hourlyRate: string) => {
		const hourlyRateString = hourlyRate.toString();
		if (hourlyRate === "") return null;
		if (hourlyRateString.includes(",")) {
			return hourlyRateString.replace(",", ".");
		} else {
			return hourlyRateString;
		}
	};

	//put

	const { isSuccess, isError, mutate } = useMutation(putHourlyRates, {
		onSuccess: () => {
			openFeedback("Ставка изменена", "success");
			setTimeout(() => {
				client.invalidateQueries("hourlyRates");
				client.invalidateQueries("getRateInfo");
			}, 150);
			closeSecondary();
		},
		onError: () => {
			openFeedback("Что-то не так, проверьте поля", "success");
		},
	});
	const postRates = () => {
		mutate({
			...info,
			workerRateId: Number(id),
			hourlyRate: formatHourlyRate(info.hourlyRate),
		});
		if (!info.year) setyearreq(true);
		if (!info.month) setmonthreq(true);
		if (!info.companyId) setcompanyreq(true);
		if (!info.cityId) setgeoreq(true);
		if (!info.contractorId) setcontractorreq(true);
		if (!info.jobTitleId) setjobreq(true);
		if (!info.hourlyRate) setratereq(true);

		if (info.year) setyearreq(false);
		if (info.month) setmonthreq(false);
		if (info.companyId) setcompanyreq(false);
		if (info.cityId) setgeoreq(false);
		if (info.contractorId) setcontractorreq(false);
		if (info.jobTitleId) setjobreq(false);
		if (info.hourlyRate) setratereq(false);
	};
	// navigate to table after success

	//filling the fields and state

	const { data: rateInfo, isSuccess: isRateInfoSuccess } = useQuery(
		["getRateInfo", id],
		(q) => getRateInfo(q),
		{
			refetchInterval: 0,
			refetchOnWindowFocus: false,
		}
	);

	const { openFeedback, closeFeedback } = useFeedback();
	const client = useQueryClient();

	useEffect(() => {
		if (isRateInfoSuccess === true) {
			// console.log(rateInfo.data.companyId);
			setInfo((prev: any) => ({
				...prev,
				companyId: rateInfo?.data?.companyId,
				year: rateInfo?.data?.year,
				month: rateInfo?.data?.month,
				cityId: rateInfo?.data?.cityId,
				contractorId: rateInfo?.data?.contractorId,
				jobTitleId: rateInfo?.data?.jobTitleId,
				hourlyRate: rateInfo?.data?.hourlyRate,
			}));
		}
	}, [isRateInfoSuccess, rateInfo]);

	// close modal secondary
	const closeSecondary = () => {
		setModal((prev) => ({
			...prev,
			secondaryOpened: false,
		}));
	};

	return (
		<div style={{ width: "430px" }} className="form-inner side-modal-container">
			<div className="center-container-1">
				<form>
					{/* year */}
					{/* {rateInfo?.data?.year && (
						<InputAutocomplete
							id={"year"}
							data={data?.data?.years}
							setState={setInfo}
							stateNames={["year"]}
							label={"Год*"}
							requiredState={yearreq}
							defaultInputVal={rateInfo?.data?.year?.toString()}
						/>
					)} */}

					<SelectCustom
						arrowPosition="far-right"
						styles={{ marginBottom: "16px" }}
						data={data?.data?.years ? data?.data.years : { 2022: "2022" }}
						setState={setInfo}
						stateName="year"
						label="Год*"
						requiredState={yearreq}
						initial={
							rateInfo?.data?.year ? rateInfo?.data?.year.toString() : ""
						}
					/>

					<MonthsSelectCustom
						arrowPosition="far-right"
						styles={{ marginBottom: "16px" }}
						data={data?.data?.months ? data?.data?.months : { 1: "Январь" }}
						setState={setInfo}
						stateName="month"
						label="Месяц"
						requiredState={monthreq}
						initial={
							rateInfo?.data?.monthDescr
								? rateInfo?.data?.monthDescr.toString()
								: ""
						}
					/>

					{/* month */}
					{/* {rateInfo?.data?.month && (
						<InputAutocomplete
							id={"month"}
							data={data?.data?.months}
							setState={setInfo}
							stateNames={["month"]}
							label={"Месяц*"}
							requiredState={monthreq}
							defaultInputVal={rateInfo?.data?.month.toString()}
						/>
					)} */}

					{/* company */}
					{rateInfo?.data?.companyName && (
						<InputAutocomplete
							id={"company"}
							data={data?.data?.companies}
							setState={setInfo}
							stateNamesToNull={["cityId", "contractorId", "jobTitleId"]}
							clearRefs={[geoRef, contractorRef, jobTitleIdRef]}
							stateNames={["companyId"]}
							label={"Компания*"}
							requiredState={companyreq}
							defaultInputVal={rateInfo?.data?.companyName}
						/>
					)}
					{/* geo */}
					{rateInfo?.data?.geo && (
						<InputAutocomplete
							ref={geoRef}
							id={"geo"}
							data={data?.data?.geo}
							setState={setInfo}
							stateNames={["cityId"]}
							disabled={!!!info.companyId}
							label={"Регион и город*"}
							requiredState={georeq}
							defaultInputVal={rateInfo?.data?.geo}
						/>
					)}
					{/* contractors */}
					{rateInfo?.data?.contractorName && (
						<InputAutocomplete
							ref={contractorRef}
							id={"contractor"}
							data={data?.data?.contractors}
							setState={setInfo}
							stateNames={["contractorId"]}
							disabled={!!!info.companyId}
							label={"Подрядчик*"}
							requiredState={contractorreq}
							defaultInputVal={rateInfo?.data?.contractorName}
						/>
					)}
					{/* jobName */}
					{rateInfo?.data?.jobTitleName && (
						<InputAutocomplete
							dropup
							ref={jobTitleIdRef}
							id={"job"}
							data={data?.data?.jobTitles}
							setState={setInfo}
							stateNames={["jobTitleId"]}
							disabled={!!!info.companyId}
							label={"Должность*"}
							requiredState={jobreq}
							defaultInputVal={rateInfo?.data?.jobTitleName}
						/>
					)}
					{/* ставка */}
					<div className="mt-2">
						<label className="form-label" htmlFor="outletaddress">
							Ставка*
						</label>
						<input
							value={info.hourlyRate ? info.hourlyRate : ""}
							onChange={(e) => {
								if (e.target.value === "" || re.test(e.target.value)) {
									setInfo((prev: any) => ({
										...prev,
										hourlyRate: e.target.value,
									}));
								}
							}}
							id="hourlyRate"
							className="form-control"
						/>
						{ratereq && <p className="text-danger">обязательное поле</p>}
					</div>
				</form>
			</div>

			<div className="bottom-container-3">
				<div className="flex">
					<CommonBtn onClick={closeSecondary} text="Отменить" type="light" />
					<CommonBtn onClick={postRates} text="Сохранить" />
				</div>
			</div>
		</div>
	);
}

export default EditRate;
