import React from "react";
import { createPortal } from "react-dom";
import "../../../styles/modalFeedback/modalFeedback.css";
import { useContext } from "react";
import ModalFeedbackContext from "../../../context/ModalFeedbackContext";

function ModalFeedback() {
	// get data from the context

	const data = useContext(ModalFeedbackContext);
	const { opened, message, type, title } = data.feedback;

	const close = () => {
		data.setFeedback({
			opened: false,
			message: null,
			type: "success",
		});
	};

	const icon = () => {
		if ((type as string) === "error")
			return (
				<img
					className="type"
					src="../../../icons/feedback/error.svg"
					alt="error"
				/>
			);
		if ((type as string) === "success")
			return (
				<img
					className="type"
					src="../../../icons/feedback/success.svg"
					alt="success"
				/>
			);
		if ((type as string) === "info")
		return (
			<img
				className="type"
				src="../../../icons/feedback/info-circle-exclamation.svg"
				alt="info"
			/>
		);
		return <div></div>;
	};

	if (opened) {
		return createPortal(
			<div className="modal-feedback">
				{icon()}
				{/* {title && <p>{title}</p>} */}
				<div className="info">
					<h3>{title}</h3>
					<p>{message}</p>
				</div>
				<img
					onClick={() => close()}
					className="close"
					src="../../../icons/del.svg"
					alt="close"
				/>
			</div>,
			document.body
		);
	} else {
		return <></>;
	}
}

export default ModalFeedback;
