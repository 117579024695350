import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useFeedback from "../../../../hooks/useFeedback";
import { changeCreds } from "../../../../api/auth";
import ConfirmModal from "../../../utility/confirmModal/ConfirmModal";
import DeleteBtn from "../../../utility/Buttons/DeleteBtn";
import CommonBtn from "../../../utility/Buttons/CommonBtn";

interface IProps {
	login: string;
	password: string;
	setModal: any;
	userId: string;
}
function HiddenContractorInfo({ login, password, setModal, userId }: IProps) {
	const [hidden, setHidden] = useState(true);
	const switchHidden = () => {
		setHidden((prev) => !prev);
	};
	const client = useQueryClient();
	const {openFeedback, closeFeedback} = useFeedback();


	const { mutate: mutateChangeCreds } = useMutation(changeCreds, {
		onSuccess: () => {
			openFeedback("Пароль успешно изменен", "success");
			setTimeout(() => {
				client.invalidateQueries("infoContractorSupport");
				closeConfirmation();
			}, 150);
		},
		onError: () => {
			openFeedback("Что-то пошло не так", "error");
		},
	});

   const openChangeCreds = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title="Изменение пароля"
					text={"Вы уверены, что хотите сгенерировать новый пароль для этого подрядчика?"}
				>
					<DeleteBtn
						text="Да, изменить"
						onClick={() => {
							mutateChangeCreds(userId);
							closeConfirmation();
							setTimeout(() => {
								client.invalidateQueries("contractors");
								client.invalidateQueries("infoContractorSupport");
							}, 150);
                     // setModal({opened:false})

						}}
					/>
					<CommonBtn text="Отменить" onClick={closeConfirmation} type="light" />
				</ConfirmModal>
			),
		}));
	};

   // close conformation modal
	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	return (
		<div className="hidden-info">
			<h3
				style={{
					fontSize: "16px",
					color: "#474F5A",
					fontWeight: "400",
				}}
			>
				Данные об аккаунте подрядчика
			</h3>
			<div className="items">
				<p className="gray">Логин:</p>
				<p className={hidden ? "blurred" : ""}>{login || "-"}</p>
			</div>
			<div className="items">
				<p className="gray">Пароль:</p>
				<p className={hidden ? "blurred" : ""}>{password || "-"}</p>
			</div>
			<p className="show-btn">
				<img onClick={switchHidden} src="../../icons/eye.svg" alt="" />
				<p onClick={switchHidden}>{hidden ? "Показать" : "Скрыть"}</p>
			</p>
			<p onClick={openChangeCreds} className="generate-creds">Сгенерировать новый логин</p>
		</div>
	);
}

export default HiddenContractorInfo;
