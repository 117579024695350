import React from "react";
import dayjs from "dayjs";
import SheetWidget from "./SheetWidget";
import useLanguage from "../../hooks/useLanguage";
import Loading from "../../components/utility/Loading";

interface IProps {
	daysInChosenMonth: number | undefined;
	month: string | null;
	year: string | null;
	workerData?: any;
	workerId: any;
	setDaySelected: React.Dispatch<React.SetStateAction<number | null>>;
	dayState: any;
	setCurrentDayStatus: React.Dispatch<
		React.SetStateAction<{
			id: string | number;
			name: string;
			workedHours: string;
			isAvailableToEdit: any;
		} | null>
	>;
	workerStatus: string | undefined;
}

function Sheet({
	daysInChosenMonth,
	month,
	year,
	workerData,
	workerId,
	setDaySelected,
	dayState,
	setCurrentDayStatus,
}: IProps) {
	const { L } = useLanguage();
	//creating an array of dates to map inputs on, [1,2,...,31]
	const datesArray: number[] = (() => {
		let arr: number[] = [];
		if (daysInChosenMonth) {
			for (let i = 1; i <= daysInChosenMonth; i++) {
				arr.push(i);
			}
			return arr;
		} else {
			return [];
		}
	})();

	const firstDayNumber = dayjs(`${year}-${month}-01`).get("d");
	let arr2 = [];
	if (firstDayNumber) {
		for (let i = 1; i < firstDayNumber; i++) {
			arr2.push(i);
		}
	}
	const blankHTMLItems: any[] = arr2.map((element) => {
		//elements to shift the beginning of the calendar
		return <div key={element - 999}></div>;
	});

	//formatting the date to DD format and setting it as state
	const minTwoDigits = (n: number) => {
		return (n < 10 ? "0" : "") + n.toString();
	};
	//! api stuff.

	const calendarForms = datesArray.map((date) => {
		if (workerData) {
			let a = workerData.days.filter((day: any) => day.dayWorkDate == date);
			if (a[0]) {
				return (
					<>
						<div className="calendar-form">
							<SheetWidget
								workerStatus={workerData?.workerStatusId}
								setCurrentDayStatus={setCurrentDayStatus}
								dayState={dayState}
								setDaySelected={setDaySelected}
								value={`${a[0]?.workedHours}ч., ${a[0].workDayStatus}`}
								workedHours={a[0].workedHours}
								workDayStatus={a[0].workDayStatus}
								workdayStatusId={a[0].workdayStatusId}
								hoursStatus={a[0].hoursStatus}
								isAvailableToEdit={a[0].isAvailableToEditHours}
								day={date}
								key={date}
								month={month}
								year={year}
								workerId={workerId}
								date={minTwoDigits(date)}
								dateNotFormatted={date}
							/>
						</div>
					</>
				);
			} else {
				return (
					<>
						<div className="calendar-form">
							{/* <input key={date} className="form-control" type="text" /> */}
							<SheetWidget
								workerStatus={workerData?.workerStatusId}
								isAvailableToEdit={a[0]?.isAvailableToEditHours}
								setCurrentDayStatus={setCurrentDayStatus}
								dayState={dayState}
								setDaySelected={setDaySelected}
								workerId={workerId}
								key={date}
								day={date}
								month={month}
								year={year}
								value={""}
								date={minTwoDigits(date)}
								dateNotFormatted={date}
							/>
						</div>
					</>
				);
			}
		} else {
			return (
				<>
					<div className="calendar-form">
						{/* <input key={date} className="form-control" type="text" /> */}
						<SheetWidget
							workerStatus={workerData?.workerStatusId}
							isAvailableToEdit={undefined}
							setCurrentDayStatus={setCurrentDayStatus}
							dayState={dayState}
							setDaySelected={setDaySelected}
							workerId={workerId}
							key={date}
							day={date}
							month={month}
							year={year}
							value={""}
							date={minTwoDigits(date)}
							dateNotFormatted={date}
						/>
					</div>
				</>
			);
		}
	});

	const calendarArr: any[] = [...blankHTMLItems, ...calendarForms];

	if (calendarArr[0] != undefined) {
		return (
			<>

				<div className="css-grid-container-week ">
					<p className="day-of-week">{L("ПН", "Mon")}</p>
					<p className="day-of-week">{L("ВТ", "Tue")}</p>
					<p className="day-of-week">{L("СР", "Wed")}</p>
					<p className="day-of-week">{L("ЧТ", "Thu")}</p>
					<p className="day-of-week">{L("ПТ", "Fri")}</p>
					<p className="dayoff day-of-week">{L("СБ", "Sat")}</p>
					<p className="dayoff weekday day-of-week">{L("ВС", "Sun")}</p>
					{calendarArr}
				</div>
			</>
		);
	} else {
		return (
			<>
				<Loading />
			</>
		)
	}
}

export default Sheet;
