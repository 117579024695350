import React from "react";
import MoreInfoItem from "../outlets/OutletInfo/MoreInfoItem";
import { shortenFullname } from "../../utils/shortenFullname";

interface IProps {
	payload?: {
		workerRequestId: number;
		position: string;
		customObjectId: string;
		objectId: number;
		objectName: string;
		objectResponsibleFullName: string;
		candidateId: number;
		candidateFullName: string;
		hourlyRate: number;
		phoneNumber: string;
		citizenship: string;
		passport: string;
		visitDate: string;
		candidateStatusId: number;
		address: string;
		candidateStatus: string;
		isAvailableToSetStatus: boolean;
		requestDeadline: string;
		contractorId: number;
		contractorName: string;
	};
}

function CandidateInfo(props: IProps) {
	const roleNotDirector = localStorage.getItem("role") != "Director";

	const data = props.payload;
	if (data) {
		return (
			<div className="more-info-container">
				<MoreInfoItem data={data.candidateStatus} name="Статус" />
				<MoreInfoItem data={data.workerRequestId} name="№ Заявки" />
				<MoreInfoItem
					data={shortenFullname(data.candidateFullName)}
					name="Имя"
				/>
				<MoreInfoItem data={data.hourlyRate} name="Ставка без НДС" />
				<MoreInfoItem data={data.position} name="Должность" />
				{roleNotDirector && (
					<MoreInfoItem data={data.objectName} name="Филиал" />
				)}
				{roleNotDirector && <MoreInfoItem data={"Добавить"} name="Регион" />}
				{roleNotDirector && <MoreInfoItem data={"Добавить"} name="Город" />}
				{roleNotDirector && <MoreInfoItem data={data.address} name="Адрес" />}
				<MoreInfoItem data={data.phoneNumber} name="Телефон" />
				<MoreInfoItem
					data={data.requestDeadline ? data.requestDeadline : ""}
					name="Дедлайн"
				/>
				<MoreInfoItem
					data={data.visitDate ? data.visitDate.slice(0, 10) : ""}
					name="Дата визита"
				/>
				<MoreInfoItem
					data={data.visitDate ? data.visitDate.slice(0, 10) : ""}
					name="Дата визита"
				/>
				<MoreInfoItem data={data.citizenship} name="Гражданство" />
				<MoreInfoItem data={data.passport} name="Паспорт" />
			</div>
		);
	}
	return <></>;
}

export default CandidateInfo;
