import React from "react";
import Loading from "../../../../components/utility/Loading";
import { useMutation, useQueryClient } from "react-query";
import { postConfirmHoursBtns } from "../../../../api/workerHoursBtnsV0";
import useFeedback from "../../../../hooks/useFeedback";
import useLanguage from "../../../../hooks/useLanguage";
interface IProps {
	added: any[];
	uncheckAll: () => void;
	actionName: string | undefined;
	id: number | undefined;
	positive: boolean | undefined;
	datestart?: string | undefined;
	dateend?: string | undefined;
	queryName: string;
}
function HoursBtn({
	uncheckAll,
	added,
	actionName,
	id,
	positive,
	datestart,
	dateend,
	queryName,
}: IProps) {
	const { L } = useLanguage();
	const shouldBeActive = added.length > 0;
	const { openFeedback, closeFeedback } = useFeedback();
	const client = useQueryClient();

	// post
	const { mutate } = useMutation(postConfirmHoursBtns, {
		onSuccess: () => {
			openFeedback(L("Сохранено!", "Saved!"), "success");
			uncheckAll();
			setTimeout(() => {
				client.invalidateQueries(queryName);
				client.invalidateQueries("employeesContractors");
				client.invalidateQueries("employees");
				client.invalidateQueries("notificationsAll");
			}, 150);

			setTimeout(() => {
				client.invalidateQueries(queryName);
				client.invalidateQueries("employeesContractors");
				client.invalidateQueries("employees");
				client.invalidateQueries("notificationsAll");
			}, 300);
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	// post handler
	const handlePost = () => {
		if (!shouldBeActive) return;
		if (datestart && dateend && id) {
			mutate({
				startWorkDate: datestart,
				endWorkDate: dateend,
				hoursStatusId: id,
				workerIds: added,
			});
		}
	};

	// classes
	const bgColor = () => {
		if (positive === undefined) return "";
		if (positive) return "positive";
		if (!positive) return "negative";
	};
	const bgOpacity = () => {
		if (added === undefined) return "";
		if (shouldBeActive) return "active";
		if (!shouldBeActive) return "unactive";
	};

	return (
		<div
			onClick={handlePost}
			className={`hrs-confirm-btn ${bgColor()} ${bgOpacity()}`}
		>
			<p>{actionName || L("загрузка...", "Loading...")}</p>
		</div>
	);
}

export default HoursBtn;
