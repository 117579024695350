import { useQuery, useMutation } from "react-query";
import { getEmployeesRequests, refreshWorkerRequest } from "../api/employees";
import Loading from "../components/utility/Loading";
import TablePagination from "../components/utility/TablePagination";
import { useState } from "react";
import EmployeeRequestFilters from "./EmployeeRequestFilters";
import { deleteWorkerRequest } from "../api/employees";
import SortArrow from "../components/utility/SortArrow";
import TableTotalCount from "../components/utility/TableTotalCount";
import ModalMain from "../components/utility/ModalMain/ModalMain";
import EmployeesRequestsInfo from "./employeesRequestsInfo/EmployeesRequestsInfo";
import EmployeeRequestsCandidate from "./employeesRequestsInfo/EmployeeRequestsCandidate";
import IconInputFilter from "../components/utility/IconInputFilter";
import CommonBtn from "../components/utility/Buttons/CommonBtn";
import FindEmployee from "./FindEmployee";
import useLanguage from "../hooks/useLanguage";

function EmployeesRequests() {
	const { L } = useLanguage();

	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		index: "",
		region: "",
		city: "",
		outlet: "",
		address: "",
		// objectId: "",
		job: "",
		requestStatus: "",
		createdBy: "",
		StartCreatedOn: "",
		EndCreatedOn: "",
		UnderChainManagerId: "",
		workerRequestId: "", // must be integer
	});
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc
	const { data, isSuccess, refetch } = useQuery(
		["employeesRequests", { pageNumber: page }, filters, sort],
		(q) => getEmployeesRequests(q),
		{
			enabled: true,
			retry: 2,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 5000,
			refetchInterval: 10000,
		}
	);

	//* modals
	// filters
	const [modalFilters, setModalFilters] = useState<{
		opened: boolean;
	}>({
		opened: false,
	});
	// create
	const [modalCreateNew, setModalCreateNew] = useState({
		opened: false,
	});

	// info
	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		confirmOpened?: boolean;
		secondaryOpened?: boolean;
	}>({
		opened: false,
		payload: null,
		confirmOpened: false,
		secondaryOpened: false,
	});

	let headings = [
		// { name: "ID" },
		{ name: L("№ Заявки", "Request ID"), colname: "workerRequestId" },
		{ name: L("Должность", "Job title") },
		{ name: L("ID филиала", "Outlet ID") },
		{ name: L("Филиал", "Outlet") },
		{ name: L("Локация", "Location") },
		{ name: L("Статус заявки", "Request status") },

		{
			name: L("Активные кандидаты", "Active candidates"),
			colname: "numOfCandidates",
		},
		{
			name: L("Всего кандидатов", "Total candidates"),
			colname: "numOfTotalCandidates",
		},
		{ name: L("График", "Schedule") },
		{ name: L("Пол", "Sex") },
		// { name: "Восстановить" },
		// { name: "Редакт." },
		// { name: "Ген. Кан." },
	];

	if (localStorage.getItem("role") == "Director") {
		headings = [
			// { name: "ID" },
			{ name: L("№ Заявки", "Request ID"), colname: "workerRequestId" },
			{ name: L("Должность", "Job title") },
			{ name: L("Статус заявки", "Request status") },

			{
				name: L("Активные кандидаты", "Active candidates"),
				colname: "numOfCandidates",
			},
			{
				name: L("Всего кандидатов", "Total candidates"),
				colname: "numOfTotalCandidates",
			},

			{ name: L("График", "Schedule") },
			{ name: L("Пол", "Sex") },
			// { name: "Удалить" },
			// { name: "Восстановить" },
			// { name: "Ген. Кан." },
		];
	}

	const columns = data?.data?.workerRequests?.map((col) => {
		return {
			workerRequestId: col.workerRequestId,
			createdByFullName: col.createdByFullName,
			region: col.region,
			city: col.city,
			position: col.position,
			numOfCandidates: col.numOfCandidates,
			objectName: col.objectName,
			sex: col.sex,
			schedule: col.schedule,
			requestStatus: col.requestStatus,
			workStartDate: col.workStartDate,
			workEndDate: col.workEndDate,
			createdOn: col.createdOn,
			isAvailableToRefreshRequest: col.isAvailableToRefreshRequest,
			isAvailableToConfirmRequest: col.isAvailableToConfirmRequest,
			isAvailableToDeclineRequest: col.isAvailableToDeclineRequest,
			isAvailableToGenerateCandidate: col.isAvailableToGenerateCandidate,
			isAvailableToDeleteRequest: col.isAvailableToDeleteRequest,
			customObjectId: col.customObjectId,
			address: col.address,
			numOfTotalCandidates: col.numOfTotalCandidates,
			comment: col.comment,
		};
	});

	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};

	const roleNotDirector = localStorage.getItem("role") != "Director";

	// refresh workerRequest

	const { mutate } = useMutation(refreshWorkerRequest);
	const refreshRequest = (workerRequestId: string | number) => {
		mutate(workerRequestId);
		setTimeout(() => {
			refetch();
		}, 500);
	};

	//delete workerRequest

	const { mutate: remove } = useMutation(deleteWorkerRequest);
	const deleteRequest = (workerRequestId: string | number) => {
		remove(workerRequestId);
		setTimeout(() => {
			refetch();
		}, 500);
	};
	return (
		<div className="container-fluid ">
			<div className="upper-bar">
				<div className="upper-bar-first">
					<IconInputFilter
						setFilters={setFilters}
						placeholder={L("Искать заявку", "Search request")}
					/>
					<div
						onClick={() => setModalFilters({ opened: true })}
						className="filter-btn"
					>
						<img
							src="../../icons/setting-5.svg"
							alt=""
							width="20px"
							height="20px"
						/>
						<p>{L("Фильтры", "Filters")}</p>
					</div>

					{
						<TableTotalCount
							name={L("Заявок", "Requests")}
							headers={data?.headers["x-pagination"]}
						/>
					}
				</div>
				<div className="upper-bar-second">
					<CommonBtn
						onClick={() =>
							setModalCreateNew({
								opened: true,
							})
						}
						text=""
					>
						<img src="../../icons/add.svg" alt="powerpoint" />
						<p>{L("Найти сотрудника", "Find employee")}</p>
					</CommonBtn>
				</div>
			</div>

			<ModalMain
				opened={modalFilters.opened}
				title={L("Фильтры", "Filters")}
				setModal={setModalFilters}
			>
				<EmployeeRequestFilters setFilters={setFilters} filters={filters} />
			</ModalMain>
			<ModalMain
				title={L("Найти сотрудника", "Find employee")}
				setModal={setModalCreateNew}
				opened={modalCreateNew.opened}
			>
				<FindEmployee setModal={setModalCreateNew} />
			</ModalMain>
			<ModalMain
				setModal={setModalInfo}
				opened={modalInfo.opened}
				title={L("Информация о заявке", "Request information")}
				modal={modalInfo}
				secondary={() => (
					<EmployeeRequestsCandidate
						setModal={setModalInfo}
						payload={modalInfo?.payload}
					/>
				)}
				secondaryOpened={modalInfo.secondaryOpened || false}
			>
				<EmployeesRequestsInfo
					payload={modalInfo.payload}
					setModal={setModalInfo}
				/>
			</ModalMain>
			{isSuccess ? (
				<>
					<div className="table-container">
						<table cellSpacing={0} className="my-table">
							<thead>
								<tr>
									{headings.map((heading) => (
										<th>
											<div className="d-flex align-items-end ">
												<div>{heading.name}</div>
												{heading.colname && (
													<div className="">
														<SortArrow
															sort={sort}
															heading={heading}
															setSort={setSort}
														/>
													</div>
												)}
											</div>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{columns &&
									columns.map((col) => {
										let workStartDate = col?.workStartDate
											? col.workStartDate
													.replace("T", " ")
													.replace("-", ".")
													.replace("-", ".")
													.slice(0, -8)
											: "";

										let workEndDate = col?.workEndDate
											? col.workEndDate
													.replace("T", " ")
													.replace("-", ".")
													.replace("-", ".")
													.slice(0, -8)
											: "";

										let createdOn = col?.createdOn
											? col.createdOn
													.replace("T", " ")
													.replace("-", ".")
													.replace("-", ".")
													.slice(0, 10)
											: "";

										let sex = null;
										if (col.sex == true) {
											sex = L("М", "M");
										} else if (col.sex == false) {
											sex = L("Ж", "F");
										} else {
											sex = "";
										}

										let cantBeChanged =
											!col.isAvailableToDeleteRequest &&
											!col.isAvailableToRefreshRequest;

										return (
											<tr
												className={`${col.numOfCandidates > 0 && "active"}`}
												key={col.workerRequestId}
												onClick={() => {
													setModalInfo({
														opened: true,
														payload: {
															col,
														},
													});
												}}
											>
												<td className="size-item">{col.workerRequestId}</td>
												<td>{col.position}</td>
												{roleNotDirector && <td>{col.customObjectId}</td>}
												{roleNotDirector && <td>{col.objectName}</td>}
												{roleNotDirector && (
													<td>
														{col.address && (
															<div
																title={`${col.region}, ${col.city}, ${col.address}`}
																className="with-icon"
															>
																<img src="../../icons/location.svg" alt="" />
																<p>{`${col.region}, ${
																	col.city
																},  ${col.address.slice(0, 30)}...`}</p>
															</div>
														)}
													</td>
												)}

												<td>{col.requestStatus}</td>
												<td>{col.numOfCandidates}</td>
												<td>{col.numOfTotalCandidates}</td>
												<td>{col.schedule}</td>
												<td>{sex}</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default EmployeesRequests;
