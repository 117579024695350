import { useQuery } from "react-query";
import InputFilter from "../utility/InputFilter";
import { createArrayOptions } from "../../utils/createArrayOptionsFromObj";
import "../../styles/filters/filters.css";
import { getCandidates } from "../../api/candidates";
import FilterAutocomplete from "../utility/FilterAutocomplete";
interface IProps {
	filters?: any;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
}
function CandidatesFilters({ setFilters, filters }: IProps) {
	const { data, isSuccess } = useQuery(
		["candidatesRequestsFilters", { pageNumber: 1 }, filters],
		getCandidates
	);

	const regions = createArrayOptions(data?.data?.filterValues?.regions);
	const cities = createArrayOptions(data?.data?.filterValues?.cities);
	const objectNames = createArrayOptions(data?.data?.filterValues?.objectNames);
	const jobTitles = createArrayOptions(data?.data?.filterValues?.jobTitles);
	// const contractors = createArrayOptions(data?.data.filterValues.)
	const workerStatuses = createArrayOptions(
		data?.data?.filterValues?.workerStatuses
	);
	const contractors = createArrayOptions(data?.data?.filterValues?.contractors);

	const chosenFilters = data?.data?.chosenFilters;

	let checkRegion = regions && localStorage.getItem("role") != "Director";
	let checkCity = cities && localStorage.getItem("role") != "Director";
	return (
		<div className="">
			<InputFilter
				filterField="workerRequestId"
				setFilters={setFilters}
				placeholder={"Пример: 918267"}
				type={"text"}
				label="Номер заявки"
			/>

			{workerStatuses && (
				<FilterAutocomplete
					setFilters={setFilters}
					placeholder="Пример: Кандидат принят на стажировку"
					label="Статус кандидата"
					filterName="WorkerStatusId"
					options={data?.data?.filterValues?.workerStatuses}
					selectedValue={chosenFilters?.workerStatuses?.value}
				/>
			)}

			<InputFilter
				filterField="StartCreatedOn"
				setFilters={setFilters}
				placeholder={"Создан от"}
				label="Дата собеседования от"
				type={"date"}
			/>

			<InputFilter
				filterField="EndCreatedOn"
				setFilters={setFilters}
				placeholder={"Создан до"}
				label="Дата собеседования до"
				type={"date"}
			/>

			{jobTitles && (
				<FilterAutocomplete
					setFilters={setFilters}
					placeholder="Пример: Кассир"
					label="Позиция"
					filterName="job"
					options={data?.data?.filterValues?.jobTitles}
					selectedValue={chosenFilters?.jobTitles?.value}
				/>
			)}
			{contractors && (
				<FilterAutocomplete
					setFilters={setFilters}
					placeholder="Пример: Подрядчик 1"
					label="Подрядчик"
					filterName="ContractorId"
					options={data?.data?.filterValues?.contractors}
					selectedValue={chosenFilters?.contractors?.value}
				/>
			)}

			{checkRegion && (
				<FilterAutocomplete
					setFilters={setFilters}
					placeholder="Пример: Омская область"
					label="Регион"
					filterName="region"
					options={data?.data?.filterValues?.regions}
					selectedValue={chosenFilters?.regions?.value}
				/>
			)}

			{checkCity && (
				<FilterAutocomplete
					setFilters={setFilters}
					placeholder="Пример: Омск"
					label="Город"
					filterName="city"
					options={data?.data?.filterValues?.cities}
					selectedValue={chosenFilters?.cities?.value}
				/>
			)}
			{objectNames && (
				<FilterAutocomplete
					setFilters={setFilters}
					placeholder="Пример: Продукты_224"
					label="Филиал"
					filterName="outlet"
					options={data?.data?.filterValues?.objectNames}
					selectedValue={chosenFilters?.objectNames?.value}
				/>
			)}
			<InputFilter
				filterField="CandidateFullName"
				setFilters={setFilters}
				placeholder={"Пример: Иван Иванович Шушпинов"}
				type={"text"}
				label="ФИО кандидата"
			/>
		</div>
	);
}

export default CandidatesFilters;
