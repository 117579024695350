import React, { useState } from "react";
import MoreInfoItem from "../../../outlets/OutletInfo/MoreInfoItem";
import { sexCheck } from "../../../../utils/sexCheck";
import DeleteBtn from "../../../utility/Buttons/DeleteBtn";
import ConfirmModal from "../../../utility/confirmModal/ConfirmModal";
import CommonBtn from "../../../utility/Buttons/CommonBtn";
import { useMutation, useQueryClient } from "react-query";
import useFeedback from "../../../../hooks/useFeedback";
import { deleteWorkerRequestS } from "../../../../api/for_support/support_worker_requests";
import EmployeeReqEditS from "./EmployeeReqEditS";

interface IProps {
	payload: {
		col: {
			workerRequestId: any;
			createdByFullName: any;
			region: any;
			city: string;
			position: any;
			numOfCandidates: any;
			objectName: any;
			sex: any;
			schedule: any;
			requestStatus: any;
			workStartDate: any;
			workEndDate: any;
			address: any;
			isAvailableToRefreshRequest: any;
			isAvailableToConfirmRequest: any;
			isAvailableToDeclineRequest: any;
			isAvailableToGenerateCandidate: any;
			isAvailableToDeleteRequest: any;
			customObjectId: any;
			createdOn: any;
			contractorName: any;
			comment: any;
		};
	};
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			confirmOpened?: boolean | undefined;
			secondaryOpened?: boolean | undefined;
		}>
	>;
}

function EmployeeReqInfoS({ payload, setModal }: IProps) {
	const { openFeedback, closeFeedback } = useFeedback();
	const client = useQueryClient();
	const roleNotDirector = localStorage.getItem("role") != "Director";

	const [active, setActive] = useState<"request" | "candidates" | "edit">(
		"request"
	);

	// close conformation modal
	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	// close modal
	const closeModal = () => {
		setModal((prev: any) => ({
			...prev,
			opened: false,
			confirmOpened: false,
		}));
	};

	const { mutate: mutateDelete } = useMutation(deleteWorkerRequestS, {
		onSuccess: () => {
			openFeedback("Заявка успешно снята", "success");
			closeModal();
			setTimeout(() => {
				client.invalidateQueries("employeRequestsSupport");
				// closeConfirmation();
			}, 150);
		},
		onError: () => {
			openFeedback("Что-то пошло не так", "error");
		},
	});

	// open for delete request
	const openDelete = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title="Удаление заявки"
					text={"Вы уверены, что хотите снять заявку?"}
				>
					<DeleteBtn
						text="Да, снять"
						onClick={() => {
							mutateDelete(payload.col.workerRequestId);
							closeConfirmation();
						}}
					/>
					<CommonBtn text="Отменить" onClick={closeConfirmation} type="light" />
				</ConfirmModal>
			),
		}));
	};

	if (active === "edit") {
		return (
			<EmployeeReqEditS
				setModal={setModal}
				workerRequestId={payload.col.workerRequestId}
			/>
		);
	}

	return (
		<>
			<div className="more-info-container scrolled-3">
				<MoreInfoItem name="№ Заявки" data={payload.col.workerRequestId} />
				{roleNotDirector && (
					<MoreInfoItem name="ID Филиала" data={payload.col.customObjectId} />
				)}
				{roleNotDirector && (
					<MoreInfoItem name="Филиал" data={payload.col.objectName} />
				)}
				{roleNotDirector && (
					<MoreInfoItem name="Регион" data={payload.col.region} />
				)}
				{roleNotDirector && (
					<MoreInfoItem name="Город" data={payload.col.city} />
				)}
				{roleNotDirector && (
					<MoreInfoItem name="Адрес" data={payload.col.address} />
				)}

				<MoreInfoItem
					name="Активные кандидаты"
					data={payload.col.numOfCandidates}
				/>

				<MoreInfoItem name="График" data={payload.col.schedule} />
				<MoreInfoItem name="Пол" data={sexCheck(payload.col.sex)} />
				<MoreInfoItem name="Должность" data={payload.col.position} />
				<MoreInfoItem
					name="Создана"
					data={payload.col.createdOn ? payload.col.createdOn.slice(0, 10) : ""}
				/>
				{roleNotDirector && (
					<MoreInfoItem name="Создавший" data={payload.col.createdByFullName} />
				)}
				<MoreInfoItem
					name="Начало работы"
					data={
						payload.col.workStartDate
							? payload.col.workStartDate.slice(0, 10)
							: ""
					}
				/>
				<MoreInfoItem
					name="Завершение работы"
					data={
						payload.col.workEndDate ? payload.col.workEndDate.slice(0, 10) : ""
					}
				/>
			</div>
			<div
				className="inline-styles"
				style={{
					display: "flex",
					justifyContent: "space-between",

					marginBottom: "15px",
				}}
			>
				{payload.col.isAvailableToDeleteRequest && (
					<DeleteBtn
						onClick={openDelete}
						styles={{ height: "20px", width: "183px", marginLeft: "-10px" }}
						text="Снять заявку"
						classes="btn-modal-left"
					/>
				)}
			</div>
			<div
				className="inline-styles"
				style={{
					display: "flex",
					justifyContent: "space-between",

					marginBottom: "0px",
				}}
			>
				<CommonBtn
					onClick={() => setActive("edit")}
					styles={{ width: "183px", marginRight: "-10px" }}
					text="Редактировать"
					classes="btn-modal-right"
				/>
			</div>
		</>
	);
}

export default EmployeeReqInfoS;
