import { useState, useEffect, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { createArrayOptions } from "../../utils/createArrayOptionsFromObj";

type FilterAutocompleteProps = {
	options: any;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
	filterName: string;
	selectedValue?: any;
	id?: string;
	placeholder?: string;
	defaultFilterValue?: string | number | null;
	label?: string;
	dropup?: boolean;
};

const FilterAutocomplete = ({
	options,
	setFilters,
	filterName,
	selectedValue,
	id,
	placeholder = "",
	defaultFilterValue = null,
	label,
	dropup,
}: FilterAutocompleteProps) => {
	const [isChosen, setIsChosen] = useState(false);
	const ref: any = useRef(null);

	// selected value cannot be number
	const returnSelected = (selectedValue: any) => {
		if (selectedValue) return [selectedValue.toString()];
		return [];
	};

	// show arrow down or clear btn
	useEffect(() => {
		if (ref.current.getInput().value) {
			// library is bugged, this is a crotch.
			setIsChosen(true);
		}
	}, [ref.current && ref.current.getInput().value, selectedValue]);

	return (
		<div className="filter-input">
			{label && <label className="filter-autocomplete-label">{label}</label>}
			<Typeahead
				dropup={dropup}
				selected={returnSelected(selectedValue)}
				ref={ref}
				placeholder={placeholder}
				labelKey={"value"}
				id={id}
				onChange={(optArr: any[]) => {
					if (optArr[0]) {
						setFilters((prev: any) => ({
							...prev,
							[`${filterName}`]: optArr[0].id,
						}));
					}
				}}
				options={createArrayOptions(options)}
				renderMenuItemChildren={(option: any) => (
					<div key={option.id}>
						<span>{option.value}</span>
					</div>
				)}
			>
				{isChosen ? (
					<div
						onClick={() => {
							ref.current.clear();
							setIsChosen(false);
							setFilters((prev: any) => ({
								...prev,
								[`${filterName}`]: defaultFilterValue,
							}));
						}}
						className="close-typeahead-filter"
					>
						<img src="../../icons/del.svg" alt="" />
					</div>
				) : (
					<div className="typeahead-down-arrow">
						<img width="20px" src="../../icons/input-arrow-down.svg" alt="" />
					</div>
				)}
			</Typeahead>
		</div>
	);
};

export default FilterAutocomplete;
