import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	candidateGetInfo,
	deleteCandidate,
	refreshCandidate,
} from "../../../../api/for_contractors/candidates_contractors";
import InfoSwitch from "../../../outlets/OutletInfo/InfoSwitch";
import CommonBtn from "../../../utility/Buttons/CommonBtn";
import MoreInfoItem from "../../../outlets/OutletInfo/MoreInfoItem";
import { sexCheck } from "../../../../utils/sexCheck";
import DeleteBtn from "../../../utility/Buttons/DeleteBtn";
import ConfirmModal from "../../../utility/confirmModal/ConfirmModal";
import useFeedback from "../../../../hooks/useFeedback";
import Loading from "../../../utility/Loading";
import { sliceString } from "../../../../utils/sliceString";
import useLanguage from "../../../../hooks/useLanguage";

interface IProps {
	candidateId: number;
	setActive: React.Dispatch<React.SetStateAction<"candidate" | "request">>;
	active: "candidate" | "request";
	setModal: any;
}

function CandidateContractors({
	candidateId,
	active,
	setActive,
	setModal,
}: IProps) {
	const { L } = useLanguage();

	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();

	const { data, isSuccess: isData } = useQuery(
		["candidatesGetInfo", candidateId],
		(q) => candidateGetInfo(q),
		{
			// refetchInterval: 20000,
			refetchOnWindowFocus: true,
		}
	);

	const isAvailableToEditCandidate = data?.data?.isAvailableToEditCandidate;
	const isAvailableToDeleteCandidate = data?.data?.isAvailableToDeleteCandidate;
	const isAvailableToRefreshCandidate =
		data?.data?.isAvailableToRefreshCandidate;

	const moreInfo = (
		<div className="more-info-container">
			<div style={{ marginTop: "16px" }} className="divider-sm"></div>
			<MoreInfoItem
				name={L("Статус", "Status")}
				data={data?.data?.candidateStatus}
			/>
			<MoreInfoItem
				name={L("Ставка с НДС", "Rate with tax")}
				data={data?.data?.hourlyRate}
			/>
			<MoreInfoItem
				name={L("Ставка с НДС", "Rate with tax")}
				data={data?.data?.hourlyRateVAT}
			/>

			<MoreInfoItem
				name={L("Кандидат", "Candidate")}
				data={`${data?.data?.lastName} ${data?.data.firstName} ${
					data?.data.middleName || ""
				}`}
			/>
			{/* <MoreInfoItem name="Должность" data={data?.data?.position} /> */}
			{/* <MoreInfoItem name="Компания" data={candidate.companyName} /> */}

			{/* <MoreInfoItem name="Филиал" data={data?.data?.objectName} /> */}
			{/* <MoreInfoItem name="Регион" data={candidate.region} /> */}
			{/* <MoreInfoItem name="Город" data={candidate.city} /> */}
			<MoreInfoItem
				name={L("Пол", "Sex")}
				data={data?.data.candidateSexString}
			/>
			<MoreInfoItem
				name={L("Дата рождения", "Birth date")}
				data={sliceString(data?.data.birthday)}
			/>
			<MoreInfoItem
				name={L("Телефон", "Phone")}
				data={data?.data?.phoneNumber}
			/>
			<MoreInfoItem
				name={L("Гражданство", "Citizenship")}
				data={data?.data?.citizenship}
			/>
			<MoreInfoItem
				name={L("Паспорт", "Passport")}
				data={data?.data?.passport}
			/>
			<MoreInfoItem
				name={L("Должность", "Job title")}
				data={data?.data?.position}
			/>
			<MoreInfoItem
				name={L("Дата визита", "Visit date")}
				data={data?.data?.visitDate ? data?.data?.visitDate.slice(0, 10) : ""}
			/>
			<MoreInfoItem
				name={L("Комментарий компании", "Company comment")}
				data={data?.data?.candidateComment}
			/>

			{/* <MoreInfoItem name="Адрес" data={candidate.address} /> */}

			{/* <MoreInfoItem
					name=" Комментарий компании"
					data={candidate.candidateComment}
				/> */}
		</div>
	);

	const notDeletableRefreshable =
		isAvailableToDeleteCandidate === false &&
		isAvailableToRefreshCandidate === false;
	// delete
	const { mutate: mutateDelete } = useMutation(deleteCandidate, {
		onSuccess: () => {
			openFeedback(
				L("Кандидат успешно снят", "Candidate has been removed"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("candidates");
				client.invalidateQueries("candidatesGetInfo");
			}, 200);

			setTimeout(() => {
				client.invalidateQueries("candidatesGetInfo");
			}, 400);
			setModal((prev: any) => ({
				...prev,
				secondaryOpened: false,
			}));
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	// refresh
	const { mutate: mutateRefresh } = useMutation(refreshCandidate, {
		onSuccess: () => {
			openFeedback(
				L("Кандидат успешно восстановлен", "Candidate has been restored"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("candidates");
				client.invalidateQueries("candidatesGetInfo");
			}, 200);
			setTimeout(() => {
				client.invalidateQueries("candidatesGetInfo");
			}, 400);
			setModal((prev: any) => ({
				...prev,
				secondaryOpened: false,
			}));
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	// Open Conformations
	const openDelete = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title="Снятие кандидата"
					text={L(
						"Вы уверены, что хотите снять кандидата?",
						"Are you sure you want to remove this candidate?"
					)}
				>
					<DeleteBtn
						text={L("Да, снять", "Yes, remove")}
						onClick={() => {
							mutateDelete(candidateId);
							closeConfirmation();
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	const openRefresh = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Восстановление кандидата", "Candidate restoration")}
					text={L(
						"Вы уверены, что хотите восстановить кандидата?",
						"Are you sure you want to restore this canditate?"
					)}
				>
					<CommonBtn
						text={L("Да, восстановить", "Yes, restore")}
						onClick={() => {
							mutateRefresh(candidateId);
							closeConfirmation();
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	// close conformation modal
	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	return (
		<div className="side-modal-container">
			<div className="center-container-2">
				<InfoSwitch
					options={["candidate", "request"]}
					optionsNames={L(["Кандидат", "Заявка"], ["Candidate", "Request"])}
					active={active}
					setActive={setActive}
				/>
				{isData ? moreInfo : <Loading type="inside" />}
			</div>
			<div className="bottom-container-2">
				<div className="flex">
					{/* delete */}
					{isAvailableToDeleteCandidate === true && (
						<DeleteBtn
							onClick={openDelete}
							text={L("Снять кандидата", "Remove candidate")}
							styles={
								isAvailableToEditCandidate
									? { width: "43%" }
									: { width: "100%" }
							}
						/>
					)}
					{/* refresh */}
					{isAvailableToRefreshCandidate && (
						<CommonBtn
							onClick={openRefresh}
							styles={
								!isAvailableToEditCandidate && {
									width: "100%",
								}
							}
							text={L("Восстановить кандидата", "Restore candidate")}
							type="light"
						/>
					)}
					{/* edit */}
					{isAvailableToEditCandidate && (
						<CommonBtn
							onClick={() => {
								setModal((prev: any) => ({
									...prev,
									secondaryOpened: true,
									payload: {
										...prev.payload,
										candidateId: candidateId,
									},
								}));
								setActive("request");
							}}
							styles={notDeletableRefreshable ? { width: "100%" } : {}}
							text={L("Изменить", "Edit")}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default CandidateContractors;
