import { createArrayOptions } from "../../utils/createArrayOptionsFromObj";
import InputFilter from "../utility/InputFilter";
import FilterAutocomplete from "../utility/FilterAutocomplete";
import { useEffect, useRef } from "react";
import CommonBtn from "../utility/Buttons/CommonBtn";
import useLanguage from "../../hooks/useLanguage";

interface IProps {
	filters: any;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
	data: any;
	isSuccess: any;
	datestart: any;
	setdatestart: any;
	dateend: any;
	setdateend: any;
	modalFilters: any;
}

const roleNotDirector = localStorage.getItem("role") != "Director";

function OutletsFilters({ setFilters, data, filters }: IProps) {
	const { L } = useLanguage();

	const regions = createArrayOptions(data?.data?.filterValues?.regions);
	const cities = createArrayOptions(data?.data?.filterValues?.cities);
	const objectNames = createArrayOptions(data?.data?.filterValues?.objectNames);
	const chosenFilters = data?.data?.chosenFilters;

	const objectResponsibleFullNameRef: any = useRef(null);
	const customIdRef: any = useRef();
	useEffect(() => {
		if (data) {
			if (filters.outlet) customIdRef.current.value = filters.outlet;
			if (filters.customObjectId)
				customIdRef.current.value = filters.customObjectId;

			if (filters.fio) objectResponsibleFullNameRef.current.value = filters.fio;
		}
	}, [data, filters]);

	// clear filters
	const clear = () => {
		customIdRef.current.value = "";
		objectResponsibleFullNameRef.current.value = "";
		setFilters({
			region: "",
			city: "",
			customObjectId: "",
			fio: "",
			searchInput: "",
		});
	};
	const roleNotUnder = localStorage.getItem("role") != "UnderChainManager";
	return (
		<div>
			<div style={{ marginBottom: "20px" }}>
				<CommonBtn
					onClick={clear}
					type="light"
					text={L("Очистить фильтры", "Clear filters")}
				/>
			</div>

			{roleNotUnder && (
				<FilterAutocomplete
					id="UnderChainManagerId"
					options={data?.data?.filterValues?.underChainManagers}
					setFilters={setFilters}
					filterName="UnderChainManagerId"
					selectedValue={chosenFilters?.underChainManagers?.value}
					placeholder={L("Пример: Иванов Иван Иванович", "Example: John Smith")}
					label={L("Ответственный менеджер", "Responsible manager")}
				/>
			)}

			{objectNames && (
				<FilterAutocomplete
					id="customObjId"
					options={data?.data?.filterValues?.objectNames}
					setFilters={setFilters}
					filterName="outlet"
					selectedValue={chosenFilters?.objectNames?.value}
					placeholder={L("Пример: Продукты", "Example: Grocery")}
					label={L("Название филиала", "Outlet name")}
				/>
			)}

			{regions && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="region"
					options={data?.data?.filterValues?.regions}
					id="region"
					selectedValue={chosenFilters?.regions?.value}
					label={L("Регион", "Region")}
					placeholder={L("Пример: Омская область", "Example: Scotland")}
				/>
			)}

			{cities && (
				<FilterAutocomplete
					options={data?.data?.filterValues?.cities}
					filterName="city"
					setFilters={setFilters}
					id="city"
					selectedValue={chosenFilters?.cities?.value}
					placeholder={L("Пример: Симферополь", "Example: Glasgow")}
					label={L("Город", "City")}
				/>
			)}

			<InputFilter
				ref={customIdRef}
				filterField="customObjectId"
				setFilters={setFilters}
				placeholder={L("Пример: 32242069", "Example: 32242069 ")}
				type={"text"}
				label={L("Идентификатор", "ID")}
			/>

			<InputFilter
				ref={objectResponsibleFullNameRef}
				label={L("Ответственный", "Responsible")}
				filterField="fio"
				setFilters={setFilters}
				placeholder={L("Пример: Иванов Иван Иванович", "Example: John Smith")}
				type={"text"}
			/>
		</div>
	);
}

export default OutletsFilters;
