export const BASEURL = `${process.env.REACT_APP_AUTH_URL}/api`;
export const BASEURLV0 = `${process.env.REACT_APP_AUTH_URL}/api/v0`;
export const BASEURLV1 = `${process.env.REACT_APP_AUTH_URL}/api/v1`;
export const BASEURLV2 = `${process.env.REACT_APP_AUTH_URL}/api/v2`;
export const BASEURLV3 = `${process.env.REACT_APP_AUTH_URL}/api/v3`;


// dev-catcher-it.ru
// Unsused ones
// export const KLADRURL = "https://kladr-api.ru/api.php?";
// export const KLADRKEY = "6A6kZiBz64srfTDh6QSh4ibTRAFGTEb7";
