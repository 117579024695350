interface ICommonBtn {
	text: string;
	styles?: any;
	onClick?: React.MouseEventHandler;
	classes?: string;
	type?: "light" | "common";
	children?: any;
	disabled?: boolean
}

function CommonBtn({
	text = "Текст",
	disabled = false,
	styles,
	onClick,
	classes,
	type = "common",
	children,
}: ICommonBtn) {
	return (
		<div
			onClick={!disabled ? onClick : () => {}}
			className={
				classes ? (disabled ? `common-btn ${type} ${classes} disabled` : `common-btn ${type} ${classes}`)  : (disabled ? `common-btn ${type} disabled` : `common-btn ${type}`)
			}
			style={styles}
		>
			{!children ? <p>{text}</p> : children}
		</div>
	);
}

export default CommonBtn;
