import { useState } from "react";
import { dlExample } from "../../../api/objects";
import "../../../styles/uploadInstructions/uploadInstructions.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import UploadRatesWidget from "./UploadRatesWidgets";
import { getRatesUploadTemplate } from "../../../api/rates";

function UploadRates() {
	const [isShowMore, setIsShowMore] = useState(false);

	return (
		<div className="upload-container">
			<div className="">
				<UploadRatesWidget />
			</div>
			<div className="instruction">
				<div
					className="example-download-container"
					onClick={getRatesUploadTemplate}
				>
					<img
						src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
						alt=""
						width="25px"
					/>
					<p>Скачать пример файла</p>
				</div>
			</div>
		</div>
	);
}

export default UploadRates;
