import React from "react";
import { useEffect, useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { createArrayOptions } from "../../../../utils/createArrayOptionsFromObj";
import { useNavigate } from "react-router";
import {
	getAddressesS,
	getAvaliableCompanies,
	getGeopositionsS,
	postWorkerRequestS,
} from "../../../../api/for_support/support_worker_requests";
import { getWorkerRequestCreationTemplateS } from "../../../../api/for_support/support_worker_requests";
import InputAutocomplete from "../../../../components/utility/InputAutocomplete";
import InputControlled from "../../../../components/utility/InputControlled";
import CommonBtn from "../../../utility/Buttons/CommonBtn";
import useFeedback from "../../../../hooks/useFeedback";
import SelectCustom from "../../../utility/SelectCustom";
import MultipleChoice from "../../../utility/MultipleChoice";

interface IProps {
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
		}>
	>;
}

function FindEmployeeS({ setModal }: IProps) {
	const { openFeedback, closeFeedback } = useFeedback();
	const navigate = useNavigate();
	const client = useQueryClient();

	const [isAddrDisabled, setIsAddrDisabled] = useState(true);
	const [isGeoDisabled, setIsGeoDisabled] = useState(true);
	const [isAllContractorsChosen, setIsAllContractorsChosen] = useState(false);
	const [info, setInfo] = useState<any>({
		companyId: null,
		address: null,
		objectId: null,
		jobTitleId: "",
		sex: null,
		contractorIds: [],
		workScheduleId: "",
		comment: "",
		workStartDate: "",
		workEndDate: null,
		cityId: null,
		deadline: null,
	});
	const addrRef: any = useRef(null);
	const geoRef: any = useRef(null);
	const objectNameRef: any = useRef(null);
	const [compreq, setcompreq] = useState(false);
	const [objreq, setobjreq] = useState(false);
	const [georeq, setgeoreq] = useState(false);
	const [addrreq, setaddrreq] = useState(false);
	const [jobreq, setjobreq] = useState(false);
	const [contractorreq, setcontractorreq] = useState(false);
	const [schreq, setschreq] = useState(false);
	const [startreq, setstartreq] = useState(false);
	// const [endtreq, setendreq] = useState(false);
	const contractorRef: any = useRef(null);

	// get avaliable companies query

	const { data: companies, isSuccess: isCompanies } = useQuery(
		"getAvaliableCompanies",
		getAvaliableCompanies
	);

	//query
	const { data } = useQuery(
		["employeeTemplate", info.companyId],
		getWorkerRequestCreationTemplateS
	);

	const { data: addresses, isSuccess: isAddresses } = useQuery(
		["getAddresses", info.companyId, info.objectId, info.cityId],
		(q) => getAddressesS(q),
		{
			enabled: !!info.cityId,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);

	// close modal func

	const close = () => {
		setModal((prev) => ({
			...prev,
			opened: false,
		}));
	};

	useEffect(() => {
		if (info.objectId) {
			setIsGeoDisabled(false);
		}
		if (info.cityId) {
			setIsAddrDisabled(false);
		}
		if (!info.cityId) {
			setIsAddrDisabled(true);
		}
	}, [info.objectId, info.cityId]);

	const { data: geo, isSuccess: isGeo } = useQuery(
		["getGeos", info.companyId, info.objectId],
		(q) => getGeopositionsS(q),
		{
			enabled: !!info.objectId,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);

	const addrArr = createArrayOptions(addresses?.data);
	const objectNames = createArrayOptions(data?.data.objectNames);
	const jobTitles = createArrayOptions(data?.data.jobTitles);
	const contractors = createArrayOptions(data?.data.contractors);
	const workSceduleTitles = createArrayOptions(data?.data.workSceduleTitles);

	//post
	const { data: res, mutate } = useMutation(postWorkerRequestS, {
		onSuccess: () => {
			openFeedback("Заявка на поиск успешно создана", "success");
			setTimeout(() => {
				client.invalidateQueries("employeesRequests");
			}, 150);
			close();
		},
		onError: () => {
			openFeedback("Проверьте, заполнены ли все поля", "error");
		},
	});

	const roleNotDirector = localStorage.getItem("role") != "Director";
	const sendRequest = () => {
		const whatIsSex = (sex: string | null) => {
			if (sex === "true") {
				return true;
			}
			if (sex === "false") {
				return false;
			}
			if (sex === "null") {
				return null;
			} else {
				return null;
			}
		};
		const realSex = whatIsSex(info.sex);
		mutate({
			...info,
			deadline: info.deadline ? info.deadline : null,
			workEndDate: info.workEndDate ? info.workEndDate : null,
			sex: realSex,
		});
		if (roleNotDirector) {
			if (!info.companyId) setcompreq(true);
			if (!info.objectId) setobjreq(true);
			if (!info.address) setaddrreq(true);
			if (!info.cityId) setgeoreq(true);
		}
		if (!info.jobTitleId) setjobreq(true);
		if (!info.workScheduleId) setschreq(true);
		if (!info.contractorIds[0]) setcontractorreq(true);
		if (!info.workStartDate) setstartreq(true);
		// if (!info.workEndDate) setendreq(true);

		if (info.companyId) setcompreq(false);
		if (info.objectId) setobjreq(false);
		if (info.cityId) setgeoreq(false);
		if (info.address) setaddrreq(false);
		if (info.jobTitleId) setjobreq(false);
		if (info.workScheduleId) setschreq(false);
		if (info.contractorIds[0]) setcontractorreq(false);
		if (info.workStartDate) setstartreq(false);
		// if (info.workEndDate) setendreq(false);
	};

	const newSexOptions = {
		null: "Без разницы",
		true: "Мужчины",
		false: "Женщины",
	};

	const objcheck =
		localStorage.getItem("role") != "Director" && objectNames != undefined;

	// role check
	// console.log(companies);
	// console.log(isAddresses);
	return (
		<section className="">
			<div className="form-inner">
				<div style={{ minHeight: "83vh" }}>
					<form className="">
						<InputAutocomplete
							data={companies?.data!}
							setState={setInfo}
							stateNames={["companyId"]}
							stateNamesToNull={["address", "cityId", "objectId"]}
							clearRefs={[addrRef, geoRef, objectNameRef]}
							id="companyId"
							label="Компания*"
							requiredState={compreq}
						/>

						{objcheck && (
							<InputAutocomplete
								ref={objectNameRef}
								data={data?.data.objectNames}
								setState={setInfo}
								stateNames={["objectId"]}
								stateNamesToNull={["address", "cityId"]}
								clearRefs={[addrRef, geoRef]}
								id="outletId"
								label="Филиал*"
								requiredState={objreq}
							/>
						)}

						{objcheck && (
							<>
								<InputAutocomplete
									ref={geoRef}
									id={"outletId"}
									data={geo?.data}
									stateNames={["cityId"]}
									stateNamesToNull={["address"]}
									setState={setInfo}
									clearRefs={[addrRef]}
									disabled={isGeoDisabled}
									label="Регион и город*"
									requiredState={georeq}
								/>
							</>
						)}
						{/* address */}

						{objcheck && (
							<InputAutocomplete
								data={addresses?.data}
								id="address"
								ref={addrRef}
								setState={setInfo}
								stateNames={["address", "objectId"]}
								label="Адрес*"
								disabled={isAddrDisabled}
								requiredState={addrreq}
							/>
						)}

						{jobTitles && (
							<InputAutocomplete
								data={data?.data.jobTitles}
								setState={setInfo}
								stateNames={["jobTitleId"]}
								id="job"
								requiredState={jobreq}
								label="Должность*"
							/>
						)}

						{data?.data.contractors && (
							<MultipleChoice
								data={data?.data.contractors}
								setState={setInfo}
								stateName="contractorIds"
								stateData={info.contractorIds}
								label="Подрядчики*"
								requiredState={contractorreq}
							/>
						)}

						{workSceduleTitles && (
							<SelectCustom
								styles={{ marginBottom: "16px" }}
								label="График работы*"
								data={data?.data.workSceduleTitles}
								setState={setInfo}
								stateName="workScheduleId"
								initial=""
								requiredState={schreq}
								arrowPosition="far-right"
							/>
						)}

						<SelectCustom
							styles={{ marginBottom: "16px" }}
							label="Пол"
							data={newSexOptions}
							setState={setInfo}
							stateName="sex"
							initial="Без разницы"
							arrowPosition="far-right"
						/>
						<InputControlled
							value={info.workStartDate}
							setState={setInfo}
							stateName="workStartDate"
							label="Начало работы*"
							type="date"
							requiredState={startreq}
						/>

						<div className="mt-2">
							<label className="form-label" htmlFor="directoremail">
								Окончание работы
							</label>
							<input
								onChange={(e) =>
									setInfo((prev: any) => ({
										...prev,
										workEndDate: e.target.value,
									}))
								}
								id="directoremail"
								type="date"
								className="form-control"
							/>
							{/* {endtreq && <p className="text-danger">обязательное поле</p>} */}
						</div>

						<div className="mt-2">
							<label className="form-label" htmlFor="deadline">
								Дедлайн подрядчика
							</label>
							<input
								onChange={(e) =>
									setInfo((prev: any) => ({
										...prev,
										deadline: e.target.value,
									}))
								}
								id="directoremail"
								type="date"
								className="form-control"
							/>
							{/* {endtreq && <p className="text-danger">обязательное поле</p>} */}
						</div>

						<InputControlled
							value={info.comment}
							setState={setInfo}
							stateName="comment"
							label="Комментарий к заявке"
						/>
					</form>
				</div>
			</div>
			<div
				style={{
					borderTop: "1px solid #f7f8fa",
					paddingTop: "20px",
					marginTop: "50px",
					marginBottom: "30px",
					display: "flex",
					justifyContent: "space-around",
					width: "100%",
				}}
				className="btns"
			>
				<CommonBtn
					styles={{ width: "175px" }}
					text="Отменить"
					type="light"
					onClick={close}
				/>
				<CommonBtn
					styles={{ width: "175px" }}
					text="Найти сотрудника"
					onClick={() => sendRequest()}
				/>
			</div>
		</section>
	);
}

export default FindEmployeeS;
