import React from "react";
import MoreInfoItem from "../../../components/outlets/OutletInfo/MoreInfoItem";
import { reverseFormatDate } from "../../../utils/formatDate";
import { AxiosResponse } from "axios";
import { IEmployees } from "../../../api/employees";
import { addThousandSeparator } from "../../../utils/thousandSeparator";
import useLanguage from "../../../hooks/useLanguage";

interface IProps {
	datestart: any;
	dateend: any;
	data: AxiosResponse<IEmployees, any> | undefined | any;
	noDateSplit?: boolean;
}
function GeneralInfo({ datestart, dateend, data, noDateSplit }: IProps) {
	const { L } = useLanguage();
	const roleNotDirector = localStorage.getItem("role") != "Director";
	const roleNotUnder = localStorage.getItem("role") != "UnderChainManager";

	return (
		<div className="more-info-container">
			{!noDateSplit && (
				<MoreInfoItem
					name={L("Выбранный период", "Chosen period")}
					data={`${reverseFormatDate(datestart)} – ${reverseFormatDate(
						dateend
					)}`}
				/>
			)}
			{noDateSplit && (
				<MoreInfoItem
					name={L("Выбранный период", "Chosen period")}
					data={datestart}
				/>
			)}

			<MoreInfoItem
				name={L("Часов не согласовано", "Hours not approved")}
				data={data?.data?.sumWorkedHoursNotApproved}
			/>
			<MoreInfoItem
				name={L("Часов на согласовании", "Hours on review")}
				data={data?.data?.totalWorkerStats?.sumWorkedHoursInReview}
			/>
			<MoreInfoItem
				name={L(
					"Часов подтвержденных подрядчиком",
					"Hours approved by contractor"
				)}
				data={data?.data?.totalWorkerStats?.sumWorkedHoursApprovedByContractor}
			/>
			<MoreInfoItem
				name={L("Часов подтвержденных менеджером", "Hours approved by manager")}
				data={
					data?.data?.totalWorkerStats?.sumWorkedHoursApprovedByChainManager
				}
			/>
			<MoreInfoItem
				styles={{ fontWeight: 500 }}
				name={L("Всего часов", "Total hours")}
				data={data?.data?.totalWorkerStats?.sumTotalWorkedHours}
			/>
			<div
				style={{
					marginTop: "40px",
					marginBottom: "30px",
				}}
			></div>
			<div className="divider-sm"></div>

			{roleNotDirector && roleNotUnder && (
				<MoreInfoItem
					name={L("Денег не согласовано", "Money not approved")}
					data={addThousandSeparator(
						data?.data?.totalWorkerStats?.sumCostsWithVatWorkedHoursNotApproved!
					)}
				/>
			)}
			{roleNotDirector && roleNotUnder && (
				<MoreInfoItem
					name={L("Денег на согласовании", "Money on review")}
					data={addThousandSeparator(
						data?.data?.totalWorkerStats?.sumCostsWithVatWorkedHoursInReview!
					)}
				/>
			)}
			{roleNotDirector && roleNotUnder && (
				<MoreInfoItem
					name={L(
						"Денег подтвержденных подрядчиком",
						"Money approved by contractor"
					)}
					data={addThousandSeparator(
						data?.data?.totalWorkerStats
							?.sumCostsWithVatWorkedHoursApprovedByContractor!
					)}
				/>
			)}
			{roleNotDirector && roleNotUnder && (
				<MoreInfoItem
					name={L(
						"Денег подтвержденных менеджером",
						"Money approved by manager"
					)}
					data={addThousandSeparator(
						data?.data?.totalWorkerStats
							?.sumCostsWithVatWorkedHoursApprovedByChainManager!
					)}
				/>
			)}
			{roleNotDirector && roleNotUnder && (
				<MoreInfoItem
					styles={{ fontWeight: 500 }}
					name={L("Всего денег", "Total money")}
					data={addThousandSeparator(
						data?.data?.totalWorkerStats?.sumCostsWithVat!
					)}
				/>
			)}
		</div>
	);
}

export default GeneralInfo;
