import { useEffect, useState } from "react";

const useLanguage = () => {
	const [lang, setLang] = useState("EN");
	useEffect(() => {
		setLang(localStorage.getItem("lang") || "EN");
	}, [localStorage.getItem("lang")]);

	const L = (RU: any, EN: any) => {
		if (lang === "RU") return RU;
		if (lang === "EN") return EN;
		throw new Error("LANGUAGE ERROR");
	};

	return { L };
};

export default useLanguage;
