import { getObject, getUnderChainManagers } from "../../api/objects";
import { useParams } from "react-router";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Loading from "../utility/Loading";
import { createArrayOptions } from "../../utils/createArrayOptionsFromObj";
import { getObjects } from "../../api/objects";
import { useState, useEffect } from "react";
import { changeObject } from "../../api/objects";
import { getGeopositions } from "../../api/objects";
import "../../styles/forms/FormNewEdit.css";
import CommonBtn from "../utility/Buttons/CommonBtn";
import InputAutocomplete from "../utility/InputAutocomplete";
import InputControlled from "../utility/InputControlled";
import useFeedback from "../../hooks/useFeedback";
import useLanguage from "../../hooks/useLanguage";

interface IOutletValues {
	region: string;
	city: string;
	address: string;
	email: string;
	isObjectActive: true | false;
	numberOfEmployees: number;
	objectName: string;
	objectResponsibleFullName: string;
	phoneNumber: string;
}

interface IProps {
	objectId: undefined | number | string;
	setModalInfo: any;
}

function Outlet(props: IProps) {
	const { L } = useLanguage();

	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();
	let geoPositions: any = [];
	const [edited, setEdited] = useState({
		cityId: "",
		address: "",
		email: "",
		numberOfEmployees: 0,
		firstName: "",
		lastName: "",
		middleName: "",
		phoneNumber: "",
		phoneNumberWithoutCode: "",
		objectName: "",
		customObjectId: "",
		objectId: "",
		cityName: "",
		reportedToId: "",
	});

	const [adrreq, setadrreq] = useState(false);
	const [cityreq, setcityreq] = useState(false);
	const [namereq, setnamereq] = useState(false);
	const [lastreq, setlastreq] = useState(false);
	const [objnamereq, setobjnamereq] = useState(false);

	const params = useParams();
	const id = params?.outletId?.toString();
	const { isSuccess, data } = useQuery(
		// getInfo
		"meow",
		() =>
			getObject({
				objectId: props.objectId,
			}),
		{
			refetchInterval: false,
			cacheTime: 0,
			retry: false,
		}
	);

	const outletInfo = data?.data;
	const { data: objects } = useQuery("outlets", getObjects);
	const { data: geoPositionsRaw } = useQuery("geoPositions", getGeopositions, {
		refetchInterval: false,
		cacheTime: 0,
		retry: false,
	});
	// if (isSuccess) {
	// 	console.log(outletInfo);
	// }
	// underchain query
	const { data: underManagersData } = useQuery(
		"getUnderChainManagers",
		getUnderChainManagers
	);
	// sending PUT

	const {
		mutate,
		isError,
		isSuccess: isS,
		error,
	} = useMutation<any, any, any>(changeObject, {
		onError: () => {
			openFeedback(
				L(
					"Ошибка, пожалуйста, проверьте, заполнены ли все поля",
					"Error, please, check input fields"
				),
				"error"
			);
		},
		onSuccess: () => {
			openFeedback(
				L("Филиал успешно изменен", "Outlet changed successfully"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("outlets");
			}, 400);
			props.setModalInfo(false);
		},
	});
	const change = () => {
		if (!edited.cityId) setcityreq(true);
		if (!edited.address) setadrreq(true);
		if (!edited.firstName) setnamereq(true);
		if (!edited.lastName) setlastreq(true);
		if (!edited.objectName) setobjnamereq(true);
		if (edited.phoneNumberWithoutCode) {
			mutate({
				...edited,
				phoneNumber: L("+7", "+44") + edited.phoneNumberWithoutCode,
			});
		} else {
			mutate(edited);
		}
	};
	useEffect(() => {
		setcityreq(false);
		setadrreq(false);
		setnamereq(false);
		setlastreq(false);
		setobjnamereq(false);
	}, [isS]);
	geoPositions = createArrayOptions(geoPositionsRaw?.data);

	if (isS) {
	}
	// setting initial state
	useEffect(() => {
		if (outletInfo) {
			setEdited((prev: any) => ({
				...prev,
				email: outletInfo.email,
			}));
			if (outletInfo.phoneNumber) {
				if (outletInfo.phoneNumber.slice(0, L(2, 3)) === L("+7", "+44")) {
					setEdited((prev: any) => ({
						...prev,
						phoneNumberWithoutCode: outletInfo.phoneNumber
							.toString()
							.slice(L(2, 3)),
					}));
				} else {
					setEdited((prev: any) => ({
						...prev,
						phoneNumberWithoutCode: outletInfo.phoneNumber.toString(),
					}));
				}
			}
			setEdited((prev: any) => ({
				...prev,
				numberOfEmployees: outletInfo.numberOfEmployees,
			}));
			//! i need this very much thanks
			setEdited((prev: any) => ({
				...prev,
				objectName: outletInfo.objectName,
			}));

			setEdited((prev: any) => ({
				...prev,
				customObjectId: outletInfo.customObjectId,
			}));
			if (outletInfo.firstName) {
				setEdited((prev: any) => ({
					...prev,
					firstName: outletInfo.firstName,
				}));
			}
			if (outletInfo.lastName) {
				setEdited((prev: any) => ({
					...prev,
					lastName: outletInfo.lastName,
				}));
			}

			if (outletInfo.middleName) {
				setEdited((prev: any) => ({
					...prev,
					middleName: outletInfo.middleName,
				}));
			}
			// try to fix city name and region
			if (outletInfo.city) {
				setEdited((prev: any) => ({
					...prev,
					cityName: `${outletInfo.city} (${outletInfo.region})`,
				}));
			}

			if (outletInfo.address) {
				setEdited((prev: any) => ({
					...prev,
					address: outletInfo.address,
				}));
			}
			if (outletInfo.cityId) {
				setEdited((prev: any) => ({
					...prev,
					cityId: outletInfo.cityId,
				}));
			}
			if (outletInfo.reportedToId) {
				setEdited((prev: any) => ({
					...prev,
					reportedToId: outletInfo.reportedToId,
				}));
			}
			// setEdited((prev: any) => ({
			// 	...prev,
			// 	objectId: outletInfo.objectId,
			// }));
		}
		if (outletInfo?.objectName) {
			setEdited((prev: any) => ({
				...prev,
				objectName: outletInfo.objectName,
			}));
		}
	}, [outletInfo]);

	const outletAndGeoCheck = outletInfo && geoPositions;
	useEffect(() => {
		if (props.objectId) {
			setEdited((prev: any) => ({
				...prev,
				objectId: props?.objectId?.toString(),
			}));
		}
	}, [geoPositionsRaw?.data]);
	useEffect(() => {
		if (outletAndGeoCheck && outletInfo.cityId) {
			if (outletInfo.cityId) {
				// tslint:disable-next-line
				// add if cityId -1 check ?
				if (createArrayOptions(geoPositionsRaw?.data)[outletInfo.cityId - 1]) {
					const trueCityId = createArrayOptions(geoPositionsRaw?.data)[
						outletInfo.cityId - 1
					].id;
					setEdited((prev: any) => ({
						...prev,
						cityId: trueCityId,
					}));
				}
			}
		}
	}, [outletInfo, geoPositionsRaw?.data]);

	const cityPlaceholder = (cityId: number) => {
		if (outletAndGeoCheck) {
			if (geoPositions[outletInfo["cityId"] - 1]) {
				return geoPositions[outletInfo.cityId - 1].value as any;
			} else {
				return "";
			}
		}
	};

	// console.log(cityPlaceholder(outletInfo?.cityId));
	const roleNotUnder = localStorage.getItem("role") != "UnderChainManager";

	if (isSuccess && outletInfo) {
		return (
			<div className="form-inner">
				<form className="">
					<div id="why" className="outlet-create-no-scroll">
						<div className="mt-2">
							<label className="form-label" htmlFor="firstName">
								{L("Идентификатор", "ID")}
							</label>
							<input
								value={edited.customObjectId}
								onChange={(e: any) =>
									setEdited((prev: any) => ({
										...prev,
										customObjectId: e.target.value,
									}))
								}
								type="text"
								id="firstName"
								className="form-control"
							/>
						</div>

						{edited?.cityName !== "" && (
							<InputAutocomplete
								defaultInputVal={edited?.cityName}
								data={geoPositionsRaw?.data}
								id="idk"
								setState={setEdited}
								stateNames={["cityId"]}
								label={L("Регион и город*", "Region and city*")}
							/>
						)}

						<InputControlled
							value={edited.address}
							setState={setEdited}
							stateName="address"
							label={L("Адрес*", "Address*")}
							requiredState={adrreq}
						/>

						<InputControlled
							value={edited.objectName}
							setState={setEdited}
							stateName="objectName"
							requiredState={objnamereq}
							label={L("Название филиала*", "Outlet name*")}
							placeholder={L("Пример: Продукты", "Example: Grocery")}
						/>

						{outletInfo && roleNotUnder && (
							<InputAutocomplete
								id="underChainManagers"
								data={underManagersData?.data}
								setState={setEdited}
								stateNames={["reportedToId"]}
								label={L("Менеджер сети", "Manager")}
								defaultInputVal={outletInfo?.underChainManagerFullName || ""} // may give error! Check it
							/>
						)}

						<InputControlled
							value={edited.firstName}
							setState={setEdited}
							stateName="firstName"
							placeholder={L("Пример:Иван", "Example: John")}
							requiredState={namereq}
							label={L("Имя директора*", "Director's name*")}
						/>

						<InputControlled
							value={edited.lastName}
							setState={setEdited}
							stateName="lastName"
							placeholder={L("пример: Иванов", "Example:  Smith")}
							requiredState={lastreq}
							label={L("Фамилия директора*", "Director's lastname*")}
						/>

						<InputControlled
							value={edited.middleName}
							setState={setEdited}
							stateName="middleName"
							placeholder={L("Пример: Иванович", "Example:  William")}
							label={L("Отчество директора", "Director's middlename")}
						/>

						<InputControlled
							value={edited.email}
							setState={setEdited}
							stateName="email"
							requiredState={error?.response?.status === 410}
							errorMessage={error?.response?.data?.error}
							label="E-mail"
						/>

						<div style={{ marginBottom: "2rem" }} className="mt-2">
							<label className="form-label" htmlFor="phoneNumber">
								{L("Телефон", "Phone")}
							</label>
							<div
								className={L("phonefield-container", "phonefield-container-gb")}
							>
								<input
									value={edited.phoneNumberWithoutCode}
									onChange={(e: any) =>
										setEdited((prev: any) => ({
											...prev,
											phoneNumberWithoutCode: e.target.value.toString(),
										}))
									}
									type="text"
									id="phoneNumber"
									className="phonefield form-control"
								/>
							</div>
						</div>
					</div>
				</form>

				<CommonBtn
					onClick={() => change()}
					classes="btn-modal-right"
					text={L("Сохранить", "Save")}
				/>
			</div>
		);
	} else {
		return <Loading />;
	}
}

export default Outlet;
