import { useEffect, useState } from "react";
import OutletsFilters from "./OutletsFilters";
import { useMutation, useQuery } from "react-query";
import { deleteObjectsMultiple, dlObject, getObjects } from "../../api/objects";
import Loading from "../utility/Loading";
import TablePagination from "../utility/TablePagination";
import { useNavigate } from "react-router-dom";
import SortArrow from "../utility/SortArrow";
import ModalMain from "../utility/ModalMain/ModalMain";
import UploadOutlets from "./UploadOutlets";
import useTableCheck from "../../hooks/useTableCheck";
import CreateOutlet from "./CreateOutlet";
import TableTotalCount from "../utility/TableTotalCount";
import DeleteBtn from "../utility/Buttons/DeleteBtn";
import UncheckAllBtn from "../utility/Buttons/UncheckAllBtn";
import OutletInfo from "./OutletInfo/OutletInfo";
import CommonBtn from "../utility/Buttons/CommonBtn";
import IconInputFilter from "../utility/IconInputFilter";
import useFeedback from "../../hooks/useFeedback";
import useLanguage from "../../hooks/useLanguage";
function Outlets() {
	const { L } = useLanguage();

	const navigate = useNavigate();
	if (!localStorage.getItem("token")) {
		navigate("/login");
	}
	const { openFeedback, closeFeedback } = useFeedback();
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		region: "",
		city: "",
		customObjectId: "",
		fio: "",
		searchInput: "",
		UnderChainManagerId: "",
	});
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc
	const [datestart, setdatestart] = useState("");
	const [dateend, setdateend] = useState("");
	//* modals
	const [modalUploadObjects, setModalUploadObjects] = useState<{
		opened: boolean;
		payload?: any;
	}>({
		opened: false,
		payload: null,
	});

	const [modalNew, setModalNew] = useState<{
		opened: boolean;
		payload?: any;
	}>({
		opened: false,
		payload: null,
	});

	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		confirmOpened?: boolean;
	}>({
		opened: false,
		payload: null,
		confirmOpened: false,
	});

	const [modalFilters, setModalFilters] = useState<{
		opened: boolean;
	}>({
		opened: false,
	});

	const [modalCreateNew, setModalCreateNew] = useState({
		opened: false,
	});
	//* /modals
	const {
		isSuccess,
		data: objects,
		refetch,
	} = useQuery(
		["outlets", { pageNumber: page }, filters, sort],

		(q) => getObjects(q),

		{
			enabled: true,
			retry: 2,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 5000,
		}
	);
	// deletion
	const { mutate } = useMutation(deleteObjectsMultiple, {
		onSuccess: () => {
			uncheckAll();
			openFeedback(
				L("Филиалы успешно удалены", "Outlets are deleted successfully"),
				"success"
			);
			setTimeout(() => {
				refetch();
			}, 400);
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	//!check stuff
	const idsArray = objects?.data.objects.map((el) => el.objectId) || [];
	const {
		addChecked,
		switchCheckedAll,
		getChecked,
		checked,
		added, // this is passed to delete multiple
		isAllChecked,
		total,
		uncheckAll,
	} = useTableCheck({
		ids: idsArray,
	});

	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};

	const headings: any = [
		{ name: L("ID филиала", "Outlet ID") },
		{ name: L("Название филиала", "Outlet name") },
		{ name: L("Локация", "Location") },
		{ name: L("Ответственный", "Responsible") },
		// { name: "Город", colname: "city" },
		// { name: "Регион", colname: "region" },
		// { name: "Создан", colname: "createdOn" },
		// { name: "E-mail" },
		// { name: "Инфо ответственного" },
		// { name: "Телефон" },
		// { name: "Редактировать" },
	];

	const columns = objects?.data?.objects?.map((object) => {
		return {
			userId: object.userId,
			objectId: object.objectId,
			region: object.region,
			city: object.city,
			address: object.address,
			createdOn: object.createdOn,
			email: object.email,
			isObjectActive: object.isObjectActive,
			numberOfEmployees: object.numberOfEmployees,
			objectName: object.objectName,
			objectResponsibleFullName: object.objectResponsibleFullName,
			phoneNumber: object.phoneNumber,
			customObjectId: object.customObjectId,
			created: object.createdOn,
			underChainManagerFullName: object.underChainManagerFullName,
		};
	});
	return (
		<>
			<div className="container-fluid">
				{modalUploadObjects.opened && (
					<ModalMain
						title={L("Загрузка филиалов", "Upload outlets")}
						setModal={setModalUploadObjects}
						opened={modalUploadObjects.opened}
					>
						<UploadOutlets />
						<div className="form-divider"></div>

						<CommonBtn
							text={L("Отменить", "Cancel")}
							type="light"
							onClick={() => setModalUploadObjects({ opened: false })}
							classes="btn-modal-left"
						></CommonBtn>
					</ModalMain>
				)}
				{modalNew && (
					<ModalMain
						title={L("Создание филиалов", "Outlets creation")}
						setModal={setModalNew}
						opened={modalNew.opened}
					>
						<CreateOutlet setModalCreateNew={setModalCreateNew} />
					</ModalMain>
				)}
				{modalInfo && (
					<ModalMain
						modal={modalInfo}
						setModal={setModalInfo}
						title={L("Информация об объекте", "Outlet information")}
						opened={modalInfo.opened}
					>
						<OutletInfo
							payload={modalInfo.payload}
							setModalInfo={setModalInfo}
						/>
					</ModalMain>
				)}

				<ModalMain
					setModal={setModalFilters}
					title={L("Фильтры", "Filters")}
					opened={modalFilters.opened}
				>
					<OutletsFilters
						modalFilters={modalFilters}
						datestart={datestart}
						dateend={dateend}
						setdatestart={setdatestart}
						setdateend={setdateend}
						setFilters={setFilters}
						filters={filters}
						data={objects}
						isSuccess={isSuccess}
					/>
				</ModalMain>
				<ModalMain
					setModal={setModalCreateNew}
					opened={modalCreateNew.opened}
					title={L("Создание филиала", "Create outlet")}
				>
					<CreateOutlet setModalCreateNew={setModalCreateNew} />
				</ModalMain>
				<div className="upper-bar">
					{total === 0 ? (
						<>
							<div className="upper-bar-first">
								<IconInputFilter
									setFilters={setFilters}
									placeholder={L("Искать филиал", "Search outlet")}
								/>
								<div
									onClick={() => setModalFilters({ opened: true })}
									className="filter-btn"
								>
									<img
										src="../../icons/setting-5.svg"
										alt=""
										width="20px"
										height="20px"
									/>
									<p>{L("Фильтры", "Filters")}</p>
								</div>

								{
									<TableTotalCount
										name={L("Филиалы", "Outlets")}
										headers={objects?.headers["x-pagination"]}
									/>
								}
							</div>
							<div className="upper-bar-second">
								<img
									src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
									width="35px"
									style={{ marginRight: "30px", cursor: "pointer" }}
									alt=""
									onClick={() =>
										setModalUploadObjects({
											opened: true,
										})
									}
								/>
								<CommonBtn
									onClick={() =>
										setModalCreateNew({
											opened: true,
										})
									}
									text=""
								>
									<img src="../../icons/add.svg" alt="powerpoint" />
									<p>{L("Создать филиал", "Create outlet")}</p>
								</CommonBtn>
							</div>
						</>
					) : (
						<>
							<div className="upper-bar-first">
								<TableTotalCount
									name={L("Выделено", "Checked")}
									type="checked"
									total={total}
								/>
							</div>
							<div className="upper-bar-second">
								<DeleteBtn
									text={L("Удалить", "Delete")}
									icon
									onClick={() => mutate(added as any)}
								/>
								<UncheckAllBtn
									onClick={uncheckAll}
									styles={{ marginLeft: "16px" }}
								/>
							</div>
						</>
					)}
				</div>
				{isSuccess ? (
					<>
						{/* table */}
						<div className="table-container">
							<table cellSpacing={0} className="my-table choose-table">
								<thead>
									<tr>
										<th>
											<div className="">
												<div
													style={{ marginLeft: "8px" }}
													className="radio-container"
												>
													<input
														className="no-radio-styles"
														type="radio"
														checked={isAllChecked}
														onChange={() => true}
														onClick={switchCheckedAll}
													/>
												</div>
											</div>
										</th>
										{headings.map((heading: any) => (
											<th key={heading.name}>
												<div className="d-flex align-items-end ">
													<div>{heading.name}</div>
													{heading.colname && (
														<div className="">
															<SortArrow
																sort={sort}
																heading={heading}
																setSort={setSort}
															/>
														</div>
													)}
												</div>
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{columns &&
										columns.map((col) => {
											return (
												<tr
													key={col.objectId}
													onClick={() =>
														setModalInfo({
															opened: true,
															payload: {
																userId: col.userId,
																objectResponsibleFullName:
																	col.objectResponsibleFullName,
																objectName: col.objectName,
																customObjectId: col.customObjectId,
																numberOfEmployees: col.numberOfEmployees,
																region: col.region,
																city: col.city,
																fullnameResponsible:
																	col.objectResponsibleFullName,
																phone: col.phoneNumber,
																email: col.email,
																id: col.objectId,
																address: col.address,
																underChainManagerFullName:
																	col.underChainManagerFullName,
																uncheckAll: uncheckAll,
															},
														})
													}
												>
													<td>
														<div className="radio-container">
															<input
																onClick={(e) => {
																	e.stopPropagation();
																	addChecked(col.objectId);
																}}
																type="radio"
																checked={getChecked(col.objectId)}
																onChange={() => true}
															/>
														</div>
													</td>
													<td>{col.customObjectId}</td>
													<td>{col.objectName}</td>
													<td>
														{col.address && (
															<div className="with-icon">
																<img src="../../icons/location.svg" alt="" />
																<p>{`${col.region}, ${col.city},  ${col.address}`}</p>
															</div>
														)}
													</td>
													<td>
														{col.objectResponsibleFullName && (
															<div className="with-icon">
																<img src="../../icons/guy.svg" alt="" />
																<p>{col.objectResponsibleFullName}</p>
															</div>
														)}
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>

						<TablePagination
							page={page}
							setPage={setPage}
							paginationInfo={getPaginationInfo(objects)}
						/>
					</>
				) : (
					<Loading />
				)}
			</div>
		</>
	);
}

export default Outlets;
