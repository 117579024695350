import { instanceV0 } from "./instances";

export type TConfirmHrsBtn = {
	id: 20 | 30 | 40 | 50 | 60;
	status: string;
	isActive: boolean;
	actionName: string;
	isPositiveAction: boolean;
};

export const getConfirmHoursBtns = async () => {
	const response = await instanceV0.get<TConfirmHrsBtn[]>("/WorkerHours");
	return response;
};

type IPostConfirmHrs = {
	startWorkDate: string;
	endWorkDate: string;
	hoursStatusId: number;
	workerIds: number[];
};
export const postConfirmHoursBtns = async (fields: IPostConfirmHrs) => {
	const response = instanceV0.post("/WorkerHours", JSON.stringify(fields), {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return response;
};
