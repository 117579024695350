import React, { useState } from "react";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import useFeedback from "../../../hooks/useFeedback";
import { useMutation, useQueryClient } from "react-query";
import { createCompanyEmployee } from "../../../api/company_employees";
import InputControlled from "../../utility/InputControlled";
import useLanguage from "../../../hooks/useLanguage";

interface IProps {
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
		}>
	>;
}

function CreateRegionalManager({ setModal }: IProps) {
	const { L } = useLanguage();

	const [info, setInfo] = useState({
		firstName: "",
		lastName: "",
		middleName: "",
		email: "",
		phoneNumber: null,
		phoneNumberWithoutCode: "",
	});

	// feedback
	const { openFeedback, closeFeedback } = useFeedback();

	// client
	const client = useQueryClient();

	// validation
	const [firstnamereq, setfirstnamereq] = useState(false);
	const [lastnamereq, setlastnamereq] = useState(false);
	const [emailreq, setemailreq] = useState(false);
	const [phoneNumberreq, setphoneNumberreq] = useState(false);

	// modals actions
	const closeSecondary = () => {
		setModal((prev: any) => ({
			...prev,
			secondaryOpened: false,
		}));
	};

	// query
	const { mutate, error } = useMutation<any, any, any>(createCompanyEmployee, {
		onSuccess: () => {
			closeSecondary();
			setModal({
				opened: false,
			});
			openFeedback(
				L("Региональный менеджер создан", "Regional manager was created"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("regionalManagers");
			}, 150);
		},
		onError: () => {
			openFeedback(
				L(
					"Что-то пошло не так, проверьте, заполнены ли все поля",
					"Something went wrong"
				),
				"error"
			);
		},
	});

	const postCompanyEmployee = () => {
		if (!info.firstName) setfirstnamereq(true);
		if (!info.lastName) setlastnamereq(true);
		if (!info.email) setemailreq(true);
		if (!info.phoneNumberWithoutCode) setphoneNumberreq(true);

		if (info.firstName) setfirstnamereq(false);
		if (info.lastName) setlastnamereq(false);
		if (info.email) setemailreq(false);
		if (info.phoneNumberWithoutCode) setphoneNumberreq(false);
		if (error) {
			if (error.response.status === 410) setemailreq(true);
		}
		if (info.phoneNumberWithoutCode) {
			mutate({
				...info,
				phoneNumber: L("+7", "+44") + info.phoneNumberWithoutCode,
			});
		} else {
			mutate({ ...info });
		}
	};
	// error
	const whichError = () => {
		if (error) {
			if (error.response.status === 410)
				return L(
					"Менеджер с такой почтой уже существует",
					"Manager with this email already exists"
				);
		} else {
			return L("Поле должно быть заполнено", "The field must not be empty");
		}
	};

	return (
		<div className="form-inner side-modal-container">
			<form style={{ maxWidth: "99%" }} className="center-contaoner-3">
				<InputControlled
					label={L("Имя*", "Name*")}
					setState={setInfo}
					stateName="firstName"
					value={info.firstName}
					requiredState={firstnamereq}
				/>

				<InputControlled
					label={L("Фамилия*", "Last name*")}
					setState={setInfo}
					stateName="lastName"
					value={info.lastName}
					requiredState={lastnamereq}
				/>

				<InputControlled
					label={L("Отчество", "Middle name")}
					setState={setInfo}
					stateName="middleName"
					value={info.middleName}
				/>
				<InputControlled
					label="Email*"
					setState={setInfo}
					stateName="email"
					value={info.email}
					requiredState={emailreq}
					errorMessage={whichError()}
				/>

				{/* phone */}
				<div className="mt-2">
					<label className="form-label" htmlFor="phoneNumber">
						{L("Телефон*", "Phone*")}
					</label>
					<div className={L("phonefield-container", "phonefield-container-gb")}>
						<input
							style={phoneNumberreq ? { border: "1px solid #ff3b30" } : {}}
							value={info.phoneNumberWithoutCode}
							onChange={(e: any) =>
								setInfo((prev: any) => ({
									...prev,
									phoneNumberWithoutCode: e.target.value.toString(),
									phoneNumber: e.target.value.toString(),
								}))
							}
							type="telephone"
							id="phoneNumber"
							className=" phonefield form-control"
						/>
					</div>
					{phoneNumberreq && (
						<p className="input-validation-text">
							{L("Обязательное поле", "The field must not be empty")}
						</p>
					)}
				</div>
			</form>
			<div className="bottom-container-3">
				<div className="flex">
					<CommonBtn
						onClick={() => setModal({ opened: false })}
						type="light"
						text={L("Отменить", "Cancel")}
					/>
					<CommonBtn
						onClick={postCompanyEmployee}
						text={L("Создать", "Create")}
					/>
				</div>
			</div>
		</div>
	);
}

export default CreateRegionalManager;
