import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
	dlEmployees,
	getEmployeesContractors,
} from "../../../api/for_contractors/employees_contractors";
import Loading from "../../../components/utility/Loading";
import TablePagination from "../../../components/utility/TablePagination";
import EmployeeFilters from "../../../employees/EmployeesPage/modals/EmployeesFIlters"; //
import { GrClose } from "react-icons/gr";
import "../../../styles/modalCandidate/modalCandidate.css";
import { Link } from "react-router-dom";
import { createArrayOptions } from "../../../utils/createArrayOptionsFromObj";
import SortArrow from "../../utility/SortArrow";
import EmployeeFiltersContractors from "./EmployeesFiltersContractors";
import { dlWorkers } from "../../../api/employees";
import ModalMain from "../../utility/ModalMain/ModalMain";
import MoreInfoItem from "../../outlets/OutletInfo/MoreInfoItem";
import TableTotalCount from "../../utility/TableTotalCount";
import IconInputFilter from "../../utility/IconInputFilter";
import TimeSheet from "../../timesheet/TimeSheet";
import EmployeeInfo from "../../../employees/EmployeeInfo.tsx/EmployeeInfo";
import EmployeesInfoContractor from "./EmployeesInfoContractor";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BiRuble } from "react-icons/bi";
import { addThousandSeparator } from "../../../utils/thousandSeparator";
import { formatDate, reverseFormatDate } from "../../../utils/formatDate";
import useTableCheck from "../../../hooks/useTableCheck";
import TableHeadSortWithCheck from "../../utility/Table/headings/TableHeadSortWithCheck";
import { TableCheckBody } from "../../utility/Table/TableCheck";
import EmployeesPeriod from "../../../employees/EmployeesPage/elements/EmployeesPeriod";
import HoursBtns from "../../../employees/EmployeesPage/elements/btns/HoursBtns";
import GeneralInfo from "../../../employees/EmployeesPage/modals/GeneralInfo";
import UncheckAllBtn from "../../utility/Buttons/UncheckAllBtn";
import TableHoursIcon from "../../utility/Table/TableHoursIcon";
import useLanguage from "../../../hooks/useLanguage";

function EmployeesContractors() {
	const { L } = useLanguage();

	const [isShownInfo, setIsShownInfo] = useState(false);
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		index: "",
		region: "",
		ObjectId: "",
		city: "",
		job: "",
		outlet: "",
		address: "",
		contractorName: "",
		contractorId: "",
		WorkerFullname: "",
		ObjectResponsibleFullName: "",
		hoursStatusId: "",
		searchInput: "",
		workerStatusId: "",
		StartWorkDate: null,
		EndWorkDate: null,
		company: "",
	});
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc
	const [datestart, setdatestart] = useState("");
	const [dateend, setdateend] = useState("");

	const { data, isSuccess, isError } = useQuery(
		["employeesContractors", { pageNumber: page }, filters, sort],
		(q) => getEmployeesContractors(q),
		{
			enabled: true,
			retry: 2,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 5000,
		}
	);

	//!check stuff
	const idsArray = data?.data.workers.map((el) => el.workerId) || [];
	const {
		addChecked,
		switchCheckedAll,
		getChecked,
		checked,
		added, // this is passed to delete multiple
		isAllChecked,
		total,
		uncheckAll,
	} = useTableCheck({
		ids: idsArray,
	});

	//* modals
	const [modalFilters, setModalFilters] = useState<{
		opened: boolean;
	}>({
		opened: false,
	});

	const [generalInfoModal, setGeneralInfoModal] = useState({
		opened: false,
	});
	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		secondaryOpened?: boolean;
		confirmOpened?: boolean;
	}>({
		opened: false,
		payload: null,
		secondaryOpened: false,
		confirmOpened: false,
	});

	//*/modals

	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};
	let headings: any = [
		{ name: L("Компания", "Company") },
		{ name: L("Филиал", "Outlet") },

		{ name: L("ID филиала", "Outlet ID") },

		{ name: L("Локация", "Location") },

		{ name: L("Сотрудник", "Employee") },
		{ name: L("Должность", "Job title") },

		{
			name: L("Не согласовано", "Not approved"),
			colname: "workedHoursNotApproved",
		},
		{
			name: L("На согл. у подрядчика", "Awaiting approval by contractor"),
			colname: "workedHoursInReview",
		},
		{
			name: L("На согл. у менеджера", "Awaiting approval by manager"),
			colname: "workedHoursApprovedByContractor",
		},
		{
			name: L("Согл. менеджером", "Approved by manager"),
			colname: "workedHoursApprovedByChainManager",
		},
		{ name: L("Всего часов", "Total hours"), colname: "totalWorkedHours" },
	];

	const workers = data?.data?.workers;

	const chosenFilters = data?.data.chosenFilters;

	useEffect(() => {
		if (chosenFilters?.startWorkDate) {
			setdatestart(chosenFilters.startWorkDate);
		}
		if (chosenFilters?.endWorkDate) {
			setdateend(chosenFilters.endWorkDate);
		}
	}, [isSuccess, chosenFilters]);
	return (
		<div className="container-fluid">
			<div className="upper-bar">
				{total === 0 ? (
					<>
						<div className="upper-bar-first">
							<IconInputFilter
								inputsize="small"
								setFilters={setFilters}
								placeholder={L("Искать сотрудника", "Search employee")}
							/>

							<EmployeesPeriod
								dateend={dateend}
								datestart={datestart}
								setFilters={setFilters}
								setdateend={setdateend}
								setdatestart={setdatestart}
								filters={filters}
							/>

							<div
								onClick={() => setModalFilters({ opened: true })}
								className="filter-btn"
							>
								<img
									src="../../icons/setting-5.svg"
									alt=""
									width="20px"
									height="20px"
								/>
								<p>{L("Фильтры", "Filters")}</p>
							</div>
							<TableTotalCount
								name={L("Сотрудников", "Employees")}
								headers={data?.headers["x-pagination"]}
							/>
						</div>

						<div className="upper-bar-second">
							<img
								src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
								width="35px"
								style={{ marginRight: "30px", cursor: "pointer" }}
								alt=""
								onClick={() => {
									dlEmployees(page, filters, datestart, dateend);
								}}
							/>
							<div
								onClick={() => setGeneralInfoModal({ opened: true })}
								className=""
							>
								<p style={{ color: "#5e73e1", cursor: "pointer" }}>
									{L("Cводная информация", "Summary")}
								</p>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="upper-bar-first">
							<TableTotalCount name="Выделено" type="checked" total={total} />
						</div>
						<div className="upper-bar-second">
							<HoursBtns
								added={added}
								datestart={datestart}
								dateend={dateend}
								uncheckAll={uncheckAll}
								queryName="employees"
							/>
							<UncheckAllBtn
								onClick={uncheckAll}
								styles={{ marginLeft: "16px" }}
							/>
						</div>
					</>
				)}
			</div>
			<ModalMain
				modal={modalInfo}
				setModal={setModalInfo}
				opened={modalInfo.opened}
				title="Информация о сотруднике"
				secondary={() => (
					<TimeSheet
						setModal={setModalInfo}
						workerId={modalInfo.payload.workerId}
						workerNameContractors={modalInfo?.payload?.workerFullname}
					/>
				)}
				secondaryOpened={modalInfo.secondaryOpened || false}
			>
				<EmployeesInfoContractor
					// secondaryOpened={false}
					secondaryOpened={modalInfo.secondaryOpened || false}
					setModal={setModalInfo}
					modal={modalInfo}
					payload={modalInfo.payload}
				/>
			</ModalMain>
			{isSuccess ? (
				<>
					<ModalMain
						opened={modalFilters.opened}
						title={L("Фильтры", "Filters")}
						setModal={setModalFilters}
					>
						<EmployeeFiltersContractors
							datestart={datestart}
							dateend={dateend}
							setdatestart={setdatestart}
							setdateend={setdateend}
							data={data}
							isSuccess={isSuccess}
							setFilters={setFilters}
							filters={filters}
						/>
					</ModalMain>

					<ModalMain
						setModal={setGeneralInfoModal}
						opened={generalInfoModal.opened}
						title={L("Сводная информация", "Summary")}
					>
						<div className="more-info-container">
							<GeneralInfo
								data={data}
								dateend={dateend}
								datestart={datestart}
							/>
						</div>
					</ModalMain>

					<div
						className={
							isShownInfo ? `modal-candidate` : `modal-candidate hidden`
						}
					>
						<div className="modal-candidate-inner">
							<div className="modal-candidate-content-container">
								<h3>Сводная информация</h3>
								<p className="gray-text-mini">Выбранный период</p>
								<h4>
									{reverseFormatDate(datestart)} – {reverseFormatDate(dateend)}
								</h4>
								<p className="gray-text-mini">Всего часов</p>
								<h4>{data.data.totalWorkerStats.sumTotalWorkedHours}</h4>
								<p className="gray-text-mini">Суммарная стоимость</p>
								<h4>{data.data.totalWorkerStats.sumCosts}</h4>
								<p className="gray-text-mini">Суммарная стоимость с НДС</p>
								<h4>{data.data.totalWorkerStats.sumCostsWithVat}</h4>

								<div
									onClick={() => setIsShownInfo(false)}
									className="close-btn-modal"
								>
									<GrClose size={20} />
								</div>
							</div>
						</div>
					</div>
					<div className="table-container ">
						<table
							cellSpacing={0}
							className="my-table choose-table table-responsive-sm"
						>
							<TableHeadSortWithCheck
								headings={headings}
								setSort={setSort}
								sort={sort}
								isAllChecked={isAllChecked}
								switchCheckedAll={switchCheckedAll}
							/>
							<tbody>
								{workers &&
									workers.map((col) => {
										let workStartDate = col?.workStartDate;

										let workEndDate = col?.workEndDate;
										return (
											<tr
												onClick={() => {
													setModalInfo({
														opened: true,
														payload: {
															workerId: col.workerId,
															region: col.region,
															city: col.city,
															objectName: col.objectName,
															position: col.position,
															workerFullname: col.workerFullname,
															citizenship: col.citizenship,
															workStartDate: col.workStartDate,
															workEndDate: workEndDate,
															address: col.address,
															company: col?.companyName,
															totalWorkedHours: col.totalWorkedHours,
															costsWithVat: col.costsWithVat,
															costs: col.costs,
															externalRateVat: col.externalRateVat,
															externalRate: col.externalRate,
															workerStatusName: col.workerStatusName,
															workerStatusId: col.workerStatusId,
															birthday: col.birthday,
															workerSexString: col.workerSexString,
															passport: col.passport,
															phoneNumber: col.phoneNumber,
															objectResponsibleFullName:
																col.objectResponsibleFullName,
															objectResponsibleEmail:
																col.objectResponsibleEmail,
															objectResponsiblePhoneNumber:
																col.objectResponsiblePhoneNumber,
															// customObjectId: col.customObjectId,
															datestart: datestart,
															dateend: dateend,
														},
													});
												}}
											>
												<TableCheckBody
													itemId={col.workerId}
													addChecked={addChecked}
													getChecked={getChecked}
												/>
												<td>{col.companyName}</td>
												<td>{col.objectName}</td>
												<td>{col.customObjectId}</td>
												<td>
													{col.address && (
														<div
															title={`${col.region}, ${col.city}, ${col.address}`}
															className="with-icon"
														>
															<img src="../../icons/location.svg" alt="" />
															<p>{`${col.region}, ${
																col.city
															},  ${col.address.slice(0, 30)}...`}</p>
														</div>
													)}
												</td>
												<td>{`${col.workerStatusNameForTable} ${col.workerFullname}`}</td>
												<td>{col.position}</td>
												{/* <td>{col.workerStatusName}</td> */}
												<td>
													<TableHoursIcon
														hours={col.workedHoursNotApproved}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
												<td>
													<TableHoursIcon
														hours={col.workedHoursInReview}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
												<td>
													<TableHoursIcon
														hours={col.workedHoursApprovedByContractor}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
												<td>
													<TableHoursIcon
														hours={col.workedHoursApprovedByChainManager}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
												<td>
													<TableHoursIcon
														hours={col.totalWorkedHours}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default EmployeesContractors;
