import { useState } from "react";
import UploadOutletsWidget from "./UploadOutletsWidget";
import { dlExample } from "../../api/objects";
import "../../styles/uploadInstructions/uploadInstructions.css";
import useLanguage from "../../hooks/useLanguage";
function UploadOutlets() {
	const { L } = useLanguage();
	const [isShowMore, setIsShowMore] = useState(false);

	return (
		<div className="upload-container">
			<div className="">
				<UploadOutletsWidget />
			</div>
			<div className="instruction">
				<div className="example-download-container" onClick={dlExample}>
					<img
						src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
						alt=""
						width="25px"
					/>
					<p>{L("Скачать пример файла", "Download an example")}</p>
				</div>
			</div>
		</div>
	);
}

export default UploadOutlets;
