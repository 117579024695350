import { useState, useEffect, useRef } from "react";
import InputFilter from "../../utility/InputFilter";
import { getEmployeesContractors } from "../../../api/for_contractors/employees_contractors";
import FilterAutocomplete from "../../utility/FilterAutocomplete";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import { createArrayOptions } from "../../../utils/createArrayOptionsFromObj";
import useLanguage from "../../../hooks/useLanguage";
const dayjs = require("dayjs");

interface IProps {
	filters?: any;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
	data?: any;
	isSuccess?: any;
	datestart: any;
	setdatestart: any;
	dateend: any;
	setdateend: any;
}
function EmployeeFiltersContractors({
	setFilters,
	filters,
	data,
	setdatestart,
	setdateend,
}: IProps) {
	const { L } = useLanguage();

	// get the beginning and the end of the month

	function getFirstDayOfMonth() {
		const firstDay = dayjs().startOf("month");
		return firstDay.format("YYYY-MM-DD");
	}

	function getLastDayOfMonth() {
		const lastDay = dayjs().endOf("month");
		return lastDay.format("YYYY-MM-DD");
	}

	const regions = createArrayOptions(data?.data?.workerFilterValues?.regions);
	const cities = createArrayOptions(data?.data?.workerFilterValues?.cities);
	const objectNames = createArrayOptions(
		data?.data?.workerFilterValues?.objectNames
	);

	const jobTitles = createArrayOptions(
		data?.data?.workerFilterValues?.jobTitles
	);
	const contractorNames = createArrayOptions(
		data?.data?.workerFilterValues?.contractorNames
	);

	const addressRef: any = useRef(null);

	const chosenFilters = data?.data?.chosenFilters;

	let checkRegion = regions && localStorage.getItem("role") != "Director";
	let checkCity = cities && localStorage.getItem("role") != "Director";
	let checkFIO = localStorage.getItem("role") != "Director";
	let checkObjects = objectNames && localStorage.getItem("role") != "Director";

	// input filters fix
	const customIdRef: any = useRef();
	const workerFullnameRef: any = useRef();
	const objectResponsibleFullNameRef: any = useRef();
	console.log(customIdRef.current);

	// role check
	const roleNotDirector = localStorage.getItem("role") != "Director";

	// clear filters
	const clear = () => {
		// objectResponsibleFullNameRef.current.value = "";
		customIdRef.current.value = "";
		workerFullnameRef.current.value = "";
		addressRef.current.value = "";
		customIdRef.current.value = "";

		setFilters((prev: any) => ({
			...prev,
			index: "",
			region: "",
			ObjectId: "",
			city: "",
			job: "",
			outlet: "",
			address: "",
			contractorName: "",
			contractorId: "",
			WorkerFullname: "",
			ObjectResponsibleFullName: "",
			company: "",
			hoursStatusId: "",
			workerStatusId: "",
		}));
		setdatestart(getFirstDayOfMonth());
		setFilters((prev: any) => ({
			...prev,
			StartWorkDate: getFirstDayOfMonth(),
		}));
		setdateend(getLastDayOfMonth());
		setFilters((prev: any) => ({
			...prev,
			EndWorkDate: getLastDayOfMonth(),
		}));
	};
	useEffect(() => {
		if (data) {
			if (filters.index) customIdRef.current.value = filters.index;
			if (filters.ObjectId) customIdRef.current.value = filters.ObjectId;
			if (filters.WorkerFullname)
				workerFullnameRef.current.value = filters.WorkerFullname;
			if (filters.ObjectResponsibleFullName)
				objectResponsibleFullNameRef.current.value =
					filters.ObjectResponsibleFullName;
			if (filters.address) addressRef.current.value = filters.address;
		}
	}, [data]);
	return (
		<div>
			<div style={{ marginBottom: "20px" }}>
				<CommonBtn
					onClick={clear}
					type="light"
					text={L("Очистить фильтры", "Clear filters")}
				/>
			</div>

			<FilterAutocomplete
				setFilters={setFilters}
				filterName="hoursStatusId"
				options={data?.data?.workerFilterValues?.hoursStatuses}
				selectedValue={chosenFilters?.hoursStatuses?.value}
				label={L("Статус по часам", "Status by hours")}
				placeholder={L("Пример: На согласовании", "Example: In review")}
			/>

			{data && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="company"
					options={data?.data?.workerFilterValues?.companies}
					selectedValue={chosenFilters?.companies?.value}
					label={L("Компания", "Company")}
					placeholder={L("Пример: Продукты", "Example: Grocery")}
				/>
			)}

			{checkObjects && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="outlet"
					options={data?.data?.workerFilterValues?.objectNames}
					selectedValue={chosenFilters?.objectNames?.value}
					label={L("Филиал", "Outlet")}
					placeholder={L("Пример: Продукты_242", "Example: Grocery_242")}
				/>
			)}

			<InputFilter
				ref={customIdRef}
				filterField="index"
				setFilters={setFilters}
				placeholder={L("Пример: 918267", "Example: 24224")}
				label={L("ID филиала", "Outlet ID")}
				type={"text"}
			/>

			{/*dry region */}
			{checkRegion && roleNotDirector && (
				<div className="grid-filters-item">
					<FilterAutocomplete
						options={data?.data?.workerFilterValues?.regions}
						setFilters={setFilters}
						filterName={"region"}
						selectedValue={chosenFilters?.regions?.value}
						placeholder={L("Пример: Омская область", "Example: Scotland")}
						defaultFilterValue={""}
						label={L("Регион", "Region")}
					/>
				</div>
			)}
			{/* city */}
			{checkCity && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="city"
					options={data?.data?.workerFilterValues?.cities}
					selectedValue={chosenFilters?.cities?.value}
					label={L("Город", "City")}
					placeholder={L("Пример: Симферополь", "Example: Glasgow")}
				/>
			)}

			{checkFIO && (
				<InputFilter
					ref={addressRef}
					filterField="address"
					setFilters={setFilters}
					placeholder={L(
						"Пример: Улица Амурская 1",
						"Example: St. John Street, 1"
					)}
					type={"text"}
					label={L("Адрес", "Address")}
				/>
			)}
			{jobTitles && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="job"
					options={data?.data?.workerFilterValues?.jobTitles}
					selectedValue={chosenFilters?.jobTitles?.value}
					label={L("Должность", "Job title")}
					placeholder={L("Пример: Кассир", "Example: Cashier")}
				/>
			)}
			<InputFilter
				ref={workerFullnameRef}
				filterField="WorkerFullname"
				setFilters={setFilters}
				placeholder={L(
					"Пример: Иванов Иван Иванович",
					"Example: John Williams Smith"
				)}
				label={L("ФИО сотрудника", "Employee's fullname")}
				type={"text"}
			/>

			<FilterAutocomplete
				setFilters={setFilters}
				filterName="workerStatusId"
				options={data?.data?.workerFilterValues?.workerStatuses}
				selectedValue={chosenFilters?.workerStatus?.value}
				label={L("Статус работника", "Employee's status")}
				placeholder={L("Выбраны все", "All")}
			/>

			<div style={{ marginBottom: "2rem" }}></div>
		</div>
	);
}

export default EmployeeFiltersContractors;
