import { instanceV3 } from "../instances";
import { QueryFunctionContext } from "react-query";

interface ICompanies {
	candidateAnotherFitted: number;
	candidateHired: number;
	candidateNotFitted: number;
	candidateNotShowedUp: number;
	companyName: string;
	numOfAcceptedRequests: number;
	numOfCandidates: number;
	numOfCompanyActiveRequests: number;
	numOfCompanyTotalRequests: number;
	numOfFiredEmployeeCurrentMonth: number;
	numOfWorkers: number;
}

export const getCompanies = (q: QueryFunctionContext<any>) => {
	let column = "";
	let order = "";

	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	if (q?.queryKey[2]?.heading) {
		column = `&Column=${q?.queryKey[2]?.heading}`;
	}
	if (q?.queryKey[2]?.order) {
		order = `&SortType=${q?.queryKey[2]?.order}`;
	}

	const response = instanceV3.get<ICompanies[]>(
		`/Companies${page}${column}${order}`
	);

	return response;
};

export const dlCompanies = async (asInQuery: any[]) => {
	let column = "";
	let order = "";
	let page = "";
	if (asInQuery[1]) {
		// console.log(q.queryKey[1].pageNumber);
		page = "?pageNumber=" + asInQuery[1];
	}

	if (asInQuery[2]?.heading) {
		column = `&Column=${asInQuery[2]?.heading}`;
	}
	if (asInQuery[2]?.order) {
		order = `&SortType=${asInQuery[2]?.order}`;
	}

	await instanceV3
		.get(`/Companies${page}&download=true${column}${order}`, {
			responseType: "blob",
			headers: {
				"Content-Type":
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			},
		})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "file.xlsx");
			document.body.appendChild(link);
			link.click();
		});
};
