import { isBoolean } from "lodash";
import { useState, useRef, forwardRef, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { createArrayOptions } from "../../utils/createArrayOptionsFromObj";

interface IProps {
	data: any;
	setState: any;
	stateNames: string[];
	stateNamesToNull?: string[];
	id: string;
	disabled?: boolean;
	placeholder?: string;
	label?: string;
	clearRefs?: any[];
	required?: boolean;
	defaultInput?: string;
	defaultInputVal?: any;
	errorMessage?: any;
	requiredState?: boolean | undefined;
	dropup?: boolean;
}

const InputAutocomplete = forwardRef(
	(
		{
			data,
			setState,
			stateNames,
			stateNamesToNull,
			id = "autocomplete",
			disabled = false,
			placeholder = "",
			label = "",
			clearRefs,
			required = false,
			defaultInputVal,
			requiredState,
			errorMessage = localStorage.getItem("lang") === "RU"
				? "Поле должно быть заполнено"
				: "Field must not be empty",
			dropup,
		}: IProps,
		ref?: any
	) => {
		const needValidation = isBoolean(requiredState) && requiredState === true;

		const clearOutsideAutocompletes = () => {
			clearRefs?.forEach((refToClear) => {
				if (refToClear.current) {
					refToClear.current.clear();
				}
			});
		};

		const setStates = (option: any) => {
			stateNames.forEach((stateName) => {
				setState((prev: any) => ({
					...prev,
					[stateName]: option.id,
				}));
			});
			stateNamesToNull?.forEach((stateNameToNull) => {
				setState((prev: any) => ({
					...prev,
					[stateNameToNull]: null,
				}));
			});
		};
		const handleKeyDown = (event: any) => {
			if (event.keyCode === 9 /* tab */) {
				console.log("handle");
				console.log(event);
			}
		};
		return (
			<>
				{label && (
					<label className="form-label mt-2 " htmlFor={id}>
						{label}
					</label>
				)}
				<Typeahead
					dropup={dropup}
					onChange={(el: any) => {
						// allows changing state on tab
						if (el[0]) {
							setStates(el[0]);
							clearOutsideAutocompletes();

							console.log(el[0]);
						}
					}}
					onKeyDown={handleKeyDown}
					allowNew={false}
					inputProps={{
						className: `${needValidation ? "input-error" : ""} autocomplete`,
					}}
					defaultInputValue={defaultInputVal}
					ref={ref}
					id={id}
					disabled={disabled}
					options={createArrayOptions(data)}
					labelKey={"value"}
					placeholder={placeholder}
					renderMenuItemChildren={(option: any) => (
						<div
							onClick={() => {
								clearOutsideAutocompletes();
								setStates(option);
							}}
							key={option.id}
						>
							<span>{option.value}</span>
						</div>
					)}
				>
					<div className="typeahead-down-arrow-not-filter">
						<img width="20px" src="../../icons/input-arrow-down.svg" alt="" />
					</div>
				</Typeahead>
				{needValidation && (
					<p className="input-validation-text">{errorMessage}</p>
				)}
			</>
		);
	}
);

export default InputAutocomplete;
