import React, { useState } from "react";
import TableHeadSort from "../utility/Table/headings/TableHeadSort";
import Loading from "../utility/Loading";
import IconInputFilter from "../utility/IconInputFilter";
import CommonBtn from "../utility/Buttons/CommonBtn";
import ModalMain from "../utility/ModalMain/ModalMain";
import CreateRegionalManager from "./modals/CreateSource";
import { useQuery } from "react-query";
import { getCompanyEmployees } from "../../api/company_employees";
import TablePagination from "../utility/TablePagination";
import RegionalManagerInfo from "./modals/SourceInfo";
import useLanguage from "../../hooks/useLanguage";
import CreateSource from "./modals/CreateSource";

function RegionalManagers() {
	const { L } = useLanguage();

	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		searchInput: "",
	});
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc
	const headings = [
		{ name: L("Название", "Name") },
		{ name: L("Тип", "Last name") },
		{ name: L("Ссылка", "Middle name") },
		{ name: L("Заявок", "Email") },
		{ name: L("Статус", "Email") },
	];

	// query

	const { data, isSuccess } = useQuery(
		["regionalManagers", { pageNumber: page }, filters],
		(q) => getCompanyEmployees(q)
	);

	// pagination
	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};

	// modals

	// create
	const [modalCreateNew, setModalCreateNew] = useState({
		opened: false,
	});

	// info
	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		secondaryOpened?: boolean;
		payload?: any;
		confirmOpened?: boolean;
	}>({
		opened: false,
		secondaryOpened: false,
		payload: null,
	});

	// modals
	return (
		<div className="container-fluid">
			<div className="upper-bar">
				<div className="upper-bar-first">
					<IconInputFilter
						setFilters={setFilters}
						placeholder={L("Искать менеджера", "Search manager")}
					/>
				</div>
				<div className="upper-bar-second">
					<CommonBtn
						onClick={() =>
							setModalCreateNew({
								opened: true,
							})
						}
						text=""
					>
						<img src="../../icons/add.svg" alt="add" />
						<p>{L("Добавить источник", "Create source")}</p>
					</CommonBtn>
				</div>
			</div>
			{/* modals */}
			<ModalMain
				opened={modalCreateNew.opened}
				setModal={setModalCreateNew}
				title={L("Создать источник", "Create source")}
			>
				<CreateSource setModal={setModalCreateNew} />
			</ModalMain>

			<ModalMain
				modal={modalInfo}
				opened={modalInfo.opened}
				setModal={setModalInfo}
				title={L("Информация о менеджере", "Manager's information")}
			>
				<RegionalManagerInfo
					payload={modalInfo.payload}
					setModal={setModalInfo}
				/>
			</ModalMain>
			{/* modals */}
			{isSuccess ? (
				<>
					<div className="table-container">
						<table cellSpacing={0} className="my-table">
							<TableHeadSort
								headings={headings}
								setSort={setSort}
								sort={sort}
							/>
							<tbody>
								{data.data.map((col) => {
									return (
										<tr
											onClick={() => {
												setModalInfo({
													opened: true,
													payload: col.underChainManagerId,
												});
											}}
										>
											<td className="size-item">{col.firstName}</td>
											<td>{col.lastName}</td>
											<td>{col.middleName}</td>
											<td>{col.email}</td>
											<td>{col.phoneNumber}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default RegionalManagers;
