import React, { useState } from "react";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import useFeedback from "../../../hooks/useFeedback";
import { useMutation, useQueryClient } from "react-query";
import { createCompanyEmployee } from "../../../api/sources";
import InputControlled from "../../utility/InputControlled";
import useLanguage from "../../../hooks/useLanguage";
import SelectCustom from "../../utility/SelectCustom";

interface IProps {
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
		}>
	>;
}

const sourceOptions = {
	google: "Гугл таблица",
};

const enabledOptions = {
	true: "Активно",
	false: "Неактивно"
};

function CreateRegionalManager({ setModal }: IProps) {
	const { L } = useLanguage();

	const [info, setInfo] = useState({
		name: "",
		type: "",
		link: "",
		enabled: "",
	});

	// feedback
	const { openFeedback, closeFeedback } = useFeedback();

	// client
	const client = useQueryClient();

	// validation
	const [firstnamereq, setfirstnamereq] = useState(false);
	const [lastnamereq, setlastnamereq] = useState(false);
	const [emailreq, setemailreq] = useState(false);
	const [phoneNumberreq, setphoneNumberreq] = useState(false);

	// modals actions
	const closeSecondary = () => {
		setModal((prev: any) => ({
			...prev,
			secondaryOpened: false,
		}));
	};

	// query
	const { mutate, error } = useMutation<any, any, any>(createCompanyEmployee, {
		onSuccess: () => {
			closeSecondary();
			setModal({
				opened: false,
			});
			openFeedback(
				L("Региональный менеджер создан", "Regional manager was created"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("regionalManagers");
			}, 150);
		},
		onError: () => {
			openFeedback(
				L(
					"Что-то пошло не так, проверьте, заполнены ли все поля",
					"Something went wrong"
				),
				"error"
			);
		},
	});

	const postCompanyEmployee = () => {
		if (!info.name) setfirstnamereq(true);
		if (!info.type) setlastnamereq(true);
		if (!info.link) setemailreq(true);
		if (!info.enabled) setphoneNumberreq(true);

		if (info.name) setfirstnamereq(false);
		if (info.type) setlastnamereq(false);
		if (info.link) setemailreq(false);
		if (info.enabled) setphoneNumberreq(false);
		if (error) {
			if (error.response.status === 410) setemailreq(true);
		}

		mutate({ ...info });
	};
	// error
	const whichError = () => {
		if (error) {
			if (error.response.status === 410)
				return L(
					"Менеджер с такой почтой уже существует",
					"Manager with this email already exists"
				);
		} else {
			return L("Поле должно быть заполнено", "The field must not be empty");
		}
	};

	return (
		<div className="form-inner side-modal-container">
			<form style={{ maxWidth: "99%" }} className="center-contaoner-3">
				<InputControlled
					label={L("Название*", "Name*")}
					setState={setInfo}
					stateName="name"
					value={info.name}
					requiredState={firstnamereq}
				/>
				<SelectCustom
					styles={{ marginBottom: "16px" }}
					label="Тип источника"
					data={sourceOptions}
					setState={setInfo}
					stateName="type"
					//initial={workerReqData?.data.workerRequestInfo.sexString}
					arrowPosition="far-right"
				/>

				<InputControlled
					label={L("Ссылка", "Middle name")}
					setState={setInfo}
					stateName="link"
					value={info.link}
				/>

<SelectCustom
					styles={{ marginBottom: "16px" }}
					label="Активно"
					data={enabledOptions}
					setState={setInfo}
					stateName="enabled"
					//initial={workerReqData?.data.workerRequestInfo.sexString}
					arrowPosition="far-right"
				/>
			</form>
			<div className="bottom-container-3">
				<div className="flex">
					<CommonBtn
						onClick={() => setModal({ opened: false })}
						type="light"
						text={L("Отменить", "Cancel")}
					/>
					<CommonBtn
						onClick={postCompanyEmployee}
						text={L("Создать", "Create")}
					/>
				</div>
			</div>
		</div>
	);
}

export default CreateRegionalManager;
