import React, { useContext } from 'react'
import NotificationContext from '../../../../context/NotificationsContext';
import SortArrow from '../../SortArrow';
import { RiErrorWarningLine } from 'react-icons/ri';


interface IProps {
   headings: any;
   sort: any;
	setSort: any;
}

function TableHeadMap({headings, sort, setSort}:IProps) {
   const data = useContext(NotificationContext);

	const shouldShowNotification = (colName: any) => {
		if (data.notifications?.notifications) {
			const notificationForThisPage = data.notifications?.notifications.filter((n) => n.column == colName)
			if (notificationForThisPage.length > 0) {
				return true;
			}
		}
	}
  return (
   <>
   {headings.map((heading: any) => (
					<th key={heading.name}>
						<div className="d-flex align-items-end heading-container">
							{shouldShowNotification(heading.name) && <div className="notification-dot"><RiErrorWarningLine size={17} color="red" /></div>}
							<div className="heading-name"><span>{heading.name}</span></div>
							{heading.colname && (
								<div className="">
									<SortArrow sort={sort} heading={heading} setSort={setSort} />
								</div>
							)}
						</div>
					</th>
				))}
   </>
  )
}

export default TableHeadMap