import { useState, useMemo, forwardRef } from "react";
import debounce from "lodash.debounce";

interface IProps {
	setFilters: React.Dispatch<React.SetStateAction<any>>;
	filterField: string;
	filters?: any;
	placeholder?: string;
	type?: string;
	ref?: any;
	label?: string;
	inputsize?: "small";
}
const InputFilter = forwardRef((props: IProps, ref?: any) => {
	const [currentVal, setCurrentVal] = useState("");

	const changeHandler = (event: any) => {
		setCurrentVal(event.target.value);
		props.setFilters((prev: any) => ({
			...prev,
			[`${props.filterField}`]: event.target.value,
		}));
	};
	const debouncedChangeHandler = useMemo(() => {
		return debounce(changeHandler, 800);
	}, []);

	return (
		<div className="filter-input">
			{props.label && (
				<label className="filter-autocomplete-label">{props.label}</label>
			)}
			<input
				placeholder={props.placeholder}
				onChange={(e) => debouncedChangeHandler(e)}
				className={props.inputsize === "small" ? `general-search-sm` : ``}
				type={props.type}
				ref={ref}
			/>
		</div>
	);
});

export default InputFilter;
