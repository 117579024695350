import React, { useState } from "react";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import DeleteBtn from "../../utility/Buttons/DeleteBtn";
import Outlet from "../Outlet";
import InfoSwitch from "./InfoSwitch";
import MoreInfoItem from "./MoreInfoItem";
import ResponsibleInfo from "./ResponsibleInfo";
import ConfirmModal from "../../utility/confirmModal/ConfirmModal";
import { useMutation, useQueryClient } from "react-query";
import { deleteObjectsMultiple } from "../../../api/objects";
import useFeedback from "../../../hooks/useFeedback";
import { changeCreds } from "../../../api/auth";
import useLanguage from "../../../hooks/useLanguage";

function OutletInfo({ payload, setModalInfo }: any) {
	const { L } = useLanguage();

	const client = useQueryClient();
	const [active, setActive] = useState<"object" | "director" | "edit">(
		"object"
	);
	const { openFeedback, closeFeedback } = useFeedback();
	// deletion
	const { data, mutate, isSuccess } = useMutation(deleteObjectsMultiple, {
		onSuccess: () => {
			openFeedback(
				L("Филиал успешно удален", "Outlet is deleted successfully"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("outlets");
				payload.uncheckAll();
				closeModal();
			}, 100);
		},
	});

	// open conformation modal

	const openConformation = () => {
		setModalInfo((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Удаление Филиала", "Outlet deletion")}
					text={L(
						"Удаление филиала - это необратимая операция. Вы уверены, что хотите удалить филиал?",
						"This action cannot be reversed, are you sure you want to delete this outlet?"
					)}
				>
					<DeleteBtn
						text={L("Да, удалить", "Delete")}
						onClick={() => {
							mutate([payload.id]);
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	// close conformation modal
	const closeConfirmation = () => {
		setModalInfo((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	const closeModal = () => {
		setModalInfo((prev: any) => ({
			...prev,
			confirmOpened: false,
			opened: false,
			payload: null,
		}));
	};
	const roleNotUnder = localStorage.getItem("role") != "UnderChainManager";

	// object
	if (active === "object") {
		return (
			<>
				<InfoSwitch
					options={["object", "director"]}
					optionsNames={L(["Филиал", "Директор"], ["Outlet", "Director"])}
					active={active}
					setActive={setActive}
				/>
				<div className="more-info-container">
					<MoreInfoItem
						name={L("Название филиала", "Outlet")}
						data={payload.objectName}
					/>
					<MoreInfoItem
						name={L("Индикатор филиала", "Outlet ID")}
						data={payload.customObjectId}
					/>
					{roleNotUnder && (
						<MoreInfoItem
							name={L("Ответственный менеджер", "Responsible manager")}
							data={payload.underChainManagerFullName}
						/>
					)}

					<MoreInfoItem name={L("Регион", "Region")} data={payload.region} />
					<MoreInfoItem name={L("Город", "City")} data={payload.city} />
					<MoreInfoItem name={L("Адрес", "Address")} data={payload.address} />

					<div className="form-divider"></div>
					<DeleteBtn
						onClick={openConformation}
						text={L("Удалить филиал", "Delete outlet")}
						icon
						classes="btn-modal-left"
					/>
					<CommonBtn
						text={L("Редактировать", "Edit")}
						classes="btn-modal-right"
						onClick={() => setActive("edit")}
					/>
				</div>
			</>
		);
	}

	// director
	const getResponsible = (fullname: string | undefined) => {
		if (fullname === undefined) return ["-", "-", "-"];
		const arr = fullname.split(" ");
		const [name, surname, middlename] = arr; // i know dis is stupd but you see what i did here
		return [name, surname, middlename];
	};
	if (active === "director") {
		return (
			<>
				<InfoSwitch
					options={["object", "director"]}
					optionsNames={L(["Филиал", "Директор"], ["Outlet", "Director"])}
					active={active}
					setActive={setActive}
				/>
				<div className="more-info-container">
					<MoreInfoItem
						name={L("Фамилия", "Last name")}
						data={getResponsible(payload.fullnameResponsible)[0]}
					/>
					<MoreInfoItem
						name={L("Имя", "First name")}
						data={getResponsible(payload.fullnameResponsible)[1]}
					/>
					<MoreInfoItem
						name={L("Отчество", "Middle name")}
						data={getResponsible(payload.fullnameResponsible)[2]}
					/>
					<MoreInfoItem name="Email" data={payload.email} />
					<MoreInfoItem name={L("Телефон", "Phone")} data={payload.phone} />
					<ResponsibleInfo
						objectId={payload.id}
						userId={payload.userId}
						setModal={setModalInfo}
					/>
					<div className="form-divider"></div>
					<DeleteBtn
						onClick={openConformation}
						text={L("Удалить филиал", "Delete outlet")}
						icon
						classes="btn-modal-left"
					/>
					<CommonBtn
						text={L("Редактировать", "Edit")}
						classes="btn-modal-right"
						onClick={() => setActive("edit")}
					/>
				</div>
			</>
		);
	}

	if (active === "edit") {
		return (
			<>
				<div className="more-info-container">
					<Outlet objectId={payload.id} setModalInfo={setModalInfo} />
				</div>
				<div className="form-divider"></div>

				<CommonBtn
					type="light"
					classes="btn-modal-left"
					text={L("Отменить", "Cancel")}
					onClick={() => setActive("object")}
				/>
			</>
		);
	}

	return (
		<div>
			<InfoSwitch
				options={["object", "director"]}
				optionsNames={L(["Филиал", "Директор"], ["Outlet", "Director"])}
				active={active}
				setActive={setActive}
			/>
		</div>
	);
}

export default OutletInfo;
