import { useQuery } from "react-query";
import InputFilter from "../../utility/InputFilter";
import { useRef, useEffect } from "react";
import { createArrayOptions } from "../../../utils/createArrayOptionsFromObj";

import FilterAutocomplete from "../../utility/FilterAutocomplete";
import SelectCustom from "../../utility/SelectCustom";
import {
	getMonthFromNumberEn,
	monthKeyType,
	monthsData,
	yearsDataReverse,
} from "../../../utils/datesData";
import { getMonthFromNumber } from "../../../utils/datesData";
import MonthsSelectCustom from "../../utility/MonthsSelectCustom";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import { getWorkTimeSheetContractors } from "../../../api/for_contractors/employees_contractors";
import useLanguage from "../../../hooks/useLanguage";
import React from "react";
interface IProps {
	filters: any;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
	year: string;
	month: string;
}
function TimeTableFiltersContractors({
	filters,
	setFilters,
	year,
	month,
}: IProps) {
	const { L } = useLanguage();

	const { data, isSuccess } = useQuery(
		["timetablefilters", filters],
		getWorkTimeSheetContractors
	);

	const regions = createArrayOptions(data?.data?.workerFilterValues?.regions);
	const cities = createArrayOptions(data?.data?.workerFilterValues?.cities);
	const objectNames = createArrayOptions(
		data?.data?.workerFilterValues?.objectNames
	);
	const jobTitles = createArrayOptions(
		data?.data?.workerFilterValues?.jobTitles
	);
	const contractorNames = createArrayOptions(
		data?.data?.workerFilterValues?.contractorNames
	);
	const chosenFilters = data?.data?.chosenFilters;

	const customIdRef: any = useRef();

	const idRef: any = useRef(null);
	const addrRef: any = useRef(null);
	const workerRef: any = useRef(null);
	const respRef: any = useRef(null);
	const companyRef: any = useRef(null);

	let checkRegion = regions && localStorage.getItem("role") != "Director";
	let checkCity = cities && localStorage.getItem("role") != "Director";
	let checkObjectNames =
		objectNames && localStorage.getItem("role") != "Director";

	// fill some filters on modal open

	useEffect(() => {
		if (data) {
			if (filters.index) customIdRef.current.value = filters.index;
			if (filters.CustomObjectId) idRef.current.value = filters.CustomObjectId;
			if (filters.address) workerRef.current.value = filters.address;
			if (filters.WorkerFullname)
				addrRef.current.value = filters.WorkerFullname;
			if (filters.ObjectResponsibleFullName)
				respRef.current.value = filters.ObjectResponsibleFullName;
		}
	}, [data, filters]);

	// clear filters
	const clear = () => {
		customIdRef.current.value = "";
		addrRef.current.value = "";
		workerRef.current.value = "";
		// respRef.current.value = "";

		setFilters((prev: any) => ({
			...prev,
			index: "",
			objectId: "",
			region: "",
			city: "",
			address: "",
			contractorId: "",
			job: "",
			ObjectResponsibleFullName: "",
			WorkerFullname: "",
			CustomObjectId: "",
			company: "",
			workerStatusId: "",
		}));
	};
	console.log(monthsData);
	return (
		<>
			<div style={{ marginBottom: "20px" }}>
				<CommonBtn
					onClick={clear}
					type="light"
					text={L("Очистить фильтры", "Clear filters")}
				/>
			</div>
			<SelectCustom
				arrowPosition="far-right"
				styles={{ marginBottom: "16px" }}
				data={yearsDataReverse}
				setState={setFilters}
				stateName="year"
				initial={year}
				label={L("Год", "Year")}
			/>

			<MonthsSelectCustom
				arrowPosition="far-right"
				styles={{ marginBottom: "16px" }}
				data={monthsData}
				setState={setFilters}
				stateName="month"
				initial={L(
					getMonthFromNumber(month as monthKeyType),
					getMonthFromNumberEn(month as monthKeyType)
				)}
				label={L("Месяц", "Month")}
			/>

			<FilterAutocomplete
				setFilters={setFilters}
				filterName="company"
				options={data?.data?.workerFilterValues?.companies}
				selectedValue={chosenFilters?.companies?.value}
				label={L("Компания", "Company")}
				placeholder={L("Пример: Продукты", "Example: Grocery")}
				id="company"
			/>

			{checkObjectNames && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="objectId"
					options={data?.data?.workerFilterValues?.objectNames}
					id="objectId"
					selectedValue={chosenFilters?.objectNames?.value}
					label={L("Филиал", "Outlet")}
					placeholder={L("Пример: Продукты", "Example: Grocery")}
				/>
			)}

			<InputFilter
				ref={customIdRef}
				filterField="index"
				setFilters={setFilters}
				label={L("ID филиала", "Outlet ID")}
				placeholder={L("Пример: 24562", "Example: 24562")}
				type={"text"}
			/>

			{checkRegion && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="region"
					options={data?.data?.workerFilterValues?.regions}
					id="region"
					selectedValue={chosenFilters?.regions?.value}
					label={L("Регион", "Region")}
					placeholder={L("Пример: Омская область", "Example: Scotland")}
				/>
			)}

			{checkCity && (
				<FilterAutocomplete
					options={data?.data?.workerFilterValues?.cities}
					filterName="city"
					setFilters={setFilters}
					id="city"
					selectedValue={chosenFilters?.cities?.value}
					placeholder={L("Пример: Симферополь", "Example: Glasgow")}
					label={L("Город", "City")}
				/>
			)}

			<InputFilter
				ref={addrRef}
				filterField="address"
				setFilters={setFilters}
				label={L("Адрес", "Address")}
				placeholder={L(
					"Пример: Улица Амурская 1",
					"Example: St. John Street, 1"
				)}
				type={"text"}
			/>
			{jobTitles && (
				<FilterAutocomplete
					options={data?.data?.workerFilterValues?.jobTitles}
					filterName="job"
					setFilters={setFilters}
					id="job"
					selectedValue={chosenFilters?.jobTitles?.value}
					placeholder={L("Пример: Кассир", "Example: Cashier")}
					label={L("Должность", "Job title")}
				/>
			)}

			<InputFilter
				ref={workerRef}
				filterField="WorkerFullname"
				setFilters={setFilters}
				label={L("ФИО работника", "Employee's full name")}
				placeholder={L(
					"Пример: Иванов Иван Иванович",
					"Example: John Williams Smith"
				)}
				type={"text"}
			/>
			<div style={{ marginBottom: "3rem" }}>
				<FilterAutocomplete
					dropup
					setFilters={setFilters}
					filterName="workerStatusId"
					options={data?.data?.workerFilterValues?.workerStatuses}
					selectedValue={chosenFilters?.workerStatus?.value}
					label={L("Статус работника", "Employee's status")}
					placeholder={L("Выбраны все", "Default: All")}
				/>
			</div>
		</>
	);
}

export default TimeTableFiltersContractors;
