import { useState } from "react";
import UploadReqsWidgetS from "./UploadReqsWidgetS";
import { getReqsUploadTemplate } from "../../../../../api/for_support/support_worker_requests";

function UploadReqsS() {
	const [isShowMore, setIsShowMore] = useState(false);

	return (
		<div className="upload-container">
			<div className="">
				<UploadReqsWidgetS />
			</div>
			<div className="instruction">
				<div
					className="example-download-container"
					onClick={getReqsUploadTemplate}
				>
					<img
						src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
						alt=""
						width="25px"
					/>
					<p>Скачать пример файла</p>
				</div>
			</div>
		</div>
	);
}

export default UploadReqsS;
