//  "DD.MM.YYYY"  to "YYYY-MM-DD"

export function formatDate(date: string | undefined | null): string {
	if (date === undefined || date === null) {
		return "";
	}

	const parts = date.split(".");
	if (parts.length !== 3) {
		throw new Error("Invalid date format");
	}

	const day = parts[0].padStart(2, "0");
	const month = parts[1].padStart(2, "0");
	const year = parts[2];

	return `${year}-${month}-${day}`;
}

export function reverseFormatDate(date: string | undefined | null): string {
	if (date === undefined || date === null) {
		return "";
	}

	const parts = date.split("-");
	// if (parts.length !== 3) {
	// 	throw new Error("Invalid date format");
	// }

	const year = parts[0];
	const month = parts[1];
	const day = parts[2];

	return `${day}.${month}.${year}`;
}

// no padstart, 02.05.2023 -> 2023-05-02
export function formatDateFlipped(date: string | undefined | null): string {
	if (date === undefined || date === null) {
		return "";
	}

	const parts = date.split(".");
	const partsNew = [];
	partsNew.push(parts[2]);
	partsNew.push(parts[1]);
	partsNew.push(parts[0]);

	if (partsNew.length !== 3) {
		throw new Error("Invalid date format");
	}

	const day = partsNew[0];
	const month = partsNew[1];
	const year = partsNew[2];

	return `${year}-${month}-${day}`;
}
