export function isDateBeforeToday(dateString: string | "" | null): boolean {
	// YYYY-MM-DD
	if (!dateString) return false;
	const inputDate = new Date(dateString);
	const today = new Date();

	// Set hours to 0 to ignore time component and only compare dates
	inputDate.setHours(0, 0, 0, 0);
	today.setHours(0, 0, 0, 0);

	return inputDate < today;
}
