import { instanceV1 } from "./instances";

import { QueryFunctionContext } from "react-query";

export type THourlyRates = {
	workerRates: {
		year: number;
		monthId: number;
		month: number;
		monthDescr: string;
		companyId: number;
		companyName: string;
		regionId: number;
		regionName: string;
		cityId: number;
		cityName: string;
		contractorId: number;
		contractorName: string;
		jobTitleId: number;
		jobTitleName: string;
		hourlyRate: number | null;
		hourlyRateWithVat: number | null;
		workerRateId: number;
	}[];
	filterValues: {
		years: {
			[key: string]: number;
		};
		months: {
			[key: string]: string;
		};
		companies: {
			[key: string]: string;
		};
		regions: {
			[key: string]: string;
		};
		cities: {
			[key: string]: string;
		};
		contractors: {
			[key: string]: string;
		};
		jobTitles: {
			[key: string]: string;
		};
	};
	chosenFilters: {
		years: {
			key: any;
			value: any;
		};
		months: {
			key: any;
			value: any;
		};
		companies: {
			key: any;
			value: any;
		};
		regions: {
			key: any;
			value: any;
		};
		cities: {
			key: any;
			value: any;
		};
		contractors: {
			key: any;
			value: any;
		};
		jobTitles: {
			key: any;
			value: any;
		};
	};
};

export const getRates = async (q: QueryFunctionContext<any>) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let year = "";
	if (q?.queryKey[2]?.year) {
		if (q?.queryKey[2]?.year !== "1") {
			year = `&Year=${q?.queryKey[2]?.year}`;
		}
	}

	let month = "";
	if (q?.queryKey[2]?.month) {
		if (q?.queryKey[2]?.month !== "00") {
			month = `&Month=${q?.queryKey[2]?.month}`;
		}
	}

	let company = "";
	if (q?.queryKey[2]?.company) {
		company = `&CompanyId=${q?.queryKey[2]?.company}`;
	}

	let region = "";
	if (q?.queryKey[2]?.region) {
		region = `&RegionId=${q?.queryKey[2]?.region}`;
	}
	let city = "";
	if (q?.queryKey[2]?.city) {
		city = `&CityId=${q?.queryKey[2]?.city}`;
	}

	let contractor = "";
	if (q?.queryKey[2]?.contractor) {
		contractor = `&ContractorId=${q?.queryKey[2]?.contractor}`;
	}

	let job = "";
	if (q?.queryKey[2]?.job) {
		job = `&JobTitleId=${q?.queryKey[2]?.job}`;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}
	const response = await instanceV1.get<THourlyRates>(
		`HourlyRates${page}${year}${month}${company}${region}${city}${contractor}${job}${column}${order}${searchInput}`
	);
	return response;
};

// getCreation template

type TRatesCreationTemplate = {
	years: {
		[key: string]: number;
	};
	months: {
		[key: string]: string;
	};

	companies: {
		[key: string]: string;
	};

	geo: {
		[key: string]: string;
	};
	contractors: {
		[key: string]: string;
	};

	jobTitles: {
		[key: string]: string;
	};
};
export const getRatesCreationTemplate = async (
	q: QueryFunctionContext<any>
) => {
	let companyId = "";
	if (q?.queryKey[1]) {
		companyId = "?companyId=" + q.queryKey[1];
	}

	const response = await instanceV1.get<TRatesCreationTemplate>(
		`HourlyRates/getCreationTemplate${companyId}`
	);
	return response;
};

// post hourly rates

export const postHourlyRates = async (ratesInfo: any) => {
	const response = await instanceV1.post(
		"/HourlyRates",
		JSON.stringify(ratesInfo),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
};

export const deleteHourlyRates = async (workerRateId: number) => {
	const response = await instanceV1.delete(
		`/HourlyRates?workerRateId=${workerRateId.toString()}`
	);
	return response;
};

export const putHourlyRates = async (ratesInfo: any) => {
	const response = await instanceV1.put(
		"/HourlyRates",
		JSON.stringify(ratesInfo),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
};

type TGetRateInfo = {
	year: number;
	quarterId: number;
	quarter: number;
	monthId: number;
	month: number;
	monthDescr: string;
	monthDescrRu: string;
	companyId: number;
	companyName: string;
	isCompanyActive: boolean;
	cityId: number;
	cityName: string;
	regionId: number;
	regionName: string;
	geo: string;
	district: string;
	isGeoActive: boolean;
	contractorId: number;
	contractorName: string;
	isContractorActive: boolean;
	jobTitleId: number;
	jobTitleName: string;
	isJobTitleActive: boolean;
	workerRateId: number;
	hourlyRate: number;
	hourlyRateCreatedBy: string;
	hourlyRateCraetedOn: string;
	hourlyRateWithVat: string;
};
export const getRateInfo = async (q: QueryFunctionContext<any>) => {
	const response = await instanceV1.get<TGetRateInfo>(
		`HourlyRates/getInfo?workerRateId=${q.queryKey[1]}`
	);
	return response;
};

export const getRatesUploadTemplate = async () => {
	await instanceV1
		.get(`/HourlyRates/getUploadTemplate`, {
			responseType: "blob",
			headers: {
				"Content-Type":
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			},
		})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "file.xlsx");
			document.body.appendChild(link);
			link.click();
		});
};

export const uploadRates = async (file: any) => {
	const formData = new FormData();
	formData.append("file", file);

	const response = instanceV1.post("HourlyRates/uploadWorkerRates", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response;
};
