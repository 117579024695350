import React, { useState, useEffect } from "react";
import InfoSwitch from "../../../outlets/OutletInfo/InfoSwitch";
import CommonBtn from "../../../utility/Buttons/CommonBtn";
import { useQueryClient } from "react-query";
import MoreInfoItem from "../../../outlets/OutletInfo/MoreInfoItem";
import { sexCheck } from "../../../../utils/sexCheck";
import WorkerRequestCandidatesContractors from "./WorkerRequestCandidatesContractors";
import useLanguage from "../../../../hooks/useLanguage";

interface IProps {
	payload: {
		workerRequestId: number;
		companyId: number;
		companyName: string;
		regionId: number;
		region: string;
		cityId: number;
		city: string;
		objectId: number;
		objectName: string;
		position: number;
		schedule: string;
		sex: null | false | true;
		workStartDate: string;
		requestDeadline: string;
		workEndDate: string;
		comment: string | null;
		requestStatus: string;
		address: string;
		createdOn: string;
		numOfSentCandidates: number;
		hourlyRate: any;
		hourlyRateVAT: any;
		objectResponsibleFullName: any;
		objectResponsibleEmail: any;
		ObjectResponsiblePhoneNumber: any;
	};
	setModal: any;
	secondaryWhich: "edit" | "create" | "candidate";
	setSecondaryWhich: React.Dispatch<
		React.SetStateAction<"edit" | "create" | "candidate">
	>;
	modal: any;
}

function WorkerRequestInfoContractors({
	payload,
	setModal,
	secondaryWhich,
	setSecondaryWhich,
	modal,
}: IProps) {
	const { L } = useLanguage();

	const [active, setActive] = useState<"request" | "candidates" | "edit">(
		"request"
	);

	// get data from query on the page
	const client = useQueryClient();
	const dataQuery = client.getQueryData<any>("employeesRequestsContractors", {
		exact: false,
	});
	const getWorkerReqData = () => {
		const dataineed = dataQuery?.data?.workerRequests.filter(
			(workerReq: any) => {
				return workerReq.workerRequestId == payload.workerRequestId;
			}
		)[0];
		return dataineed;
	};

	// console.log(getWorkerReqData());

	// open secondary create candidate
	const openSecondaryCreateCandidate = () => {
		setSecondaryWhich("create");
		setModal((prev: any) => ({
			...prev,
			secondaryOpened: true,
		}));
	};

	if (active === "request") {
		const renderInfo = (
			<div className="center-container  hide-scrollbar">
				<div className="more-info-container">
					<MoreInfoItem
						name={L("Статус заявки", "Request status")}
						data={getWorkerReqData()?.requestStatus || payload.requestStatus}
					/>
					<MoreInfoItem
						name={L("№ Заявки", "Request ID")}
						data={payload.workerRequestId}
					/>
					<MoreInfoItem
						name={L("Филиал", "Outlet")}
						data={payload.objectName}
					/>
					<MoreInfoItem name={L("Регион", "Region")} data={payload.region} />
					<MoreInfoItem name={L("Город", "City")} data={payload.city} />
					<MoreInfoItem name={L("Адрес", "Address")} data={payload.address} />
					<MoreInfoItem
						name={L("ФИО ответственного", "Responsible's full name")}
						data={payload.objectResponsibleFullName}
					/>
					<MoreInfoItem
						name={L("Почта ответственного", "Responsible's email")}
						data={payload.objectResponsibleEmail}
					/>
					<MoreInfoItem
						name={L("Телефон ответственного", "Responsible's phone")}
						data={payload.ObjectResponsiblePhoneNumber}
					/>

					<MoreInfoItem
						name={L("Отправлено кандидатов", "Candidates sent")}
						data={
							getWorkerReqData().numOfSentCandidates
								? getWorkerReqData().numOfSentCandidates
								: payload.numOfSentCandidates
						}
					/>
					<MoreInfoItem
						name={L("График", "Schedule")}
						data={payload.schedule}
					/>
					<MoreInfoItem name={L("Пол", "Sex")} data={sexCheck(payload.sex)} />
					<MoreInfoItem
						name={L("Должность", "Job title")}
						data={payload.position}
					/>
					<MoreInfoItem name={L("Ставка", "Rate")} data={payload.hourlyRate} />
					<MoreInfoItem
						name={L("Ставка с НДС", "Rate with tax")}
						data={payload.hourlyRateVAT}
					/>
					<MoreInfoItem
						name={L("Комментарий", "Comment")}
						data={
							getWorkerReqData().comment
								? getWorkerReqData().comment
								: payload.comment
						}
					/>
					<MoreInfoItem
						name={L("Начало работы", "Work start")}
						data={
							payload.workStartDate ? payload.workStartDate.slice(0, 10) : ""
						}
					/>
					<MoreInfoItem
						name={L("Завершение работы", "Work end")}
						data={payload.workEndDate ? payload.workEndDate.slice(0, 10) : ""}
					/>
					<MoreInfoItem
						name={L("Дедлайн заявки", "Request deadline")}
						data={
							payload.requestDeadline
								? payload.requestDeadline?.slice(0, 10)
								: ""
						}
					/>
				</div>
			</div>
		);
		return (
			<div className="side-modal-container">
				<div>
					<InfoSwitch
						options={["request", "candidates"]}
						optionsNames={L(["Заявка", "Кандидаты"], ["Request", "Candidates"])}
						active={active}
						setActive={setActive}
					/>
					{renderInfo}
				</div>
				<div className="bottom-container">
					<CommonBtn
						onClick={openSecondaryCreateCandidate}
						text={L("Направить кандидата", "Send candidate")}
					/>
				</div>
			</div>
		);
	}

	if (active === "candidates") {
		return (
			<div>
				<InfoSwitch
					options={["request", "candidates"]}
					optionsNames={L(["Заявка", "Кандидаты"], ["Request", "Candidates"])}
					active={active}
					setActive={setActive}
				/>
				<WorkerRequestCandidatesContractors
					active={active}
					setActive={setActive}
					secondaryWhich={secondaryWhich}
					setSecondaryWhich={setSecondaryWhich}
					setModal={setModal}
					workerRequestId={payload.workerRequestId}
				/>
			</div>
		);
	}
	return <div>WorkerRequestInfoContractors</div>;
}

export default WorkerRequestInfoContractors;
