import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createContractor, editContractor, getInfoContractorsSupport } from '../../../../api/for_support/support_contractors';
import CommonBtn from '../../../utility/Buttons/CommonBtn';
import InputControlled from '../../../utility/InputControlled';
import { getAvailableCompanies } from '../../../../api/for_support/support_contractors';
import MultipleChoice from '../../../utility/MultipleChoice';
import useFeedback from '../../../../hooks/useFeedback';

interface IProps {
   setModal: any;
}

function CreateContractorSupport({setModal}:IProps) {
   const client = useQueryClient();
	const {openFeedback, closeFeedback} = useFeedback();
   const [edited, setEdited] = useState({
      contractorName: "",
		firstname: "",
		lastname: "",
		middlename: "",
      email: "",
      companyIds: [],
		phoneNumber: "",
		phoneNumberWithoutCode: "",
	});

   	// required states
	const [contractornamereq, setcontractornamereq] = useState(false);
	const [firstnamereq, setfirstnamereq] = useState(false);
	const [emailreq, setemailreq] = useState(false);
	const [lastnamereq, setlastnamereq] = useState(false);
	const [phoneNumberreq, setphoneNumberreq] = useState(false);
   const [compreq, setcompreq] = useState(false)

   
   // get companies
   const {data: companies, isSuccess:gotCompanies} = useQuery("getCompanies", getAvailableCompanies);

  

 

   const { mutate, error } = useMutation<any, any, any>(createContractor, {
		onSuccess: () => {
			openFeedback("Подрядчик успешно создан", "success");
         setModal({opened:false})
			setTimeout(() => {
				client.invalidateQueries("contractors");
				client.invalidateQueries("infoContractorSupport");
			}, 150);
		},
		onError: () => openFeedback("Пожалуйста, проверьте поля", "error"),
	});
   
   const whichError = () => {
		if (error) {
			if (error?.response?.status === 410)
				return "Пользователь с такой почтой уже существует";
		} else {
			return "Поле должно быть заполнено";
		}
	};

   const putContractor = () => {
      if (!edited.firstname) setfirstnamereq(true);
		if (!edited.lastname) setlastnamereq(true);
		if (!edited.email) setemailreq(true);
      if (edited.companyIds.length < 1) setcompreq(true)
      if (!edited.contractorName) setcontractornamereq(true)



      if (edited.firstname) setfirstnamereq(false);
		if (edited.email) setemailreq(false);
		if (edited.lastname) setlastnamereq(false);
      if (edited.phoneNumberWithoutCode) setphoneNumberreq(false);
      if (edited.companyIds.length >= 1) setcompreq(false)
      if (edited.contractorName) setcontractornamereq(false)


      if (error) {
			if (error.response.status === 410) setemailreq(true);
		}

      if (edited.phoneNumberWithoutCode) {
			mutate({
				...edited,
				phoneNumber: "+7" + edited.phoneNumberWithoutCode,
			});
		} else {
			mutate(edited);
		}
   }


   return (
    <div className='form-inner side-modal-container'>
      <div className='center-container-1'>
         <form>
         <InputControlled
					label="Название*"
					setState={setEdited}
					stateName="contractorName"
					requiredState={contractornamereq}
					value={edited.contractorName}
				/>
         <InputControlled
					label="Имя*"
					setState={setEdited}
					stateName="firstname"
					requiredState={firstnamereq}
					value={edited.firstname}
				/>
				<InputControlled
					label="Фамилия*"
					setState={setEdited}
					stateName="lastname"
					requiredState={lastnamereq}
					value={edited.lastname}
				/>
				<InputControlled
					label="Отчество"
					setState={setEdited}
					stateName="middlename"
					value={edited.middlename}
				/>
            <InputControlled
					label="Email*"
					setState={setEdited}
					stateName="email"
					value={edited.email}
					requiredState={emailreq}
					errorMessage={whichError()}
				/>
            <label className="form-label" htmlFor="phoneNumber">
						Телефон
					</label>
					<div className="phonefield-container">
						<input
							style={phoneNumberreq ? { border: "1px solid #ff3b30" } : {}}
							value={edited.phoneNumberWithoutCode}
							onChange={(e: any) =>
								setEdited((prev: any) => ({
									...prev,
									phoneNumberWithoutCode: e.target.value.toString(),
									phoneNumber: e.target.value.toString(),
								}))
							}
							type="telephone"
							id="phoneNumber"
							className=" phonefield form-control"
						/>
					</div>
					{phoneNumberreq && (
						<p className="input-validation-text">обязательное поле</p>
					)}
            <div style={{zIndex: 10000, marginTop:"-15px"}}>
            {gotCompanies && <MultipleChoice
            key={"sfsf"}
            data={companies?.data}
            stateData={edited.companyIds}
            setState={setEdited}
            stateName='companyIds'
            arrowPosition='far-right'
            label='Компании'
            requiredState={compreq}
            />}
            </div>

					
         </form>
      </div>
      <div className="bottom-container-3">
				<div className="flex">
					<CommonBtn onClick={() => setModal({opened: false})} text="Отменить" type="light" />
					<CommonBtn onClick={() => putContractor()}  text="Сохранить" />
				</div>
			</div>
    </div>
  )
}

export default CreateContractorSupport