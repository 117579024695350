import { createContext, useState } from "react";
import { TNotifications } from "../api/notifications";

interface INotificationContextChildren {
	children: React.ReactNode;
}

// can add | and something else in the future
type TNotificationContext = {
	notifications: TNotifications | null;
	setNotifications: React.Dispatch<React.SetStateAction<TNotifications | null>>;
};

const NotificationContext = createContext<TNotificationContext>({
	notifications: null, // not filled by default
	setNotifications: () => {},
});

export const NotificationsProvider: React.FC<INotificationContextChildren> = ({
	children,
}) => {
	const [notifications, setNotifications] = useState<TNotifications | null>(
		null
	);
	return (
		<NotificationContext.Provider value={{ notifications, setNotifications }}>
			{children}
		</NotificationContext.Provider>
	);
};
export default NotificationContext;
