import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { editWorkerReq, getWorkerRequestInfo } from "../../../../api/employees";
import { getWorkerRequestCreationTemplate } from "../../../../api/employees";
import {
	getAddresses,
	getCurrentAddress,
	getCurrentGeo,
	getGeopositions,
} from "../../../../api/objects";
import InputAutocomplete from "../../../../components/utility/InputAutocomplete";
import SelectCustom from "../../../../components/utility/SelectCustom";
import InputControlled from "../../../../components/utility/InputControlled";
import CommonBtn from "../../../../components/utility/Buttons/CommonBtn";
import useFeedback from "../../../../hooks/useFeedback";
import MultipleChoice from "../../../../components/utility/MultipleChoice";
import {
	editWorkerReqS,
	getAddressesS,
	getAvaliableCompanies,
	getCurrentAddressS,
	getCurrentGeoS,
	getGeopositionsS,
	getWorkerRequestCreationTemplateS,
	getWorkerRequestInfoS,
} from "../../../../api/for_support/support_worker_requests";

interface IProps {
	workerRequestId: number;
	setModal: any;
}
function EmployeeReqEditS({ workerRequestId, setModal }: IProps) {
	const [edited, setEdited] = useState({
		workerRequestId: workerRequestId,
		companyId: null,
		objectId: null,
		cityId: null,
		address: null,
		jobTitleId: null,
		workScheduleId: null,
		sex: null,
		contractorIds: [],
		workStartDate: null,
		workEndDate: null,
		comment: "",
		deadline: null,
		cityIdChangedFromInitial: false,
	});

	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();

	// mutate and valiadate
	const { mutate, isSuccess: isDone } = useMutation(editWorkerReqS, {
		onSuccess: () => {
			openFeedback("Заявка успешно изменена", "success");
			setTimeout(() => {
				client.invalidateQueries("employeesRequests");
				closeModal();
			}, 400);
		},
		onError: () => {
			openFeedback("Что-то пошло не так", "error");
		},
	});

	const roleNotDirector = localStorage.getItem("role") != "Director";
	const change = () => {
		const whatIsSex = (sex: string | null) => {
			if (sex === "true") {
				return true;
			}
			if (sex === "false") {
				return false;
			}
			if (sex === "null") {
				return null;
			} else {
				return null;
			}
		};
		const realSex = whatIsSex(edited.sex);

		mutate({
			...edited,
			deadline: edited.deadline ? edited.deadline : null,
			workEndDate: edited.workEndDate ? edited.workEndDate : null,
			sex: realSex,
		});
		if (roleNotDirector) {
			if (!edited.objectId) setobjreq(true);
			if (!edited.address) setaddrreq(true);
			if (!edited.cityId) setgeoreq(true);
		}
		if (!edited.workScheduleId) setschreq(true);
		if (!edited.jobTitleId) setjobreq(true);
		if (!edited.contractorIds[0]) setcontractorreq(true);
		if (!edited.workStartDate) setstartreq(true);
		if (edited.cityId) setgeoreq(false);
		if (edited.objectId) setobjreq(false);
		if (edited.address) setaddrreq(false);
		if (edited.workScheduleId) setschreq(false);
		if (edited.jobTitleId) setjobreq(false);
		if (edited.contractorIds[0]) setcontractorreq(false);
		if (edited.workStartDate) setstartreq(false);
	};

	// validation states
	const [compreq, setcompreq] = useState(false);
	const [objreq, setobjreq] = useState(false); //филиал
	const [georeq, setgeoreq] = useState(false);
	const [addrreq, setaddrreq] = useState(false);
	const [jobreq, setjobreq] = useState(false); //job
	const [contractorreq, setcontractorreq] = useState(false); //contractors
	const [schreq, setschreq] = useState(false); //schedule (5/2)
	const [startreq, setstartreq] = useState(false); //work start

	// ref to clear address
	const addrRef = useRef<any>(null);

	// close modal
	const closeModal = () => {
		setModal((prev: any) => ({
			...prev,
			opened: false,
		}));
	};

	// get company

	const { data: companies, isSuccess: isCompanies } = useQuery(
		"getAvaliableCompanies",
		getAvaliableCompanies
	);

	// get worker request creation template
	const { data: template, isSuccess: isTemplate } = useQuery(
		["workerRequestTemplate", edited.companyId],
		getWorkerRequestCreationTemplateS
	);

	// get worker request info
	const { data: workerReqData, isSuccess: isWorkerReqData } = useQuery(
		["workerRequestInfo", { workerRequestId: workerRequestId }],
		(q) => getWorkerRequestInfoS(q),
		{
			refetchOnWindowFocus: false,
			refetchInterval: false,
		}
	);

	// initial; fill the state with data from worker requestInfo
	useEffect(() => {
		const dataInfo = workerReqData?.data.workerRequestInfo;
		if (!dataInfo) return;
		const {
			companyId,
			objectId,
			cityId,
			jobTitleId,
			workScheduleId,
			workStartDate,
			deadline,
			workEndDate,
			comment,
			sex,
		} = dataInfo;

		setEdited((prev) => ({
			...prev,
			...(companyId && { companyId: companyId.toString() }),
			...(objectId && { address: objectId.toString() }),
			...(objectId && { objectId: objectId.toString() }),
			...(cityId && { cityId }),
			...(jobTitleId && { jobTitleId }),
			...(workScheduleId && { workScheduleId }),
			...(workStartDate && { workStartDate: workStartDate.slice(0, 10) }),
			...(deadline && { deadline: deadline.slice(0, 10) }),
			...(workEndDate && { workEndDate: workEndDate.slice(0, 10) }),
			...(comment && { comment }),
			...(sex && { sex }),
			...(workerReqData?.data?.contractorIds && {
				contractorIds: workerReqData.data.contractorIds.map((id) =>
					id.toString()
				),
			}),
		}));
	}, [workerReqData]);

	// get geolocation (region and city)
	const { data: geo, isSuccess: isGeo } = useQuery(
		["getGeos", edited.companyId, edited.objectId],
		(q) => getGeopositionsS(q),
		{
			enabled: !!edited.objectId,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);

	// current geoposition (string)
	const { data: currentGeo, isSuccess: isCurrentGeo } = useQuery(
		["getCurrentGeo", edited.objectId],
		(q) => getCurrentGeoS(q),
		{
			enabled: isGeo,
			refetchOnWindowFocus: false,
			// refetchInterval: false,
		}
	);

	// compare current geo with geos and getting city id
	useEffect(() => {
		const compareGeos = () => {
			if (isGeo && isCurrentGeo) {
				const currentName = currentGeo.data.geoposition; // now find the key to put into state...

				const findKey = () => {
					let correspondingKey = "";
					for (const [key, value] of Object.entries(geo.data)) {
						if (value === currentName) {
							correspondingKey = key;
							setEdited((prev: any) => ({
								...prev,
								cityId: correspondingKey,
							}));
						}
					}
				};
				findKey();
			}
		};
		compareGeos();
	}, [isCurrentGeo, isGeo]);

	// CROTCH TO MAKE ADDRESS FIELD WORK
	useEffect(() => {
		if (addrRef.current) {
			const inputAddr = addrRef.current.getInput();
			inputAddr.value = "";
		}
	}, [edited.cityId]);

	useEffect(() => {
		if (addrRef.current) {
			if (edited.address != edited.objectId) {
				const inputAddr = addrRef.current.getInput();
				inputAddr.value = "";
			}
		}
	}, [edited.objectId]);

	// get addresses
	const { data: addresses, isSuccess: isAddresses } = useQuery(
		["getAddresses", edited.companyId, edited.objectId, edited.cityId],
		(q) => getAddressesS(q),
		{
			enabled: !!edited.cityId,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);

	// current address
	const { data: currentAddr, isSuccess: isCurrentAddress } = useQuery(
		["getCurrentAddress", edited.objectId],
		(q) => getCurrentAddressS(q),
		{
			enabled: isAddresses,
			refetchOnWindowFocus: false,
		}
	);

	// compare current address with addresses and getting address id
	useEffect(() => {
		const compareAddresses = () => {
			if (isGeo && isCurrentGeo) {
				const currentName = currentAddr?.data; // now find the key to put into state...

				const findKey = () => {
					let correspondingKey = "";
					for (const [key, value] of Object.entries(geo.data)) {
						if (value === currentName) {
							correspondingKey = key;
							setEdited((prev: any) => ({
								...prev,
								address: correspondingKey,
							}));
						}
					}
				};
				findKey();
			}
		};
		compareAddresses();
	}, [isCurrentGeo, isGeo]);

	// sex
	const sexOptions = {
		null: "Без разницы",
		true: "Мужчины",
		false: "Женщины",
	};
	// console.log(companies?.data);
	// mutate

	const objectNameRef = useRef(null);
	useEffect(() => {
		if (objectNameRef.current !== null) {
			//@ts-ignore
			console.log(objectNameRef.current.getInput());
			//@ts-ignore
			objectNameRef.current.getInput().value = "";
			// objectNameRef.current.;
		}
		// objectNameRef?.current?.input = "";
	}, [edited.companyId]);

	return (
		<>
			<div className="form-inner">
				<div style={{ minHeight: "83vh" }}>
					{/*
					For Debug
					<div>
						<p>debug</p>
						<p>city {edited.cityId}</p>
						<p>addr {edited.address}</p>
						<p>objId {edited.objectId}</p>
					</div> */}
					{workerReqData?.data?.workerRequestInfo?.companyId &&
						companies?.data && (
							<InputAutocomplete
								data={companies?.data}
								defaultInputVal={workerReqData?.data?.workerRequestInfo?.companyName.toString()}
								setState={setEdited}
								stateNames={["companyId"]}
								stateNamesToNull={["address", "cityId", "objectId"]}
								// clearRefs={[addrRef, geoRef, objectNameRef]}
								id="companyId"
								label="Компания*"
								requiredState={compreq}
							/>
						)}
					{edited.cityId && isWorkerReqData && roleNotDirector && (
						<div className="mt-2">
							<InputAutocomplete
								ref={objectNameRef}
								data={template?.data?.objectNames}
								defaultInputVal={
									workerReqData?.data?.workerRequestInfo.objectName
								}
								id="outlet"
								setState={setEdited}
								stateNames={["objectId", "cityIdChangedFromInitial"]}
								stateNamesToNull={["cityId", "address"]}
								label="Филиал*"
								required
								requiredState={objreq}
							/>
						</div>
					)}

					{!edited.cityId && isWorkerReqData && (
						<div className="mt-2">
							<InputAutocomplete
								ref={objectNameRef}
								data={template?.data?.objectNames}
								defaultInputVal={""}
								id="outlet"
								setState={setEdited}
								stateNames={["objectId", "cityIdChangedFromInitial"]}
								stateNamesToNull={["cityId", "address"]}
								label="Филиал*"
								required
								requiredState={objreq}
							/>
						</div>
					)}

					{isGeo && isCurrentGeo && (
						<div className="mt-2">
							<InputAutocomplete
								data={geo.data}
								defaultInputVal={currentGeo?.data.geoposition}
								id="geo"
								setState={setEdited}
								stateNames={["cityId", "cityIdChangedFromInitial"]}
								stateNamesToNull={["address"]}
								label="Регион и город*"
								required
								requiredState={georeq}
							/>
						</div>
					)}

					{/* ~~~~~~~MEGA LEVEL CROTCH~~~~~~~ */}

					{isAddresses &&
						isCurrentAddress &&
						!edited.cityIdChangedFromInitial && (
							<InputAutocomplete
								data={addresses.data}
								defaultInputVal={currentAddr.data}
								id="geo"
								setState={setEdited}
								stateNames={["address", "objectId"]}
								label="Адрес*"
								required
								requiredState={addrreq}
							/>
						)}

					{/* the one that is real. */}
					{edited.cityIdChangedFromInitial && edited.cityId && (
						<InputAutocomplete
							ref={addrRef}
							data={isAddresses && addresses.data}
							id="geo"
							setState={setEdited}
							stateNames={["address", "objectId"]}
							label="Адрес*"
							required
							requiredState={addrreq}
						/>
					)}

					{!edited.cityId && (
						<InputAutocomplete
							data={isAddresses && addresses.data}
							id="geo"
							setState={setEdited}
							stateNames={["address", "objectId"]}
							label="Адрес*"
							disabled={true}
							required
							requiredState={addrreq}
						/>
					)}

					{/* ~~~~~~~MEGA LEVEL CROTCH~~~~~~~ */}

					<SelectCustom
						// styles={{ marginBottom: "12px", marginTop: "12px" }}
						label="Должность*"
						data={template?.data.jobTitles}
						setState={setEdited}
						stateName="jobTitleId"
						initial={workerReqData?.data.workerRequestInfo.position}
						requiredState={schreq}
						arrowPosition="far-right"
					/>
					{template?.data.contractors && (
						<MultipleChoice
							data={template?.data.contractors}
							setState={setEdited}
							stateName="contractorIds"
							stateData={edited.contractorIds}
							label="Подрядчики*"
							requiredState={contractorreq}
						/>
					)}

					{workerReqData && (
						<div>
							<InputControlled
								setState={setEdited}
								value={edited.workStartDate}
								stateName="workStartDate"
								type="date"
								label="Начало работы*"
								requiredState={startreq}
							/>
						</div>
					)}
					{workerReqData && (
						<InputControlled
							setState={setEdited}
							value={edited.workEndDate}
							stateName="workEndDate"
							type="date"
							label="Окончание работы"
						/>
					)}

					<SelectCustom
						styles={{ marginBottom: "16px" }}
						label="Пол"
						data={sexOptions}
						setState={setEdited}
						stateName="sex"
						initial={workerReqData?.data.workerRequestInfo.sexString}
						arrowPosition="far-right"
					/>

					<SelectCustom
						styles={{ marginBottom: "12px" }}
						label="График работы*"
						data={template?.data.workSceduleTitles}
						setState={setEdited}
						stateName="workScheduleId"
						initial={workerReqData?.data.workerRequestInfo.schedule}
						requiredState={schreq}
						arrowPosition="far-right"
					/>

					{workerReqData && (
						<div style={{ marginBottom: "12px" }}>
							<InputControlled
								setState={setEdited}
								value={edited.deadline}
								stateName="deadline"
								type="date"
								label="Дедлайн подрядчика"
							/>
						</div>
					)}
					<InputControlled
						value={edited.comment}
						setState={setEdited}
						stateName="comment"
						label="Комментарий к заявке"
					/>
				</div>
				<div
					style={{
						borderTop: "1px solid #f7f8fa",
						paddingTop: "20px",
						marginTop: "50px",
						marginBottom: "30px",
						display: "flex",
						justifyContent: "space-around",
						// position: "absolute",
						width: "100%",
						// bottom: "50px",
					}}
					className="btns"
				>
					<CommonBtn
						styles={{ width: "185px" }}
						text="отменить"
						type="light"
						onClick={closeModal}
					/>
					<CommonBtn
						styles={{ width: "185px" }}
						text="Сохранить"
						onClick={change}
					/>
				</div>
			</div>
		</>
	);
}

export default EmployeeReqEditS;
