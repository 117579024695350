import { instanceV3 } from "../instances";
import { QueryFunctionContext } from "react-query";

interface IEmployees {
	workers: {
		companyId: number;
		companyName: string;
		regionId: number;
		region: string;
		cityId: number;
		city: string;
		objectId: number;
		objectName: string;
		workerId: number;
		workerFullname: string;
		jobTitleId: number;
		position: string;
		citizenship: string;
		workStartDate: string;
		workEndDate: string;
		address: string;
		workerStatusId: number;
		workerStatusName: string;
		birthday: string;
		workerSexString: string;
		passport: string;
		phoneNumber: string;
		totalWorkedHours: number;
		externalRate: number;
		externalRateVat: number;
		costs: number;
		costsWithVat: number;
		objectResponsibleFullName: any;
		objectResponsibleEmail: any;
		objectResponsiblePhoneNumber: any;
		//  worked hours approved stuff
		workedHoursNotApproved: number;
		workedHoursInReview: number;
		workedHoursApprovedByContractor: number;
		workedHoursApprovedByChainManager: number;
		costsWithVatNotApproved: any;
		costsWithVatNotInReview: any;
		costsWithVatApprovedByContractor: any;
		costsWithVatApprovedByChainManager: any;
		workerStatusNameForTable: string;
		customObjectId: any;
	}[];
	totalWorkerStats: {
		sumTotalWorkedHours: number;
		sumWorkedHoursNotApproved: number;
		sumWorkedHoursInReview: number;
		sumWorkedHoursApprovedByContractor: number;
		sumWorkedHoursApprovedByChainManager: number;
		sumCostsWithVatWorkedHoursNotApproved: number;
		sumCostsWithVatWorkedHoursInReview: number;
		sumCostsWithVatWorkedHoursApprovedByContractor: number;
		sumCostsWithVatWorkedHoursApprovedByChainManager: number;
		sumCosts: number;
		sumCostsWithVat: number;
		avgExternalRate: any;
		avgExternalRateVat: any;
	};
	workerFilterValues: {
		regions: {
			[key: string]: string;
		};
		cities: {
			[key: string]: string;
		};
		objectNames: {
			[key: string]: string;
		};
		jobTitles: {
			[key: string]: string;
		};
		workerStatuses: {
			[key: string]: string;
		};
		contractorNames: {
			[key: string]: string;
		};
		hourStatuses: {
			[key: string]: string;
		};
	};
	chosenFilters: {
		cities: any;
		regions: any;
		contractorNames: any;
		objectNames: any;
		workerStatuses: any;
		jobTitles: any;
		hoursStatuses: any;
		workerStatus: any;

		startWorkDate: string;
		endWorkDate: string;
	};
}
export const getEmployeesContractors = async (q: QueryFunctionContext<any>) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let region = "";
	if (q?.queryKey[2]?.region) {
		region = `&RegionId=${q?.queryKey[2]?.region}`;
	}
	let city = "";
	if (q?.queryKey[2]?.city) {
		city = `&CityId=${q?.queryKey[2]?.city}`;
	}

	let outlet = "";
	if (q?.queryKey[2]?.outlet) {
		outlet = `&ObjectId=${q?.queryKey[2]?.outlet}`;
	}

	let address = "";
	if (q?.queryKey[2]?.address) {
		address = `&Address=${q?.queryKey[2]?.address}`;
	}

	let job = "";
	if (q?.queryKey[2]?.job) {
		job = `&JobTitleId=${q?.queryKey[2]?.job}`;
	}

	let ObjectId = "";
	if (q?.queryKey[2]?.ObjectId) {
		ObjectId = `&CustomObjectId=${q?.queryKey[2]?.ObjectId}`;
	}
	let customObjectId = "";
	if (q?.queryKey[2]?.index) {
		customObjectId = `&CustomObjectId=${q?.queryKey[2]?.index}`;
	}

	let contractorId = "";
	if (q?.queryKey[2]?.contractorId) {
		contractorId = `&contractorId=${q?.queryKey[2]?.contractorId}`;
	}

	let WorkerFullname = "";
	if (q?.queryKey[2]?.WorkerFullname) {
		WorkerFullname = `&WorkerFullname=${q?.queryKey[2]?.WorkerFullname}`;
	}

	let ObjectResponsibleFullName = "";
	if (q?.queryKey[2]?.ObjectResponsibleFullName) {
		ObjectResponsibleFullName = `&ObjectResponsibleFullName=${q?.queryKey[2]?.ObjectResponsibleFullName}`;
	}

	let StartWorkDate = "";
	if (q?.queryKey[2]?.StartWorkDate) {
		StartWorkDate = `&StartWorkDate=${q?.queryKey[2]?.StartWorkDate}`;
	}

	let EndWorkDate = "";
	if (q?.queryKey[2]?.EndWorkDate) {
		EndWorkDate = `&EndWorkDate=${q?.queryKey[2]?.EndWorkDate}`;
	}
	let hoursStatusId = "";
	if (q?.queryKey[2]?.hoursStatusId) {
		hoursStatusId = `&HoursStatusId=${q?.queryKey[2]?.hoursStatusId}`;
	}

	let company = "";
	if (q?.queryKey[2]?.company) {
		company = `&CompanyId=${q?.queryKey[2]?.company}`;
	}

	let workerStatusId = "";
	if (q?.queryKey[2]?.workerStatusId) {
		workerStatusId = `&workerStatusId=${q?.queryKey[2]?.workerStatusId}`;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}

	const response = instanceV3.get<IEmployees>(
		`Workers${page}&PageSize=100${region}${city}${job}${WorkerFullname}${ObjectResponsibleFullName}${StartWorkDate}${EndWorkDate}${contractorId}${ObjectId}${column}${order}${address}${outlet}${company}${searchInput}${customObjectId}${hoursStatusId}${workerStatusId}`
	);
	return response;
};

//time table v3

export const getWorkTimeSheetContractors = async (
	q: QueryFunctionContext<any>
) => {
	let page = "";
	if (q?.queryKey[2]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "&pageNumber=" + q.queryKey[2].pageNumber;
	}

	let objectId = "";
	if (q?.queryKey[1]?.objectId) {
		objectId = `&objectId=${q?.queryKey[1]?.objectId}`;
	}

	let region = "";
	if (q?.queryKey[1]?.region) {
		region = `&regionId=${q?.queryKey[1]?.region}`;
	}

	let city = "";
	if (q?.queryKey[1]?.city) {
		city = `&cityId=${q?.queryKey[1]?.city}`;
	}

	let contractorId = "";
	if (q?.queryKey[1]?.contractorId) {
		contractorId = `&contractorId=${q?.queryKey[1]?.contractorId}`;
	}

	let job = "";
	if (q?.queryKey[1]?.job) {
		job = `&jobTitleId=${q?.queryKey[1]?.job}`;
	}

	let workerId = "";
	if (q?.queryKey[1]?.workerId) {
		workerId = `&workerId=${q?.queryKey[1]?.workerId}`;
	}

	let company = "";
	if (q?.queryKey[1]?.company) {
		company = `&CompanyId=${q?.queryKey[1]?.company}`;
	}

	let WorkerFullname = "";
	if (q?.queryKey[1]?.WorkerFullname) {
		WorkerFullname = `&WorkerFullname=${q?.queryKey[1]?.WorkerFullname}`;
	}

	let ObjectResponsibleFullName = "";
	if (q?.queryKey[1]?.ObjectResponsibleFullName) {
		ObjectResponsibleFullName = `&ObjectResponsibleFullName=${q?.queryKey[1]?.ObjectResponsibleFullName}`;
	}
	let customObjectId = "";
	if (q?.queryKey[1]?.index) {
		customObjectId = `&CustomObjectId=${q?.queryKey[1]?.index}`;
	}
	let workerStatusId = "";
	if (q?.queryKey[1]?.workerStatusId) {
		workerStatusId = `&workerStatusId=${q?.queryKey[1]?.workerStatusId}`;
	}
	let year = "";
	if (q?.queryKey[1]?.year) {
		year = `&year=${q?.queryKey[1]?.year}`;
	}

	let month = "";
	if (q?.queryKey[1]?.month) {
		month = `&month=${q?.queryKey[1]?.month}`;
	}

	let searchInput = "";
	if (q?.queryKey[1]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[1]?.searchInput}`;
	}
	const response = await instanceV3.get<any>(
		`Workers/getWorkTimeSheet?CompanyId=1&${objectId}${workerId}${year}${month}${region}${city}${contractorId}${job}${WorkerFullname}${page}${ObjectResponsibleFullName}${searchInput}${company}${customObjectId}${workerStatusId}`
	);
	return response;
};

// download

export const dlEmployees = async (
	page: number,
	filters: any,
	datestart: any,
	dateend: any
) => {
	let pageNumber = `?pageNumber=${page}`;
	const ContractorId = `&contractorId=${filters.ContractorId}`;
	const region = `&RegionId=${filters.region}`;
	const city = `&CityId=${filters.city}`;
	const outlet = `&ObjectId=${filters.outlet}`;
	const address = `&Address=${filters.address}`;
	const job = `&JobTitleId=${filters.job}`;
	const worker = `&WorkerFullname=${filters.WorkerFullname}`;
	const responsible = `&ObjectResponsibleFullName=${filters.ObjectResponsibleFullName}`;
	const start = `&StartWorkDate=${datestart}`;
	const end = `&EndWorkDate=${dateend}`;

	await instanceV3
		.get(
			`/Workers${pageNumber}${ContractorId}${region}${city}${outlet}${address}${job}${worker}${responsible}${start}${end}&download=true`,
			{
				responseType: "blob",
				headers: {
					"Content-Type":
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				},
			}
		)
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "file.xlsx");
			document.body.appendChild(link);
			link.click();
		});
};

export const getEmployeeInfoContractor = async (
	q: QueryFunctionContext<any>
) => {
	// add start and end when its ready

	const response = await instanceV3.get<any>(
		`Workers/getinfo?workerId=${q?.queryKey[1]}&startWorkDate=${q?.queryKey[2]}&endWorkDate=${q?.queryKey[3]}`
	);
	return response;
};
