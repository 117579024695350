import { QueryFunctionContext } from "react-query";
import { instanceV0 } from "./instances";
export type TNotifications = {
	notifications: {
		page: string;
		pageLink: string;
		title: string | null;
		content: string | null;
		column: string | null;
	}[];
};
export type TNotificationElement = TNotifications["notifications"][number];
export const getNotifications = async (q: QueryFunctionContext) => {
	const response = instanceV0.get<TNotifications>(
		`notifications?page=${q.queryKey[1]}`
	);
	return response;
};
