import React, { useState } from "react";
import MoreInfoItem from "../../components/outlets/OutletInfo/MoreInfoItem";
import CommonBtn from "../../components/utility/Buttons/CommonBtn";
import EmployeeChangeStatus from "./EmployeeChangeStatus";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addThousandSeparator } from "../../utils/thousandSeparator";
import { sliceString } from "../../utils/sliceString";
import { getEmployeeInfo } from "../../api/employees";
import useLanguage from "../../hooks/useLanguage";
import ConfirmModal from "../../components/utility/confirmModal/ConfirmModal";
import { changeCreds } from "../../api/auth";
import DeleteBtn from "../../components/utility/Buttons/DeleteBtn";

interface IEmployeeInfoProps {
	payload: any;
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			secondaryOpened?: boolean;
		}>
	>;
	modal: {
		opened: boolean;
		payload?: any;
		secondaryOpened?: boolean;
	};
	secondaryOpened: boolean;
	setWhichSecondary: React.Dispatch<React.SetStateAction<"hours" | "edit">>;
	whichSecondary: "hours" | "edit";
	startWorkDate: string;
	endWorkDay: string;
}

function EmployeeInfo({
	payload,
	setModal,
	modal,
	secondaryOpened = false,
	whichSecondary,
	setWhichSecondary,
	startWorkDate,
	endWorkDay,
}: IEmployeeInfoProps) {
	const { L } = useLanguage();

	const [hidden, setHidden] = useState(true);
	const switchHidden = () => {
		setHidden((prev) => !prev);
	};

	const switchSecondaryHours = () => {
		if (secondaryOpened && whichSecondary === "edit") {
			setWhichSecondary("hours");
		}

		if (secondaryOpened && whichSecondary === "hours") {
			setModal((prev) => ({ ...modal, secondaryOpened: false }));
		}

		if (!secondaryOpened && whichSecondary === "edit") {
			setWhichSecondary("hours");
			setModal((prev) => ({ ...modal, secondaryOpened: true }));
		}

		if (!secondaryOpened && whichSecondary === "hours") {
			setModal((prev) => ({ ...modal, secondaryOpened: true }));
		}
	};

	const switchSecondaryEdit = () => {
		setWhichSecondary("edit");
		setModal((prev) => ({ ...modal, secondaryOpened: true }));
	};

	const hoursText = () => {
		if (secondaryOpened && whichSecondary === "hours") {
			return L("Скрыть рабочие часы", "Hide working hours");
		} else {
			return L("Выставить рабочие часы", "Set working hours");
		}
	};

	//	 GET INFO
	const client = useQueryClient();
	const dataQuery = client.getQueryData<any>("employees", {
		exact: false,
	});
	const getWorkerData = () => {
		const dataineed = dataQuery.data.workers.filter((worker: any) => {
			return worker.workerId == payload.workerId;
		})[0];
		return dataineed;
	};

	// get data
	const { data, isSuccess } = useQuery(
		["employeeInfo", payload.workerId, startWorkDate, endWorkDay],
		(q) => getEmployeeInfo(q)
	);
	const workerData = data?.data.workerInfo;

	// role check
	const roleNotDirector = localStorage.getItem("role") != "Director";
	const roleNotUnder = localStorage.getItem("role") != "UnderChainManager";
	// console.log(getWorkerData());
	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};
	const { mutate: mutateChangeCreds } = useMutation(changeCreds, {
		onSuccess: () => {
			openFeedback(
				L("Пароль успешно изменен", "Password has been changed"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("respInfo");
				closeConfirmation();
			}, 150);
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});
	const openChangeCreds = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Изменение пароля", "Change the password")}
					text={L(
						"Вы уверены, что хотите сгенерировать новый пароль для работника?",
						"Are you sure you want to generate a new password for this employee?"
					)}
				>
					<DeleteBtn
						text={L("Да, изменить", "Yes, change")}
						onClick={() => {
							mutateChangeCreds(payload.workerId);
							closeConfirmation();
							// closeModal();
							setTimeout(() => {
								client.invalidateQueries("outlets");
								client.invalidateQueries("respInfo");
							}, 150);
							// setModal({opened:false})
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};
	return (
		<div>
			<EmployeeChangeStatus
				workerStatusId={payload.workerStatusId}
				workerId={payload.workerId}
				workerStatusName={payload.workerStatusName}
				setModal={setModal}
			/>
			<div
				// style={{ maxHeight: "65vh", height: "65vh" }}
				className="more-info-container scrolled"
			>
				<div className="divider-sm"></div>
				<MoreInfoItem
					name={L("ID филиала", "Outlet ID")}
					data={payload.customObjectId}
				/>
				{roleNotDirector && (
					<MoreInfoItem name={L("Регион", "Region")} data={payload.region} />
				)}
				{roleNotDirector && (
					<MoreInfoItem name={L("Город", "City")} data={payload.city} />
				)}
				{roleNotDirector && (
					<MoreInfoItem
						name={L("Филиал", "Outlet")}
						data={payload.objectName}
					/>
				)}
				<MoreInfoItem
					name={L("Должность", "Job title")}
					data={payload.position}
				/>
				<MoreInfoItem
					name={L("ФИО рабочего", "Worker full name")}
					data={workerData?.workerFullname}
				/>
				<MoreInfoItem
					name={L("Пол", "Sex")}
					data={workerData?.workerSexString}
				/>
				<MoreInfoItem
					name={L("Дата рождения", "Birth date")}
					data={sliceString(workerData?.birthday)}
				/>

				<MoreInfoItem
					name={L("Гражданство", "Citizenship")}
					data={workerData?.citizenship}
				/>
				<MoreInfoItem
					name={L("Паспорт", "Passport")}
					data={workerData?.passport}
				/>
				<MoreInfoItem
					name={L("Телефон", "Phone")}
					data={workerData?.phoneNumber}
				/>

				<MoreInfoItem
					name={L("Дата начала работы", "Work start date")}
					data={payload.workStartDate ? payload.workStartDate.slice(0, 10) : ""}
				/>
				<MoreInfoItem
					name={L("Завершение работы", "Work end date")}
					data={payload.workEndDate}
				/>
				{data?.data?.workerInfo?.dismissalDate && (
					<MoreInfoItem
						name={L("Дата увольнения", "Date of dismissal")}
						data={data?.data?.workerInfo?.dismissalDate}
					/>
				)}

				<MoreInfoItem
					name={L("Выбранный расчетный период", "Selected billing period")}
					data={`${startWorkDate} – ${endWorkDay} `}
				/>

				{/* <MoreInfoItem name="Адрес" data={payload.address} /> */}
				{roleNotDirector && roleNotUnder && (
					<MoreInfoItem
						name={L("Ставка", "Rate")}
						data={payload.externalRate}
					/>
				)}
				{roleNotDirector && roleNotUnder && (
					<MoreInfoItem
						name={L("Ставка с НДС", "Rate with tax")}
						data={payload.externalRateVat}
					/>
				)}
				<MoreInfoItem
					name={L("Отработано часов", "Total hours worked")}
					// data={getWorkerData().totalWorkedHours}
					data={data?.data.workerInfo.totalWorkedHours}
				/>
				{roleNotDirector && roleNotUnder && (
					<MoreInfoItem
						name={L("Стоимость услуг без НДС", "Total cost without tax")}
						// data={addThousandSeparator(getWorkerData().costs)}
						data={data?.data?.workerInfo.costs}
					/>
				)}

				{roleNotDirector && roleNotUnder && (
					<MoreInfoItem
						name={L("Стоимость услуг с НДС", "Total cost with tax")}
						data={data?.data?.workerInfo.costsWithVat}
					/>
				)}
				<div className="hidden-info">
					<h3
						style={{
							fontSize: "16px",
							color: "#474F5A",
							fontWeight: "400",
						}}
					>
						{L("Данные об аккаунте сотрудника", "Employees's account info")}
					</h3>
					<div className="items">
						<p className="gray">{L("Логин:", "Login:")}</p>
						<p className={hidden ? "blurred" : ""}>
							{data?.data?.workerInfo?.workerUserName || "-"}
						</p>
					</div>
					<div className="items">
						<p className="gray">{L("Пароль:", "Password")}</p>
						<p className={hidden ? "blurred" : ""}>
							{data?.data?.workerInfo?.workerPassword || "-"}
						</p>
					</div>
					<p className="show-btn">
						<img onClick={switchHidden} src="../../icons/eye.svg" alt="" />
						<p onClick={switchHidden}>
							{hidden ? L("Показать", "Show") : L("Скрыть", "Hide")}
						</p>
					</p>
					<p onClick={openChangeCreds} className="generate-creds">
						{L("Сгенерировать новый пароль", "Generate new password")}
					</p>
				</div>
			</div>
			<div className="form-divider"></div>
			{payload.isAvaliableToEdit ? (
				<div className="flex-employee-info">
					<CommonBtn
						onClick={switchSecondaryEdit}
						classes=""
						text={L("Изменить", "Edit")}
					/>
					<CommonBtn
						onClick={switchSecondaryHours}
						text={hoursText()}
						type="light"
						// classes="btn-modal-center"
					/>
				</div>
			) : (
				<CommonBtn
					onClick={switchSecondaryHours}
					text={
						secondaryOpened ? "Скрыть рабочие часы" : "Выставить рабочие часы"
					}
					type="light"
					classes="btn-modal-center"
				/>
			)}
		</div>
	);
}

export default EmployeeInfo;
function openFeedback(arg0: any, arg1: string) {
	throw new Error("Function not implemented.");
}
