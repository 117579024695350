import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import LoginPage from "../../Login/LoginPage";
import SortArrow from "../../utility/SortArrow";
import RatesFilters from "./RatesFilters";
import { deleteHourlyRates, getRates } from "../../../api/rates";
import Loading from "../../utility/Loading";
import TablePagination from "../../utility/TablePagination";
import { numberToOneOrNoneCommas } from "../../../utils/numberToOneOrNoneCommas";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import dayjs from "dayjs";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import TableTotalCount from "../../utility/TableTotalCount";
import IconInputFilter from "../../utility/IconInputFilter";
import ModalMain from "../../utility/ModalMain/ModalMain";
import CreateRates from "./CreateRates";
import RateInfo from "./RateInfo/RateInfo";
import EditRate from "./EditRate";
import UploadRates from "./UploadRates";

function Rates() {
	const [searchParams] = useSearchParams();
	const [page, setPage] = useState(1);

	// only need to do this once.
	const minTwoDigits = (n: number) => {
		return (n < 10 ? "0" : "") + n.toString();
	};
	const [filters, setFilters] = useState({
		year: "",
		month: "",
		company: null,
		region: null,
		city: null,
		contractor: null,
		job: null,
		searchInput: "",
	});

	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc

	//* modals
	const [modalFilters, setModalFilters] = useState<{
		opened: boolean;
	}>({
		opened: false,
	});

	// create
	const [modalCreateNew, setModalCreateNew] = useState({
		opened: false,
	});

	// info
	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		confirmOpened?: boolean;
		secondaryOpened?: boolean;
	}>({
		opened: false,
		payload: null,
		confirmOpened: false,
		secondaryOpened: false,
	});
	// upload
	const [modalUploadRates, setModalUploadRates] = useState<{
		opened: boolean;
		payload?: any;
	}>({
		opened: false,
		payload: null,
	});

	const { isSuccess, data, refetch } = useQuery(
		["hourlyRates", { pageNumber: page }, filters, sort],

		(q) => getRates(q),

		{
			enabled: true,
			retry: 2,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 5000,
		}
	);

	let headings = [
		{ name: "Год", colname: "year" },
		{ name: "Месяц", colname: "month" },
		{ name: "Компания" },
		{ name: "Регион" },
		{ name: "Город" },
		{ name: "Подрядчик" },
		{ name: "Должность" },
		{ name: "Ставка с НДС", colname: "hourlyRate" },
		{ name: "Ставка", colname: "hourlyRate" },
	];

	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};
	return (
		<div className="container-fluid">
			<div className="upper-bar">
				<div className="upper-bar-first">
					<IconInputFilter
						setFilters={setFilters}
						placeholder="Искать ставку"
					/>
					<div
						onClick={() => setModalFilters({ opened: true })}
						className="filter-btn"
					>
						<img
							src="../../icons/setting-5.svg"
							alt=""
							width="20px"
							height="20px"
						/>
						<p>Фильтры</p>
					</div>

					{
						<TableTotalCount
							name="Ставок"
							headers={data?.headers["x-pagination"]}
						/>
					}
				</div>
				<div className="upper-bar-second">
					<img
						src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
						width="35px"
						style={{ marginRight: "30px", cursor: "pointer" }}
						alt=""
						onClick={() =>
							setModalUploadRates({
								opened: true,
							})
						}
					/>
					<CommonBtn
						onClick={() =>
							setModalCreateNew({
								opened: true,
							})
						}
						text="Создать ставку"
					>
						<img src="../../icons/add.svg" alt="powerpoint" />
						<p>Создать ставку</p>
					</CommonBtn>
				</div>
			</div>
			<ModalMain
				title="Создать ставку"
				setModal={setModalCreateNew}
				opened={modalCreateNew.opened}
			>
				<CreateRates setModal={setModalCreateNew} />
			</ModalMain>

			<ModalMain
				opened={modalFilters.opened}
				setModal={setModalFilters}
				title="Фильтры"
			>
				<RatesFilters
					year={filters.year}
					month={filters.month}
					data={data}
					filters={filters}
					setFilters={setFilters}
				/>
			</ModalMain>

			<ModalMain
				modal={modalInfo}
				setModal={setModalInfo}
				opened={modalInfo.opened}
				title="Ставка"
				secondaryOpened={modalInfo.secondaryOpened}
				secondary={() => (
					<EditRate id={modalInfo?.payload} setModal={setModalInfo} />
				)}
			>
				<RateInfo setModal={setModalInfo} id={modalInfo?.payload} />
			</ModalMain>

			{modalUploadRates.opened && (
				<ModalMain
					title="Загрузка ставок"
					setModal={setModalUploadRates}
					opened={modalUploadRates.opened}
				>
					{/* <UploadOutlets />
					<div className="form-divider"></div> */}
					<UploadRates />

					<CommonBtn
						text="Отменить"
						type="light"
						onClick={() => setModalUploadRates({ opened: false })}
						classes="btn-modal-left"
					></CommonBtn>
				</ModalMain>
			)}

			{isSuccess ? (
				<>
					<div className="table-container">
						<table cellSpacing={0} className="my-table">
							<thead>
								<tr>
									{headings.map((heading) => (
										<th>
											<div className="d-flex align-items-end ">
												<div>{heading.name}</div>
												{heading.colname && (
													<div className="">
														<SortArrow
															sort={sort}
															heading={heading}
															setSort={setSort}
														/>
													</div>
												)}
											</div>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{data?.data?.workerRates &&
									data?.data?.workerRates.map((col) => {
										return (
											<tr
												onClick={() => {
													setModalInfo({
														opened: true,
														payload: col?.workerRateId,
													});
												}}
											>
												<td className="size-item">{col.year}</td>
												<td>{col.monthDescr}</td>
												<td>{col.companyName}</td>
												<td>{col.regionName}</td>
												<td>{col.cityName}</td>
												<td>{col.contractorName}</td>
												<td>{col.jobTitleName}</td>
												<td>{col.hourlyRateWithVat?.toFixed(1)}</td>
												<td>{col.hourlyRate?.toFixed(1)}</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default Rates;
