/*
turns object with a structure like 
obj = {
    1: "meow1",
    2: "meow2",
    3: "meEEooEWW"
}

to an array with a structure like
arr = [
    {
    1: "meow1";
    },
    {
        2: "meow2"
    },
    {
    3: "meEEooEWW"
    }
]

    Used for autocomplete component
*/

export const createArrayOptions = (optionsObjs: any) => {
	if (optionsObjs) {
		const optionsArr = Object.entries(optionsObjs).map(([key, value]) => ({
			[key]: value,
		}));

		// console.log(optionsArr);
		return optionsArr.map((option) => {
			return {
				id: Object.keys(option)[0],
				//@ts-ignore
				value: option[Object.keys(option)[0]]
					? option[Object.keys(option)[0]]?.toString()
					: option[Object.keys(option)[0]],
			};
		});
	} else {
		return [];
	}
};
