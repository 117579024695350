import React from "react";
import { useState } from "react";
import { useMutation } from "react-query";
import { setWorkerStats } from "../../api/employees";
import { GrClose } from "react-icons/gr";
import { Typeahead } from "react-bootstrap-typeahead";
import { hoursText } from "../../utils/datesData";
import { FaLock } from "react-icons/fa";
import useLanguage from "../../hooks/useLanguage";

interface IProps {
	value?: any;
	day: any;
	year: any;
	month: any;
	workerId: any;
	date: any;
	workDayStatus?: any;
	workdayStatusId?: any;
	workedHours?: any;
	setDaySelected: React.Dispatch<React.SetStateAction<number | null>>;
	dateNotFormatted: any;
	dayState: any;
	hoursStatus?: any;
	isAvailableToEdit: any;
	setCurrentDayStatus: React.Dispatch<
		React.SetStateAction<{
			id: string | number;
			name: string;
			workedHours: string;
			isAvailableToEdit: any;
		} | null>
	>;
	workerStatus: string | undefined;
}
function SheetWidget({
	value,
	year,
	month,
	day,
	workerId,
	date,
	workDayStatus,
	workedHours,
	setDaySelected,
	dateNotFormatted,
	dayState,
	setCurrentDayStatus,
	workdayStatusId,
	hoursStatus,
	isAvailableToEdit,
	workerStatus,
}: IProps) {
	const { L } = useLanguage();

	const [isDisplayed, setIsDisplayed] = useState(false);
	const [hours, setHours] = useState<any>(null);
	const [status, setStatus] = useState<any>(null);

	const { mutate, isSuccess, isError } = useMutation(setWorkerStats);

	const change = () => {
		mutate({
			workerId: workerId,
			year: year,
			month: month,
			days: [
				{
					day: day,
					workdayStatusId: status,
					hours: hours,
				},
			],
		});
		setTimeout(() => {
			if (isSuccess) setIsDisplayed(false);
		}, 600);
	};

	const checkDisabled = status == 2 || status == 3;

	const avaliableStatuses = [
		{
			status: 1,
			title: L("Явка", "Turnout"),
		},
		{
			status: 2,
			title: L("Неявка", "No-show"),
		},
		{
			status: 3,
			title: L("Больничный", "Disease"),
		},
	];

	const statusColored = () => {
		if (!workDayStatus) return;
		if (workDayStatus === "Явка")
			return <p className="day-status green">{workDayStatus}</p>;
		if (workDayStatus === "Неявка")
			return <p className="day-status red">{workDayStatus}</p>;
		if (workDayStatus === "Болезнь")
			return <p className="day-status">{workDayStatus}</p>;
		if (workDayStatus === "Turnout")
			return <p className="day-status green">{workDayStatus}</p>;
		if (workDayStatus === "No-show")
			return <p className="day-status red">{workDayStatus}</p>;
		if (workDayStatus === "Disease")
			return <p className="day-status">{workDayStatus}</p>;
	};

	const isDayChosen = () => {
		if (dateNotFormatted === dayState) return true;
	};

	const canBeEdited = () => {
		if (workerStatus === "80") {
			return "no-edit";
		}

		if (isAvailableToEdit === "0") {
			return "no-edit";
		} else {
			return "";
		}
	};
	const lock = () => {
		if (workerStatus === "80") {
			return "false";
		}
		if (isAvailableToEdit === "0") {
			return "false";
		} else {
			return "";
		}
	};

	return (
		<div>
			<div
				onClick={() => {
					if (typeof workDayStatus === "string" && setCurrentDayStatus) {
						setCurrentDayStatus({
							id: workdayStatusId,
							name: workDayStatus,
							workedHours: workedHours,
							isAvailableToEdit: isAvailableToEdit,
						});
					} else {
						setCurrentDayStatus && setCurrentDayStatus(null);
					}
					setDaySelected(dateNotFormatted);
				}}
				className={
					isDayChosen() ? `day chosen ${canBeEdited()}` : `day ${canBeEdited()}`
				}
			>
				{lock() && <div className="lock">{<FaLock />}</div>}
				<p className="day-num">{date}</p>
				{statusColored() || <p className="day-status">-</p>}
				<p className="day-hours">{hoursText(workedHours)}</p>
				<p className="hours-status">{hoursStatus || "-"}</p>
			</div>
			<div className={isDisplayed ? `outer` : `outer hidden`}>
				<div className="inner">
					<div className="inner-inner">
						<div>
							<Typeahead
								id="status"
								options={avaliableStatuses}
								labelKey={"title"}
								className={"sheet-options"}
								placeholder={"Выберите статус*"}
								renderMenuItemChildren={(option: any) => (
									<div
										onClick={() => {
											if (option.status == 1) setStatus(1);
											if (option.status == 2) {
												setStatus(2);
												setHours(0);
											}
											if (option.status == 3) {
												setStatus(3);
												setHours(0);
											}
										}}
									>
										<p className="">{option.title}</p>
									</div>
								)}
							/>
						</div>

						<input
							disabled={checkDisabled}
							value={hours}
							onChange={(e) => {
								setHours(e.target.value);
							}}
							placeholder={"часов"}
							className="form-control"
						/>
					</div>
					<button onClick={change} className="btn btn-primary">
						Сохранить
					</button>
					{isSuccess && <p className="text-success">Сохранено!</p>}
					{isError && <p className="text-danger">Пожалуйста, заполните поля</p>}
					<GrClose
						onClick={() => setIsDisplayed(false)}
						className="close-btn-modal"
					/>
				</div>
			</div>
		</div>
	);
}

export default SheetWidget;
