import { isBoolean } from "lodash";
import React from "react";

interface IProps {
	value: string | number | null;
	stateName: string;
	setState: any;
	label?: string;
	placeholder?: string;
	requiredState?: boolean | undefined;
	errorMessage?: any;
	type?: string;
	errorSecondary?: any; // a function that returns string
}
function InputControlled({
	value,
	stateName,
	setState,
	label,
	placeholder,
	requiredState,
	type,
	errorMessage = "Поле должно быть заполнено",
	errorSecondary = () => false,
}: IProps) {
	const needValidation = isBoolean(requiredState) && requiredState === true;

	return (
		<div className="input-controlled">
			{label && <label className="form-label">{label}</label>}
			<input
				className={`${needValidation || errorSecondary() ? "input-error" : ""}`}
				value={value || ""}
				onChange={(e: any) => {
					setState((prev: any) => ({
						...prev,
						[`${stateName}`]: e.target.value,
					}));
				}}
				type={type ? type : "text"}
				placeholder={placeholder || ""}
			/>
			{needValidation && (
				<p className="input-validation-text">{errorMessage}</p>
			)}
			{errorSecondary() && (
				<p className="input-validation-text">{errorSecondary()}</p>
			)}
		</div>
	);
}

export default InputControlled;
