import { TableCheckHead } from "../TableCheck";
import TableHeadMap from "./TableHeadMap";

interface IProps {
	sort: any;
	setSort: any;
	headings: any;
	// checks
	isAllChecked: boolean;
	switchCheckedAll: () => void;
}

function TableHeadSortWithCheck({
	sort,
	setSort,
	headings,
	isAllChecked,
	switchCheckedAll,
}: IProps) {
	return (
		<thead>
			<tr>
				<TableCheckHead
					isAllChecked={isAllChecked}
					switchCheckedAll={switchCheckedAll}
				/>
				<TableHeadMap headings={headings} setSort={setSort} sort={sort} />
			</tr>
		</thead>
	);
}

export default TableHeadSortWithCheck;
// <table cellSpacing={0} className="my-table choose-table">
// Table needs to have these classes!
