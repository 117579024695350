import { useQuery } from "react-query";
import { useState, useEffect, useRef } from "react";
import { Typeahead, ClearButton } from "react-bootstrap-typeahead";
import InputFilter from "../../utility/InputFilter";
import { createArrayOptions } from "../../../utils/createArrayOptionsFromObj";
import { getWorkerRequestsContractors } from "../../../api/for_contractors/worker_requests_contractors";
import "../../../styles/filters/filters.css";
import FilterAutocomplete from "../../utility/FilterAutocomplete";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import SelectCustom from "../../utility/SelectCustom";
import useLanguage from "../../../hooks/useLanguage";
interface IProps {
	filters?: any;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
}
function WorkerRequestsFiltersContractors({ setFilters, filters }: IProps) {
	const { L } = useLanguage();

	const { data, isSuccess } = useQuery(
		["employeesRequestsFiltersContractors", { pageNumber: 1 }, filters],
		getWorkerRequestsContractors
	);

	const regions = createArrayOptions(data?.data?.filterValues?.regions);
	const cities = createArrayOptions(data?.data?.filterValues?.cities);
	const objectNames = createArrayOptions(data?.data?.filterValues?.objectNames);
	const jobTitles = createArrayOptions(data?.data?.filterValues?.jobTitles);
	const requestStatuses = createArrayOptions(
		data?.data?.filterValues?.requestStatuses
	);

	const chosenFilters = data?.data?.chosenFilters;
	const addressRef: any = useRef(null);
	const customIdRef: any = useRef();
	const idReq: any = useRef();
	const byRef: any = useRef();
	const createdFromRef: any = useRef();
	const createdBeforeRef: any = useRef();

	let checkRegion = regions && localStorage.getItem("role") != "Director";
	let checkCity = cities && localStorage.getItem("role") != "Director";
	let checkObjects = objectNames && localStorage.getItem("role") != "Director";
	let checkFIO = localStorage.getItem("role") != "Director";
	// role check
	const roleNotDirector = localStorage.getItem("role") != "Director";

	// clear filters
	const clear = () => {
		customIdRef.current.value = "";
		addressRef.current.value = "";
		idReq.current.value = "";
		// byRef.current.value = "";
		createdFromRef.current.value = "";
		createdBeforeRef.current.value = "";

		setFilters({
			index: "",
			region: "",
			city: "",
			outlet: "",
			address: "",
			job: "",
			requestStatus: "",
			createdBy: "",
			StartCreatedOn: "",
			EndCreatedOn: "",
			workerRequestId: "", // must be integer
			company: "",
			RequestStatus: "active",
			sex: "",
		});
	};

	const statusData = {
		All: L("Все", "All"),
		active: L("Активные", "Active"),
		unactive: L("Неактивные", "Unactive"),
	};

	const getStatusValue = () => {
		if (filters.RequestStatus === "All") return L("Все", "All");
		if (filters.RequestStatus === "active") return L("Активные", "Active");
		if (filters.RequestStatus === "unactive")
			return L("Неактивные", "Unactive");
		return "";
	};

	const sexData = {
		"": L("Не важно", "Any"),
		true: L("Мужчины", "Male"),
		false: L("Женщины", "Female"),
	};

	const getSexValue = () => {
		if (filters.sex === "") return L("Не важно", "Any");
		if (filters.sex === "true") return L("Мужчины", "Male");
		if (filters.sex === "false") return L("Женщины", "Female");
		return "";
	};

	useEffect(() => {
		if (data) {
			if (filters.index) customIdRef.current.value = filters.index;
			if (filters.address) addressRef.current.value = filters.address;
			if (filters.createdBy) byRef.current.value = filters.createdBy;
			if (filters.workerRequestId)
				idReq.current.value = filters.workerRequestId;
		}
		if (filters.StartCreatedOn)
			createdFromRef.current.value = filters.StartCreatedOn;
		if (filters.EndCreatedOn)
			createdBeforeRef.current.value = filters.EndCreatedOn;
	}, [data]);
	return (
		<div>
			<div style={{ marginBottom: "20px" }}>
				<CommonBtn
					onClick={clear}
					type="light"
					text={L("Очистить фильтры", "Clear filters")}
				/>
			</div>
			<InputFilter
				ref={idReq}
				filterField="workerRequestId"
				setFilters={setFilters}
				placeholder={L("Пример: 231", "Example: 231")}
				label={L("№ Заявки", "Request ID")}
				type={"text"}
			/>

			<SelectCustom
				arrowPosition="far-right"
				styles={{ marginBottom: "16px" }}
				data={statusData}
				setState={setFilters}
				stateName="RequestStatus"
				initial={getStatusValue()}
				label={L("Статус заявки", "Request Status")}
			/>

			<FilterAutocomplete
				id="company"
				setFilters={setFilters}
				filterName="company"
				options={data?.data?.filterValues?.companies}
				selectedValue={chosenFilters?.companies?.value}
				label={L("Компания", "Company")}
				placeholder={L("Пример: Продукты", "Example: Grocery")}
			/>

			{checkObjects && (
				<FilterAutocomplete
					id="outlet"
					setFilters={setFilters}
					filterName="outlet"
					options={data?.data?.filterValues?.objectNames}
					selectedValue={chosenFilters?.objectNames?.value}
					label={L("Филиал", "Outlet")}
					placeholder={L("Пример: Продукты_4541", "Example: Grocery_4541")}
				/>
			)}

			{roleNotDirector && (
				<InputFilter
					ref={customIdRef}
					filterField="index"
					setFilters={setFilters}
					placeholder={L("Пример: 918267", "Example: 918267")}
					label={L("ID филиала", "Outlet ID")}
					type={"text"}
				/>
			)}

			{checkRegion && (
				<FilterAutocomplete
					id="region"
					setFilters={setFilters}
					filterName="region"
					options={data?.data?.filterValues?.regions}
					selectedValue={chosenFilters?.regions?.value}
					label={L("Регион", "Region")}
					placeholder={L("Пример: Омская область", "Example: Scotland")}
				/>
			)}

			{checkCity && (
				<FilterAutocomplete
					id="city"
					setFilters={setFilters}
					filterName="city"
					options={data?.data?.filterValues?.cities}
					selectedValue={chosenFilters?.cities?.value}
					label={L("Город", "City")}
					placeholder={L("Пример: Симферополь", "Example: Glasgow")}
				/>
			)}

			{checkFIO && (
				<InputFilter
					ref={addressRef}
					filterField="address"
					setFilters={setFilters}
					label={L("Адрес", "Address")}
					placeholder={L(
						"Пример: Улица Амурская 1",
						"Example: St. John Street, 1"
					)}
					type={"text"}
				/>
			)}

			<SelectCustom
				arrowPosition="far-right"
				styles={{ marginBottom: "16px" }}
				data={data?.data?.filterValues?.workSceduleTitles}
				setState={setFilters}
				stateName="WorkSceduleTitleId"
				initial={chosenFilters?.workSceduleTitles?.value}
				label={L("График", "Schedule")}
				placeholder={L("Пример: 5/2", "Example: 5/2")}
			/>
			{/* jobs */}

			{jobTitles && (
				<FilterAutocomplete
					id="job"
					setFilters={setFilters}
					filterName="job"
					options={data?.data?.filterValues?.jobTitles}
					selectedValue={chosenFilters?.jobTitles?.value}
					label={L("Должность", "Job title")}
					placeholder={L("Пример: Кассир", "Example: Cashier")}
				/>
			)}

			<SelectCustom
				arrowPosition="far-right"
				styles={{ marginBottom: "16px" }}
				data={sexData}
				setState={setFilters}
				stateName="sex"
				initial={getSexValue()}
				label={L("Пол сотрудника", "Exmployee's sex")}
			/>

			<InputFilter
				ref={createdFromRef}
				filterField="StartCreatedOn"
				setFilters={setFilters}
				type={"date"}
				label={L("Создана от", "Created from")}
			/>

			<InputFilter
				ref={createdBeforeRef}
				filterField="EndCreatedOn"
				setFilters={setFilters}
				label={L("Создана до", "Created before")}
				type={"date"}
			/>
			<div style={{ marginBottom: "2rem" }}></div>
		</div>
	);
}

export default WorkerRequestsFiltersContractors;
