import useLanguage from "../../hooks/useLanguage";
import { IPagination } from "../../interfaces/interfaces";
import { createArrFromNums } from "../../utils/createArrFromNums";
interface IProps {
	paginationInfo: IPagination | undefined;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	//refetch?: Function | null
}



function TablePagination({
	paginationInfo,
	page: currentPage,
	setPage,
	//refetch
}: IProps) {

	function updatePage(newpage: number) {
		//console.log("test132312132213");
		setPage(newpage);
		//if(refetch) {
		//	refetch();
		//}
	}
	const {L} = useLanguage();
	
	const totalPagesArr = createArrFromNums(paginationInfo?.TotalPages);
	const sliceArray = (arr: number[], x: number) => {
		let endIndex = x + 5;
		let startIndex = x - 5;

		if (startIndex < 1) {
			endIndex -= startIndex;
			startIndex = 1;
		}

		if (endIndex > arr.length) {
			endIndex = arr.length;
		}
		if (startIndex < 1) {
			startIndex = 1;
		}
		return arr.slice(startIndex, endIndex);
	};

	const createPagesToShowArr = () => {
		if (!paginationInfo) return [];
		if (paginationInfo?.TotalCount < 15) {
			return createArrFromNums(paginationInfo.TotalPages);
		} else {
			// console.log(sliceArray(totalPagesArr, currentPage));
			return sliceArray(totalPagesArr, currentPage);
		}
	};

	const pagesToShow = createPagesToShowArr();

	// console.log(paginationInfo);

	const prevPage = () => {
		if (currentPage > 1) {
			updatePage(currentPage - 1);
			window.scrollTo(0, 0);
		}
	};

	const nextPage = () => {
		if (!paginationInfo) return;
		if (currentPage < paginationInfo?.TotalPages) {
			updatePage(2);
			window.scrollTo(0, 0);
		}
	};

	const itemsShown = () => {
		if (paginationInfo) {
			const shownStart =
				currentPage * paginationInfo.PageSize - paginationInfo.PageSize + 1;
			const shownEnd = shownStart - 1 + paginationInfo.PageSize;
			if (paginationInfo.TotalCount < paginationInfo.PageSize) {
				return `${L("Показ", "Shown")} ${shownStart} — ${paginationInfo.TotalCount} ${L("из", "from")} ${paginationInfo.TotalCount}`;
			} else {
				return `${L("Показ", "Shown")} ${shownStart} — ${shownEnd} ${L("из", "from")} ${paginationInfo.TotalCount}`;
			}
		}
		return "";
	};
	if (paginationInfo) {
		return (
			<div className={"page-navigation"}>
				<div className="items-shown">{itemsShown()}</div>
				<ul className="pagination">
					<li className="page-item pagination-navigate" onClick={prevPage}>
						<p
							className={
								paginationInfo.HasPrevious ? `page-link` : `page-link disabled`
							}
						>
							{L("Назад", "Back")}
						</p>
					</li>

					{paginationInfo.TotalPages != 1 && (
						<li
							onClick={() => {
								window.scrollTo(0, 0);

								updatePage(1);
							}}
							className={currentPage === 1 ? `page-item active` : `page-item`}
						>
							<p>1</p>
						</li>
					)}
					{currentPage >= 7 && (
						<li className="page-item disabled">
							<p className="page-link">...</p>
						</li>
					)}
					{pagesToShow.map((pageNumber) => (
						<li
							onClick={() => {
								window.scrollTo(0, 0);

								updatePage(pageNumber);
							}}
							key={pageNumber}
							className={
								currentPage === pageNumber ? `page-item active` : `page-item`
							}
						>
							<p className="page-link">{pageNumber}</p>
						</li>
					))}
					{currentPage < paginationInfo.TotalPages - 6 && (
						<>
							<li className="page-item disabled">
								<p className="page-link">...</p>
							</li>
							<li
								className={
									currentPage === paginationInfo.TotalPages
										? `page-item active`
										: `page-item`
								}
							>
								<p
									onClick={() => {
										window.scrollTo(0, 0);

										updatePage(paginationInfo.TotalPages);
									}}
									className="page-link"
								>
									{paginationInfo.TotalPages}
								</p>
							</li>
						</>
					)}
					{currentPage == paginationInfo.TotalPages - 6 && (
						<li
							className={
								currentPage === paginationInfo.TotalPages
									? `page-item active`
									: `page-item`
							}
						>
							<p
								onClick={() => {
									window.scrollTo(0, 0);

									updatePage(paginationInfo.TotalPages);
								}}
								className="page-link"
							>
								{paginationInfo.TotalPages}
							</p>
						</li>
					)}
					<li className="page-item pagination-navigate" onClick={nextPage}>
						<p
							className={
								paginationInfo.HasNext ? `page-link` : `page-link disabled`
							}
						>
							{L("Вперед", "Next")}
						</p>
					</li>
				</ul>
				<div className="item-to-fill-space-if-you-read-this-i-love-you-<3"></div>
			</div>
		);
	} else {
		return <></>;
	}
}

export default TablePagination;
