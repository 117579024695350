import ModalFeedbackContext from "../context/ModalFeedbackContext";
import { useContext, useState } from "react";



const useFeedback = () => {
	const data = useContext(ModalFeedbackContext);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
	const openFeedback = (message: string, type: "success" | "error" | "info", noclose?:boolean, title?:string | undefined | null) => {
		if (!data.feedback.opened || data.feedback.message !== message) {
			console.log("fired");
			if (timeoutId) clearTimeout(timeoutId);
			data.setFeedback({
				opened: true,
				message,
				type,
				title
			});
			const newTimeoutId = setTimeout(() => {
				if (!noclose) {
					setTimeoutId(newTimeoutId);
					data.setFeedback({
						opened: false,
						message: null,
						type,
					});
				}
			}, 5000);
			
		}
		console.log("fired outside");
	};

	const closeFeedback = () => {
		data.setFeedback({
			opened: false,
			message: null,
			type: "success"
		})
	}

	return {openFeedback, closeFeedback};
};

export default useFeedback;
