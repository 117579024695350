import axios from "axios";
import {
	BASEURL,
	BASEURLV2,
	BASEURLV1,
	BASEURLV3,
	BASEURLV0,
} from "./constants";

//V2
export const instanceV2 = axios.create({
	baseURL: BASEURLV2,
	headers: {
		Authorization: ``,
	},
});

instanceV2.interceptors.request.use(async (req) => {
	try {
		const response = await axios.post<any>(
			`${BASEURL}/Token/refresh`,
			JSON.stringify({
				accessToken: localStorage.getItem("token"),
				refreshToken: localStorage.getItem("refreshToken"),
			}),
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		localStorage.setItem("token", response.data.accessToken);

		req!.headers!.Authorization = `Bearer ${localStorage.getItem("token")}`;
	} catch (err: any) {
		console.log(err.response.status);
		console.log("what happened");
		console.log(err.response.status);
		if (err.response.status === 501) {
			localStorage.removeItem("token");
			localStorage.removeItem("role");
			localStorage.removeItem("refreshToken");
			window.location.reload();
		}
	}
	return req;
});

//V1

export const instanceV1 = axios.create({
	baseURL: BASEURLV1,
	headers: {
		Authorization: ``,
	},
});
instanceV1.interceptors.request.use(async (req) => {
	try {
		const response = await axios.post<any>(
			`${BASEURL}/Token/refresh`,
			JSON.stringify({
				accessToken: localStorage.getItem("token"),
				refreshToken: localStorage.getItem("refreshToken"),
			}),
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		localStorage.setItem("token", response.data.accessToken);

		req!.headers!.Authorization = `Bearer ${localStorage.getItem("token")}`;
	} catch (err: any) {
		console.log(err.response.status);
		console.log("what happened");
		console.log(err.response.status);
		if (err.response.status === 501) {
			localStorage.removeItem("token");
			localStorage.removeItem("role");
			localStorage.removeItem("refreshToken");
			window.location.reload();
		}
	}
	return req;
});

export const instanceV3 = axios.create({
	baseURL: BASEURLV3,
	headers: {
		Authorization: ``,
	},
});
instanceV3.interceptors.request.use(async (req) => {
	try {
		const response = await axios.post<any>(
			`${BASEURL}/Token/refresh`,
			JSON.stringify({
				accessToken: localStorage.getItem("token"),
				refreshToken: localStorage.getItem("refreshToken"),
			}),
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		localStorage.setItem("token", response.data.accessToken);

		req!.headers!.Authorization = `Bearer ${localStorage.getItem("token")}`;
	} catch (err: any) {
		console.log(err.response.status);
		console.log("what happened");
		console.log(err.response.status);
		if (err.response.status === 501) {
			localStorage.removeItem("token");
			localStorage.removeItem("role");
			localStorage.removeItem("refreshToken");
			window.location.reload();
		}
	}
	return req;
});

export const instanceV0 = axios.create({
	baseURL: BASEURLV0,
	headers: {
		Authorization: ``,
	},
});
instanceV0.interceptors.request.use(async (req) => {
	try {
		const response = await axios.post<any>(
			`${BASEURL}/Token/refresh`,
			JSON.stringify({
				accessToken: localStorage.getItem("token"),
				refreshToken: localStorage.getItem("refreshToken"),
			}),
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		localStorage.setItem("token", response.data.accessToken);

		req!.headers!.Authorization = `Bearer ${localStorage.getItem("token")}`;
	} catch (err: any) {
		console.log(err.response.status);
		console.log("what happened");
		console.log(err.response.status);
		if (err.response.status === 501) {
			localStorage.removeItem("token");
			localStorage.removeItem("role");
			localStorage.removeItem("refreshToken");
			window.location.reload();
		}
	}
	return req;
});
