import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getCandidates } from "../../../../api/for_contractors/candidates_contractors";
import Loading from "../../../utility/Loading";
import useLanguage from "../../../../hooks/useLanguage";
interface IProps {
	workerRequestId: any;
	setModal: any;
	secondaryWhich: "edit" | "create" | "candidate";
	setSecondaryWhich: React.Dispatch<
		React.SetStateAction<"edit" | "create" | "candidate">
	>;
	active: any;
	setActive: React.Dispatch<
		React.SetStateAction<"edit" | "request" | "candidates">
	>;
}

function WorkerRequestCandidatesContractors({
	workerRequestId,
	setModal,
	secondaryWhich,
	setSecondaryWhich,
	active,
	setActive,
}: IProps) {
	const { L } = useLanguage();

	const [activeCandidate, setActiveCandidate] = useState<number | null>(null);
	const client = useQueryClient();
	// fetch candidates
	const filters = {
		workerRequestId: workerRequestId,
		PageSize: 200,
	};

	const { isSuccess, data, refetch } = useQuery(
		["candidates", { pageNumber: 1 }, filters],

		(q) => getCandidates(q),

		{
			enabled: active === "candidates",
			retry: 2,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 5000,
		}
	);
	const candidates = data?.data?.candidates;

	// open secondary modal
	const openSecondary = (candidateData: any) => {
		setSecondaryWhich("candidate");
		setModal((prev: any) => ({
			...prev,
			secondaryOpened: true,
			payload: { ...prev.payload, data: candidateData, setActive: setActive },
		}));
	};

	// candidates list onclick
	const candidateListClickHandler = (
		candidateId: number,
		candidateData: any
	) => {
		setTimeout(() => {
			setActiveCandidate(candidateId);
			openSecondary(candidateData);
		}, 10);
	};

	// candidates list
	const candidatesList = candidates?.map((candidate) => {
		const candidateName = candidate.candidateFullName;
		return (
			<div
				key={candidate.candidateId}
				onClick={() =>
					candidateListClickHandler(candidate.candidateId, candidate)
				}
				className={
					activeCandidate === candidate.candidateId
						? `candidates-list active`
						: `candidates-list`
				}
			>
				<div className="info">
					<p>{L("Кандидат:", "Candidate:")}</p>
					<p>{candidateName}</p>
				</div>
				<div className="info">
					<p>{L("Дата визита:", "Visit date")}</p>
					<p>{candidate.visitDate && `${candidate.visitDate.slice(0, 10)}`}</p>
				</div>
				<div className="info">
					<p>{L("Статус:", "Status:")}</p>
					<p>{candidate.candidateStatus}</p>
				</div>
				<div className="info">
					<p>{L("Ставка с НДС:", "Rate with tax:")}</p>
					<p>
						{candidate.hourlyRate &&
							`${candidate.hourlyRateVAT} ${L("руб/ч", "")}`}
					</p>
				</div>
			</div>
		);
	});

	if (!isSuccess) return <Loading type="inside" />;
	if (isSuccess && data?.data?.candidates.length === 0)
		return (
			<p className="color-gray-text">{L("Кандидатов нет", "No candidates")}</p>
		);

	return <div>{candidatesList || ""}</div>;
}

export default WorkerRequestCandidatesContractors;
