import "../../styles/bootstrapCustom/custom.scss";
import { Link, NavLink, useLocation } from "react-router-dom";

function NavRates() {
	const location = useLocation();
	const checkLocation = location.pathname === "/rates";

	return (
		<>
			{/* <nav className="navbar navbar-expand bg-gray-200 p-0">
				<div className="container-fluid ">
					<ul className="navbar-nav">
						<li className="navbar-item">
							<Link
								className={
									checkLocation ? `nav-link me-2 active` : `nav-link me-2`
								}
								to="/rates"
							>
								Список
							</Link>
						</li>

						<li className="navbar-item">
							<NavLink to="/rates/create" className="nav-link me-2">
								Создать
							</NavLink>
						</li>
						<li className="navbar-item">
							<NavLink to="/rates/upload" className="nav-link me-2">
								Загрузить
							</NavLink>
						</li>
					</ul>
				</div>
			</nav> */}
		</>
	);
}

export default NavRates;
