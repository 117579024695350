import axios from "axios";
import { BASEURL, BASEURLV0 } from "./constants";
import { QueryFunctionContext } from "react-query";

const authInstance = axios.create({
	baseURL: `${BASEURL}/auth`,
});

interface ILoginResponse {
	token: string;
	refreshToken: string;
}

interface ILoginValues {
	userName: string;
	password: string;
}

export const login = async ({ userName, password }: any) => {
	// const userName = q?.queryKey[1]?.userName;
	// const password = q?.queryKey[1]?.password;
	const response = await axios.get<ILoginResponse>(
		`${BASEURLV0}/auth/Login?UserName=${userName}&Password=${password}`
	);
	return response;
};

interface IUser {
	id: string;
	userName: "koptevgleb@gmail.com";
	firstname: string;
	lastname: string;
	companyId: number;
	objectId: any;
	role: "Oracle" | "Director" | "ChainManager" | "Contractor" | string;
	test: any;
	le: "RU" | "EN";
}
export const getUser = async (q: QueryFunctionContext<any>) => {
	let token = q?.queryKey[1].token;
	const response = await axios.get<IUser>(
		`${BASEURL}/Token/getUser`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	return response;
};

export const changeCreds = async (userId: string | number) => {
	const response = await axios.post(
		`${BASEURLV0}/auth/resetPassword`,
		userId,
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};
