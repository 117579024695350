import { instanceV1 } from "../instances";
import { QueryFunctionContext } from "react-query";

interface IEmployeeReqs {
	filterValues: any;
	workerRequests: {
		workerRequestId: number;
		companyId: number;
		companyName: string;
		regionId: number;
		region: string;
		cityId: number;
		city: string;
		objectId: number;
		objectName: string;
		position: number;
		schedule: string;
		sex: null | false | true;
		workStartDate: string;
		requestDeadline: string;
		workEndDate: string;
		comment: string | null;
		requestStatus: string;
		address: string;
		createdOn: string;
		numOfSentCandidates: number;
		hourlyRate: any;
		hourlyRateVAT: any;
		numOfCandidates: any;
		numOfTotalCandidates: any;
	}[];
	chosenFilters: {
		cities: any;
		objectNames: any;
		regions: any;
		jobTitles: any;
		requestStatuses: any;
		companies: any;
		workSceduleTitles: any;
		underChainManagers: any;
	};
}

export const getWorkerRequestsSupport = async (
	q: QueryFunctionContext<any>
) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let region = "";
	if (q?.queryKey[2]?.region) {
		region = `&RegionId=${q?.queryKey[2]?.region}`;
	}

	let objectId = "";

	let customObjectId = "";
	if (q?.queryKey[2]?.index) {
		customObjectId = `&CustomObjectId=${q?.queryKey[2]?.index}`;
	}

	let companyId = "";
	if (q?.queryKey[2]?.companyId) {
		companyId = `&CompanyId=${q?.queryKey[2]?.companyId}`;
	}

	let city = "";
	if (q?.queryKey[2]?.city) {
		city = `&CityId=${q?.queryKey[2]?.city}`;
	}
	let outlet = "";
	if (q?.queryKey[2]?.outlet) {
		outlet = `&ObjectId=${q?.queryKey[2]?.outlet}`;
	}

	let address = "";
	if (q?.queryKey[2]?.address) {
		address = `&Address=${q?.queryKey[2]?.address}`;
	}

	let job = "";
	if (q?.queryKey[2]?.job) {
		job = `&JobTitleId=${q?.queryKey[2]?.job}`;
	}

	let requestStatus = "";
	if (q?.queryKey[2]?.requestStatus) {
		requestStatus = `&requestStatusId=${q?.queryKey[2]?.requestStatus}`;
	}
	let company = "";
	if (q?.queryKey[2]?.company) {
		company = `&CompanyId=${q?.queryKey[2]?.company}`;
	}
	let createdBy = "";

	if (q?.queryKey[2]?.createdBy) {
		createdBy = `&CreatedByFullName=${q?.queryKey[2]?.createdBy}`;
	}

	let StartCreatedOn = "";
	if (q?.queryKey[2]?.StartCreatedOn) {
		StartCreatedOn = `&StartCreatedOn=${q?.queryKey[2]?.StartCreatedOn}`;
	}

	let EndCreatedOn = "";
	if (q?.queryKey[2]?.EndCreatedOn) {
		EndCreatedOn = `&EndCreatedOn=${q?.queryKey[2]?.EndCreatedOn}`;
	}

	let RequestStatus = "";
	if (q?.queryKey[2]?.RequestStatus) {
		RequestStatus = `&RequestStatus=${q?.queryKey[2]?.RequestStatus}`;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	// only number!
	function stringToNumber(str: string): number | null {
		const num = Number(str);
		return isNaN(num) ? null : num;
	}
	let workerRequestId = stringToNumber(q?.queryKey[2]?.workerRequestId);
	if (workerRequestId === 0) {
		workerRequestId = null;
	}
	let WorkSceduleTitleId = stringToNumber(q?.queryKey[2]?.WorkSceduleTitleId);

	// sex
	let sex = "";
	if (q?.queryKey[2]?.sex) {
		sex = `&Sex=${q?.queryKey[2]?.sex}`;
	}

	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}
	const response = await instanceV1.get<IEmployeeReqs>(
		`WorkerRequests${page}${region}${city}${outlet}${createdBy}${StartCreatedOn}${EndCreatedOn}${job}${requestStatus}${column}${order}${objectId}${address}${customObjectId}${company}${searchInput}${
			workerRequestId ? `&workerRequestId=${workerRequestId}` : ""
		}${
			WorkSceduleTitleId ? `&WorkSceduleTitleId=${WorkSceduleTitleId}` : ""
		}${RequestStatus}${sex}${companyId}`
	);
	return response;
};

// creation

export const getAvaliableCompanies = async () => {
	const response = await instanceV1.get("WorkerRequests/getAvailableCompanies");
	return response;
};

interface ICreationTemplate {
	objectNames: any;
	addresses: any;
	workSceduleTitles: any;
	jobTitles: any;
	contractors: any;
}

export const getWorkerRequestCreationTemplateS = async (
	q: QueryFunctionContext<any>
) => {
	const response = await instanceV1.get<ICreationTemplate>(
		`WorkerRequests/getCreationTemplate?companyId=${q.queryKey[1]}`
	);
	return response;
};

export const getGeopositionsS = async (q: QueryFunctionContext<any>) => {
	const response = await instanceV1.get(
		// 1 qq == company id, 2nd == objectid
		`WorkerRequests/getGeopositions?companyId=${q.queryKey[1]}&objectId=${q.queryKey[2]}`
	);
	return response;
};

export const getAddressesS = async (q: QueryFunctionContext<any>) => {
	const response = await instanceV1.get(
		// 1 qq == company id, 2nd == objectid, 3d == cityid
		`WorkerRequests/getAddresses?companyId=${q.queryKey[1]}&objectId=${q.queryKey[2]}&cityId=${q.queryKey[3]}`
	);
	return response;
};

export const postWorkerRequestS = async (fields: any) => {
	const response = instanceV1.post("WorkerRequests", JSON.stringify(fields), {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return response;
};

export const deleteWorkerRequestS = async (
	workerRequestId: number | string
) => {
	const response = await instanceV1.delete(
		`WorkerRequests?WorkerRequestId=${workerRequestId.toString()}`
	);
	return response;
};

// uploads

export const getReqsUploadTemplate = async () => {
	await instanceV1
		.get(`/WorkerRequests/getUploadTemplate`, {
			responseType: "blob",
			headers: {
				"Content-Type":
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			},
		})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "file.xlsx");
			document.body.appendChild(link);
			link.click();
		});
};

export const uploadReqs = async (file: any) => {
	const formData = new FormData();
	formData.append("file", file);

	const response = instanceV1.post(
		"WorkerRequests/uploadWorkerRequests",
		formData,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response;
};

interface IEmployeeReq {
	workerRequestInfo: {
		workerRequestId: number;
		objectId: number;
		customObjectId: string;
		companyId: number; // пятерочка
		companyName: string;
		objectName: string; // пятерочка петра
		objectResponsibleFullName: string;
		regionId: number;
		region: string;
		cityId: number;
		city: string;
		address: string;
		jobTitleId: number;
		position: string;
		workScheduleId: number;
		schedule: string;
		sex: true | false | null;
		sexString: string;
		deadline: any;
		workStartDate: string;
		workEndDate: string | null;
		comment: string;
		requestStatusId: number;
		requestStatus: string;
		isActive: boolean;
		numOfCandidates: number;
		candidateStatusId: any;
		workerStatusId: any;
	};
	contractors: any; // {3: "Подрядчик 1",}
	contractorIds: number[];
	workStartDateString: string;
	workEndDateString: string | null;
}

export const getWorkerRequestInfoS = async (q: QueryFunctionContext<any>) => {
	let workerRequestId = "";
	if (q?.queryKey[1]?.workerRequestId) {
		workerRequestId = `?workerRequestId=${q?.queryKey[1]?.workerRequestId}`;
	}

	const response = await instanceV1.get<IEmployeeReq>(
		`/WorkerRequests/getinfo${workerRequestId}`
	);

	return response;
};

export const getCurrentGeoS = async (q?: QueryFunctionContext<any>) => {
	let objectId = "";
	if (q?.queryKey[1]) {
		objectId = `?ObjectId=${q?.queryKey[1]}`;
	}
	const response = await instanceV1.get(
		`WorkerRequests/getCurrentGeo${objectId}`
	);

	return response;
};

export const getCurrentAddressS = async (q: QueryFunctionContext<any>) => {
	let ObjectId = "";
	if (q?.queryKey[1]) {
		ObjectId = `?ObjectId=${q?.queryKey[1]}`;
	}
	const response = await instanceV1.get(
		`WorkerRequests/getCurrentAddress${ObjectId}`
	);
	return response;
};

export const editWorkerReqS = async (edited: any) => {
	const response = await instanceV1.put(
		"/WorkerRequests",
		JSON.stringify(edited),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};
