import React from "react";

interface IProps {
	active: string;
	options: string[];
	optionsNames: string[];
	setActive: React.Dispatch<React.SetStateAction<any>>;
	secondAvaliable?: boolean;
}

function InfoSwitch(props: IProps) {
	return (
		<div className="info-switch">
			<p
				onClick={() => props.setActive(props.options[0])}
				className={props.active === props.options[0] ? "active" : ``}
			>
				{props.optionsNames[0]}
			</p>
			<p
				onClick={() => {
					if (!props.secondAvaliable === false) {
						props.setActive(props.options[1]);
					}
					if (props.secondAvaliable === undefined) {
						props.setActive(props.options[1]);
					}
				}}
				className={props.active === props.options[1] ? "active" : ``}
			>
				{props.optionsNames[1]}
			</p>
		</div>
	);
}

export default InfoSwitch;
