import React from "react";
import InfoSwitch from "../../../outlets/OutletInfo/InfoSwitch";
import CommonBtn from "../../../utility/Buttons/CommonBtn";
import CandidateContractors from "./CandidateContractors";
import CandWorkerReqInfoContractors from "./CandWorkerReqInfoContractors";
import useLanguage from "../../../../hooks/useLanguage";

interface IProps {
	payload: {
		workerRequestId: number;
		position: string;
		customObjectId: string;
		objectId: number;
		objectName: string;
		candidateId: number;
		candidateFullName: string;
		hourlyRate: number;
		phoneNumber: string;
		citizenship: string;
		passport: string;
		visitDate: string;
		candidateStatusId: number;
		address: string;
		candidateStatus: string;
		isAvailableToSetStatus: boolean;
		requestDeadline: string;
		contractorId: number;
		contractorName: string;
		objectResponsibleFullName: any;
		objectResponsibleEmail: any;
		ObjectResponsiblePhoneNumber: any;
	};
	active: "candidate" | "request";
	setActive: React.Dispatch<React.SetStateAction<"candidate" | "request">>;
	modal: any;
	setModal: any;
}
function CandidatesInfoContractors({
	payload,
	active,
	setActive,
	setModal,
}: IProps) {
	const { L } = useLanguage();

	if (active === "candidate")
		return (
			<CandidateContractors
				setModal={setModal}
				active={active}
				setActive={setActive}
				candidateId={payload?.candidateId}
			/>
		);

	if (active === "request")
		return (
			<>
				<InfoSwitch
					options={["candidate", "request"]}
					optionsNames={L(["Кандидат", "Заявка"], ["Candidate", "Request"])}
					active={active}
					setActive={setActive}
				/>
				<CandWorkerReqInfoContractors
					workerRequestId={payload.workerRequestId}
				/>
			</>
		);

	return <div>CandidateInfoContractors</div>;
}

export default CandidatesInfoContractors;
