import "./styles/general/general.css";
import { Routes, Route, Navigate } from "react-router-dom";
import TimeSheet from "./components/timesheet/TimeSheet";
import Outlets from "./components/outlets/Outlets";
import LayoutOutlet from "./components/Layout/LayoutOutlet";
import LayoutStaff from "./components/Layout/LayoutStaff";
import { useEffect } from "react";
import TimeTable from "./components/timesheet/TimeTable";
import LayoutContractors from "./components/Layout/LayoutContractors";
import CreateOutlet from "./components/outlets/CreateOutlet";
import Outlet from "./components/outlets/Outlet";
import LoginPage from "./components/Login/LoginPage";
import Employees from "./employees/EmployeesPage/Employees";
import EmployeesRequests from "./employees/EmployeesRequests";
import Contractors from "./Contractors/Contractors";
import FindEmployee from "./employees/FindEmployee";
import Candidates from "./components/Candidates/Candidates";
import { useNavigate } from "react-router-dom";
import EmployeeRequestEdit from "./employees/EmployeeRequestEdit";
import UploadOutlets from "./components/outlets/UploadOutlets";
import Rates from "./components/forSupport/Rates/Rates";
import LayoutRates from "./components/Layout/LayoutRates";
import CreateRates from "./components/forSupport//Rates/CreateRates";
import EditRate from "./components/forSupport//Rates/EditRate";
import UploadRates from "./components/forSupport//Rates/UploadRates";
import CandidatesContractors from "./components/forContractors/Candidates/CandidatesContractors";
import CreateCandidate from "./components/forContractors/Candidates/CreateCandidate";
import WorkerRequestsContractors from "./components/forContractors/WorkerRequests/WorkerRequestsContractors";
import EditCandidate from "./components/forContractors/Candidates/EditCandidate";
import EmployeesContractors from "./components/forContractors/Employees/EmployeesContractors";
import TimeTableContractors from "./components/forContractors/TimeTable/TimeTableContractors";
import ModalFeedback from "./components/utility/ModalFeedback/ModalFeedback";
import { ModalFeedbackProvider } from "./context/ModalFeedbackContext";
import Layout from "./components/Layout/Layout";
import Companies from "./components/forContractors/Companies/Companies";
import EmployeesRequestsS from "./components/forSupport/Requests/EmployeesRequestsS";
import RegionalManagers from "./components/RegionalManagers/RegionalManagers";
import Sources from "./components/Sources/Sources";
import NotificationContext from "./context/NotificationsContext";
import { NotificationsProvider } from "./context/NotificationsContext";
import ContractorsSupport from "./components/forSupport/Contractors/ContractorsSupport";
import * as Sentry from "@sentry/react";

function App() {
	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem("token")) {
			navigate("/login");
		}
	}, []);

	Sentry.init({
		dsn: "https://48ea70b88834cd45deb8444296114793@o4507481777045504.ingest.de.sentry.io/4507590983221328",
		integrations: [
			Sentry.browserTracingIntegration(),
			//Sentry.replayIntegration(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

	// roles
	const isRoleContractor = localStorage.getItem("role") === "Contractor";
	const navigateHome = () => {
		const role = localStorage.getItem("role");
		if (role === "Contractor") {
			return "/requests";
		} else if (role === "Support") {
			return "rates";
		} else {
			return "/outlets";
		}
	};

	const requests = () => {
		if (isRoleContractor) {
			return <WorkerRequestsContractors />;
		} else if (localStorage.getItem("role") === "Support") {
			return <EmployeesRequestsS />;
		} else {
			return <EmployeesRequests />;
		}
	};

	const contractors = () => {
		if (localStorage.getItem("role") === "Support")
			return <ContractorsSupport />;
		return <Contractors />;
	};

	return (
		<div className="App">
			<ModalFeedbackProvider>
				<NotificationsProvider>
					<Routes>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/" element={<Layout />}>
							<Route index element={<Navigate to={navigateHome()} />} />
							<Route path="/outlets" element={<Outlets />} />
							<Route path="regional-managers" element={<RegionalManagers />} />
							<Route path="sources" element={<Sources />} />
							<Route path="/outlets/upload" element={<UploadOutlets />} />

							<Route
								path="employees"
								element={
									!isRoleContractor ? <Employees /> : <EmployeesContractors />
								}
							/>

							<Route
								path="timetable"
								element={
									!isRoleContractor ? <TimeTable /> : <TimeTableContractors />
								}
							/>
							<Route path="requests" element={requests()} />

							<Route path="companies" element={<Companies />} />

							<Route
								path="candidates"
								element={
									!isRoleContractor ? <Candidates /> : <CandidatesContractors />
								}
							/>
							{/* <Route
							path="candidates/candidate/:CandidateId"
							element={<EditCandidate />}
						/> */}

							<Route path="/contractors" element={contractors()} />
						</Route>
						<Route path="/rates" element={<LayoutRates />}>
							<Route path="/rates" element={<Rates />} />
							<Route path="/rates/upload" element={<UploadRates />} />
							{/* <Route path="/rates/create" element={<CreateRates />} /> */}
							{/* <Route path="/rates/rate/:rate" element={<EditRate />} /> */}
						</Route>
					</Routes>
					<ModalFeedback />
				</NotificationsProvider>
			</ModalFeedbackProvider>
		</div>
	);
}

export default App;
