/*
 * Handle this context via useFeedback custom hook,
 * don't edit directly
 */

import { createContext, useState } from "react";

interface IProps {
	children: React.ReactNode;
}
interface IModalFeedbackContext {
	feedback: {
		opened: boolean;
		message: string | null;
		type: "success" | "error" | "info";
		title?:string | undefined | null;
	};
	setFeedback: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			message: string | null;
			type: "success" | "error" | "info";
			title?:string | undefined | null;

		}>
	>;
}

const ModalFeedbackContext = createContext<IModalFeedbackContext>({
	feedback: {
		opened: false,
		message: null,
		type: "success",
	},
	setFeedback: () => {},
});




export const ModalFeedbackProvider: React.FC<IProps> = ({ children }) => {
	const [feedback, setFeedback] = useState<{
		opened: boolean;
		message: string | null;
		type: "success" | "error" | "info";
	}>({
		opened: false,
		message: null,
		type: "success",
	});



	return (
		<ModalFeedbackContext.Provider value={{ feedback, setFeedback }}>
			{children}
		</ModalFeedbackContext.Provider>
	);
};

export default ModalFeedbackContext;

