import React from "react";

interface IDeleteBtn {
	icon?: boolean;
	text?: string;
	styles?: any;
	onClick?: React.MouseEventHandler;
	classes?: string;
}

function DeleteBtn({
	icon,
	text = "Удалить",
	styles,
	onClick,
	classes,
}: IDeleteBtn) {
	return (
		<div
			onClick={onClick}
			className={classes ? `delete-btn ${classes}` : `delete-btn`}
			style={styles}
		>
			{icon && (
				<img src="../../icons/trash.svg" width="20px" height="20px" alt="" />
			)}
			<p>{text}</p>
		</div>
	);
}

export default DeleteBtn;
