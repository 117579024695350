interface IPropsHead {
	isAllChecked: boolean;
	switchCheckedAll: () => void;
}

export const TableCheckHead = ({
	isAllChecked,
	switchCheckedAll,
}: IPropsHead) => {
	return (
		<th onClick={switchCheckedAll}>
			<div>
				{/* change margin left i guess? if needed.  */}
				<div style={{ marginLeft: "6px" }} className="radio-container">
					<input
						className="no-radio-styles"
						type="radio"
						checked={isAllChecked}
						onChange={() => true}
					/>
				</div>
			</div>
		</th>
	);
};

// <table cellSpacing={0} className="my-table choose-table">
// Table needs to have these classes!

interface IPropsBody {
	itemId: any;
	getChecked: any;
	addChecked: any;
}
export const TableCheckBody = ({
	getChecked,
	addChecked,
	itemId,
}: IPropsBody) => {
	return (
		<td
			onClick={(e) => {
				e.stopPropagation();
				addChecked(itemId);
			}}
		>
			<div className="radio-container">
				<input
					type="radio"
					checked={getChecked(itemId)}
					onChange={() => true}
				/>
			</div>
		</td>
	);
};
