import {
	candidateGetInfo,
	postCandidates,
} from "../../../api/for_contractors/candidates_contractors";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState, useRef, useEffect } from "react";
import InputAutocomplete from "../../utility/InputAutocomplete";
import { Navigate, useNavigate, useParams } from "react-router";
import { Typeahead } from "react-bootstrap-typeahead";
import { putCandidates } from "../../../api/for_contractors/candidates_contractors";
import { workerRequestsGetInfo } from "../../../api/for_contractors/worker_requests_contractors";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import InputControlled from "../../utility/InputControlled";
import SelectCustom from "../../utility/SelectCustom";
import useFeedback from "../../../hooks/useFeedback";
import { formatDate } from "../../../utils/formatDate";
import useLanguage from "../../../hooks/useLanguage";

interface IProps {
	candidateId: any;
	setSecondaryWhich?: React.Dispatch<
		React.SetStateAction<"create" | "edit" | "candidate">
	>;
	// for when it's used in Candidates
	setModal?: any;
	setActive?: any;
}

function EditCandidate({
	candidateId,
	setSecondaryWhich,
	setModal,
	setActive,
}: IProps) {
	const { L } = useLanguage();

	// getting workerRequestId from params
	const { openFeedback, closeFeedback } = useFeedback();
	const client = useQueryClient();
	const id = candidateId;

	//

	const [info, setInfo] = useState<any>({
		firstname: "",
		lastname: null,
		middlename: "",
		birthday: "",
		passport: "",
		sex: null,
		phoneNumber: null,
		citizenship: "",
		visitDate: "",
		phoneNumberWithoutCode: "",
	});

	// refs to clear inputs
	const geoRef = useRef(null);
	const contractorRef = useRef(null);
	const jobTitleIdRef = useRef(null);
	// regex to check if number
	const re = /^\d+(\.|,)?\d*$/;
	// validation

	const [firstnamereq, setfirstnamereq] = useState(false);
	const [lastnamereq, setlastnamereq] = useState(false);
	const [birthdayreq, setbirthdayreq] = useState(false);
	const [passportreq, setpassportreq] = useState(false);
	const [sexreq, setsexreq] = useState(false);
	const [phoneNumberreq, setphoneNumberreq] = useState(false);
	const [citizenshipreq, setcitizenshipreq] = useState(false);
	const [visitDatereq, servisitDatereq] = useState(false);

	// post

	const { isSuccess, isError, mutate, error } = useMutation<any, any>(
		putCandidates,
		{
			onSuccess: () => {
				openFeedback(
					L("Кандидат успешно изменен", "Candidated has been edited"),
					"success"
				);
				setTimeout(() => {
					client.invalidateQueries("candidates");
					client.invalidateQueries("employeeRequestsContractors");
					client.invalidateQueries("candidatesGetInfo");
				}, 150);
				setTimeout(() => {
					client.invalidateQueries("candidatesGetInfo");
				}, 400);
				if (setSecondaryWhich) {
					setSecondaryWhich("candidate");
				}
				// when used in Candidates
				if (setActive) {
					setActive("candidate");
				}
				if (setModal) {
					setModal((prev: any) => ({
						...prev,
						secondaryOpened: false,
					}));
				}
			},
			onError: () => {
				openFeedback(
					L(
						"Что-то пошло не так, проверьте, заполнены ли все поля",
						"Something went wrong"
					),
					"error"
				);
			},
		}
	);

	const postCandidate = () => {
		const whatIsSex = (sex: any) => {
			if (sex === "true") {
				return true;
			}
			if (sex === "false") {
				return false;
			}
			if (sex === null) {
				return null;
			}
			if (sex === true) {
				return true;
			}
			if (sex === false) {
				return false;
			}
			if (sex === "null") {
				return null;
			} else {
				return null;
			}
		};

		const realSex = whatIsSex(info.sex);

		if (!info.firstname) setfirstnamereq(true);
		if (!info.lastname) setlastnamereq(true);
		if (!info.birthday) setbirthdayreq(true);
		if (!info.passport) setpassportreq(true);
		if (info.sex === null) setsexreq(true);
		if (!info.phoneNumberWithoutCode) setphoneNumberreq(true);
		if (!info.citizenship) setcitizenshipreq(true);
		if (!info.visitDate) servisitDatereq(true);

		if (info.firstname) setfirstnamereq(false);
		if (info.lastname) setlastnamereq(false);
		if (info.birthday) setbirthdayreq(false);
		if (info.passport) setpassportreq(false);
		if (info.sex !== null) setsexreq(false);
		if (info.phoneNumberWithoutCode) setphoneNumberreq(false);
		if (info.citizenship) setcitizenshipreq(false);
		if (info.visitDate) servisitDatereq(false);

		if (error) {
			if (error.response.status === 411) setpassportreq(true);
		}

		if (info.phoneNumberWithoutCode) {
			mutate({
				candidateId: id,
				...info,
				phoneNumber: L("+7", "+44") + info.phoneNumberWithoutCode,
				sex: realSex,
			});
		} else {
			mutate({ candidateId: id, ...info, workerRequestId: id, sex: realSex });
		}
	};

	const whichError = () => {
		if (error) {
			if (error.response.status === 411)
				return L(
					"Кандидат с таким паспортом уже был направлен на вакансию",
					"Candidate with this passport has already been sent"
				);
		} else {
			return L("Поле должно быть заполнено", "Field must not be empty");
		}
	};

	// options
	const sexOptions = [
		{
			value: true,
			title: L("Мужчины", "Male"),
		},
		{
			value: false,
			title: L("Женщины", "Female"),
		},
	];

	// get existing info

	const { data, isSuccess: isData } = useQuery(
		["candidatesGetInfo", id],
		(q) => candidateGetInfo(q),
		{
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);
	useEffect(() => {
		if (isData === true) {
			setInfo((prev: any) => ({
				...prev,
				firstname: data?.data?.firstName,
				lastname: data?.data?.lastName,
				middlename: data?.data?.middleName,
				birthday: formatDate(data?.data?.birthday),
				passport: data?.data?.passport,
				sex: data?.data?.candidateSex,
				phoneNumberWithoutCode: data?.data?.phoneNumber,
				citizenship: data?.data?.citizenship,
				visitDate: formatDate(data?.data?.visitDate),
			}));
			if (data?.data?.phoneNumber) {
				if (data?.data?.phoneNumber.slice(0, L(2, 3)) === L("+7", "+44")) {
					setInfo((prev: any) => ({
						...prev,
						phoneNumberWithoutCode: data?.data?.phoneNumber
							.toString()
							.slice(L(2, 3)),
					}));
				} else {
					setInfo((prev: any) => ({
						...prev,
						phoneNumberWithoutCode: data?.data?.phoneNumber.toString(),
					}));
				}
			}
		}
	}, [isData, data]);

	// more info
	const { data: reqdata, isSuccess: isReqData } = useQuery(
		["workerReqGetInfo", data?.data?.workerRequestId],
		(q) => workerRequestsGetInfo(q),
		{
			enabled: isData,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);

	// sex options
	const newSexOptions = {
		true: L("Мужчина", "Male"),
		false: L("Женщина", "Female"),
	};
	return (
		<>
			<section className="side-modal-container" style={{ width: "450px" }}>
				<div className="form-inner center-container-2">
					<div
						style={{
							marginTop: "-6px",
							marginBottom: "-3px",
							fontSize: "14px",
							color: "#474f5a",
						}}
						className="main-modal-head"
					>
						<h2>{L("Изменить кандидата", "Edit candidate")}</h2>
					</div>
					<form style={{ maxWidth: "99%" }}>
						<InputControlled
							label={L("Имя*", "First name*")}
							setState={setInfo}
							stateName="firstname"
							value={info.firstname}
							requiredState={firstnamereq}
						/>

						<InputControlled
							label={L("Фамилия*", "Last name*")}
							setState={setInfo}
							stateName="lastname"
							value={info.lastname}
							requiredState={lastnamereq}
						/>

						<InputControlled
							label={L("Отчество", "Middle name")}
							setState={setInfo}
							stateName="middlename"
							value={info.middlename}
						/>

						<InputControlled
							label={L("Дата рождения*", "Birth date*")}
							setState={setInfo}
							stateName="birthday"
							value={info.birthday}
							type="date"
							requiredState={birthdayreq}
						/>

						<InputControlled
							label={L("Паспорт*", "Passport")}
							setState={setInfo}
							stateName="passport"
							value={info.passport}
							requiredState={passportreq}
							errorMessage={whichError()}
						/>

						<SelectCustom
							styles={{ marginBottom: "16px" }}
							label={L("Пол*", "Sex*")}
							data={newSexOptions}
							setState={setInfo}
							stateName="sex"
							initial={
								data?.data?.candidateSexString || L("Выберите", "Choose..")
							}
							arrowPosition="far-right"
							requiredState={sexreq}
						/>

						<InputControlled
							label={L("Гражданство*", "Citizenship*")}
							setState={setInfo}
							stateName="citizenship"
							value={info.citizenship}
							requiredState={citizenshipreq}
						/>

						<InputControlled
							label={L("Дата визита*", "Visit date*")}
							setState={setInfo}
							stateName="visitDate"
							value={info.visitDate}
							type="date"
							requiredState={visitDatereq}
						/>

						{/* phone */}
						<div className="mt-2">
							<label className="form-label" htmlFor="phoneNumber">
								{L("Телефон*", "Phone*")}
							</label>
							<div
								className={L("phonefield-container", "phonefield-container-gb")}
							>
								<input
									style={phoneNumberreq ? { border: "1px solid #ff3b30" } : {}}
									value={info.phoneNumberWithoutCode}
									onChange={(e: any) =>
										setInfo((prev: any) => ({
											...prev,
											phoneNumberWithoutCode: e.target.value.toString(),
											phoneNumber: e.target.value.toString(),
										}))
									}
									type="telephone"
									id="phoneNumber"
									className=" phonefield form-control"
								/>
							</div>
							{phoneNumberreq && (
								<p className="input-validation-text">
									{" "}
									{L("Обязательное поле", "The field must not be empty")}
								</p>
							)}
						</div>
					</form>
				</div>
				<div className="bottom-container-2">
					<div className="flex">
						{setSecondaryWhich && ( // if its used in worker request do tis
							<CommonBtn
								onClick={() => setSecondaryWhich("candidate")}
								text={L("Отменить", "Cancel")}
								type="light"
							/>
						)}
						{setModal &&
							setActive && ( // if its used in worker request do tis
								<CommonBtn
									onClick={() => {
										setActive("candidate");
										setModal((prev: any) => ({
											...prev,
											secondaryOpened: false,
										}));
									}}
									text={L("Отменить", "Cancel")}
									type="light"
								/>
							)}
						<CommonBtn onClick={postCandidate} text={L("Сохранить", "Save")} />
					</div>
				</div>
			</section>
		</>
	);
}

export default EditCandidate;
