import { uploadObjects } from "../../api/objects";
import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import useFeedback from "../../hooks/useFeedback";
import useLanguage from "../../hooks/useLanguage";

interface IUploadError {
	errorDescription: string;
}
function UploadOutletsWidget() {
		const {L} = useLanguage()
	const [file, setFile] = useState<any>(null);
	const client = useQueryClient();
	const {openFeedback, closeFeedback} = useFeedback();
	const navigate = useNavigate();
	const handleFileChange = (e: any) => {
		console.log(e.target.files);
		setFile(e.target.files[0]);
		console.log(file);
	};

	const { isSuccess, mutate, isError, data, error } = useMutation<any, any>(
		uploadObjects,
		{
			onSuccess: () => {
				openFeedback(L("Филиалы успешно загружены", "Outlets uploaded successfully"), "success");
				setTimeout(() => {
					client.invalidateQueries("outlets");
				}, 400);
			},
			onError: () => {
				openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
			},
		}
	);
	const handleUpload = () => {
		mutate(file);
	};

	if (isError) console.log(error.response.data);
	const wrongExcelError = error?.response?.data;
	const axiosErr = error?.response?.data?.errors;

	const niceErrorsBro = () => {
		if (error) {
			if (error.response.data) {
				return error.response.data.map((err: any) => {
					return <div className="text-danger">{err}</div>;
				});
			}
		}
	};

	useEffect(() => {
		if (isSuccess) {
			console.log(isSuccess);
			navigate("../outlets");
		}
	}, [isSuccess]);
	return (
		<>
			<div className="">
				<div className="">
					<label htmlFor="upload" className="upload-file-label">
						<input type="file" onChange={(e) => handleFileChange(e)} />
					</label>
				</div>
				<div className="col-1">
					<button
						disabled={!!!file}
						className="btn btn-gray-300 btn-modal-right btn-form common-btn"
						style={{ width: "200px", height: "42px" }}
						onClick={handleUpload}
					>
						{L("Загрузить", "Upload")}
					</button>
				</div>
			</div>

			{isError && <div className="upload-errors">{niceErrorsBro()}</div>}
		</>
	);
}

export default UploadOutletsWidget;
