import { useQuery, useMutation } from "react-query";
import { getWorkerRequestsContractors } from "../../../api/for_contractors/worker_requests_contractors";
import Loading from "../../utility/Loading";
import TablePagination from "../../utility/TablePagination";
import { useState } from "react";
import WorkerRequestsFiltersContractors from "./WorkerRequestsFiltersContractors";
import { Link } from "react-router-dom";
import SortArrow from "../../utility/SortArrow";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import LongTextHide from "../../utility/LongTextHide";
import { BsFillPersonPlusFill } from "react-icons/bs";
import ModalMain from "../../utility/ModalMain/ModalMain";
import TableTotalCount from "../../utility/TableTotalCount";
import IconInputFilter from "../../utility/IconInputFilter";
import WorkerRequestInfoContractors from "./WorkerRequestInfoContractors/WorkerRequestInfoContractors";
import CreateCandidate from "../Candidates/CreateCandidate";
import EditCandidate from "../Candidates/EditCandidate";
import WorkerReqContractorCandidate from "./WorkerRequestInfoContractors/WorkerReqContractorCandidate";
import useLanguage from "../../../hooks/useLanguage";

function WorkerRequestsContractors() {
	const { L } = useLanguage();

	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		index: "",
		region: "",
		city: "",
		outlet: "",
		address: "",
		objectId: "",
		job: "",
		requestStatus: "",
		createdBy: "",
		StartCreatedOn: "",
		EndCreatedOn: "",
		companyId: "",
		searchInput: "",
		company: "",
		workerRequestId: "", // must be integer
		WorkSceduleTitleId: "",
		RequestStatus: "active",
		sex: "",
	});
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc
	const { data, isSuccess, refetch } = useQuery(
		["employeesRequestsContractors", { pageNumber: page }, filters, sort],
		(q) => getWorkerRequestsContractors(q),
		{
			enabled: true,
			retry: 2,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 5000,
			refetchInterval: 10000,
		}
	);

	//* modals
	// filters
	const [modalFilters, setModalFilters] = useState<{
		opened: boolean;
	}>({
		opened: false,
	});

	// info

	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		confirmOpened?: boolean;
		secondaryOpened?: boolean;
	}>({
		opened: false,
		payload: null,
		confirmOpened: false,
		secondaryOpened: false,
	});

	// used to determine which secondary modal should be opened
	const [secondaryWhich, setSecondaryWhich] = useState<
		"create" | "edit" | "candidate"
	>("create");

	// modals

	let headings = [
		{ name: L("№ Заявки", "Request ID") },
		{ name: L("Должность", "Job title") },
		{ name: L("Пол", "Sex") },
		{ name: L("Компания", "Company") },
		{ name: L("Филиал", "Outlet"), colname: "objectName " },
		{ name: L("ID филиала", "Outlet ID") },
		{ name: L("Локация", "Location") },

		{ name: L("Начало работы", "Work start") },
		{ name: L("Ставка c НДС", "Rate with tax"), colname: "hourlyRateVAT " },
		{
			name: L("Активные кандидаты", "Active candidates"),
			colname: "numOfCandidates",
		},
		{
			name: L("Всего кандидатов", "Total candidates"),
			colname: "numOfTotalCandidates",
		},
	];

	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};

	//delete workerRequest

	return (
		<div className="container-fluid text-vv-sm">
			<div className="upper-bar">
				<div className="upper-bar-first">
					<IconInputFilter
						setFilters={setFilters}
						placeholder={L("Искать заявку", "Search request")}
					/>
					<div
						onClick={() => setModalFilters({ opened: true })}
						className="filter-btn"
					>
						<img
							src="../../icons/setting-5.svg"
							alt=""
							width="20px"
							height="20px"
						/>
						<p>{L("Фильтры", "Filters")}</p>
					</div>

					{
						<TableTotalCount
							name={L("Заявок", "Requests")}
							headers={data?.headers["x-pagination"]}
						/>
					}
				</div>
				<div className="upper-bar-second"></div>
			</div>
			<ModalMain
				opened={modalFilters.opened}
				title={L("Фильтры", "Filters")}
				setModal={setModalFilters}
			>
				<WorkerRequestsFiltersContractors
					setFilters={setFilters}
					filters={filters}
				/>
			</ModalMain>

			<ModalMain
				setModal={setModalInfo}
				opened={modalInfo.opened}
				title={L("Информация о заявке", "Request information")}
				modal={modalInfo}
				secondaryOpened={modalInfo.secondaryOpened}
				secondary={() => {
					if (secondaryWhich === "create") {
						return (
							<CreateCandidate
								workerRequestId={modalInfo.payload?.workerRequestId}
								setModal={setModalInfo}
							/>
						);
					}
					if (secondaryWhich === "candidate") {
						return (
							<WorkerReqContractorCandidate
								setSecondaryWhich={setSecondaryWhich}
								setModal={setModalInfo}
								candidate={modalInfo?.payload?.data}
								setActive={modalInfo?.payload?.setActive}
							/>
						);
					}
					return (
						<EditCandidate
							setSecondaryWhich={setSecondaryWhich}
							candidateId={modalInfo?.payload?.candidateId}
						/>
					);
				}}
			>
				<WorkerRequestInfoContractors
					modal={modalInfo}
					setModal={setModalInfo}
					payload={modalInfo.payload}
					setSecondaryWhich={setSecondaryWhich}
					secondaryWhich={secondaryWhich}
				/>
			</ModalMain>
			{isSuccess ? (
				<>
					<div className="table-container">
						<table cellSpacing={0} className="my-table">
							<thead>
								<tr>
									{headings.map((heading) => (
										<th>
											<div className="d-flex align-items-end ">
												<div>{heading.name}</div>
												{heading.colname && (
													<div className="">
														<SortArrow
															sort={sort}
															heading={heading}
															setSort={setSort}
														/>
													</div>
												)}
											</div>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{isSuccess &&
									data?.data?.workerRequests.map((col) => {
										let workStartDate = col.workStartDate
											? col.workStartDate
											: // .replace("T", " ")
											  // .replace("-", ".")
											  // .replace("-", ".")
											  // .slice(0, -8)
											  "";

										let workEndDate = col.workEndDate
											? col.workEndDate
													.replace("T", " ")
													.replace("-", ".")
													.replace("-", ".")
													.slice(0, -8)
											: "";

										let createdOn = col.createdOn
											? col.createdOn
													.replace("T", " ")
													.replace("-", ".")
													.replace("-", ".")
													.slice(0, 10)
											: "";

										let sex = null;
										if (col.sex == true) {
											sex = "М";
										} else if (col.sex == false) {
											sex = "Ж";
										} else {
											sex = "";
										}

										return (
											<tr
												onClick={() => {
													setModalInfo({
														opened: true,
														payload: col,
													});
												}}
											>
												<td className="size-item">{col.workerRequestId}</td>
												<td>{col.position}</td>
												<td>{sex}</td>
												<td>{col.companyName}</td>
												<td>{col.objectName}</td>
												<td>{col.customObjectId}</td>
												<td>
													{col.address && (
														<div
															title={`${col.region}, ${col.city}, ${col.address}`}
															className="with-icon"
														>
															<img src="../../icons/location.svg" alt="" />
															<p>{`${col.region}, ${
																col.city
															},  ${col.address.slice(0, 30)}...`}</p>
														</div>
													)}
												</td>

												<td>{workStartDate}</td>
												<td>{col.hourlyRateVAT}</td>
												<td>{col.numOfCandidates}</td>
												<td>{col.numOfTotalCandidates}</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default WorkerRequestsContractors;
