import React from "react";
import useLanguage from "../../../hooks/useLanguage";

interface IUncheckAllBtn {
	styles?: any;
	onClick?: React.MouseEventHandler;
}
function UncheckAllBtn({ styles, onClick }: IUncheckAllBtn) {
	const { L } = useLanguage();
	return (
		<div onClick={onClick} style={styles} className="uncheck-all-btn">
			<img src="../../icons/del.svg" width="20px" height="20px" alt="" />
			<p>{L("Отменить выделение", "Uncheck all")}</p>
		</div>
	);
}

export default UncheckAllBtn;
