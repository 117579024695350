import dayjs from "dayjs";
import React from "react";
import useLanguage from "../../../hooks/useLanguage";
interface IProps {
	filters?: any;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
	data?: any;
	isSuccess?: any;
	datestart: any;
	setdatestart: any;
	dateend: any;
	setdateend: any;
}
function EmployeesPeriod({
	setFilters,
	filters,
	data,
	isSuccess,
	datestart,
	setdatestart,
	dateend,
	setdateend,
}: IProps) {
	const { L } = useLanguage();
	// get the beginning and the end of the month
	function getFirstDayOfMonth() {
		const firstDay = dayjs().startOf("month");
		return firstDay.format("YYYY-MM-DD");
	}

	function getLastDayOfMonth() {
		const lastDay = dayjs().endOf("month");
		return lastDay.format("YYYY-MM-DD");
	}

	return (
		<div className="employees-working-period-block">
			<div className="start">
				<input
					title={L("Начало расчетного периода", "Billing period start")}
					value={datestart && datestart}
					onChange={(e) => {
						setdatestart(e.target.value);
						setFilters((prev: any) => ({
							...prev,
							StartWorkDate: e.target.value,
						}));
					}}
					className="form-control"
					type="date"
					name=""
					id=""
				/>
			</div>
			<div className="end">
				<input
					title={L("Окончание расчетного периода", "Billing period end")}
					value={dateend}
					onChange={(e) => {
						setdateend(e.target.value);
						setFilters((prev: any) => ({
							...prev,
							EndWorkDate: e.target.value,
						}));
					}}
					// defaultValue={"2013-01-08"}
					className="form-control"
					type="date"
					name=""
					id=""
				/>
			</div>
		</div>
	);
}

export default EmployeesPeriod;
