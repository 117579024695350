import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { dlWorkers, getEmployees } from "../../api/employees";
import Loading from "../../components/utility/Loading";
import TablePagination from "../../components/utility/TablePagination";
import EmployeeChangeStatus from "./modals/EmployeeChangeStatus";
import EmployeeFilters from "./modals/EmployeesFIlters";
import { GrClose } from "react-icons/gr";
import "../../styles/modalCandidate/modalCandidate.css";
import { Link } from "react-router-dom";
import { createArrayOptions } from "../../utils/createArrayOptionsFromObj";
import SortArrow from "../../components/utility/SortArrow";
import ModalMain from "../../components/utility/ModalMain/ModalMain";
import MoreInfoItem from "../../components/outlets/OutletInfo/MoreInfoItem";
import TableTotalCount from "../../components/utility/TableTotalCount";
import EmployeeInfo from "../EmployeeInfo.tsx/EmployeeInfo";
import TimeTable from "../../components/timesheet/TimeTable";
import TimeSheet from "../../components/timesheet/TimeSheet";
import IconInputFilter from "../../components/utility/IconInputFilter";
import { shortenFullname } from "../../utils/shortenFullname";
import { AiOutlineFieldTime } from "react-icons/ai";
import { addThousandSeparator } from "../../utils/thousandSeparator";
import { BiRuble } from "react-icons/bi";
import EmployeeEdit from "./modals/EmployeeEdit";
import { reverseFormatDate } from "../../utils/formatDate";
import useTableCheck from "../../hooks/useTableCheck";
import TableHeadSortWithCheck from "../../components/utility/Table/headings/TableHeadSortWithCheck";
import { TableCheckBody } from "../../components/utility/Table/TableCheck";
import EmployeesPeriod from "./elements/EmployeesPeriod";
import GeneralInfo from "./modals/GeneralInfo";
import HoursBtns from "./elements/btns/HoursBtns";
import UncheckAllBtn from "../../components/utility/Buttons/UncheckAllBtn";
import DeleteBtn from "../../components/utility/Buttons/DeleteBtn";
import TableHoursIcon from "../../components/utility/Table/TableHoursIcon";
import useLanguage from "../../hooks/useLanguage";

function Employees() {
	const { L } = useLanguage();
	const [isShownInfo, setIsShownInfo] = useState(false);
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		region: "",
		ObjectId: "",
		city: "",
		job: "",
		outlet: "",
		address: "",
		contractorName: "",
		contractorId: "",
		WorkerFullname: "",
		ObjectResponsibleFullName: "",
		hoursStatusId: "",
		searchInput: "",
		UnderChainManagerId: "",
		StartWorkDate: null,
		EndWorkDate: null,
		workerStatusId: "",
	});
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc
	const [datestart, setdatestart] = useState("");
	const [dateend, setdateend] = useState("");

	const { data, isSuccess, isError } = useQuery(
		["employees", { pageNumber: page }, filters, sort],
		(q) => getEmployees(q),
		{
			enabled: true,
			retry: 2,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 1000000,
		}
	);

	//!check stuff
	const idsArray = data?.data.workers.map((el) => el.workerId) || [];
	const {
		addChecked,
		switchCheckedAll,
		getChecked,
		checked,
		added, // this is passed to delete multiple
		isAllChecked,
		total,
		uncheckAll,
	} = useTableCheck({
		ids: idsArray,
	});
	// console.log(added);
	//!

	//* modals
	const [modalFilters, setModalFilters] = useState<{
		opened: boolean;
	}>({
		opened: false,
	});

	const [generalInfoModal, setGeneralInfoModal] = useState({
		opened: false,
	});
	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		secondaryOpened?: boolean;
		confirmOpened?: boolean;
	}>({
		opened: false,
		payload: null,
		secondaryOpened: false,
		confirmOpened: false,
	});

	const [whichSecondary, setWhichSecondary] = useState<"hours" | "edit">(
		"hours"
	);

	//*/modals

	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};

	let headings = [
		{ name: L("ID филиала", "Outlet ID") },
		{ name: L("Филиал", "Outlet") },
		{ name: L("Должность", "Job title") },
		{ name: L("Сотрудник", "Employee") },
		{ name: L("Ответственный", "Responsible") },
		{ name: L("Подрядчик", "Contractor") },
		// { name: "Статус" },
		// { name: "Отработано", colname: "totalWorkedHours" },
		// { name: "Стоимость услуг", colname: "costsWithVats" },
		{
			name: L("Не согласовано", "Not approved"),
			colname: "workedHoursNotApproved",
		},
		{
			name: L("На согл. у подрядчика", "Awaiting approval by contractor"),
			colname: "workedHoursInReview",
		},
		{
			name: L("На согл. у менеджера", "Awaiting approval by manager"),
			colname: "workedHoursApprovedByContractor",
		},
		{
			name: L("Согл. менеджером", "Approved by manager"),
			colname: "workedHoursApprovedByChainManager",
		},
		{ name: L("Всего часов", "Total hours"), colname: "totalWorkedHours" },
	];
	if (localStorage.getItem("role") == "Director") {
		headings = [
			{ name: L("Должность", "Job title") },
			{ name: L("Сотрудник", "Employee") },
			{ name: L("Подрядчик", "Contractor") },
			// { name: "Статус" },
			// { name: "Отработано", colname: "totalWorkedHours" },
			{
				name: L("Не согласовано", "Not approved"),
				colname: "workedHoursNotApproved",
			},
			{
				name: L("На согласовании", "On review"),
				colname: "workedHoursInReview",
			},
			{
				name: L("Согл. подрядчиком", "Approved by contractor"),
				colname: "workedHoursApprovedByContractor",
			},
			{
				name: L("Согл. менеджером", "Approved by manager"),
				colname: "workedHoursApprovedByChainManager",
			},
			{ name: L("Всего часов", "Total hours"), colname: "totalWorkedHours" },
		];
	}
	if (localStorage.getItem("role") == "UnderChainManager") {
		headings = [
			{ name: L("ID филиала", "Outlet ID") },
			{ name: L("Филиал", "Outlet") },
			{ name: L("Должность", "Job title") },
			{ name: L("Работник", "Employee") },
			{ name: L("Ответственный", "Responsible") },
			{ name: L("Подрядчик", "Contractor") },
			// { name: "Статус" },
			// { name: "Отработано", colname: "totalWorkedHours" },
			{
				name: L("Не согласовано", "Not approved"),
				colname: "workedHoursNotApproved",
			},
			{
				name: L("На согласовании", "In review"),
				colname: "workedHoursInReview",
			},
			{
				name: L("Согл. подрядчиком", "Approved by contractor"),
				colname: "workedHoursApprovedByContractor",
			},
			{
				name: L("Согл. менеджером", "Approved by manager"),
				colname: "workedHoursApprovedByChainManager",
			},
			{ name: L("Всего часов", "Total hours"), colname: "totalWorkedHours" },
		];
	}

	const headingsOther = [""];
	const workers = data?.data?.workers;

	const roleNotDirector = localStorage.getItem("role") != "Director";
	const roleNotUnder = localStorage.getItem("role") != "UnderChainManager";

	const chosenFilters = data?.data.chosenFilters;
	const chosenRegion = createArrayOptions(chosenFilters?.regions);
	// console.log(chosenRegion);

	useEffect(() => {
		if (chosenFilters?.startWorkDate) {
			setdatestart(chosenFilters.startWorkDate);
		}
		if (chosenFilters?.endWorkDate) {
			setdateend(chosenFilters.endWorkDate);
		}
	}, [isSuccess, chosenFilters]);
	// console.log(datestart);
	return (
		<div className="container-fluid">
			<div className="upper-bar">
				{total === 0 ? (
					<>
						<div className="upper-bar-first">
							<IconInputFilter
								inputsize="small"
								setFilters={setFilters}
								placeholder={L("Искать сотрудника", "Search employee")}
							/>

							<EmployeesPeriod
								dateend={dateend}
								datestart={datestart}
								setFilters={setFilters}
								setdateend={setdateend}
								setdatestart={setdatestart}
								filters={filters}
								// isSuccess={isS}
							/>
							<div
								onClick={() => setModalFilters({ opened: true })}
								className="filter-btn"
							>
								<img
									src="../../icons/setting-5.svg"
									alt=""
									width="20px"
									height="20px"
								/>
								<p>{L("Фильтры", "Filters")}</p>
							</div>
							<TableTotalCount
								name={L("Сотрудников", "Employees")}
								headers={data?.headers["x-pagination"]}
							/>
						</div>

						<div className="upper-bar-second">
							<img
								src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
								width="35px"
								style={{ marginRight: "30px", cursor: "pointer" }}
								alt=""
								onClick={() => {
									dlWorkers(
										page,
										{
											...filters,
											StartWorkDate: datestart,
											EndWorkDate: dateend,
										},
										sort
									);
								}}
							/>
							<div
								onClick={() => setGeneralInfoModal({ opened: true })}
								className=""
							>
								<p style={{ color: "#5e73e1", cursor: "pointer" }}>
									{L("Cводная информация", "Summary")}
								</p>
							</div>
						</div>
					</>
				) : (
					<>
						<>
							<div className="upper-bar-first">
								<TableTotalCount
									name={L("Выделено", "Checked")}
									type="checked"
									total={total}
								/>
							</div>
							<div className="upper-bar-second">
								<HoursBtns
									added={added}
									datestart={datestart}
									dateend={dateend}
									uncheckAll={uncheckAll}
									queryName="employees"
								/>
								<UncheckAllBtn
									onClick={uncheckAll}
									styles={{ marginLeft: "16px" }}
								/>
							</div>
						</>
					</>
				)}
			</div>
			<ModalMain
				modal={modalInfo}
				setModal={setModalInfo}
				opened={modalInfo.opened}
				title={L("Информация о сотруднике", "Employee information")}
				secondary={() => {
					if (whichSecondary === "hours") {
						return (
							<TimeSheet
								setModal={setModalInfo}
								workerId={modalInfo.payload.workerId}
								workerNameOthers={modalInfo.payload.workerFullname}
							/>
						);
					} else {
						return (
							<EmployeeEdit
								setModal={setModalInfo}
								workerId={modalInfo.payload.workerId}
								startWorkDate={datestart}
								endWorkDay={dateend}
							/>
						);
					}
				}}
				secondaryOpened={modalInfo.secondaryOpened || false}
			>
				<EmployeeInfo
					startWorkDate={datestart}
					endWorkDay={dateend}
					whichSecondary={whichSecondary}
					setWhichSecondary={setWhichSecondary}
					secondaryOpened={modalInfo.secondaryOpened || false}
					setModal={setModalInfo}
					modal={modalInfo}
					payload={modalInfo.payload}
				/>
			</ModalMain>
			{isSuccess ? (
				<>
					<ModalMain
						opened={modalFilters.opened}
						title={L("Фильтры", "Filters")}
						setModal={setModalFilters}
					>
						<EmployeeFilters
							datestart={datestart}
							dateend={dateend}
							setdatestart={setdatestart}
							setdateend={setdateend}
							data={data}
							isSuccess={isSuccess}
							setFilters={setFilters}
							filters={filters}
						/>
					</ModalMain>

					<ModalMain
						setModal={setGeneralInfoModal}
						opened={generalInfoModal.opened}
						title={L("Сводная информация", "Summary")}
					>
						<GeneralInfo data={data} dateend={dateend} datestart={datestart} />
					</ModalMain>

					<div
						className={
							isShownInfo ? `modal-candidate` : `modal-candidate hidden`
						}
					>
						<div className="modal-candidate-inner"></div>
					</div>
					<div className="table-container">
						<table
							cellSpacing={0}
							className="my-table choose-table table-responsive-sm"
						>
							<TableHeadSortWithCheck
								headings={headings}
								setSort={setSort}
								sort={sort}
								isAllChecked={isAllChecked}
								switchCheckedAll={switchCheckedAll}
							/>
							<tbody>
								{workers &&
									workers.map((col) => {
										let workStartDate = col.workStartDate;

										let workEndDate = col.workEndDate;

										return (
											<tr
												key={col.workerId}
												onClick={() => {
													setModalInfo({
														opened: true,
														payload: {
															workerId: col.workerId,
															region: col.region,
															city: col.city,
															objectName: col.objectName,
															position: col.position,
															workerFullname: col.workerFullname,
															citizenship: col.citizenship,
															workStartDate: col.workStartDate,
															workEndDate: workEndDate,
															address: col.address,
															totalWorkedHours: col.totalWorkedHours,
															costsWithVat: col.externalRateVat,
															costs: col.costs,
															externalRateVat: col.externalRateVat,
															externalRate: col.externalRate,
															workerStatusName: col.workerStatusName,
															workerStatusId: col.workerStatusId,
															customObjectId: col.customObjectId,
															passport: col.passport,
															isAvaliableToEdit: col.isAvailableToEdit,
															sex: col.workerSexString,
															birthday: col.birthday,
														},
														secondaryOpened: false,
													});
												}}
											>
												<TableCheckBody
													itemId={col.workerId}
													addChecked={addChecked}
													getChecked={getChecked}
												/>
												{roleNotDirector && (
													<td>{col.customObjectId || "-"}</td>
												)}
												{roleNotDirector && <td>{col.objectName}</td>}
												<td className={roleNotDirector ? `` : "size-item"}>
													{col.position}
												</td>

												{roleNotDirector ? (
													<td>{`${
														col.workerStatusNameForTable
													} ${shortenFullname(col.workerFullname)}`}</td>
												) : (
													<td>{`${
														col.workerStatusNameForTable
													} ${shortenFullname(
														col.objectResponsibleFullName
													)}`}</td>
												)}

												{roleNotDirector && (
													<td>
														{shortenFullname(col.objectResponsibleFullName)}
													</td>
												)}
												<td>{col.contractorName}</td>
												{/* <td>{col.workerStatusName}</td> */}

												<td>
													<TableHoursIcon
														hours={col.workedHoursNotApproved}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
												<td>
													<TableHoursIcon
														hours={col.workedHoursInReview}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
												<td>
													<TableHoursIcon
														hours={col.workedHoursApprovedByContractor}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
												<td>
													<TableHoursIcon
														hours={col.workedHoursApprovedByChainManager}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
												<td>
													<TableHoursIcon
														hours={col.totalWorkedHours}
														datestart={datestart}
														dateend={dateend}
													/>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default Employees;
