import React from "react";
import { AiOutlineFieldTime } from "react-icons/ai";
import { reverseFormatDate } from "../../../utils/formatDate";
import useLanguage from "../../../hooks/useLanguage";

interface IProps {
	hours: number | string | null | undefined;
	datestart: string;
	dateend: string;
}

function TableHoursIcon({ hours, datestart, dateend }: IProps) {
	const { L } = useLanguage();

	const getHours = () => {
		if (!hours) {
			return L("0ч.", "0h.");
		} else {
			return L(`${hours}ч.`, `${hours}h.`);
		}
	};
	return (
		<div
			title={`${L("Период", "Period")}: ${reverseFormatDate(
				datestart
			)} – ${reverseFormatDate(dateend)}`}
			style={{ display: "flex", alignItems: "center" }}
		>
			<AiOutlineFieldTime size={15} style={{ marginRight: "2px" }} />
			{getHours()}
		</div>
	);
}

export default TableHoursIcon;
