// Иван Иванович Иванов => Иван И.И
export function shortenFullname(fullname?: string): string {
	if (!fullname) {
		return "";
	}

	let nameParts = fullname.split(" ");

	if (nameParts[2] == "" || nameParts[3] == "") {
		return `${nameParts[0]} ${nameParts[1].slice(0, 1)}.`;
	}
	if (nameParts[2] !== "") {
		if (nameParts.length === 3) {
			return `${nameParts[0]} ${nameParts[1].slice(0, 1)}.${nameParts[2].slice(
				0,
				1
			)}.`;
		}
	}
	if (nameParts.length === 2) {
		return `${nameParts[0]} ${nameParts[1].slice(0, 1)}.`;
	}

	return "";
}
