export const monthsData = {
	"01": "Январь",
	"02": "Февраль",
	"03": "Март",
	"04": "Апрель",
	"05": "Май",
	"06": "Июнь",
	"07": "Июль",
	"08": "Август",
	"09": "Сентябрь",
	"10": "Октябрь",
	"11": "Ноябрь",
	"12": "Декабрь",
};

export const monthsDataEng = {
	"01": "January",
	"02": "February",
	"03": "March",
	"04": "April",
	"05": "May",
	"06": "June",
	"07": "July",
	"08": "August",
	"09": "September",
	"10": "October",
	"11": "November",
	"12": "December",
};

export const yearsData = {
	"2021": 2021,
	"2022": 2022,
	"2023": 2023,
};

export const yearsDataReverse = {
	"2023": 2023,
	"2022": 2022,
	"2024": 2024,
};

export type monthKeyType = keyof typeof monthsData;
export const getMonthFromNumber = (month: monthKeyType) => {
	return monthsData[month];
};

export const getMonthFromNumberEn = (month: monthKeyType) => {
	return monthsDataEng[month];
};

export const hoursText = (hour: string | undefined): string | undefined => {
	if (hour) {
		const hourNumber = Number(hour);
		if (localStorage.getItem("lang") === "EN") {
			if (hourNumber === 1) {
				return "1 hour";
			}
			if (!hourNumber) {
				return "-";
			} else {
				return `${hour} hours`;
			}
		}
		if (hourNumber === 1) {
			return "1 час";
		} else if (hourNumber === 2) {
			return "2 часа";
		} else if (hourNumber === 3) {
			return "3 часа";
		} else if (hourNumber === 4) {
			return "4 часа";
		} else if (hourNumber > 4 && hourNumber < 21) {
			return `${hourNumber} часов`;
		} else if (hourNumber === 21) {
			return `21 час`;
		} else if (hourNumber > 21 && hourNumber < 25) {
			return `${hourNumber} часа`;
		} else if (hourNumber > 24) {
			return `${hourNumber} часов`;
		}
	}
	return "-";
};
