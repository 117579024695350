import FilterAutocomplete from "../../utility/FilterAutocomplete";
import InputFilter from "../../utility/InputFilter";
import { AxiosResponse } from "axios";
import { THourlyRates } from "../../../api/rates";
import { createArrayOptions } from "../../../utils/createArrayOptionsFromObj";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import SelectCustom from "../../utility/SelectCustom";
import {
	getMonthFromNumber,
	monthKeyType,
	monthsData,
	yearsDataReverse,
} from "../../../utils/datesData";
import MonthsSelectCustom from "../../utility/MonthsSelectCustom";
import dayjs from "dayjs";

interface IRatesProps {
	data: AxiosResponse<THourlyRates, any> | undefined;
	filters: {
		year: string;
		month: string;
		company: null;
		region: null;
		city: null;
		contractor: null;
		job: null;
		searchInput: any;
	};
	setFilters: React.Dispatch<
		React.SetStateAction<{
			year: string;
			month: string;
			company: null;
			region: null;
			city: null;
			contractor: null;
			job: null;
			searchInput: any;
		}>
	>;
	year: string;
	month: string;
}

// get current year and month
const minTwoDigits = (n: number) => {
	return (n < 10 ? "0" : "") + n.toString();
};

function RatesFilters({ data, filters, setFilters, month, year }: IRatesProps) {
	// clear filters
	const clear = () => {
		setFilters((prev: any) => ({
			...prev,
			company: null,
			region: null,
			city: null,
			contractor: null,
			job: null,
			year: "1",
			month: "00",
		}));
	};

	// i dont care i fix filters as i want

	// initial month
	const monthsDataNoChosen: { [key: string]: string } = {
		"00": "Не выбрано",
		"01": "Январь",
		"02": "Февраль",
		"03": "Март",
		"04": "Апрель",
		"05": "Май",
		"06": "Июнь",
		"07": "Июль",
		"08": "Август",
		"09": "Сентябрь",
		"10": "Октябрь",
		"11": "Ноябрь",
		"12": "Декабрь",
	};
	const initialMonth = () => {
		for (let key in monthsDataNoChosen) {
			if (filters.month === key) return monthsDataNoChosen[key];
		}
	};
	// initial year
	const yearsData: { [key: string]: any } = {
		"1": "Не выбран",
		"2023": 2023,
		"2022": 2022,
		"2024": 2024,
	};

	const initialYear = () => {
		for (let key in yearsData) {
			if (filters.year == key) return yearsData[key];
		}
	};

	return (
		<div className="">
			<div style={{ marginBottom: "20px" }}>
				<CommonBtn onClick={clear} type="light" text="Очистить фильтры" />
			</div>

			<SelectCustom
				arrowPosition="far-right"
				styles={{ marginBottom: "16px" }}
				data={{ "1": "Не выбран", ...yearsDataReverse }}
				setState={setFilters}
				stateName="year"
				initial={initialYear()}
				label="Год"
			/>

			<MonthsSelectCustom
				arrowPosition="far-right"
				styles={{ marginBottom: "16px" }}
				data={{ "00": "Не выбран", ...monthsData }}
				setState={setFilters}
				stateName="month"
				initial={initialMonth()}
				label="Месяц"
			/>

			<FilterAutocomplete
				id="company"
				options={data?.data?.filterValues?.companies}
				setFilters={setFilters}
				filterName={"company"}
				label="Компания"
				selectedValue={data?.data?.chosenFilters?.companies.value}
				placeholder={"Пример: Продукты"}
			/>
			<FilterAutocomplete
				id="region"
				options={data?.data?.filterValues?.regions}
				setFilters={setFilters}
				filterName={"region"}
				selectedValue={data?.data?.chosenFilters?.regions.value}
				label="Регион"
				placeholder={"Пример: Омская область"}
			/>
			<FilterAutocomplete
				id="city"
				label="Город"
				options={data?.data?.filterValues?.cities}
				setFilters={setFilters}
				filterName={"city"}
				selectedValue={data?.data?.chosenFilters?.cities.value}
				placeholder={"Пример: Москва"}
			/>
			<FilterAutocomplete
				id="contractor"
				options={data?.data?.filterValues?.contractors}
				setFilters={setFilters}
				filterName={"contractor"}
				selectedValue={data?.data?.chosenFilters?.contractors.value}
				placeholder={"Пример: Подрядчик 1"}
				label="Подрядчик"
			/>
			<FilterAutocomplete
				id="job"
				options={data?.data?.filterValues?.jobTitles}
				setFilters={setFilters}
				filterName={"job"}
				selectedValue={data?.data?.chosenFilters?.jobTitles?.value}
				placeholder={"Пример: Кассир"}
				label="Должность"
			/>
		</div>
	);
}

export default RatesFilters;
