import React, { useEffect, useState } from "react";
import useLanguage from "../../hooks/useLanguage";

interface IProps {
	name: string;
	headers?: string | undefined;
	type?: "standart" | "checked";
	total?: string | number;
}

function TableTotalCount({ headers, name, type = "standart", total }: IProps) {
	const { L } = useLanguage();
	// type : standart

	const [count, setCount] = useState("");
	const getCount = () => {
		if (headers) {
			if (JSON.parse(headers).TotalCount) {
				setCount(JSON.parse(headers).TotalCount.toString());
				return JSON.parse(headers).TotalCount.toString();
			}
			if (JSON.parse(headers).TotalCount === 0) {
				setCount(JSON.parse(headers).TotalCount.toString());
				return JSON.parse(headers).TotalCount.toString();
			}
		} else {
			setCount(L("Загрузка...", "Loading..."));
		}
	};

	useEffect(() => {
		getCount();
	}, [headers]);

	// type: checked

	if (type === "checked") {
		return (
			<div className="total-quantity">
				<p>{name}: &nbsp; </p>
				<p className="quantity"> {total || 0}</p>
			</div>
		);
	}

	return (
		<div className="total-quantity">
			<p>{name}: &nbsp; </p>
			<p className="quantity"> {count}</p>
		</div>
	);
}

export default TableTotalCount;
