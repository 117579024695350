import { instanceV3 } from "../instances";
import { QueryFunctionContext } from "react-query";

interface IEmployeeReqs {
	filterValues: any;
	workerRequests: {
		workerRequestId: number;
		companyId: number;
		companyName: string;
		regionId: number;
		region: string;
		cityId: number;
		city: string;
		objectId: number;
		objectName: string;
		position: number;
		schedule: string;
		sex: null | false | true;
		workStartDate: string;
		requestDeadline: string;
		workEndDate: string;
		comment: string | null;
		requestStatus: string;
		address: string;
		createdOn: string;
		numOfSentCandidates: number;
		hourlyRate: any;
		hourlyRateVAT: any;
		numOfCandidates: any;
		numOfTotalCandidates: any;
		objectResponsibleFullName: any;
		objectResponsibleEmail: any;
		ObjectResponsiblePhoneNumber: any;
		customObjectId: any;
	}[];
	chosenFilters: {
		cities: any;
		objectNames: any;
		regions: any;
		jobTitles: any;
		requestStatuses: any;
		companies: any;
		workSceduleTitles: any;
	};
}
export const getWorkerRequestsContractors = async (
	q: QueryFunctionContext<any>
) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let region = "";
	if (q?.queryKey[2]?.region) {
		region = `&RegionId=${q?.queryKey[2]?.region}`;
	}

	let objectId = "";

	let customObjectId = "";
	if (q?.queryKey[2]?.index) {
		customObjectId = `&CustomObjectId=${q?.queryKey[2]?.index}`;
	}

	let city = "";
	if (q?.queryKey[2]?.city) {
		city = `&CityId=${q?.queryKey[2]?.city}`;
	}
	let outlet = "";
	if (q?.queryKey[2]?.outlet) {
		outlet = `&ObjectId=${q?.queryKey[2]?.outlet}`;
	}

	let address = "";
	if (q?.queryKey[2]?.address) {
		address = `&Address=${q?.queryKey[2]?.address}`;
	}

	let job = "";
	if (q?.queryKey[2]?.job) {
		job = `&JobTitleId=${q?.queryKey[2]?.job}`;
	}

	let requestStatus = "";
	if (q?.queryKey[2]?.requestStatus) {
		requestStatus = `&requestStatusId=${q?.queryKey[2]?.requestStatus}`;
	}
	let company = "";
	if (q?.queryKey[2]?.company) {
		company = `&CompanyId=${q?.queryKey[2]?.company}`;
	}
	let createdBy = "";

	if (q?.queryKey[2]?.createdBy) {
		createdBy = `&CreatedByFullName=${q?.queryKey[2]?.createdBy}`;
	}

	let StartCreatedOn = "";
	if (q?.queryKey[2]?.StartCreatedOn) {
		StartCreatedOn = `&StartCreatedOn=${q?.queryKey[2]?.StartCreatedOn}`;
	}

	let EndCreatedOn = "";
	if (q?.queryKey[2]?.EndCreatedOn) {
		EndCreatedOn = `&EndCreatedOn=${q?.queryKey[2]?.EndCreatedOn}`;
	}

	let RequestStatus = "";
	if (q?.queryKey[2]?.RequestStatus) {
		RequestStatus = `&RequestStatus=${q?.queryKey[2]?.RequestStatus}`;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	// only number!
	function stringToNumber(str: string): number | null {
		const num = Number(str);
		return isNaN(num) ? null : num;
	}
	let workerRequestId = stringToNumber(q?.queryKey[2]?.workerRequestId);
	if (workerRequestId === 0) {
		workerRequestId = null;
	}
	let WorkSceduleTitleId = stringToNumber(q?.queryKey[2]?.WorkSceduleTitleId);

	// sex
	let sex = "";
	if (q?.queryKey[2]?.sex) {
		sex = `&Sex=${q?.queryKey[2]?.sex}`;
	}

	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}
	const response = await instanceV3.get<IEmployeeReqs>(
		`WorkerRequests${page}${region}${city}${outlet}${createdBy}${StartCreatedOn}${EndCreatedOn}${job}${requestStatus}${column}${order}${objectId}${address}${customObjectId}${company}${searchInput}${
			workerRequestId ? `&workerRequestId=${workerRequestId}` : ""
		}${
			WorkSceduleTitleId ? `&WorkSceduleTitleId=${WorkSceduleTitleId}` : ""
		}${RequestStatus}${sex}`
	);
	return response;
};

interface IWorkerRequestInfo {
	workerRequestId: number;
	companyName: string;
	objectName: string;
	region: string;
	city: string;
	address: string;
	position: string;
	sex: true | false | null;
	sexString: string;
	workStartDate: string;
	workEndDate: string;
	hourlyRate: any;
	hourlyRateVAT: any;
	requestDeadline: any;
	comment: string;
	objectResponsibleFullName: any;
	objectResponsibleEmail: any;
	ObjectResponsiblePhoneNumber: any;
}

export const workerRequestsGetInfo = async (q?: QueryFunctionContext<any>) => {
	let id = q?.queryKey[1];

	const response = await instanceV3.get<IWorkerRequestInfo>(
		`/WorkerRequests/getInfo?workerRequestId=${id}`
	);
	return response;
};
