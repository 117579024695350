import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { deleteHourlyRates, getRateInfo } from "../../../../api/rates";
import Loading from "../../../utility/Loading";
import CommonBtn from "../../../utility/Buttons/CommonBtn";
import DeleteBtn from "../../../utility/Buttons/DeleteBtn";
import MoreInfoItem from "../../../outlets/OutletInfo/MoreInfoItem";
import useFeedback from "../../../../hooks/useFeedback";
import ConfirmModal from "../../../utility/confirmModal/ConfirmModal";

interface IProps {
	id: number;
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			confirmOpened?: boolean | undefined;
			secondaryOpened?: boolean | undefined;
		}>
	>;
}

function RateInfo({ id, setModal }: IProps) {
	const { data: rateInfo, isSuccess } = useQuery(["getRateInfo", id], (q) =>
		getRateInfo(q)
	);

	const {openFeedback, closeFeedback} = useFeedback();
	const client = useQueryClient();

	// delete rate
	const { mutate: mutateDelete } = useMutation(deleteHourlyRates, {
		onSuccess: () => {
			openFeedback("Ставка успешно удалена", "success");
			setTimeout(() => {
				client.invalidateQueries("hourlyRates");
			}, 150);
			setModal((prev) => ({
				...prev,
				opened: false,
			}));
		},
		onError: () => {
			openFeedback("Что-то пошло не так", "success");
		},
	});

	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	// Open Conformations
	const openDelete = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title="Удалить ставку"
					text={"Вы уверены, что хотите удалить ставку?"}
				>
					<DeleteBtn
						text="Да, удалить"
						onClick={() => {
							mutateDelete(id);
							closeConfirmation();
						}}
					/>
					<CommonBtn text="Отменить" onClick={closeConfirmation} type="light" />
				</ConfirmModal>
			),
		}));
	};

	if (!isSuccess) {
		return <Loading type="inside" />;
	}

	return (
		<div className="side-modal-container">
			<div className="center-container-3">
				<div className="more-info-container">
					<MoreInfoItem name="Год" data={rateInfo?.data?.year} />
					<MoreInfoItem name="Месяц" data={rateInfo?.data?.monthDescr} />
					<MoreInfoItem name="Компания" data={rateInfo?.data?.companyName} />
					<MoreInfoItem name="Регион" data={rateInfo?.data?.regionName} />
					<MoreInfoItem name="Город" data={rateInfo?.data?.cityName} />
					<MoreInfoItem
						name="Подрядчик"
						data={rateInfo?.data?.contractorName}
					/>
					<MoreInfoItem name="Должность" data={rateInfo?.data?.jobTitleName} />
					<MoreInfoItem
						name="Ставка"
						data={rateInfo?.data?.hourlyRateWithVat}
					/>
				</div>
			</div>
			<div className="bottom-container-3">
				<div className="flex">
					<DeleteBtn
						onClick={openDelete}
						styles={{ width: "43%", height: "19px" }}
					/>
					<CommonBtn
						onClick={() => {
							setModal((prev) => ({
								...prev,
								secondaryOpened: true,
							}));
						}}
						text="Изменить"
					/>
				</div>
			</div>
		</div>
	);
}

export default RateInfo;
