import { Link, Outlet } from "react-router-dom";
import Nav from "./Nav/Nav";
import NavRates from "./NavRates";

function LayoutRates() {
	return (
		<>
			<Nav />
			<NavRates />
			<Outlet />
		</>
	);
}

export default LayoutRates;
