import React from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { RxCaretSort } from "react-icons/rx";

interface IProps {
	sort: any;
	setSort: any;
	heading: any;
}

function SortArrow({ sort, setSort, heading }: IProps) {
	const handleSortBy = (head: string) => {
		if (head != sort.heading) {
			setSort({
				heading: head,
				order: "desc",
			});
		}
		if (head == sort.heading) {
			if (sort.order == "desc") {
				setSort({
					heading: head,
					order: "asc",
				});
			}
			if (sort.order == "asc") {
				setSort({
					heading: "",
					order: "",
				});
			}
		}
	};
	if (heading.colname !== sort.heading) {
		return (
			<>
				<div
					className="sort-arrow cursor-pointer"
					onClick={() => handleSortBy(heading.colname)}
				>
					<RxCaretSort size={20} color="gray" />
				</div>
			</>
		);
	} else {
		if (sort.order === "asc") {
			return (
				<div
					className="sort-arrow cursor-pointer"
					onClick={() => handleSortBy(heading.colname)}
				>
					<AiFillCaretUp />
				</div>
			);
		}
		return (
			<div
				className="sort-arrow cursor-pointer"
				onClick={() => handleSortBy(heading.colname)}
			>
				<AiFillCaretDown />
			</div>
		);
	}
}

export default SortArrow;
