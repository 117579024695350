export const sexCheck = (sexId: any) => {
	if (localStorage.getItem("lang") === "EN") {
		if (sexId === false) {
			return "F";
		} else if (sexId === true) {
			return "M";
		} else {
			return "Any";
		}
	}
	if (sexId === false) {
		return "Ж";
	} else if (sexId === true) {
		return "М";
	} else {
		return "Не важно";
	}
};
