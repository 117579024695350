import { instanceV2 } from "./instances";
import { QueryFunctionContext } from "react-query";

type TCreateCompanyEmployee = {
	firstName: string;
	lastName: string;
	middleName: string;
	email: string;
	phoneNumber: any;
	[key: string]: any;
};

type TCompanyEmployees = {
	userId: string;
	underChainManagerId: string;
	firstName: string;
	lastName: string;
	middleName: string | null;
	email: string;
	phoneNumber: string;
	userName: string;
	password: string;
};

export const getCompanyEmployees = async (q: QueryFunctionContext<any>) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}
	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}
	const response = await instanceV2.get<TCompanyEmployees[]>(
		`CompanyEmployees${page}${searchInput}`
	);
	return response;
};

export const createCompanyEmployee = async (fields: TCreateCompanyEmployee) => {
	const response = await instanceV2.post(
		"CompanyEmployees",
		JSON.stringify(fields),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

type TEditCompanyEmployee = TCreateCompanyEmployee & {
	underChainManagerId: string;
};

export const editCompanyEmployee = async (fields: TEditCompanyEmployee) => {
	const response = await instanceV2.put(
		"CompanyEmployees",
		JSON.stringify(fields),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

export const companyEmployeeGetinfo = async (q: QueryFunctionContext) => {
	const response = await instanceV2.get<TCompanyEmployees>(
		`CompanyEmployees/getInfo?UnderChainManagerId=${q.queryKey[1]}`
	);
	return response;
};

export const deleteCompanyEmployee = async (id: any) => {
	const response = await instanceV2.delete("CompanyEmployees", {
		data: id,
		headers: {
			"Content-Type": "application/json",
		},
	});
	return response;
};
