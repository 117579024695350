import TableHeadMap from "./TableHeadMap";

interface IProps {
	sort: any;
	setSort: any;
	headings: any;
}

function TableHeadSort({ sort, setSort, headings }: IProps) {
	return (
		<thead>
			<tr>
				<TableHeadMap headings={headings} setSort={setSort} sort={sort} />
			</tr>
		</thead>
	);
}

export default TableHeadSort;
