import React from "react";
import InputFilter from "./InputFilter";

interface IProps {
	setFilters: React.Dispatch<React.SetStateAction<any>>;
	placeholder: string;
	inputsize?: "small";
}

function IconInputFilter({ setFilters, placeholder, inputsize }: IProps) {
	return (
		<div className="icon-input-filter">
			<div className="icon">
				<img src="../../icons/search.svg" alt="" />
			</div>
			<div>
				<InputFilter
					inputsize={inputsize}
					filterField="searchInput"
					setFilters={setFilters}
					placeholder={placeholder}
					type="text"
				/>
			</div>
		</div>
	);
}

export default IconInputFilter;
