import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	deleteContractor,
	getInfoContractorsSupport,
} from "../../../../api/for_support/support_contractors";
import MoreInfoItem from "../../../outlets/OutletInfo/MoreInfoItem";
import DeleteBtn from "../../../utility/Buttons/DeleteBtn";
import CommonBtn from "../../../utility/Buttons/CommonBtn";
import HiddenContractorInfo from "./HiddenContractorInfo";
import EditContractorSupport from "./EditContractorSupport";
import useFeedback from "../../../../hooks/useFeedback";
import ConfirmModal from "../../../utility/confirmModal/ConfirmModal";
interface IProps {
	setModal: any;
	payload: any;
}

function ContractorsSupportInfo({ setModal, payload }: IProps) {
	const { data, isSuccess } = useQuery(
		["infoContractorSupport", payload.id],
		(q) => getInfoContractorsSupport(q)
	);
	const [active, setActive] = useState<"info" | "edit">("info");
	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();

	const { mutate: mutateDelete } = useMutation(deleteContractor, {
		onSuccess: () => {
			openFeedback("Подрядчик успешно удален", "success");
			setTimeout(() => {
				client.invalidateQueries("employeesRequests");
				// closeConfirmation();
			}, 150);
		},
		onError: () => {
			openFeedback("Что-то пошло не так", "error");
		},
	});

	const openDelete = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title="Удаление подрядчика"
					text={"Вы уверены, что хотите удалить этого подрядчика?"}
				>
					<DeleteBtn
						text="Да, удалить"
						onClick={() => {
							client.invalidateQueries("contractors");
							client.invalidateQueries("infoContractorSupport");
							mutateDelete(payload.id);
							closeConfirmation();
							setModal({ opened: false });
						}}
					/>
					<CommonBtn text="Отменить" onClick={closeConfirmation} type="light" />
				</ConfirmModal>
			),
		}));
	};

	// close conformation modal
	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	if (active === "edit")
		return (
			<EditContractorSupport
				payload={payload}
				setActive={setActive}
				setModal={setModal}
			/>
		);
	return (
		<div className="side-modal-container">
			<div className="center-container-3">
				<div className="more-info-container">
					<MoreInfoItem
						name="Подрядчик"
						data={data?.data?.contractorInfo.contractorName}
					/>
					<MoreInfoItem
						name="Имя"
						data={data?.data?.contractorInfo.firstName}
					/>
					<MoreInfoItem
						name="Фамилия"
						data={data?.data?.contractorInfo.lastName}
					/>
					<MoreInfoItem
						name="Отчество"
						data={data?.data?.contractorInfo.middleName}
					/>
					<MoreInfoItem
						name="Телефон"
						data={data?.data?.contractorInfo.phoneNumber}
					/>
					<MoreInfoItem name="Email" data={data?.data?.contractorInfo.email} />
					<MoreInfoItem
						name="Компании"
						data={`${Object.values(
							data?.data?.companies ? data?.data?.companies : {}
						)
							.map((el, index, arr) => {
								if (index != arr.length - 1) {
									return el + ", ";
								} else return el;
							})
							.join("")}`}
					/>

					<HiddenContractorInfo
						userId={data?.data?.contractorInfo?.userId || ""}
						setModal={setModal}
						login={data?.data?.contractorInfo?.userName!}
						password={data?.data?.contractorInfo?.password!}
					/>
				</div>
			</div>
			<div className="bottom-container-3">
				<div className="flex">
					<DeleteBtn
						onClick={openDelete}
						styles={{ width: "43%", height: "19px" }}
					/>
					<CommonBtn
						onClick={() => {
							setActive("edit");
						}}
						text="Изменить"
					/>
				</div>
			</div>
		</div>
	);
}

export default ContractorsSupportInfo;
