import React, { useEffect, useState } from "react";
import { shortenFullname } from "../../../../utils/shortenFullname";
import MoreInfoItem from "../../../outlets/OutletInfo/MoreInfoItem";
import CommonBtn from "../../../utility/Buttons/CommonBtn";
import DeleteBtn from "../../../utility/Buttons/DeleteBtn";
import {
	candidateGetInfo,
	deleteCandidate,
	refreshCandidate,
} from "../../../../api/for_contractors/candidates_contractors";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useFeedback from "../../../../hooks/useFeedback";
import ConfirmModal from "../../../utility/confirmModal/ConfirmModal";
import { sexCheck } from "../../../../utils/sexCheck";
import { sliceString } from "../../../../utils/sliceString";
import Loading from "../../../utility/Loading";
import useLanguage from "../../../../hooks/useLanguage";

interface IProps {
	candidate: {
		workerRequestId: number;
		position: string;
		customObjectId: string;
		objectId: number;
		objectName: string;
		objectResponsibleFullName: string;
		candidateId: number;
		candidateFullName: string;
		hourlyRate: number;
		phoneNumber: string;
		citizenship: string;
		passport: string;
		visitDate: string;
		candidateStatusId: number;
		address: string;
		candidateStatus: string;
		isAvailableToSetStatus: boolean;
		requestDeadline: string;
		contractorId: number;
		contractorName: string;
		isAvailableToDeleteCandidate: boolean;
		isAvailableToEditCandidate: boolean;
		isAvailableToRefreshCandidate: boolean;
		comment: string;
		candidateComment: string;
		companyName: string;
		candidateSex: false | true | null;
	};
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			confirmOpened?: boolean | undefined;
			secondaryOpened?: boolean | undefined;
		}>
	>;
	setSecondaryWhich: React.Dispatch<
		React.SetStateAction<"edit" | "create" | "candidate">
	>;
	setActive: React.Dispatch<
		React.SetStateAction<"edit" | "candidates" | "request">
	>;
}

function WorkerReqContractorCandidate({
	candidate,
	setModal,
	setSecondaryWhich,
	setActive,
}: IProps) {
	const { L } = useLanguage();

	const [actionStatuses, setActionStatuses] = useState<any>({
		isAvailableToDeleteCandidate: false,
		isAvailableToRefreshCandidate: false,
		isAvailableToEditCandidate: false,
	});
	const { openFeedback, closeFeedback } = useFeedback();
	const client = useQueryClient();

	const {
		data,
		isSuccess: isData,
		isLoading,
	} = useQuery(
		["candidatesGetInfo", candidate.candidateId],
		(q) => candidateGetInfo(q),
		{
			refetchInterval: 5000,
			refetchOnWindowFocus: true,
		}
	);

	// getting some data from candidates query
	// I DONT USE ANY OF THIS IM GOING CRAZY LET IT BE HERE FOR THE TIME BEING

	// const dataQuery = client.getQueryData<any>("candidates", {
	// 	exact: false,
	// });
	// const getCandidateData = () => {
	// 	const dataineed = dataQuery?.data?.candidates?.filter((candidate: any) => {
	// 		return candidate.candidateId == candidate.candidateId;
	// 	})[0];
	// 	return dataineed;
	// };

	const title = (
		<div
			style={{
				marginTop: "-6px",
				marginBottom: "-3px",
				fontSize: "14px",
				color: "#474f5a",
			}}
			className="main-modal-head"
		>
			<h2>
				{L("Кандидат", "Candidate")}{" "}
				{shortenFullname(candidate.candidateFullName)}
			</h2>
		</div>
	);

	const moreInfo = (
		<div className="more-info-container">
			<div style={{ marginTop: "16px" }} className="divider-sm"></div>

			<MoreInfoItem name={L("Ставка", "Rate")} data={data?.data?.hourlyRate} />
			<MoreInfoItem
				name={L("Ставка с НДС", "Rate with tax")}
				data={data?.data?.hourlyRateVAT}
			/>

			<MoreInfoItem
				name={L("Кандидат", "Candidate")}
				data={`${data?.data?.lastName} ${data?.data.firstName} ${
					data?.data.middleName || ""
				}`}
			/>
			<MoreInfoItem
				name={L("Пол", "Sex")}
				data={data?.data?.candidateSexString}
			/>
			<MoreInfoItem
				name={L("Дата рождения", "Birth date")}
				data={sliceString(data?.data.birthday)}
			/>
			{/* <MoreInfoItem name="Компания" data={candidate.companyName} /> */}

			{/* <MoreInfoItem name="Филиал" data={data?.data?.objectName} /> */}
			{/* <MoreInfoItem name="Регион" data={candidate.region} /> */}
			{/* <MoreInfoItem name="Город" data={candidate.city} /> */}
			<MoreInfoItem
				name={L("Телефон", "Phone")}
				data={data?.data?.phoneNumber}
			/>
			<MoreInfoItem
				name={L("Гражданство", "Citizenship")}
				data={data?.data?.citizenship}
			/>

			<MoreInfoItem
				name={L("Паспорт", "Passport")}
				data={data?.data?.passport}
			/>
			<MoreInfoItem
				name={L("Должность", "Job title")}
				data={data?.data?.position}
			/>
			<MoreInfoItem
				name={L("Дата визита", "Visit date")}
				data={data?.data?.visitDate ? data?.data?.visitDate.slice(0, 10) : ""}
			/>
			{/* <MoreInfoItem name="Адрес" data={candidate.address} /> */}

			<MoreInfoItem
				name={L("Комментарий компании", "Companies comment")}
				data={candidate.candidateComment}
			/>
		</div>
	);

	const notDeletableRefreshable =
		candidate.isAvailableToDeleteCandidate == false &&
		candidate.isAvailableToRefreshCandidate === false;

	// delete
	const { mutate: mutateDelete } = useMutation(deleteCandidate, {
		onSuccess: () => {
			openFeedback(
				L("Кандидат успешно снят", "Candidate has been removed"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("candidates");
				client.invalidateQueries("employeesRequestsContractors");
			}, 200);
			setModal((prev) => ({
				...prev,
				secondaryOpened: false,
			}));
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	// refresh
	const { mutate: mutateRefresh } = useMutation(refreshCandidate, {
		onSuccess: () => {
			openFeedback(
				L("Кандидат успешно восстановлен", "Candidate has been restored"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("candidates");
				client.invalidateQueries("employeesRequestsContractors");
			}, 200);
			setModal((prev) => ({
				...prev,
				secondaryOpened: false,
			}));
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	// close conformation modal
	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	// Open Conformations
	const openDelete = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Снять кандидата", "Remove candidate")}
					text={L(
						"Вы уверены, что хотите снять кандидата?",
						"Are you sure you want to remove this candidate?"
					)}
				>
					<DeleteBtn
						text={L("Да, снять", "Yes, remove")}
						onClick={() => {
							mutateDelete(candidate.candidateId);
							closeConfirmation();
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	const openRefresh = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Восстановить кандидата", "Restore candidate")}
					text={L(
						"Вы уверены, что хотите восстановить кандидата?",
						"Are you sure you want to restore this candidate?"
					)}
				>
					<CommonBtn
						text={L("Да, восстановить", "Yes, restore")}
						onClick={() => {
							mutateRefresh(candidate.candidateId);
							closeConfirmation();
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	// open secondary modal for edit
	const openSecondary = (candidateData: any) => {
		// set active to "edit"
		setSecondaryWhich("edit");
		setModal((prev: any) => ({
			...prev,
			secondaryOpened: true,
			payload: { ...prev.payload, data: candidateData },
		}));
	};

	if (isLoading) {
		return (
			<div style={{ width: "450px" }}>
				<Loading />;
			</div>
		);
	}

	return (
		<div className="side-modal-container" style={{ width: "450px" }}>
			<div className="center-container-2">
				{title}
				<p className="text-gray">
					{L("Статус:", "Status:")} {candidate.candidateStatus}
				</p>
				{moreInfo}
			</div>
			<div className="bottom-container-2">
				<div className="flex">
					{/* delete */}
					{candidate.isAvailableToDeleteCandidate === true && (
						<DeleteBtn
							onClick={openDelete}
							text={L("Снять кандидата", "Remove candidate")}
							styles={
								candidate.isAvailableToEditCandidate
									? { width: "43%" }
									: { width: "100%" }
							}
						/>
					)}
					{/* refresh */}
					{candidate.isAvailableToRefreshCandidate && (
						<CommonBtn
							onClick={openRefresh}
							styles={
								!candidate.isAvailableToEditCandidate && {
									width: "100%",
								}
							}
							text={L("Восстановить кандидата", "Restore candidate")}
							type="light"
						/>
					)}
					{/* edit */}
					{candidate.isAvailableToEditCandidate && (
						<CommonBtn
							onClick={() => {
								setModal((prev) => ({
									...prev,
									payload: {
										...prev.payload,
										candidateId: candidate.candidateId,
									},
								}));
								setActive("request");
								setSecondaryWhich("edit");
							}}
							styles={notDeletableRefreshable ? { width: "100%" } : {}}
							text={L("Изменить", "Edit")}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default WorkerReqContractorCandidate;
