import axios from "axios";
import { instanceV2 } from "./instances";
import { QueryFunctionContext } from "react-query";

interface IContractors {
	contractorsFilterValues: {
		contractors: any;
	};
	contractorsKpiAll: {
		avgDaysToCloseRequests: number;
		candidateAnotherFitted: number;
		candidateHired: number;
		candidateInterview: number;
		candidateNotFitted: number;
		candidateNotShowedUp: number;
		candidateRequestCancellation: number;
		companyId: number;
		companyName: string;
		contractorId: number;
		contractorName: string;
		isActive: boolean;
		numOfAcceptedRequests: number;
		numOfCandidates: number;
		numOfClosedRequests: number;
		numOfWorkers: number;
	}[];
}

export const getContractors = async (q: QueryFunctionContext<any>) => {
	// contractors, not contRactors, yes.
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}

	const response = instanceV2.get<IContractors>(
		`/Contractors${page}${searchInput}${column}${order}`
	);
	return response;
};

export const dlContractors = async (page: number, filters: any) => {
	let pageNumber = `?pageNumber=${page}`;
	let searchInput = "";
	if (filters.searchInput) {
		searchInput = `&SearchInput=${filters.searchInput}`;
	}

	await instanceV2
		.get(`Contractors${pageNumber}${searchInput}&download=true`, {
			responseType: "blob",
			headers: {
				"Content-Type":
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			},
		})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "file.xlsx");
			document.body.appendChild(link);
			link.click();
		});
};
