import { Link } from "react-router-dom";
import { createArrFromNums } from "../../utils/createArrFromNums";
import TimeSheet from "./TimeSheet";
import ModalMain from "../utility/ModalMain/ModalMain";
import { useState } from "react";
import useLanguage from "../../hooks/useLanguage";
interface IProps {
	workerData: any;
	year: any;
	month: any;
}

function TimeTableBody({ workerData, year, month }: IProps) {
	const { L } = useLanguage();

	const isRoleContractor = !!(localStorage.getItem("role") === "Contractor");
	const roleNotDirector = localStorage.getItem("role") != "Director";

	const monthsDates = createArrFromNums(31);
	// modal
	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		secondaryOpened?: boolean;
	}>({
		opened: false,
		payload: null,
		secondaryOpened: false,
	});
	// console.log(workerData);
	return (
		<>
			<ModalMain
				setModal={setModalInfo}
				opened={modalInfo.opened}
				title="Информация о сотруднике"
				secondary={() => (
					<TimeSheet
						outsideYear={year}
						outsideMonth={month}
						setModal={setModalInfo}
						workerId={modalInfo.payload}
						workerNameOthers={workerData.workerFullname}
					/>
				)}
				secondaryOpened={modalInfo.secondaryOpened || false}
				shouldNotOpenMain
			></ModalMain>

			<div className="time-table-body-outer-container">
				{/* {!isRoleContractor && (
				<div className="num">
					<p>{workerData.workerId}</p>
				</div>
			)} */}
				<div className="info">
					<div className="info-part">
						<p>{workerData?.workerStatusNameForTable}:</p>
						<p className="gray">{workerData.workerFullname}</p>
					</div>
					<div className="info-part">
						<p>{L("Должность:", "Job title:")}</p>
						<p className="gray">{workerData.position}</p>
					</div>
					{localStorage.getItem("role") === "Contractor" && (
						<div className="info-part">
							<p>{L("Компания:", "Company:")}</p>
							<p className="gray">
								{workerData.companyName && workerData.companyName}
							</p>
						</div>
					)}
					{roleNotDirector && (
						<div className="info-part">
							<p>{L("ID филиала:", "Outlet ID:")}</p>
							<p className="gray">
								{workerData.customObjectId && workerData.customObjectId}
							</p>
						</div>
					)}
					{roleNotDirector && (
						<div className="info-part">
							<p>{L("Филиал:", "Outlet:")}</p>
							<p className="gray">
								{workerData.objectName && workerData.objectName}
							</p>
						</div>
					)}
					{!(localStorage.getItem("role") === "Contractor") && (
						<div className="info-part">
							<p>{L("Подрядчик:", "Contractor:")}</p>
							<p className="gray">
								{workerData.contractorName && workerData.contractorName}
							</p>
						</div>
					)}

					<div className="info-part">
						<p>{L("Всего часов:", "Total hours:")}</p>
						<p className="gray">
							{workerData.totalWorkedHours && workerData.totalWorkedHours}
						</p>
					</div>
				</div>

				<div className="h-table-container">
					<div className="presence-container">
						{monthsDates.map((date) => {
							let a = workerData.days.filter(
								(day: any) => day.dayWorkDate == date
							);
							// console.log(a);
							if (a[0]) {
								return (
									<p
										className={`h-table-date ${
											a[0].workDayStatus === L("Явка", "Turnout") ? "green" : ""
										} ${
											a[0].workDayStatus === L("Неявка", "No-show") ? "red" : ""
										}`}
									>
										{a[0].workDayStatus.slice(0, 1)}
									</p>
								);
							} else {
								return <p className="h-table-date"></p>;
							}
						})}
					</div>
					{/* ["workDayStatus"].slice(0, 1) */}
					<div className="h-table-dates">
						{monthsDates.map((date) => {
							let a = workerData.days.filter(
								(day: any) => day.dayWorkDate == date
							);
							// console.log(a);
							if (a[0]) {
								return <p className="h-table-date">{a[0].workedHours}</p>;
							} else {
								return <p className="h-table-date"></p>;
							}
						})}
					</div>
				</div>
				<div className="statistics-h">
					{/* <p>Явился: {workerData.daysShowedUp && workerData.daysShowedUp}</p>
				<p>Больничных: {workerData.daysSick && workerData.daysSick}</p>
				<p>Не явился: {workerData.daysShowedUp && workerData.daysShowedUp} </p> */}
					<div
						// full width if role is contractor
						style={isRoleContractor ? { width: "100%" } : {}}
						className="statistics-info"
					>
						<div className="info-part">
							<p className="green">{L("Я", "T")}</p>
							<p>{workerData.daysShowedUp ? workerData.daysShowedUp : "-"}</p>
						</div>
						<div className="info-part">
							<p>{L("Б", "D")}</p>
							<p>{workerData.daysSick ? workerData.daysSick : "-"}</p>
						</div>
						<div className="info-part">
							<p className="red">{L("Н", "N")}</p>
							<p>
								{workerData.daysNotShowedUp ? workerData.daysNotShowedUp : "-"}
							</p>
						</div>
					</div>
					{/*  no edit if contractor */}
					{!isRoleContractor && (
						<div
							className="edit"
							onClick={() => {
								setModalInfo({
									opened: true,
									payload: workerData,//.workerId,
									secondaryOpened: true,
								});
							}}
						>
							<img src="../../icons/edit.svg" alt="" />
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default TimeTableBody;
