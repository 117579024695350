interface IProps {
	type?: "inside";
}

function Loading({ type }: IProps) {
	if (type === "inside") {
		return (
			<div className="loading-inside">
				<img
					className="loader"
					src="../../icons/loader.svg"
					height="80px"
					alt="loading"
				/>
			</div>
		);
	}

	return (
		<>
			<div className="container-fluid min-vh-100 d-flex justify-content-center align-items-center loading">
				<div className="row">
					<div className="col mb-5">
						<img
							className="loader"
							src="../../icons/loader.svg"
							height="80px"
							alt="loading"
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default Loading;
