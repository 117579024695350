import React from "react";
import TimeTableBody from "../../timesheet/TimeTableBody";
import TimeTableHead from "../../timesheet/TimeTableHead";
import { useQuery } from "react-query";
import { getWorkTimeSheetContractors } from "../../../api/for_contractors/employees_contractors";
import { useState } from "react";
import TimeTableFilters from "./TimeTableFiltersContractors";
import TablePagination from "../../utility/TablePagination";
import IconInputFilter from "../../utility/IconInputFilter";
import Loading from "../../../components/utility/Loading";
import dayjs from "dayjs";
import ModalMain from "../../utility/ModalMain/ModalMain";
import MoreInfoItem from "../../outlets/OutletInfo/MoreInfoItem";
import { reverseFormatDate } from "../../../utils/formatDate";
import {
	getMonthFromNumber,
	getMonthFromNumberEn,
	monthKeyType,
} from "../../../utils/datesData";
import GeneralInfo from "../../../employees/EmployeesPage/modals/GeneralInfo";
import useLanguage from "../../../hooks/useLanguage";
// only need to do this once.
const minTwoDigits = (n: number) => {
	return (n < 10 ? "0" : "") + n.toString();
};
const currentYearTwoDigits = minTwoDigits(dayjs().year()).toString();
const currentMonthTwoDigits = minTwoDigits(dayjs().month() + 1).toString();

function TimeTableContractors() {
	const { L } = useLanguage();

	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		index: "",
		objectId: "",
		region: "",
		city: "",
		contractorId: "",
		job: "",
		// free
		ObjectResponsibleFullName: "",
		WorkerFullname: "",
		CustomObjectId: "",
		workerStatusId: "",
		year: currentYearTwoDigits,
		month: currentMonthTwoDigits,
		searchInput: "",
		company: "",
	});

	// modals
	const [modalFilters, setModalFilters] = useState<{
		opened: boolean;
	}>({
		opened: false,
	});

	const [generalInfoModal, setGeneralInfoModal] = useState({
		opened: false,
	});

	const { data, isSuccess } = useQuery(
		["timetable", filters, { pageNumber: page }],
		(q) => getWorkTimeSheetContractors(q)
	);
	const workersData = data?.data?.workers;

	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};
	return (
		<>
			<div
				style={{
					maxWidth: "98.5%",
					margin: "auto",
					marginTop: "10px",
					marginBottom: "10px",
				}}
				className="upper-bar"
			>
				<>
					<div className="upper-bar-first">
						<IconInputFilter
							setFilters={setFilters}
							placeholder={L("Искать сотрудника", "Search employee")}
						/>
						<div
							onClick={() => setModalFilters({ opened: true })}
							className="filter-btn"
						>
							<img
								src="../../icons/setting-5.svg"
								alt=""
								width="20px"
								height="20px"
							/>
							<p>{L("Фильтры", "Filters")}</p>
						</div>
					</div>
					<div className="upper-bar-second">
						<div
							onClick={() => setGeneralInfoModal({ opened: true })}
							className=""
						>
							<p style={{ color: "#5e73e1", cursor: "pointer" }}>
								{L("Cводная информация", "Summary")}
							</p>
						</div>
					</div>
				</>
			</div>
			<ModalMain
				opened={modalFilters.opened}
				setModal={setModalFilters}
				title={L("Фильтры", "Filters")}
			>
				<TimeTableFilters
					year={filters.year}
					month={filters.month}
					setFilters={setFilters}
					filters={filters}
				/>
			</ModalMain>
			{/* general info */}
			<ModalMain
				setModal={setGeneralInfoModal}
				opened={generalInfoModal.opened}
				title={L("Сводная информация", "Summary")}
			>
				<GeneralInfo
					data={data}
					datestart={`${L(
						getMonthFromNumber(filters.month as monthKeyType),
						getMonthFromNumberEn(filters.month as monthKeyType)
					)},  ${filters.year + L("г.", "y")}.`}
					dateend={"sfaasf"}
					noDateSplit={true}
				/>
			</ModalMain>
			{isSuccess ? (
			<>
			{isSuccess && (
				<>
					<TimeTableHead />
					{workersData &&
						workersData?.map((workerData: any) => (
							<TimeTableBody
								year={filters.year}
								month={filters.month}
								workerData={workerData.worker}
							/>
						))}
					<div className="mt-4 ms-4">
						<TablePagination
							page={page}
							setPage={setPage}
							paginationInfo={getPaginationInfo(data)}
						/>
					</div>
				</>
			)}
			</>
			) : (

				<Loading />
			)}
		</>
	);
}

export default TimeTableContractors;
