import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getRespEmployeeInfo } from "../../../api/objects";
import ConfirmModal from "../../utility/confirmModal/ConfirmModal";
import useFeedback from "../../../hooks/useFeedback";
import { changeCreds } from "../../../api/auth";
import DeleteBtn from "../../utility/Buttons/DeleteBtn";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import useLanguage from "../../../hooks/useLanguage";

interface IProps {
	objectId: string | number | undefined;
	setModal: any;
	userId: any;
}
function ResponsibleInfo({ objectId, setModal, userId }: IProps) {
	const { L } = useLanguage();

	const [hidden, setHidden] = useState(true);
	const switchHidden = () => {
		setHidden((prev) => !prev);
	};
	const { data, isSuccess } = useQuery(["respInfo", objectId], (q) =>
		getRespEmployeeInfo(q)
	);
	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();
	// close conformation modal
	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	const { mutate: mutateChangeCreds } = useMutation(changeCreds, {
		onSuccess: () => {
			openFeedback(
				L("Пароль успешно изменен", "Password has been changed"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("respInfo");
				closeConfirmation();
			}, 150);
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	const openChangeCreds = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Изменение пароля", "Change the password")}
					text={L(
						"Вы уверены, что хотите сгенерировать новый пароль для директора?",
						"Are you sure you want to generate a new password for this director?"
					)}
				>
					<DeleteBtn
						text={L("Да, изменить", "Yes, change")}
						onClick={() => {
							mutateChangeCreds(userId);
							closeConfirmation();
							setTimeout(() => {
								client.invalidateQueries("outlets");
								client.invalidateQueries("respInfo");
							}, 150);
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	const closeModal = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
			opened: false,
			payload: null,
		}));
	};
	console.log(data);
	return (
		<div className="hidden-info">
			<h3
				style={{
					fontSize: "16px",
					color: "#474F5A",
					fontWeight: "400",
				}}
			>
				{L("Данные об аккаунте директора", "Director's account info")}
			</h3>
			<div className="items">
				<p className="gray">{L("Логин:", "Login:")}</p>
				<p className={hidden ? "blurred" : ""}>{data?.data?.userName || "-"}</p>
			</div>
			<div className="items">
				<p className="gray">{L("Пароль:", "Password")}</p>
				<p className={hidden ? "blurred" : ""}>{data?.data?.password || "-"}</p>
			</div>
			<p className="show-btn">
				<img onClick={switchHidden} src="../../icons/eye.svg" alt="" />
				<p onClick={switchHidden}>
					{hidden ? L("Показать", "Show") : L("Скрыть", "Hide")}
				</p>
			</p>
			<p onClick={openChangeCreds} className="generate-creds">
				{L("Сгенерировать новый пароль", "Generate new password")}
			</p>
		</div>
	);
}

export default ResponsibleInfo;
