import React, { useState } from "react";
import { useQuery } from "react-query";
import { getCompanies } from "../../../api/for_contractors/companies_contractors";
import TableTotalCount from "../../utility/TableTotalCount";
import Loading from "../../utility/Loading";
import SortArrow from "../../utility/SortArrow";
import TableHeadSort from "../../utility/Table/headings/TableHeadSort";
import { dlCompanies } from "../../../api/for_contractors/companies_contractors";
import TablePagination from "../../utility/TablePagination";
import useLanguage from "../../../hooks/useLanguage";

function Companies() {
	const { L } = useLanguage();

	const [page, setPage] = useState(1);
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc

	// query
	const { data, isSuccess } = useQuery(
		["companies", { pageNumber: page }, sort],
		(q) => getCompanies(q)
	);

	// headings
	let headings = [
		{ name: L("Компания", "Company"), colname: "companyName" },
		{
			name: L("Активные заявки", "Active requests"),
			colname: "numOfCompanyActiveRequests",
		},
		{
			name: L("Всего заявок", "Total requests"),
			colname: "numOfCompanyTotalRequests",
		},
		{
			name: L("Заявки с откликами", "Requests accepted"),
			colname: "numOfAcceptedRequests",
		},
		{
			name: L("Сейчас работает", "Currently working"),
			colname: "numOfWorkers",
		},
		{
			name: L("Уволено за месяц", "Fired this month"),
			colname: "numOfFiredEmployeeCurrentMonth",
		},
		{
			name: L("Принятые кандидаты", "Candidates hired"),
			colname: "candidateHired",
		},
		{
			name: L("Кандидат не подошел", "Candidates not fitted"),
			colname: "candidateNotFitted",
		},
		{
			name: L("Подошел другой кандидат", "Another candidate hired"),
			colname: "candidateAnotherFitted",
		},
		{ name: L("Не явился", "Not showed up"), colname: "candidateNotShowedUp" },
		{
			name: L("Всего кандидатов", "Total candidates"),
			colname: "numOfCandidates",
		},
	];

	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};

	console.log(data?.data);

	return (
		<div className="container-fluid">
			<div className="upper-bar">
				<div className="upper-bar-first">
					{
						<TableTotalCount
							name={L("Компаний", "Companies")}
							headers={data?.headers["x-pagination"]}
						/>
					}
				</div>
				<div className="upper-bar-second">
					<img
						src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
						width="35px"
						style={{ marginRight: "30px", cursor: "pointer" }}
						alt=""
						onClick={() => dlCompanies(["", page, sort])}
					/>
				</div>
			</div>
			{isSuccess ? (
				<>
					<div className="table-container">
						<table cellSpacing={0} className="my-table">
							<TableHeadSort
								headings={headings}
								setSort={setSort}
								sort={sort}
							/>
							<tbody>
								{data.data?.map((company) => {
									return (
										<tr>
											<td className="size-item">{company.companyName}</td>
											<td className="size-item">
												{company.numOfCompanyActiveRequests}
											</td>
											<td className="size-item">
												{company.numOfCompanyTotalRequests}
											</td>
											<td className="size-item">
												{company.numOfAcceptedRequests}
											</td>
											<td className="size-item">{company.numOfWorkers}</td>
											<td className="size-item">
												{company.numOfFiredEmployeeCurrentMonth}
											</td>
											<td className="size-item">{company.candidateHired}</td>
											<td className="size-item">
												{company.candidateNotFitted}
											</td>
											<td className="size-item">
												{company.candidateAnotherFitted}
											</td>
											<td className="size-item">
												{company.candidateNotShowedUp}
											</td>
											<td className="size-item">{company.numOfCandidates}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default Companies;
