import { Link, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { TNotifications, getNotifications } from "../../../api/notifications";
import NavElement from "./NavElement";
import { useContext, useEffect } from "react";
import NotificationContext from "../../../context/NotificationsContext";
import useFeedback from "../../../hooks/useFeedback";
import useLanguage from "../../../hooks/useLanguage";

const isRoleContractor = !!(localStorage.getItem("role") === "Contractor");

function Nav() {
	const location = useLocation();
	const path = location.pathname.slice(1);
	const notificationContext = useContext(NotificationContext);
	const { openFeedback, closeFeedback } = useFeedback();
	const { L } = useLanguage();
	const userName = localStorage.getItem("userName");
	const role = localStorage.getItem("role");
	let roleCheck =
		localStorage.getItem("role") == "Director" ||
		localStorage.getItem("role") == "Support" ||
		localStorage.getItem("role") === "Contractor";
	const roleIsSupport = localStorage.getItem("role") == "Support";
	const navigate = useNavigate();
	const logout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("role");
		navigate("/login");
	};

	// get notifications
	const { data: notifications, isSuccess } = useQuery(
		["notificationsAll", path],
		getNotifications,
		{
			refetchOnMount: true,
			refetchInterval: 30000,
		}
	);
	// i don't know why but onSuccess doesnt run on first query. Me stupid.
	useEffect(() => {
		if (notifications != undefined) {
			notificationContext.setNotifications(notifications.data);
		}
	}, [notifications]);

	useEffect(() => {
		if (notifications && notifications?.data?.notifications[0]?.content) {
			openFeedback(
				notifications?.data?.notifications[0]?.content,
				"info",
				true,
				notifications?.data?.notifications[0]?.title
			);
		}
		if (notifications && !notifications?.data?.notifications[0]?.content) {
			closeFeedback();
		}
	}, [notifications, isSuccess, location]);

	const showContractors =
		localStorage.getItem("role") == "Support" ||
		localStorage.getItem("role") === "Contractor";

	const roleIsContractor = localStorage.getItem("role") === "Contractor";
	const roleNotUnder = localStorage.getItem("role") !== "UnderChainManager";

	if (localStorage.getItem("role") === "Support") {
		return (
			<>
				<nav className="navbar navbar-expand bg-gray-300 p-0">
					<div className="nav-container">
						<ul className="navbar-nav">
							{roleIsSupport && (
								<li className="navbar-item">
									<NavLink to="/rates" className="nav-link">
										{L("Ставки", "Rates")}
									</NavLink>
								</li>
							)}
							<li className="navbar-item">
								<NavLink to="/requests" className="nav-link">
									{L("Заявки на поиск", "Requests")}
								</NavLink>
							</li>
							<NavElement
								link="/contractors"
								name={L("Подрядчики", "Contractors")}
								notifications={notifications}
								isSuccess={isSuccess}
							/>
						</ul>
						<div className="user-card">
						<div>
							<div>
								{userName}
							</div>
							<div>
								<b>Поддержка</b>
							</div>
						</div>
						<div>
							<div onClick={logout} className="common-btn common exit-btn">
								{L("Выйти", "Logout")}
							</div>
						</div>
					</div>
					</div>
				</nav>
			</>
		);
	}
	// NAV FOR CONTRACTORS
	if (roleIsContractor) {
		return (
			<>
				<nav className="navbar navbar-expand bg-gray-300 p-0">
					<div className="nav-container">
						<ul className="navbar-nav">
							<NavElement
								link="requests"
								name={L("Заявки на поиск", "Requests")}
								notifications={notifications}
								isSuccess={isSuccess}
							/>

							<NavElement
								link="candidates"
								name={L("Кандидаты", "Candidates")}
								notifications={notifications}
								isSuccess={isSuccess}
							/>

							{!roleIsSupport && (
								<NavElement
									link="employees"
									name={L("Сотрудники", "Employees")}
									notifications={notifications}
									isSuccess={isSuccess}
								/>
							)}

							<NavElement
								link="timetable"
								name={L("Табель", "Timetable")}
								notifications={notifications}
								isSuccess={isSuccess}
							/>

							<NavElement
								link="companies"
								name={L("Компании", "Companies")}
								notifications={notifications}
								isSuccess={isSuccess}
							/>
						</ul>
						<div className="user-card">
						<div>
							<div>
								{userName}
							</div>
							<div>
								<b>Подрядчик</b>
							</div>
						</div>
						<div>
							<div onClick={logout} className="common-btn common exit-btn">
								{L("Выйти", "Logout")}
							</div>
						</div>
					</div>
					</div>
				</nav>
			</>
		);
	}

	// NAV FOR DIRECTOR AND CHAIN MANAGER

	return (
		<>
			<nav className="navbar navbar-expand bg-gray-300 p-0">
				<div className="nav-container">
					<ul className="navbar-nav">
						{!roleCheck && (
							<li className="navbar-item">
								<NavLink to="/outlets" className="nav-link me-2">
									{L("Филиалы", "Outlets")}
								</NavLink>
							</li>
						)}

						{!roleIsSupport && (
							<NavElement
								link="employees"
								name={L("Сотрудники", "Employees")}
								notifications={notifications}
								isSuccess={isSuccess}
							/>
						)}

						<NavElement
							link="timetable"
							name={L("Табель", "Timetable")}
							notifications={notifications}
							isSuccess={isSuccess}
						/>

						<NavElement
							link="requests"
							name={L("Заявки на поиск", "Requests")}
							notifications={notifications}
							isSuccess={isSuccess}
						/>
						{roleIsContractor && (
							// <li className="navbar-item">
							// 	<NavLink to="/candidates" className="nav-link me-2">
							// 		Кандидаты
							// 	</NavLink>
							// </li>
							<NavElement
								link="candidates"
								name={L("Кандидаты", "Candidates")}
								notifications={notifications}
								isSuccess={isSuccess}
							/>
						)}
						{!roleCheck && roleNotUnder && (
							<li className="navbar-item">
								<NavLink to="/regional-managers" className="nav-link me-2">
									{L("Менеджеры", "Managers")}
								</NavLink>
							</li>
						)}
						{!showContractors && (
							<li className="navbar-item">
								<NavLink to="/contractors" className="nav-link">
									{L("Подрядчики", "Contractors")}
								</NavLink>
							</li>
						)}

						{roleIsSupport && (
							<NavElement
								link="rates"
								name={L("Ставки", "Rates")}
								notifications={notifications}
								isSuccess={isSuccess}
							/>
						)}

					</ul>
					<div className="user-card">
						<div>
							<div>
								{userName}
							</div>
							<div>
								<b>{role == 'Director' ? 'Заведующий' : (role == 'ChainManager' ? 'Руководитель': 'Менеджер')}</b>
							</div>
						</div>
						<div>
							<div onClick={logout} className="common-btn common exit-btn">
								<img src="/icons/exit.svg" width="30" />
							</div>
						</div>
					</div>

				</div>
			</nav>
		</>
	);
}

export default Nav;
