import React, { useState, useRef, useEffect } from "react";
import { createArrayOptions } from "../../utils/createArrayOptionsFromObj";
import { isBoolean } from "lodash";

// this component takes in this kind of data:
const regions = {
	0: "reg1",
	1: "moscow",
};

interface ICustomSelectProps {
	data: any;
	setState: any;
	stateName: string;
	initial?: string;
	label?: string;
	simpleState?: boolean; // if true, works with non-object states and stateName can be any string, doesn't matter.
	styles?: any;
	position?: "inverse";
	arrowPosition?: "far-right";
	stylesInput?: any;
	shouldResetInitialDependancies?: any[];
	// props for validation
	requiredState?: boolean;
	errorMessage?: string;
	placeholder?: string;
}

function SelectCustom(props: ICustomSelectProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [valueChosen, setValueChosen] = useState<null | string>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const switchOpen = () => setIsOpen((prev) => !prev);

	// reset initial if shouldResetInitialDependancies is not undefined
	const dependancyArr = () => {
		if (props.shouldResetInitialDependancies !== undefined) {
			return props.shouldResetInitialDependancies;
		} else {
			return [];
		}
	};
	useEffect(() => {
		if (props.shouldResetInitialDependancies !== undefined) {
			setValueChosen(null);
		}
	}, dependancyArr());

	// when clicked on dropdown item
	const chooseOption = (element: { id: any; value: any }) => {
		if (props.simpleState) {
			props.setState(element.id);
		} else {
			props.setState((prev: any) => ({
				...prev,
				[`${props.stateName}`]: element.id,
			}));
		}

		setValueChosen(element.value);
		setIsOpen(false);
	};

	// very stupid code to make some text colored
	const textShouldBeColored = (value: any) => {
		if (value === "Больничный") return "gray";
		if (value === "Явка") return "green";
		if (value === "Неявка") return "red";
		if (value === "Disease") return "gray";
		if (value === "Turnout") return "green";
		if (value === "No-show") return "red";
	};

	// creating dropdown items to render
	const dataArray = createArrayOptions(props.data);
	const dropdownItems = dataArray.map((element) => {
		return (
			<div
				key={element.id}
				onClick={() => chooseOption(element)}
				className={`dropdown-item ${textShouldBeColored(element?.value)}`}
			>
				<div>{(element.value as string).toString()}</div>
			</div>
		);
	});

	// close if clicked outside
	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleOutsideClick);

		// clear event listener
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, [dropdownItems]);

	// update initial state and reset valueChosen
	useEffect(() => {
		setValueChosen(null);
	}, [props.initial]);

	// check if validation is needed
	const needValidation =
		isBoolean(props.requiredState) && props.requiredState === true;

	// little arrow position
	const arrowPosition = () => {
		if (props.arrowPosition === "far-right") {
			return { right: "3%" };
		}
		return {};
	};

	const isPlaceholder = () => {
		if (props.placeholder) {
			if (!valueChosen && !props.initial) {
				return "is-placeholder";
			}
		} else {
			return "";
		}
	};

	return (
		<div style={props.styles || null} className="select-custom">
			{props.label && (
				<label className="filter-autocomplete-label">{props.label}</label>
			)}

			<div ref={containerRef} className="select-custom-container">
				<p
					onClick={switchOpen}
					className={`select-chosen ${textShouldBeColored(valueChosen)}
					${textShouldBeColored(props.initial)} ${isPlaceholder()} ${
						needValidation && "error"
					}`}
					style={props.stylesInput || {}}
				>
					{valueChosen || props.initial || props.placeholder}
					{/* tiny arrow icon */}
					<div style={arrowPosition()} className="arrow-down">
						<img src="../../icons/input-arrow-down.svg" alt="" />
					</div>
				</p>
				<div
					className={
						isOpen ? `dropdown-menu ${props.position}` : `dropdown-menu hidden`
					}
				>
					{dropdownItems}
				</div>
			</div>
			{needValidation && (
				<p style={{ marginTop: "4px" }} className="input-validation-text">
					{props.errorMessage || "Поле должно быть заполнено"}
				</p>
			)}
		</div>
	);
}

export default SelectCustom;
