import React, { useEffect, useState } from "react";
import MoreInfoItem from "../../components/outlets/OutletInfo/MoreInfoItem";
import CommonBtn from "../../components/utility/Buttons/CommonBtn";
import InputControlled from "../../components/utility/InputControlled";
import SelectCustom from "../../components/utility/SelectCustom";
import { setCandidateStatus } from "../../api/candidates";
import { useMutation, useQueryClient } from "react-query";
import { shortenFullname } from "../../utils/shortenFullname";
import useFeedback from "../../hooks/useFeedback";
import { sliceString } from "../../utils/sliceString";
import useLanguage from "../../hooks/useLanguage";
interface IProps {
	payload: any;
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			confirmOpened?: boolean | undefined;
			secondaryOpened?: boolean | undefined;
		}>
	>;
}
const statuses = {
	"20": "Кандидат не пришел на собеседование",
	"30": "Кандидат не подошел",
	"40": "Кандидат принят на стажировку",
	"50": "Кандидат принят на работу",
};

const statusesEN = {
	"20": "Candidate didn't show up for the interview",
	"30": "Candidate wasn't fit for the position",
	"40": "The candidate has been accepted for an internship",
	"50": "Candidate was hired",
};

function EmployeeRequestsCandidate({ payload, setModal }: IProps) {
	const { L } = useLanguage();

	const { openFeedback, closeFeedback } = useFeedback();
	const [status, setStatus] = useState<any>({
		status: null,
		comment: null,
	});
	const [commentReq, setCommentReq] = useState(false);
	const queryClient = useQueryClient();
	const { mutate, isSuccess } = useMutation(setCandidateStatus, {
		onSuccess: () => {
			openFeedback(
				L("Статус успешно изменен", "Status has been changed successfully"),
				"success"
			);
		},
	}); // for changing status

	// A crotch. Used to update the text in Select to initial when cancel btn was pushed
	const [wasCanceledTimes, setWasCanceledTimes] = useState<number>(0);
	useEffect(() => {
		setStatus({
			status: payload.data.candidateStatusId,
			comment: null,
		});
	}, [payload.data.candidateStatusId, payload.data]);

	// close secondary
	const close = () => {
		setModal((prev) => ({
			...prev,
			secondaryOpened: false,
		}));
	};

	// close all modals
	const closeAll = () => {
		setModal((prev) => ({
			secondaryOpened: false,
			opened: false,
		}));
	};

	// used to show closemodal btn or save btns
	const statusChangedFromInitial =
		status.status !== payload.data.candidateStatusId;

	const data = payload?.data; // idk
	console.log(data);

	// change status
	const changeStatus = () => {
		if (status.status === "30" && !status.comment) {
			setCommentReq(true);
		} else {
			mutate({
				candidateId: payload.data.candidateId,
				workerStatusId: status.status,
				comment: status.comment,
			});

			// refresh query after successul mutation
			setTimeout(() => {
				if ((isSuccess && status.status === "40") || status.status === "50") {
					//! closeAll();
				}
				queryClient.invalidateQueries("candidates");
				if (isSuccess && (status.status === "20" || status.status === "30")) {
					//! close();
				}
			}, 300);
		}
	};

	// setting if comment requered
	useEffect(() => {
		if (status.status !== "30") {
			setCommentReq(false);
		}
		if (status.status === "30" && status.comment) {
			setCommentReq(false);
		}
		if (status.comment) {
			setCommentReq(false);
		}
	}, [status.comment]);

	//! write this for english ver.
	const canChangeStatus = () => {
		const status = payload.data.candidateStatus;
		if (
			status === "Принят на стажировку" ||
			status === "Принят на работу" ||
			status === "Сотрудник уже был найден" ||
			status === "Заявка была снята" ||
			status === "Уволен" ||
			status === "Снят подрядчиком"
		) {
			return false;
		} else {
			return true;
		}
	};

	// check role
	const roleNotDirector = localStorage.getItem("role") != "Director";
	const roleNotUnder = localStorage.getItem("role") != "UnderChainManager";

	return (
		<div className="employee-req-secondary">
			<div className="main-modal-head">
				<h2>
					{L("Кандидат", "Candidate")}{" "}
					{payload.data && shortenFullname(payload.data.candidateFullName)}
				</h2>
			</div>
			{!canChangeStatus() && (
				<p
					style={{ marginTop: "16px", marginBottom: "10px" }}
					className="color-gray-text"
				>{`${L("Статус", "Status")}: ${payload.data.candidateStatus}`}</p>
			)}
			{canChangeStatus() && (
				<SelectCustom
					data={L(statuses, statusesEN)}
					setState={setStatus}
					stateName="status"
					label={L("Статус", "Status")}
					initial={payload.data.candidateStatus}
					shouldResetInitialDependancies={[payload.data, wasCanceledTimes]}
				/>
			)}
			<div className="more-info-container scrolled-3">
				<div style={{ marginTop: "16px" }} className="divider-sm"></div>
				<MoreInfoItem
					name={L("Подрядчик", "Contractor")}
					data={payload.data.contractorName}
				/>
				{roleNotDirector && roleNotUnder && (
					<MoreInfoItem
						name={L("Ставка", "Rate")}
						data={payload.data.hourlyRate}
					/>
				)}
				{roleNotDirector && roleNotUnder && (
					<MoreInfoItem
						name={L("Ставка с НДС", "Rate with tax")}
						data={payload.data.hourlyRateVAT}
					/>
				)}

				<MoreInfoItem
					name={L("Кандидат", "Candidate")}
					data={payload.data.candidateFullName}
				/>
				<MoreInfoItem
					name={L("Дата рождения", "Birth date")}
					data={sliceString(payload.data.birthday)}
				/>
				<MoreInfoItem
					name={L("Пол", "Sex")}
					data={payload.data.candidateSexString}
				/>
				<MoreInfoItem
					name={L("Телефон", "Phone")}
					data={payload.data.phoneNumber}
				/>
				<MoreInfoItem
					name={L("Паспорт", "Passport")}
					data={payload.data.passport}
				/>
				<MoreInfoItem
					name={L("Должность", "Job title")}
					data={payload.data.position}
				/>

				<MoreInfoItem
					name={L("Дата визита", "Visit date")}
					data={
						payload.data.visitDate ? payload.data.visitDate.slice(0, 10) : ""
					}
				/>

				{roleNotDirector && (
					<MoreInfoItem
						name={L("Филиал", "Outlet")}
						data={payload.data.objectName}
					/>
				)}
				{roleNotDirector && (
					<MoreInfoItem
						name={L("Регион", "Region")}
						data={payload.data.region}
					/>
				)}
				{roleNotDirector && (
					<MoreInfoItem name={L("Город", "City")} data={payload.data.city} />
				)}

				{roleNotDirector && (
					<MoreInfoItem
						name={L("Адрес", "Address")}
						data={payload.data.address}
					/>
				)}

				<MoreInfoItem
					name={L("Комментарий о кандидате", "Comment on candidate")}
					data={payload.data.candidateComment}
				/>
			</div>
			<div className="candidate-comment" style={{ marginTop: "20px" }}>
				<InputControlled
					setState={setStatus}
					stateName="comment"
					value={status.comment}
					label={L(`Комментарий о кандидате`, "Comment on candidate")}
					placeholder={L("Напишите комментарий", "Write a comment")}
					requiredState={commentReq}
				/>
			</div>
			<div className="divider divider-hide-if-screen-low-height"></div>
			<div className="bottom-part">
				{statusChangedFromInitial ? (
					<div className="buttons-flex">
						<CommonBtn
							text={L("Отменить", "Cancel")}
							type="light"
							onClick={() => {
								setStatus({
									status: payload.data.candidateStatusId,
									comment: null,
								});
								setWasCanceledTimes((prev) => prev + 1);
							}}
						/>
						<CommonBtn onClick={changeStatus} text={L("Сохранить", "Save")} />
					</div>
				) : (
					<CommonBtn
						onClick={close}
						type="light"
						text={L("Скрыть кандидата", "Hide candidate")}
					/>
				)}
			</div>
		</div>
	);
}

export default EmployeeRequestsCandidate;
