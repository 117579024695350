import React, { useState, useRef, useEffect } from "react";
import { createArrayOptions } from "../../utils/createArrayOptionsFromObj";
import { isBoolean } from "lodash";
import { BsCheckAll } from "react-icons/bs";
import useLanguage from "../../hooks/useLanguage";

// this component takes in this kind of data:
const dataExample = {
	0: "Подрядчик1",
	1: "Подрядчик2",
	2: "Подрядчик лох",
};

const stateDataExample = ["0", "2"];

interface IProps {
	data: any;
	setState: any;
	stateName: string;
	stateData: string[];
	arrowPosition?: "far-right";
	label?: string;
	requiredState?: boolean;
	errorMessage?: string;
}

function MultipleChoice({
	data,
	setState,
	stateName,
	stateData,
	arrowPosition: arrowPos = "far-right",
	label,
	requiredState,
	errorMessage,
}: IProps) {
	const { L } = useLanguage();

	const [isOpen, setIsOpen] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	const switchOpen = () => setIsOpen((prev) => !prev);

	// creating data structure to work with
	const dataArray = createArrayOptions(data);
	const dataFinal = dataArray.map((element) => {
		const isActive = stateData.includes(element.id);

		return {
			id: element.id,
			value: element.value,
			active: isActive,
		};
	});

	// console.log(dataFinal);

	// creating dropdown items to render
	const dropdownItems = dataFinal.map((element) => {
		const isActive = element.active;
		return (
			<div
				key={element.id}
				onClick={() => changeOption(element)}
				className="dropdown-item"
			>
				<div className="dropdown-item-flex">
					<p>
						{element.active ? (
							<img src="../../icons/checkbox-checked.svg" />
						) : (
							<img src="../../icons/checkbox.svg" />
						)}{" "}
					</p>
					<p>{(element.value as string).toString()}</p>
				</div>
			</div>
		);
	});

	// when clicked on dropdown item
	const changeOption = (option: { id: any; value: any; active: boolean }) => {
		// if id in the state already, remove it
		if (stateData.includes(option.id)) {
			const newStateFiltered = stateData.filter((id) => id !== option.id);
			setState((prev: any) => ({
				...prev,
				[stateName]: newStateFiltered,
			}));
			// if id not in the state, add it
		} else {
			setState((prev: any) => ({
				...prev,
				[stateName]: [...stateData, option.id],
			}));
		}
	};

	// creating list of chosen items to show in select-chosen
	const chosenItems = () => {
		if (stateData.length === 0) {
			return (
				<p style={{ color: "#7e8794" }}>{L("Выберите...", "Choose...")}</p>
			);
		}
		if (stateData.length === dataFinal.length) {
			return <p>{L("Выбраны все", "All are selected")}</p>;
		}

		return dataFinal.map((element) => {
			if (element.active) {
				return (
					<div
						onClick={(e) => removeItem(e, element.id)}
						className="chosen-options"
					>
						<p>{element.value as String}</p>
						<img src="../../icons/del.svg" alt="" />
					</div>
				);
			}
		});
	};

	// remove item from chosen list when clicked in select-chosen
	const removeItem = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		clickedId: string
	) => {
		e.stopPropagation(); // so dropdown doesn't open
		const newStateFiltered = stateData.filter((id) => id !== clickedId);
		setState((prev: any) => ({
			...prev,
			[stateName]: newStateFiltered,
		}));
	};

	// little arrow position
	const arrowPosition = () => {
		if (arrowPos === "far-right") {
			return { right: "3%" };
		}
		return {};
	};

	// choose all
	const chooseAll = () => {
		setState((prev: any) => ({
			...prev,
			[stateName]: dataFinal.map((el) => el.id),
		}));
	};
	// remove all
	const removeAll = () => {
		setState((prev: any) => ({
			...prev,
			[stateName]: [],
		}));
	};

	// check if validation is needed
	const needValidation = isBoolean(requiredState) && requiredState === true;

	// close if clicked outside
	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleOutsideClick);

		// clear event listener
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, [dropdownItems]);

	return (
		<div
			className="multiple-choice"
			style={{ marginTop: "12px", marginBottom: "18px" }}
		>
			<div className="label-quantity">
				<label className="filter-autocomplete-label">{label}</label>
				<p>
					{L("Выделено:", "Selected:")} {stateData.length}
				</p>
			</div>
			<div ref={containerRef} className="multiple-container">
				<div
					onClick={switchOpen}
					className={`select-chosen   ${needValidation && "error"}`}
				>
					<div className={`chosen-options-container`}>{chosenItems()}</div>
					{/* tiny arrow icon */}
					<div style={arrowPosition()} className="arrow-down">
						<img src="../../icons/input-arrow-down.svg" alt="" />
					</div>
				</div>
				<div className={isOpen ? `dropdown-menu` : `dropdown-menu hidden`}>
					{dropdownItems}
				</div>
				{/* clean - choose all btns */}
				<div className="all">
					{stateData.length !== 0 && (
						<p onClick={removeAll} className="clear">
							{L("Очистить", "Clear")}
						</p>
					)}
					{stateData.length !== dataFinal.length && (
						<p onClick={chooseAll} className="choose-all">
							{L("Выбрать все", "Choose all")}
						</p>
					)}
				</div>
			</div>
			{needValidation && (
				<p style={{ marginTop: "4px" }} className="input-validation-text">
					{errorMessage ||
						L("Поле должно быть заполнено", "Field must not be empty")}
				</p>
			)}
		</div>
	);
}

export default MultipleChoice;
