import { useQuery, useMutation } from "react-query";
import { login } from "../../api/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUser } from "../../api/auth";
import InputControlled from "../utility/InputControlled";
import "../../styles/general/general.css";

function LoginPage() {
	const [creds, setCreds] = useState<any>({
		userName: null,
		password: null,
	});
	const navigate = useNavigate();

	const { data, mutate, isSuccess, isError } = useMutation(login);
	const { data: user, isSuccess: isUser } = useQuery(
		["getuser", { token: data?.data?.token }],
		getUser,
		{
			enabled: isSuccess,
		}
	);
	// set language
	if (isUser) localStorage.setItem("lang", user?.data?.le);

	useEffect(() => {
		if (localStorage.getItem("role") === "Oracle") navigate("/");
		if (localStorage.getItem("role") === "Director") navigate("/employees");
		if (localStorage.getItem("role") === "Support") navigate("/rates");
		if (localStorage.getItem("role") === "Contractor") navigate("/requests");
		if (localStorage.getItem("role") === "ChainManager") navigate("/");
		if (localStorage.getItem("role") === "UnderChainManager") navigate("/");
	}, [isUser, user, data, isSuccess]);
	if (isUser) {
		localStorage.setItem("role", user?.data?.role);
		localStorage.setItem("userName", user?.data.firstname+" "+user?.data.lastname);
	}

	const onSubmit: any = (values: any) => {
		console.log(values);
		if (creds.userName && creds.password) {
			mutate({
				userName: creds.userName,
				password: creds.password.replaceAll("#", "%23"),
			});
		}
		console.log(data);
	};
	if (isSuccess) {
		const token = data.data.token;
		const refreshToken = data.data.refreshToken;
		localStorage.setItem("token", token);
		localStorage.setItem("refreshToken", refreshToken);
	}
	return (
		<div className="login-container">
			<div className="login-inputs-container">
				<InputControlled
					setState={setCreds}
					value={creds.userName}
					stateName="userName"
					placeholder="Login"
				/>
				<InputControlled
					setState={setCreds}
					value={creds.password}
					stateName="password"
					placeholder="Password"
					type="password"
				/>
				<button className="btn-login" onClick={onSubmit}>
					Log in
				</button>
				{isError && <p className="login-error">Wrong login or password</p>}
			</div>
		</div>
	);
}

export default LoginPage;
