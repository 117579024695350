import SelectDateTimeSheet from "./SelectDateTimeSheet";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import Sheet from "./Sheet";
import "../../styles/timesheet/timesheet.scss";
import { getWorkerInfo, getWorkTimeSheet } from "../../api/employees";
import { useQuery } from "react-query";
import SetTime from "./SetTime";
import { getWorkTimeSheetContractors } from "../../api/for_contractors/employees_contractors";
import Loading from "../../components/utility/Loading";

interface ITimeSheetProps {
	workerId: any;
	setModal: any;
	outsideYear?: any; // used to set year outside of this component (TimeTable)
	outsideMonth?: any; // used to set months outside of this component
	workerNameContractors?: any;
	workerNameOthers?: any;
}

function TimeSheet(props: ITimeSheetProps) {
	const [year, setYear] = useState<string | null>(null);
	const [month, setMonth] = useState<string | null>(null);
	const [daySelected, setDaySelected] = useState<number | null>(null);
	const [currentDayStatus, setCurrentDayStatus] = useState<{
		id: string | number;
		name: string;
		workedHours: string;
		isAvailableToEdit: any;
	} | null>(null);

	// use different funcs for different roles
	//(q.month && q.year && q.workerId) ? 
	const whichQueryToUse = (q: any) => {
		//console.log(q.queryKey[1]);
		if (localStorage.getItem("role") === "Contractor") {
			return getWorkTimeSheetContractors(q);
		} else {
			return getWorkTimeSheet(q);
		}
	};
	//(q.queryKey[1].month && q.queryKey[1].year && q.queryKey[1].workerId) ? 
	let workerData = props.workerId
	const workerId = props.workerId.workerId;
	//const workerData = props.workerId
	console.log(props.workerId);
	const { data, isSuccess, refetch } = useQuery(
		["getworksheet", { workerId: workerId, year: year, month: month }],
		(q: any) => (q.queryKey[1].month && q.queryKey[1].year && q.queryKey[1].workerId) ? whichQueryToUse(q) : null,
		{
			refetchOnMount: false,
			//onSuccess(res) {
			//	workerData = res?.data?.rootWorkerTimeSheetXML?.workers[0].worker;
			//}
		}
	);

	//const workerData = data?.data?.rootWorkerTimeSheetXML?.workers[0].worker;
	// console.log(workerData);
	const calculateDaysInChosenMonth = () => {
		if (year && month) return dayjs(`${year}-${month}`).daysInMonth();
	};
	const daysInChosenMonth = calculateDaysInChosenMonth();

	const getWorkerFIO = () => {
		if (localStorage.getItem("role") === "Contractor") {
			if (props?.workerNameContractors) return props?.workerNameContractors;
		} else {
			if (props.workerNameOthers) {
				return props.workerNameOthers;
			} else {
				return "Загрузка...";
			}
		}
	};
	return (
		<>
			<div className="timesheet-container">
				<div className="row mx-3">
					<h4 className="worker-name">{getWorkerFIO()}</h4>

					<SelectDateTimeSheet
						outsideYear={props.outsideYear}
						outsideMonth={props.outsideMonth}
						setMonth={setMonth}
						setYear={setYear}
					/>
				</div>

				<Sheet
					workerStatus={workerData?.workerStatusId}
					setCurrentDayStatus={setCurrentDayStatus}
					dayState={daySelected}
					setDaySelected={setDaySelected}
					workerId={workerId}
					workerData={workerData}
					month={month}
					year={year}
					daysInChosenMonth={daysInChosenMonth}
				/>
				{/* All buttons are here */}
				{!(localStorage.getItem("role") === "Contractor") && (
					<SetTime
						workerStatus={workerData?.workerStatusId}
						setModal={props.setModal}
						refetch={refetch}
						day={daySelected}
						month={month}
						year={year}
						workerId={props.workerId.workerId}
						currentDayStatus={currentDayStatus}
					/>

				)}
			</div>
		</>
	);
}

export default TimeSheet;
