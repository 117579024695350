import { useQuery } from "react-query";
import { getConfirmHoursBtns } from "../../../../api/workerHoursBtnsV0";
import HoursBtn from "./HoursBtn";

interface IProps {
	added: any[];
	uncheckAll: () => void;
	datestart: string;
	dateend: string;
	queryName: string;
}

function HoursBtns({
	added,
	datestart,
	dateend,
	uncheckAll,
	queryName,
}: IProps) {
	// get btns
	const { data, isSuccess, isLoading } = useQuery(
		"getHoursBtns",
		getConfirmHoursBtns
	);

	const btnsSorted = data?.data.sort((a, b) => {
		if (a.isPositiveAction && !b.isPositiveAction) {
			return -1; // `a` comes before `b`
		}
		if (!a.isPositiveAction && b.isPositiveAction) {
			return 1; // `b` comes before `a`
		}
		return 0; // order remains the same
	});

	return (
		<div className="hrs-confirm-btns-container">
			{btnsSorted?.map((btn) => (
				<HoursBtn
					uncheckAll={uncheckAll}
					added={added}
					actionName={btn.actionName}
					id={btn.id}
					positive={btn.isPositiveAction}
					datestart={datestart}
					dateend={dateend}
					queryName={queryName}
				/>
			))}
			{!btnsSorted && (
				<>
					<HoursBtn
						uncheckAll={uncheckAll}
						actionName={undefined}
						id={undefined}
						positive={undefined}
						added={added}
						queryName={queryName}
					/>
					<HoursBtn
						uncheckAll={uncheckAll}
						actionName={undefined}
						id={undefined}
						positive={undefined}
						added={added}
						queryName={queryName}
					/>
				</>
			)}
		</div>
	);
}

export default HoursBtns;
