import { useQuery } from "react-query";
import { getContractors } from "../api/contractors";
import Loading from "../components/utility/Loading";
import TablePagination from "../components/utility/TablePagination";
import { useState } from "react";
import ContractorsFilters from "./ContractorsFilters";
import { dlContractors } from "../api/contractors";
import SortArrow from "../components/utility/SortArrow";
import TableTotalCount from "../components/utility/TableTotalCount";
import IconInputFilter from "../components/utility/IconInputFilter";
import useLanguage from "../hooks/useLanguage";

function Contractors() {
	const { L } = useLanguage();

	const [page, setPage] = useState(1);
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc
	const [filters, setFilters] = useState({
		searchInput: "",
	});
	const { data, isSuccess } = useQuery(
		["contractors", { pageNumber: page }, filters, sort],
		(q) => getContractors(q),
		{
			enabled: true,
			retry: 2,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 5000,
		}
	);

	if (isSuccess) {
		// console.log(data.data);
	}
	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};
	const headings = [
		// "Компания",
		{ name: L("Подрядчик", "Contractor") },
		{
			name: L("Сейчас работает", "Currently working"),
			colname: "numOfWorkers",
		},
		{
			name: L("Принято заявок", "Requests accepted"),
			colname: "numOfAcceptedRequests",
		},
		{ name: L("Всего", "Total"), colname: "numOfCandidates" },
		{ name: L("Нанято", "Hired"), colname: "candidateHired" },
		{
			name: L("Ср. время закрытия", "Avg. closing time"),
			colname: "avgDaysToCloseRequests",
		},
		{ name: L("Не подошло", "Not fitted"), colname: "candidateNotFitted" },
		{
			name: L("Подошел другой", "Another hired"),
			colname: "candidateAnotherFitted",
		},
		{ name: L("Не явился", "Didn't show up"), colname: "candidateNotShowedUp" },
		{
			name: L("Отмена", "Cancellations"),
			colname: "candidateRequestCancellation",
		},
		// "Отменено Заявок",
	];

	const columns = data?.data?.contractorsKpiAll?.map((col) => {
		return {
			companyId: col.companyId,
			companyName: col.companyName,
			contractorId: col.contractorId,
			contractorName: col.contractorName,
			numOfWorkers: col.numOfWorkers,
			numOfAcceptedRequests: col.numOfAcceptedRequests,
			numOfCandidates: col.numOfCandidates,
			candidateHired: col.candidateHired,
			avgDaysToCloseRequests: col.avgDaysToCloseRequests,
			candidateNotFitted: col.candidateNotFitted,
			candidateAnotherFitted: col.candidateAnotherFitted,
			candidateNotShowedUp: col.candidateNotShowedUp,
			candidateRequestCancellation: col.candidateRequestCancellation,
			numOfClosedRequests: col.numOfClosedRequests,
			isActive: col.isActive,
		};
	});

	return (
		<div className="container-fluid">
			<div className="upper-bar">
				{
					<>
						<div className="upper-bar-first">
							<IconInputFilter
								setFilters={setFilters}
								placeholder={L("Искать подрядчика", "Search contractor")}
							/>

							{
								<TableTotalCount
									name={L("Подрядчиков", "Contractors")}
									headers={data?.headers["x-pagination"]}
								/>
							}
						</div>
						<div className="upper-bar-second">
							<img
								src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
								width="35px"
								style={{ marginRight: "0px", cursor: "pointer" }}
								alt=""
								onClick={() => {
									dlContractors(page, filters);
								}}
							/>
						</div>
					</>
				}
			</div>

			{isSuccess ? (
				<>
					<table cellSpacing={0} className="my-table">
						<thead>
							<tr>
								{headings.map((heading) => (
									<th>
										<div className="d-flex align-items-end ">
											<div>{heading.name}</div>
											{heading.colname && (
												<div className="">
													<SortArrow
														sort={sort}
														heading={heading}
														setSort={setSort}
													/>
												</div>
											)}
										</div>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{columns &&
								columns.map((col) => {
									return (
										<tr>
											{/* <td>{col.companyName}</td> */}
											<td className="size-item">{col.contractorName}</td>
											<td>{col.numOfWorkers}</td>
											<td>{col.numOfAcceptedRequests}</td>
											<td>{col.numOfCandidates}</td>
											<td>{col.candidateHired}</td>
											<td>{col.avgDaysToCloseRequests}</td>
											<td>{col.candidateNotFitted}</td>
											<td>{col.candidateAnotherFitted}</td>
											<td>{col.candidateNotShowedUp}</td>
											<td>{col.candidateRequestCancellation}</td>
											{/* <td>{col.numOfClosedRequests}</td> */}
										</tr>
									);
								})}
						</tbody>
					</table>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default Contractors;
