import { postCandidates } from "../../../api/for_contractors/candidates_contractors";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState, useRef, useEffect } from "react";
import InputAutocomplete from "../../utility/InputAutocomplete";
import { Navigate, useNavigate, useParams } from "react-router";
import { Typeahead } from "react-bootstrap-typeahead";
import { workerRequestsGetInfo } from "../../../api/for_contractors/worker_requests_contractors";
import useFeedback from "../../../hooks/useFeedback";
import SelectCustom from "../../utility/SelectCustom";
import InputControlled from "../../utility/InputControlled";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import {
	getAllCandidates,
	candidateGetInfo,
} from "../../../api/for_contractors/candidates_contractors";
import { formatDate } from "../../../utils/formatDate";
import useLanguage from "../../../hooks/useLanguage";

interface IProps {
	workerRequestId: any;
	setModal: any;
}

function CreateCandidate({ workerRequestId, setModal }: IProps) {
	const { L } = useLanguage();

	const [existingId, setExistingId] = useState({
		candidate: null,
	});
	const [info, setInfo] = useState<any>({
		firstname: "",
		lastname: "",
		middlename: "",
		birthday: "",
		passport: "",
		sex: null,
		phoneNumber: null,
		citizenship: "",
		visitDate: "",
		phoneNumberWithoutCode: "",
	});

	// feedback
	const { openFeedback, closeFeedback } = useFeedback();

	// client
	const client = useQueryClient();

	// refs to clear inputs
	const geoRef = useRef(null);
	const contractorRef = useRef(null);
	const jobTitleIdRef = useRef(null);
	// regex to check if number
	const re = /^\d+(\.|,)?\d*$/;

	// get all candidates
	const { data: allCandidates } = useQuery("allCandidates", getAllCandidates);

	// get info on chosen candidate
	const { data: chosen, isSuccess: isChosen } = useQuery(
		["candidatesGetInfo", existingId.candidate],
		(q) => candidateGetInfo(q),
		{
			enabled: !!existingId.candidate,
		}
	);
	console.log(chosen?.data);

	// fill if candidate has been chosen from the form
	useEffect(() => {
		if (isChosen === true) {
			setInfo((prev: any) => ({
				...prev,
				firstname: chosen?.data?.firstName,
				lastname: chosen?.data?.lastName,
				middlename: chosen?.data?.middleName,
				birthday: formatDate(chosen?.data?.birthday),
				passport: chosen?.data?.passport,
				sex: chosen?.data?.candidateSex,
				phoneNumber: chosen?.data?.phoneNumber,
				citizenship: chosen?.data?.citizenship,
				visitDate: formatDate(chosen?.data?.visitDate),
				// visitDate: "1999-04-03",
			}));
			if (chosen?.data?.phoneNumber) {
				if (chosen?.data?.phoneNumber.slice(0, L(2, 3)) === L("+7", "+44")) {
					setInfo((prev: any) => ({
						...prev,
						phoneNumberWithoutCode: chosen?.data?.phoneNumber
							.toString()
							.slice(L(2, 3)),
					}));
				} else {
					setInfo((prev: any) => ({
						...prev,
						phoneNumberWithoutCode: chosen?.data?.phoneNumber.toString(),
					}));
				}
			}
		}
	}, [isChosen, chosen]);

	// validation
	const [firstnamereq, setfirstnamereq] = useState(false);
	const [lastnamereq, setlastnamereq] = useState(false);
	const [birthdayreq, setbirthdayreq] = useState(false);
	const [passportreq, setpassportreq] = useState(false);
	const [sexreq, setsexreq] = useState(false);
	const [phoneNumberreq, setphoneNumberreq] = useState(false);
	const [citizenshipreq, setcitizenshipreq] = useState(false);
	const [visitDatereq, servisitDatereq] = useState(false);

	const closeSecondary = () => {
		setModal((prev: any) => ({
			...prev,
			secondaryOpened: false,
		}));
	};

	const { isSuccess, isError, mutate, error } = useMutation<any, any>(
		postCandidates,
		{
			onSuccess: () => {
				closeSecondary();
				openFeedback(
					L("Кандидат успешно направлен", "Candidate has been sent"),
					"success"
				);
				setTimeout(() => {
					client.invalidateQueries("employeesRequestsContractors");
				}, 150);
			},
			onError: () => {
				openFeedback(
					L(
						"Что-то пошло не так, проверьте, заполнены ли все поля",
						"Something went wrong"
					),
					"error"
				);
			},
		}
	);

	const postCandidate = () => {
		const whatIsSex = (sex: string | null) => {
			if (sex === "true") {
				return true;
			}
			if (sex === "false") {
				return false;
			}
			if (sex === "null") {
				return null;
			} else {
				return sex;
			}
		};
		const realSex = whatIsSex(info.sex);

		if (!info.firstname) setfirstnamereq(true);
		if (!info.lastname) setlastnamereq(true);
		if (!info.birthday) setbirthdayreq(true);
		if (!info.passport) setpassportreq(true);
		if (info.sex === null) setsexreq(true);
		if (!info.phoneNumber) setphoneNumberreq(true);
		if (!info.citizenship) setcitizenshipreq(true);
		if (!info.visitDate) servisitDatereq(true);

		if (info.firstname) setfirstnamereq(false);
		if (info.lastname) setlastnamereq(false);
		if (info.birthday) setbirthdayreq(false);
		if (info.passport) setpassportreq(false);
		if (info.sex !== null) setsexreq(false);

		if (info.phoneNumber) setphoneNumberreq(false);
		if (info.citizenship) setcitizenshipreq(false);
		if (info.visitDate) servisitDatereq(false);

		if (error) {
			if (error.response.status === 411) setpassportreq(true);
		}
		if (info.phoneNumberWithoutCode) {
			mutate({
				workerRequestId: workerRequestId,
				...info,
				phoneNumber: L("+7", "+44") + info.phoneNumberWithoutCode,
				sex: realSex,
			});
		} else {
			mutate({ ...info, workerRequestId: workerRequestId, sex: realSex });
		}
	};

	const whichError = () => {
		if (error) {
			if (error.response.status === 411)
				return L(
					"Кандидат с таким паспортом уже был направлен на вакансию",
					"Candidate with this passport has already been sent"
				);
		} else {
			return L("Поле должно быть заполнено", "Field must not be empty");
		}
	};

	// sex options
	const newSexOptions = {
		true: L("Мужчина", "Male"),
		false: L("Женщина", "Female"),
	};

	const whatSexChosen = (sex: false | null | true) => {
		if (sex === true) return "Мужчина";
		if (sex === false) return "Женщина";
		if (sex === null) return "Выберите";
		return "error";
	};

	const whatSexChosenString = (sex: false | null | true | "true" | "false") => {
		if (sex === true) return L("Мужчина", "Male");
		if (sex === "true") return L("Мужчина", "Male");
		if (sex === false) return L("Женщина", "Female");
		if (sex === "false") return L("Женщина", "Female");
		if (sex === null) return L("Выберите", "Choose");
		return "error";
	};

	return (
		<>
			<section className="side-modal-container" style={{ width: "450px" }}>
				<div className="form-inner center-container-2">
					<div
						style={{
							marginTop: "-6px",
							marginBottom: "-3px",
							fontSize: "14px",
							color: "#474f5a",
						}}
						className="main-modal-head"
					>
						<h2>{L("Направить кандидата", "Send candidate")}</h2>
					</div>
					<form style={{ maxWidth: "99%" }}>
						{allCandidates?.data !== "" && (
							<InputAutocomplete
								label={L(
									"Выбрать существующего кандидата",
									"Choose an existing candidate"
								)}
								id="existing_candidate"
								setState={setExistingId}
								stateNames={["candidate"]}
								data={allCandidates?.data}
							/>
						)}

						<div className="divider-sm"></div>

						<InputControlled
							label={L("Имя*", "First name*")}
							setState={setInfo}
							stateName="firstname"
							value={info.firstname}
							requiredState={firstnamereq}
						/>

						<InputControlled
							label={L("Фамилия*", "Last name*")}
							setState={setInfo}
							stateName="lastname"
							value={info.lastname}
							requiredState={lastnamereq}
						/>

						<InputControlled
							label={L("Отчество", "Middle name")}
							setState={setInfo}
							stateName="middlename"
							value={info.middlename}
						/>

						<InputControlled
							label={L("Дата рождения*", "Birth date*")}
							setState={setInfo}
							stateName="birthday"
							value={info.birthday}
							type="date"
							requiredState={birthdayreq}
						/>

						<InputControlled
							label={L("Паспорт*", "Passport")}
							setState={setInfo}
							stateName="passport"
							value={info.passport}
							requiredState={passportreq}
							errorMessage={whichError()}
						/>

						<SelectCustom
							styles={{ marginBottom: "16px" }}
							label={L("Пол*", "Sex*")}
							data={newSexOptions}
							setState={setInfo}
							stateName="sex"
							// initial="Выберите"
							initial={whatSexChosenString(info.sex)}
							arrowPosition="far-right"
							requiredState={sexreq}
						/>

						<InputControlled
							label={L("Гражданство*", "Citizenship*")}
							setState={setInfo}
							stateName="citizenship"
							value={info.citizenship}
							requiredState={citizenshipreq}
						/>

						<InputControlled
							label={L("Дата визита*", "Visit date*")}
							setState={setInfo}
							stateName="visitDate"
							value={info.visitDate}
							type="date"
							requiredState={visitDatereq}
						/>

						{/* phone */}
						<div className="mt-2">
							<label className="form-label" htmlFor="phoneNumber">
								{L("Телефон*", "Phone*")}
							</label>
							<div
								className={L("phonefield-container", "phonefield-container-gb")}
							>
								<input
									style={phoneNumberreq ? { border: "1px solid #ff3b30" } : {}}
									value={info.phoneNumberWithoutCode}
									onChange={(e: any) =>
										setInfo((prev: any) => ({
											...prev,
											phoneNumberWithoutCode: e.target.value.toString(),
											phoneNumber: e.target.value.toString(),
										}))
									}
									type="telephone"
									id="phoneNumber"
									className=" phonefield form-control"
								/>
							</div>
							{phoneNumberreq && (
								<p className="input-validation-text">
									{" "}
									{L("Обязательное поле", "The field must not be empty")}
								</p>
							)}
						</div>
					</form>
					{isError && <p className="text-danger">{whichError()}</p>}
				</div>
				<div className="bottom-container-2">
					<div className="flex">
						<CommonBtn
							onClick={closeSecondary}
							text={L("Отменить", "Cancel")}
							type="light"
						/>
						<CommonBtn onClick={postCandidate} text={L("Направить", "Send")} />
					</div>
				</div>
			</section>
		</>
	);
}

export default CreateCandidate;
