import { useQuery } from "react-query";
import { useState, useEffect, useRef } from "react";
import { Typeahead, ClearButton } from "react-bootstrap-typeahead";
import InputFilter from "../../../utility/InputFilter";
import { createArrayOptions } from "../../../../utils/createArrayOptionsFromObj";
import { getWorkerRequestsSupport } from "../../../../api/for_support/support_worker_requests";
// import "../styles/filters/filters.css";
import FilterAutocomplete from "../../../utility/FilterAutocomplete";
import CommonBtn from "../../../utility/Buttons/CommonBtn";
interface IProps {
	filters?: any;
	setFilters: React.Dispatch<React.SetStateAction<any>>;
}
function EmployeeRequestFiltersS({ setFilters, filters }: IProps) {
	const { data, isSuccess } = useQuery(
		["employeesRequestsFilters", { pageNumber: 1 }, filters],
		getWorkerRequestsSupport
	);

	const regions = createArrayOptions(data?.data?.filterValues?.regions);
	const cities = createArrayOptions(data?.data?.filterValues?.cities);
	const objectNames = createArrayOptions(data?.data?.filterValues?.objectNames);
	const jobTitles = createArrayOptions(data?.data?.filterValues?.jobTitles);
	const requestStatuses = createArrayOptions(
		data?.data?.filterValues?.requestStatuses
	);

	const chosenFilters = data?.data?.chosenFilters;
	const regionRef: any = useRef(null);
	const cityRef: any = useRef(null);
	const jobRef: any = useRef(null);
	const statusRef: any = useRef(null);
	const objectRef: any = useRef(null);
	const addressRef: any = useRef(null);
	const customIdRef: any = useRef();
	const idReq: any = useRef();
	const byRef: any = useRef();
	const createdFromRef: any = useRef();
	const createdBeforeRef: any = useRef();

	let checkRegion = regions && localStorage.getItem("role") != "Director";
	let checkCity = cities && localStorage.getItem("role") != "Director";
	let checkObjects = objectNames && localStorage.getItem("role") != "Director";
	let checkFIO = localStorage.getItem("role") != "Director";
	// role check
	const roleNotDirector = localStorage.getItem("role") != "Director";

	// clear filters
	const clear = () => {
		customIdRef.current.value = "";
		addressRef.current.value = "";
		idReq.current.value = "";
		byRef.current.value = "";
		createdFromRef.current.value = "";
		createdBeforeRef.current.value = "";

		setFilters({
			index: "",
			region: "",
			city: "",
			outlet: "",
			address: "",
			job: "",
			requestStatus: "",
			createdBy: "",
			StartCreatedOn: "",
			EndCreatedOn: "",
			workerRequestId: "", // must be integer
		});
	};
	useEffect(() => {
		if (data) {
			if (filters.workerRequestId) customIdRef.current.value = filters.index;
			if (filters.address) addressRef.current.value = filters.address;
			if (filters.createdBy) byRef.current.value = filters.createdBy;
			if (filters.workerRequestId)
				idReq.current.value = filters.workerRequestId;
		}
		if (filters.StartCreatedOn)
			createdFromRef.current.value = filters.StartCreatedOn;
		if (filters.EndCreatedOn)
			createdBeforeRef.current.value = filters.EndCreatedOn;
	}, [data]);
	return (
		<div>
			<div style={{ marginBottom: "20px" }}>
				<CommonBtn onClick={clear} type="light" text="Очистить фильтры" />
			</div>
			<InputFilter
				ref={idReq}
				filterField="workerRequestId"
				setFilters={setFilters}
				placeholder={"Пример: 231"}
				label="№ Заявки"
				type={"text"}
			/>

			{isSuccess && (
				<FilterAutocomplete
					filterName="companyId"
					options={data?.data?.filterValues?.companies}
					selectedValue={chosenFilters?.companies?.value}
					setFilters={setFilters}
					placeholder="Пример: Продукты"
					label="Компания"
				/>
			)}

			{roleNotDirector && (
				<InputFilter
					ref={customIdRef}
					filterField="index"
					setFilters={setFilters}
					placeholder={"Пример: 918267"}
					label="ID филиала"
					type={"text"}
				/>
			)}

			{checkObjects && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="outlet"
					options={data?.data?.filterValues?.objectNames}
					selectedValue={chosenFilters?.objectNames?.value}
					label="Филиал"
					placeholder="Пример: Продукты_4515"
				/>
			)}

			{checkRegion && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="region"
					options={data?.data?.filterValues?.regions}
					selectedValue={chosenFilters?.regions?.value}
					label="Регион"
					placeholder="Пример: Омская область"
				/>
			)}

			{checkCity && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="city"
					options={data?.data?.filterValues?.cities}
					selectedValue={chosenFilters?.cities?.value}
					label="Город"
					placeholder="Пример: Омск"
				/>
			)}

			{checkFIO && (
				<InputFilter
					ref={addressRef}
					filterField="address"
					setFilters={setFilters}
					placeholder={"Пример: Улица Амурская 1"}
					type={"text"}
					label="Адрес"
				/>
			)}
			{/* jobs */}

			{jobTitles && (
				<FilterAutocomplete
					setFilters={setFilters}
					filterName="job"
					options={data?.data?.filterValues?.jobTitles}
					selectedValue={chosenFilters?.jobTitles?.value}
					label="Должность"
					placeholder="Пример: Кассир"
				/>
			)}

			{checkFIO && (
				<InputFilter
					ref={byRef}
					filterField="createdBy"
					setFilters={setFilters}
					placeholder={"Пример: Иванов Иван Иванович"}
					type={"text"}
					label="ФИО создавшего"
				/>
			)}

			<InputFilter
				ref={createdFromRef}
				filterField="StartCreatedOn"
				setFilters={setFilters}
				type={"date"}
				label="Создана от"
			/>

			<InputFilter
				ref={createdBeforeRef}
				filterField="EndCreatedOn"
				setFilters={setFilters}
				label="Создана до"
				type={"date"}
			/>
			<div style={{ marginBottom: "2rem" }}></div>
		</div>
	);
}

export default EmployeeRequestFiltersS;
