import React from "react";
import { useForm } from "react-hook-form";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { useEffect, useState, useRef } from "react";
import { getEmployeeCreationTemplate } from "../api/employees";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { createArrayOptions } from "../utils/createArrayOptionsFromObj";
import { postWorkerRequest } from "../api/employees";
import { useNavigate } from "react-router";
import { getAddresses } from "../api/objects";
import { getCurrentAddress } from "../api/objects";
import { getGeopositions } from "../api/objects";
import InputAutocomplete from "../components/utility/InputAutocomplete";
import InputControlled from "../components/utility/InputControlled";
import CommonBtn from "../components/utility/Buttons/CommonBtn";
import useFeedback from "../hooks/useFeedback";
import SelectCustom from "../components/utility/SelectCustom";
import MultipleChoice from "../components/utility/MultipleChoice";
import useLanguage from "../hooks/useLanguage";
import { isDateBeforeToday } from "../utils/isDateBeforeToday";

interface IProps {
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
		}>
	>;
}

function FindEmployee({ setModal }: IProps) {
	const { L } = useLanguage();

	const { openFeedback, closeFeedback } = useFeedback();
	const navigate = useNavigate();
	const client = useQueryClient();

	const [isAddrDisabled, setIsAddrDisabled] = useState(true);
	const [isGeoDisabled, setIsGeoDisabled] = useState(true);
	const [isAllContractorsChosen, setIsAllContractorsChosen] = useState(false);
	const [info, setInfo] = useState<any>({
		address: null,
		objectId: null,
		jobTitleId: "",
		sex: null,
		contractorIds: [],
		workScheduleId: "",
		comment: "",
		workStartDate: "",
		workEndDate: null,
		cityId: null,
		deadline: null,
	});
	const addrRef: any = useRef(null);
	const geoRef: any = useRef(null);
	const [objreq, setobjreq] = useState(false);
	const [georeq, setgeoreq] = useState(false);
	const [addrreq, setaddrreq] = useState(false);
	const [jobreq, setjobreq] = useState(false);
	const [contractorreq, setcontractorreq] = useState(false);
	const [schreq, setschreq] = useState(false);
	const [startreq, setstartreq] = useState(false);
	// const [endtreq, setendreq] = useState(false);
	//query
	const { data } = useQuery("employeeTemplate", getEmployeeCreationTemplate);
	const { data: addresses, isSuccess: isAddresses } = useQuery(
		["getAddresses", info.objectId, info.cityId],
		(q) => getAddresses(q),
		{
			enabled: !!info.cityId,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);

	// close modal func

	const close = () => {
		setModal((prev) => ({
			...prev,
			opened: false,
		}));
	};

	useEffect(() => {
		if (info.objectId) {
			setIsGeoDisabled(false);
		}
		if (info.cityId) {
			setIsAddrDisabled(false);
		}
		if (!info.cityId) {
			setIsAddrDisabled(true);
		}
	}, [info.objectId, info.cityId]);

	const { data: geo, isSuccess: isGeo } = useQuery(
		["getGeos", info.objectId],
		(q) => getGeopositions(q),
		{
			enabled: !!info.objectId,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);

	const addrArr = createArrayOptions(addresses?.data);
	const objectNames = createArrayOptions(data?.data.objectNames);
	const jobTitles = createArrayOptions(data?.data.jobTitles);
	const contractors = createArrayOptions(data?.data.contractors);
	const workSceduleTitles = createArrayOptions(data?.data.workSceduleTitles);

	//post
	const { data: res, mutate } = useMutation(postWorkerRequest, {
		onSuccess: () => {
			openFeedback(
				L("Заявка на поиск успешно создана", "Request is created successfully"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("employeesRequests");
			}, 150);
			close();
		},
		onError: () => {
			openFeedback(
				L(
					"Проверьте, заполнены ли все поля",
					"Check if all required fields are filled"
				),
				"error"
			);
		},
	});

	const roleNotDirector = localStorage.getItem("role") != "Director";
	const sendRequest = () => {
		const whatIsSex = (sex: string | null) => {
			if (sex === "true") {
				return true;
			}
			if (sex === "false") {
				return false;
			}
			if (sex === "null") {
				return null;
			} else {
				return null;
			}
		};
		const realSex = whatIsSex(info.sex);
		mutate({
			...info,
			deadline: info.deadline ? info.deadline : null,
			workEndDate: info.workEndDate ? info.workEndDate : null,
			sex: realSex,
		});
		if (roleNotDirector) {
			if (!info.objectId) setobjreq(true);
			if (!info.address) setaddrreq(true);
			if (!info.cityId) setgeoreq(true);
		}
		if (!info.jobTitleId) setjobreq(true);
		if (!info.workScheduleId) setschreq(true);
		if (!info.contractorIds[0]) setcontractorreq(true);
		if (!info.workStartDate) setstartreq(true);
		// if (!info.workEndDate) setendreq(true);

		if (info.objectId) setobjreq(false);
		if (info.cityId) setgeoreq(false);
		if (info.address) setaddrreq(false);
		if (info.jobTitleId) setjobreq(false);
		if (info.workScheduleId) setschreq(false);
		if (info.contractorIds[0]) setcontractorreq(false);
		if (info.workStartDate) setstartreq(false);
		// if (info.workEndDate) setendreq(false);
	};

	const newSexOptions = {
		null: L("Без разницы", "Any"),
		true: L("Мужчины", "Male"),
		false: L("Женщины", "Female"),
	};

	const objcheck =
		localStorage.getItem("role") != "Director" && objectNames != undefined;

	// role check

	// console.log(isAddresses);
	return (
		<section className="">
			<div className="form-inner">
				<div style={{ minHeight: "83vh" }}>
					<form className="">
						{objcheck && (
							<InputAutocomplete
								data={data?.data.objectNames}
								setState={setInfo}
								stateNames={["objectId"]}
								stateNamesToNull={["address", "cityId"]}
								clearRefs={[addrRef, geoRef]}
								id="outletId"
								label={L("Филиал*", "Outlet*")}
								requiredState={objreq}
							/>
						)}

						{objcheck && (
							<>
								<InputAutocomplete
									ref={geoRef}
									id={"outletId"}
									data={geo?.data}
									stateNames={["cityId"]}
									stateNamesToNull={["address"]}
									setState={setInfo}
									clearRefs={[addrRef]}
									disabled={isGeoDisabled}
									label={L("Регион и город*", "Region and city*")}
									requiredState={georeq}
								/>
							</>
						)}
						{/* address */}

						{objcheck && (
							<InputAutocomplete
								data={addresses?.data}
								id="address"
								ref={addrRef}
								setState={setInfo}
								stateNames={["address", "objectId"]}
								label={L("Адрес*", "Address*")}
								disabled={isAddrDisabled}
								requiredState={addrreq}
							/>
						)}

						{jobTitles && (
							<InputAutocomplete
								data={data?.data.jobTitles}
								setState={setInfo}
								stateNames={["jobTitleId"]}
								id="job"
								requiredState={jobreq}
								label={L("Должность*", "Job title*")}
							/>
						)}

						{data?.data.contractors && (
							<MultipleChoice
								data={data?.data.contractors}
								setState={setInfo}
								stateName="contractorIds"
								stateData={info.contractorIds}
								label={L("Подрядчики*", "Contractors*")}
								requiredState={contractorreq}
							/>
						)}

						{workSceduleTitles && (
							<SelectCustom
								styles={{ marginBottom: "16px" }}
								label={L("График работы*", "Schedule*")}
								data={data?.data.workSceduleTitles}
								setState={setInfo}
								stateName="workScheduleId"
								initial=""
								requiredState={schreq}
								arrowPosition="far-right"
							/>
						)}

						<SelectCustom
							styles={{ marginBottom: "16px" }}
							label={L("Пол", "Sex")}
							data={newSexOptions}
							setState={setInfo}
							stateName="sex"
							initial={L("Без разницы", "Any")}
							arrowPosition="far-right"
						/>
						<InputControlled
							value={info.workStartDate}
							setState={setInfo}
							stateName="workStartDate"
							label={L("Начало работы*", "Work start")}
							type="date"
							requiredState={startreq}
							errorSecondary={() => {
								if (isDateBeforeToday(info.workStartDate)) {
									return L("Неправильная дата", "Wrong date");
								}
							}}
						/>

						<div className="mt-2">
							<label className="form-label" htmlFor="directoremail">
								{L("Окончание работы", "Work end")}
							</label>
							<input
								onChange={(e) =>
									setInfo((prev: any) => ({
										...prev,
										workEndDate: e.target.value,
									}))
								}
								id="directoremail"
								type="date"
								className={`form-control ${
									isDateBeforeToday(info.workEndDate) && "input-error"
								}`}
							/>
							{/* {endtreq && <p className="text-danger">обязательное поле</p>} */}
							{isDateBeforeToday(info.workEndDate) && (
								<p className="input-validation-text">
									{L("Неправильная дата", "Wrong date")}
								</p>
							)}
						</div>

						<div className="mt-2">
							<label className="form-label" htmlFor="deadline">
								{L("Дедлайн подрядчика", "Contractor's deadline")}
							</label>
							<input
								onChange={(e) =>
									setInfo((prev: any) => ({
										...prev,
										deadline: e.target.value,
									}))
								}
								id="directoremail"
								type="date"
								className={`form-control ${
									isDateBeforeToday(info.deadline) && "input-error"
								}`}
							/>
							{/* {endtreq && <p className="text-danger">обязательное поле</p>} */}
							{isDateBeforeToday(info.deadline) && (
								<p className="input-validation-text">
									{L("Неправильная дата", "Wrong date")}
								</p>
							)}
						</div>

						<InputControlled
							value={info.comment}
							setState={setInfo}
							stateName="comment"
							label={L("Комментарий к заявке", "Comment for the request")}
						/>
					</form>
				</div>
			</div>
			<div
				style={{
					borderTop: "1px solid #f7f8fa",
					paddingTop: "20px",
					marginTop: "50px",
					marginBottom: "30px",
					display: "flex",
					justifyContent: "space-around",
					// position: "absolute",
					width: "100%",
					// bottom: "50px",
				}}
				className="btns"
			>
				<CommonBtn
					styles={{ width: "175px" }}
					text={L("Отменить", "Cancel")}
					type="light"
					onClick={close}
				/>
				<CommonBtn
					styles={{ width: "175px" }}
					text={L("Найти сотрудника", "Find employee")}
					onClick={() => sendRequest()}
				/>
			</div>
		</section>
	);
}

export default FindEmployee;
