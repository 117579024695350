import { useState, useEffect } from "react";

export type Ids = (string | number | undefined)[];
type Id = string | number | undefined;

interface ITableCheckProps {
	ids?: Ids;
}

const useTableCheck = ({ ids }: ITableCheckProps) => {
	const [checked, setChecked] = useState<Ids>([]);
	const [added, setAdded] = useState<Ids>([]);
	const [isAllChecked, setIsAllChecked] = useState(false);
	useEffect(() => {
		if (ids && ids.length > 0 && checked.length === 0) {
			setChecked(ids);
		}
	}, [ids]);

	useEffect(() => {
		if (added.length < checked.length) setIsAllChecked(false);
	}, [added.length]);

	const addChecked = (id: Id) => {
		if (id !== undefined && !added.includes(id)) {
			setAdded((prev) => [...prev, id]);
		}
		if (added.includes(id)) {
			setAdded(added.filter((el) => el != id));
		}
	};

	const switchCheckedAll = () => {
		if (added.length > 0 && added.length < checked.length) {
			setAdded(checked);
			setIsAllChecked(true);
		} else if (added.length > 0) {
			setAdded([]);
			setIsAllChecked(false);
		} else {
			if (ids && ids.length > 0) {
				setAdded(checked);
				setIsAllChecked(true);
			}
		}
	};

	const getChecked = (id: Id) => {
		return added.includes(id);
	};

	const uncheckAll = () => {
		setAdded([]);
	};

	const total = added.length;

	return {
		checked,
		added,
		addChecked,
		switchCheckedAll,
		getChecked,
		isAllChecked,
		total,
		uncheckAll,
	};
};

export default useTableCheck;
