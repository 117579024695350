import { QueryFunctionContext } from "react-query";
import { instanceV2 } from "./instances";

export interface IObjects {
	objects: {
		userId: any;
		customObjectId: number;
		objectName: string;
		objectResponsibleFullName: string;
		objectId: number;
		email: any;
		phoneNumber: string;
		numberOfEmployees: number;
		region: string;
		city: string;
		address: string;
		isObjectActive: boolean;
		createdOn: string;
		underChainManagerFullName: any;
	}[];
	filterValues: {
		regions: string[];
		cities: string[];
		objectNames: string[];
	};
	chosenFilters: {
		objectNames: {
			key: number;
			value: string;
		};
		regions: {
			key: number;
			value: string;
		};
		cities: {
			key: number;
			value: string;
		};
		underChainManagers: {
			key: string;
			value: string;
		};
	};
}

export const getObjects = async (q?: QueryFunctionContext<any>) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}
	let region = "";
	if (q?.queryKey[2]?.region) {
		region = `&RegionId=${q?.queryKey[2]?.region}`;
	}
	let city = "";
	if (q?.queryKey[2]?.city) {
		city = `&CityId=${q?.queryKey[2]?.city}`;
	}
	let outlet = "";
	if (q?.queryKey[2]?.outlet) {
		outlet = `&ObjectId=${q?.queryKey[2]?.outlet}`;
	}
	let fio = "";

	if (q?.queryKey[2]?.fio) {
		fio = `&ObjectRespFullName=${q?.queryKey[2]?.fio}`;
	}
	let customObjectId = "";
	if (q?.queryKey[2]?.customObjectId) {
		customObjectId = `&customObjectId=${q?.queryKey[2]?.customObjectId}`;
	}
	let UnderChainManagerId = "";
	if (q?.queryKey[2]?.UnderChainManagerId) {
		UnderChainManagerId = `&UnderChainManagerId=${q?.queryKey[2]?.UnderChainManagerId}`;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	let download: any = "";
	if (q?.queryKey[2]?.download == "true") {
		download = `&download=true`;
	}

	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}

	const response = await instanceV2.get<IObjects>(
		`Objects${page}${region}${city}${fio}${outlet}${customObjectId}${download}${column}${order}${searchInput}${UnderChainManagerId}`
	);
	return response;
};

interface IObject {
	customObjectId: string;
	objectName: string;
	firstName: string;
	lastName: string;
	middleName: string;
	email: string;
	phoneNumber: string;
	numberOfEmployees: number;
	address: string;
	cityId: number;
	city: string;
	regionId: number;
	region: string;
	underChainManagerFullName?: string;
	reportedToId?: string;
}

export const dlObject = async () => {
	await instanceV2
		.get("Workers?pageNumber=1&download=true", {
			responseType: "blob",
			headers: {
				"Content-Type":
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			},
		})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "file.xlsx");
			document.body.appendChild(link);
			link.click();
		});
};

export const getObject = async ({
	objectId,
}: {
	objectId: string | undefined | number;
}) => {
	const response = await instanceV2.get<IObject>(
		`Objects/getInfo?objectId=${objectId}`
	);
	return response;
};

export const changeObject = async (newObj: any) => {
	const response = instanceV2.put("Objects", JSON.stringify(newObj), {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return response;
};

export const createObject = async (newObj: any) => {
	const response = instanceV2.post("Objects", JSON.stringify(newObj), {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return response;
};

export const deleteObject = async (objectId: string | number) => {
	const response = instanceV2.delete(`Objects?ObjectId=${objectId} `);
	return response;
};

export const deleteObjectsMultiple = async (objects: string[] | number[]) => {
	// should take an array of nums/strings
	const response = instanceV2.delete("Objects", { data: objects });
	return response;
};

interface IGeo {
	[key: string]: string;
}

export const getGeopositions = async (q?: QueryFunctionContext<any>) => {
	let objectId = "";
	if (q?.queryKey[1]) {
		objectId = `?ObjectId=${q?.queryKey[1]}`;
	}

	const response = await instanceV2.get<IGeo>(
		`Objects/getGeopositions${objectId}`
	);
	return response;
};

export const getCurrentGeo = async (q?: QueryFunctionContext<any>) => {
	let objectId = "";
	if (q?.queryKey[1]) {
		objectId = `?ObjectId=${q?.queryKey[1]}`;
	}
	const response = await instanceV2.get(`Objects/getCurrentGeo${objectId}`);

	return response;
};

interface IAdr {
	[key: string]: string;
}
export const getAddresses = async (q: QueryFunctionContext<any>) => {
	let ObjectId = "";
	if (q?.queryKey[1]) {
		ObjectId = `?ObjectId=${q?.queryKey[1]}`;
	}

	let cityId = "";
	if (q?.queryKey[2]) {
		cityId = `&cityId=${q?.queryKey[2]}`;
	}
	const response = await instanceV2.get<IAdr>(
		`Objects/getAddresses${ObjectId}${cityId}`
	);
	return response;
};

export const getCurrentAddress = async (q: QueryFunctionContext<any>) => {
	let ObjectId = "";
	if (q?.queryKey[1]) {
		ObjectId = `?ObjectId=${q?.queryKey[1]}`;
	}
	const response = await instanceV2.get(`Objects/getCurrentAddress${ObjectId}`);
	return response;
};

export const getRespEmployeeInfo = async (q: QueryFunctionContext<any>) => {
	let objectId = "";
	if (q?.queryKey[1]) {
		// console.log(q.queryKey[1].pageNumber);
		objectId = q.queryKey[1];
	}
	const response = await instanceV2.get<any>(
		`objects/getRespEmployeeInfo?ObjectId=${objectId}`
	);
	return response;
};

export const uploadObjects = async (file: any) => {
	const formData = new FormData();
	formData.append("file", file);

	const response = instanceV2.post("/Objects/uploadObjects", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response;
};

export const dlExample = async () => {
	await instanceV2
		.get(`Objects/getUploadTemplate`, {
			responseType: "blob",
			headers: {
				"Content-Type":
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			},
		})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "file.xlsx");
			document.body.appendChild(link);
			link.click();
		});
};

export const getUnderChainManagers = async () => {
	const response = await instanceV2.get("Objects/getUnderChainManagers");
	return response;
};
