import React, { useState } from "react";
import MoreInfoItem from "../../outlets/OutletInfo/MoreInfoItem";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	companyEmployeeGetinfo,
	deleteCompanyEmployee,
} from "../../../api/company_employees";
import Loading from "../../utility/Loading";
import HiddenRegionalManagerInfo from "./HiddenSourceInfo";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import EditRegionalManager from "./EditSource";
import DeleteBtn from "../../utility/Buttons/DeleteBtn";
import useFeedback from "../../../hooks/useFeedback";
import ConfirmModal from "../../utility/confirmModal/ConfirmModal";
import useLanguage from "../../../hooks/useLanguage";

interface IProps {
	payload?: any; // id
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			secondaryOpened?: boolean | undefined;
			payload?: any;
		}>
	>;
}
function RegionalManagerInfo({ payload, setModal }: IProps) {
	const { L } = useLanguage();

	const [active, setActive] = useState<"info" | "edit">("info");
	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();
	// query
	const { data, isSuccess } = useQuery(
		["regionalManagerGetInfo", payload],
		(q) => companyEmployeeGetinfo(q)
	);

	// delete
	const { mutate: mutateDelete } = useMutation(deleteCompanyEmployee, {
		onSuccess: () => {
			setModal({
				opened: false,
			});
			openFeedback(
				L("Региональный менеджер удален", "Regional manager has been deleted"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("regionalManagerGetInfo");
				client.invalidateQueries("regionalManagers");
			}, 400);

			setTimeout(() => {
				client.invalidateQueries("regionalManagerGetInfo");
				client.invalidateQueries("regionalManagers");
			}, 150);
			setModal((prev: any) => ({
				...prev,
				secondaryOpened: false,
			}));
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	// Open Conformations
	const openDelete = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Удаление менеджера", "Manager deletion")}
					text={L(
						"Вы уверены, что вы хотите удалить этого менеджера?",
						"Are you sure you want to delete this manager?"
					)}
				>
					<DeleteBtn
						text={L("Да, удалить", "Yes, delete")}
						onClick={() => {
							mutateDelete(payload);
							closeConfirmation();
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};
	// close conformation modal
	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	if (!isSuccess) return <Loading type="inside" />;
	if (active === "edit")
		return <EditRegionalManager id={payload} setActive={setActive} />;
	return (
		<div className="side-modal-container">
			<div className="form-inner center-container-2 more-info-container">
				<MoreInfoItem name={L("Имя", "Name")} data={data?.data.firstName} />
				<MoreInfoItem
					name={L("Фамилия", "Last name")}
					data={data?.data.lastName}
				/>
				<MoreInfoItem
					name={L("Отчество", "Middle name")}
					data={data?.data.middleName}
				/>
				<MoreInfoItem name={L("Почта", "Email")} data={data?.data.email} />
				<MoreInfoItem
					name={L("Телефон", "Phone")}
					data={data?.data.phoneNumber}
				/>
				<HiddenRegionalManagerInfo
					setModal={setModal}
					userId={data?.data?.userId}
					login={data?.data.userName}
					password={data?.data.password}
				/>
			</div>
			<div className="bottom-container-3">
				<div className="flex">
					<DeleteBtn
						styles={{ width: "190px", height: "20px" }}
						onClick={() => openDelete()}
						text={L("Удалить", "Delete")}
					/>
					<CommonBtn
						onClick={() => setActive("edit")}
						text={L("Редактировать", "Edit")}
					/>
				</div>
			</div>
		</div>
	);
}

export default RegionalManagerInfo;
