import { instanceV1 } from "../instances";
import { QueryFunctionContext } from "react-query";

export const getContractorsSupport = async (q: QueryFunctionContext<any>) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	const response = await instanceV1.get(`Contractors${page}${searchInput}`);
	return response;
};

interface IContractorInfoSupport {
	contractorInfo: {
		userId: string;
		contractorId: number;
		contractorName: string;
		firstName: string;
		lastName: string;
		middleName: string | null;
		phoneNumber: string | null;
		userName: string;
		password: string | null;
		email: string;
	};
	companies: {
		[key: number]: string;
	};
}

export const getInfoContractorsSupport = async (
	q: QueryFunctionContext<any>
) => {
	const response = await instanceV1.get<IContractorInfoSupport>(
		`Contractors/getInfo?contractorId=${q.queryKey[1]}`
	);
	return response;
};

export const getAvailableCompanies = async () => {
	const response = await instanceV1.get("Contractors/getAvailableCompanies");
	return response;
};

export type IContractor = {
	contractorid: number;
	firstName: string;
	lastName: string;
	middleName: string;
	phoneNumber: string;
	contractorName: string;
	email: string;
	companyIds: number[];
};

export const editContractor = async (edited: IContractor) => {
	const response = instanceV1.put(
		`Contractors?contractorid=${edited.contractorid}`,
		JSON.stringify(edited),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

export const createContractor = async (edited: IContractor) => {
	const response = instanceV1.post(`Contractors`, JSON.stringify(edited), {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return response;
};

export const deleteContractor = async (contractorid: number) => {
	const response = instanceV1.delete(
		`Contractors?contractorid=${contractorid}`
	);
	return response;
};
