import axios from "axios";
import { BASEURL } from "./constants";
import { QueryFunctionContext } from "react-query";
import { instanceV2 } from "./instances";
import { isDateBeforeToday } from "../utils/isDateBeforeToday";

export const a = {};

export interface IEmployees {
	workers: {
		workerId: number;
		customObjectId: string;
		regionId: number;
		region: string;
		cityId: number;
		city: string;
		contractorId: number;
		contractorName: string;
		objectId: number;
		objectName: string;
		workerFullname: string;
		jobTitleId: number;
		position: string;
		citizenship: string;
		workStartDate: string;
		workEndDate: string;
		address: string;
		workerStatusId: number;
		workerStatusName: string;
		objectResponsibleFullName: string;
		passport: any;
		isAvailableToEdit: boolean;
		workerSexString: string;
		birthday: string;
		totalWorkedHours: number;
		externalRate: number;
		externalRateVat: number;
		costs: number;
		costsWithVat: number;
		//  worked hours approved and stUff
		workedHoursNotApproved: number;
		workedHoursInReview: number;
		workedHoursApprovedByContractor: number;
		workedHoursApprovedByChainManager: number;
		costsWithVatNotApproved: any;
		costsWithVatNotInReview: any;
		costsWithVatApprovedByContractor: any;
		costsWithVatApprovedByChainManager: any;
		workerStatusNameForTable: string;
	}[];
	totalWorkerStats: {
		sumTotalWorkedHours: number;
		sumWorkedHoursNotApproved: number;
		sumWorkedHoursInReview: number;
		sumWorkedHoursApprovedByContractor: number;
		sumWorkedHoursApprovedByChainManager: number;
		sumCostsWithVatWorkedHoursNotApproved: number;
		sumCostsWithVatWorkedHoursInReview: number;
		sumCostsWithVatWorkedHoursApprovedByContractor: number;
		sumCostsWithVatWorkedHoursApprovedByChainManager: number;
		sumCosts: number;
		sumCostsWithVat: number;
		avgExternalRate: any;
		avgExternalRateVat: any;
	};
	workerFilterValues: {
		regions: {
			[key: string]: string;
		};
		cities: {
			[key: string]: string;
		};
		objectNames: {
			[key: string]: string;
		};
		jobTitles: {
			[key: string]: string;
		};
		workerStatuses: {
			[key: string]: string;
		};
		contractorNames: {
			[key: string]: string;
		};
		hourStatuses: {
			[key: string]: string;
		};
		underChainManagers: {
			[key: string]: string;
		};
	};
	chosenFilters: {
		cities: any;
		regions: any;
		contractorNames: any;
		objectNames: any;
		workerStatuses: any;
		jobTitles: any;
		hoursStatuses: any;
		startWorkDate: string;
		endWorkDate: string;
		underChainManagers: string;
		workerStatus: any;
	};
}

export const getEmployees = async (q: QueryFunctionContext<any>) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let region = "";
	if (q?.queryKey[2]?.region) {
		region = `&RegionId=${q?.queryKey[2]?.region}`;
	}
	let city = "";
	if (q?.queryKey[2]?.city) {
		city = `&CityId=${q?.queryKey[2]?.city}`;
	}

	let outlet = "";
	if (q?.queryKey[2]?.outlet) {
		outlet = `&ObjectId=${q?.queryKey[2]?.outlet}`;
	}

	let address = "";
	if (q?.queryKey[2]?.address) {
		address = `&Address=${q?.queryKey[2]?.address}`;
	}

	let job = "";
	if (q?.queryKey[2]?.job) {
		job = `&JobTitleId=${q?.queryKey[2]?.job}`;
	}

	let ObjectId = "";
	if (q?.queryKey[2]?.ObjectId) {
		ObjectId = `&CustomObjectId=${q?.queryKey[2]?.ObjectId}`;
	}

	let contractorId = "";
	if (q?.queryKey[2]?.contractorId) {
		contractorId = `&contractorId=${q?.queryKey[2]?.contractorId}`;
	}

	let WorkerFullname = "";
	if (q?.queryKey[2]?.WorkerFullname) {
		WorkerFullname = `&WorkerFullname=${q?.queryKey[2]?.WorkerFullname}`;
	}

	let ObjectResponsibleFullName = "";
	if (q?.queryKey[2]?.ObjectResponsibleFullName) {
		ObjectResponsibleFullName = `&ObjectResponsibleFullName=${q?.queryKey[2]?.ObjectResponsibleFullName}`;
	}

	let StartWorkDate = "";
	if (q?.queryKey[2]?.StartWorkDate) {
		StartWorkDate = `&StartWorkDate=${q?.queryKey[2]?.StartWorkDate}`;
	}

	let EndWorkDate = "";
	if (q?.queryKey[2]?.EndWorkDate) {
		EndWorkDate = `&EndWorkDate=${q?.queryKey[2]?.EndWorkDate}`;
	}

	let hoursStatusId = "";
	if (q?.queryKey[2]?.hoursStatusId) {
		hoursStatusId = `&HoursStatusId=${q?.queryKey[2]?.hoursStatusId}`;
	}
	let workerStatusId = "";
	if (q?.queryKey[2]?.workerStatusId) {
		workerStatusId = `&workerStatusId=${q?.queryKey[2]?.workerStatusId}`;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}
	let UnderChainManagerId = "";
	if (q?.queryKey[2]?.UnderChainManagerId) {
		UnderChainManagerId = `&UnderChainManagerId=${q?.queryKey[2]?.UnderChainManagerId}`;
	}
	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}

	const response = instanceV2.get<IEmployees>(
		`Workers${page}&PageSize=100${region}${city}${job}${WorkerFullname}${ObjectResponsibleFullName}${StartWorkDate}${EndWorkDate}${contractorId}${ObjectId}${column}${order}${address}${outlet}${searchInput}${hoursStatusId}${UnderChainManagerId}${workerStatusId}`
	);
	return response;
};

interface IWorker {
	workerInfo: {
		customObjectId: string;
		regionId: number;
		region: string;
		cityId: number;
		city: string;
		contractorId: number;
		contractorName: string;
		objectId: number;
		objectName: string;
		objectResponsibleFullName: string;
		workerId: number;
		workerFullname: string;
		firstName: string;
		lastName: string;
		middleName: string;
		phoneNumber: string;
		passport: string;
		birthday: string;
		sex: true | false | null;
		workerSexString: string;
		jobTitleId: number;
		position: string;
		citizenship: string;
		workStartDate: string;
		workEndDate: string;
		craetedOnWorkerInfo: string;
		address: string;
		workerStatusId: number;
		workerStatusName: string;
		totalWorkedHours: number;
		externalRate: any;
		externalRateVat: any;
		costs: any;
		costsWithVat: any;
		dismissalDate: string;
		workerUserName: string;
		workerPassword: string;
	};
	startReportDate: string;
	endReportDate: string;
}

export const getEmployeeInfo = async (q: QueryFunctionContext<any>) => {
	// add start and end when its ready

	const response = await instanceV2.get<IWorker>(
		`Workers/getinfo?workerId=${q?.queryKey[1]}&startWorkDate=${q?.queryKey[2]}&endWorkDate=${q?.queryKey[3]}`
	);
	return response;
};

interface IEditEmployee {
	workerId: number;
	firstname: string;
	lastname: string;
	middlename: string | null;
	birthday: string;
	passport: string;
	sex: true | false | null;
	phoneNumber: string;
	citizenship: string;
}

export const editEmployee = async (fields: IEditEmployee) => {
	const response = instanceV2.put("/Workers", JSON.stringify(fields), {
		headers: {
			"Content-Type": "application/json",
		},
	});
	return response;
};

export const dlWorkers = async (page: number, filters: any, sort: any) => {
	let pageNumber = `?pageNumber=${page}`;

	const region = `&RegionId=${filters.region}`;
	const city = `&City=${filters.city}`;
	const job = `&JobTitleId=${filters.job}`;
	const contractorId = `&contractorId=${filters.contractorId}`;
	const WorkerFullName = `&WorkerFullname=${filters.WorkerFullname}`;
	const ObjectResponsibleFullName = `&ObjectResponsibleFullName=${filters.ObjectResponsibleFullName}`;
	const StartWorkDate = `&StartWorkDate=${filters.StartWorkDate}`;
	const EndWorkDate = `&EndWorkDate=${filters.EndWorkDate}`;
	const searchInput = `&searchInput=${filters.searchInput}`;

	//sort
	const column = `&Column=${sort.heading}`;
	const sortType = `&SortType=${sort.order}`;
	await instanceV2
		.get(
			`Workers${pageNumber}&download=true${region}${city}${job}${contractorId}${WorkerFullName}${ObjectResponsibleFullName}${StartWorkDate}${EndWorkDate}${searchInput}${column}${sortType}`,
			{
				responseType: "blob",
				headers: {
					"Content-Type":
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				},
			}
		)
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "file.xlsx");
			document.body.appendChild(link);
			link.click();
		});
};

interface IEmployeeReqs {
	filterValues: any;
	workerRequests: {
		city: string;
		cityId: number;
		numOfTotalCandidates: any;
		comment: string | null;
		[key: string]: any;
		isAvailableToRefreshRequest: boolean;
		isAvailableToConfirmRequest: boolean;
		isAvailableToDeclineRequest: boolean;
		isAvailableToGenerateCandidate: boolean;
		isAvailableToDeleteRequest: boolean;
	}[];
	chosenFilters: {
		cities: any;
		objectNames: any;
		regions: any;
		jobTitles: any;
		requestStatuses: any;
		underChainManagers: any;
	};
}

export const getEmployeesRequests = async (q: QueryFunctionContext<any>) => {
	const token = localStorage.getItem("token");
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let region = "";
	if (q?.queryKey[2]?.region) {
		region = `&RegionId=${q?.queryKey[2]?.region}`;
	}

	let objectId = "";

	let customObjectId = "";
	if (q?.queryKey[2]?.index) {
		customObjectId = `&CustomObjectId=${q?.queryKey[2]?.index}`;
	}

	let city = "";
	if (q?.queryKey[2]?.city) {
		city = `&CityId=${q?.queryKey[2]?.city}`;
	}
	let outlet = "";
	if (q?.queryKey[2]?.outlet) {
		outlet = `&ObjectId=${q?.queryKey[2]?.outlet}`;
	}

	let address = "";
	if (q?.queryKey[2]?.address) {
		address = `&Address=${q?.queryKey[2]?.address}`;
	}

	let job = "";
	if (q?.queryKey[2]?.job) {
		job = `&JobTitleId=${q?.queryKey[2]?.job}`;
	}

	let requestStatus = "";
	if (q?.queryKey[2]?.requestStatus) {
		requestStatus = `&requestStatusId=${q?.queryKey[2]?.requestStatus}`;
	}

	let UnderChainManagerId = "";
	if (q?.queryKey[2]?.UnderChainManagerId) {
		UnderChainManagerId = `&UnderChainManagerId=${q?.queryKey[2]?.UnderChainManagerId}`;
	}

	let createdBy = "";

	if (q?.queryKey[2]?.createdBy) {
		createdBy = `&CreatedByFullName=${q?.queryKey[2]?.createdBy}`;
	}

	let StartCreatedOn = "";
	if (q?.queryKey[2]?.StartCreatedOn) {
		StartCreatedOn = `&StartCreatedOn=${q?.queryKey[2]?.StartCreatedOn}`;
	}

	let EndCreatedOn = "";
	if (q?.queryKey[2]?.EndCreatedOn) {
		EndCreatedOn = `&EndCreatedOn=${q?.queryKey[2]?.EndCreatedOn}`;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	// only number!
	function stringToNumber(str: string): number | null {
		const num = Number(str);
		return isNaN(num) ? null : num;
	}
	let workerRequestId = stringToNumber(q?.queryKey[2]?.workerRequestId);
	if (workerRequestId === 0) {
		workerRequestId = null;
	}

	console.log(workerRequestId);

	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}
	const response = await instanceV2.get<IEmployeeReqs>(
		`WorkerRequests${page}${region}${city}${outlet}${createdBy}${StartCreatedOn}${EndCreatedOn}${job}${requestStatus}${column}${order}${objectId}${address}${customObjectId}${searchInput}${
			workerRequestId ? `&workerRequestId=${workerRequestId}` : ""
		}${UnderChainManagerId}`
	);
	return response;
};

interface IEmployeeReqInfo {
	workerRequestInfo: {
		workerRequestId: number;
		objectId: number;
		customObjectId: string;
		objectName: string;
		objectResponsibleId: string;
		objectResponsibleFullName: string;
		objectResponsibleEmail: string;
		objectResponsiblePhoneNumber: string;
		regionId: number;
		region: string;
		cityId: number;
		city: string;
		address: string;
		isObjectActive: true;
		isCompanyActive: true;
		jobTitleId: number;
		workScheduleId: string;
		sex: boolean;
		sexString: string;
		workStartDate: string;
		workEndDate: string;
		comment: any;
		deadline: any;
		requestStatusId: number;
		requestStatus: string;
		isActive: boolean;
		createdBy: string;
		createdOn: string;
		createdByFullName: string;
		position: string;
		schedule: string;
		numOfCandidates: number;
		numOfTotalCandidates: number;
		numOfTotalCandidatesForCompany: number;
		candidateStatusId: null;
		workerStatusId: null;
	};
	contractorIds: number[];
	workStartDateString: string;
	workEndDateString: string;
}

export const getInfoEmployeeReq = async (q: QueryFunctionContext<any>) => {
	const response = await instanceV2.get<IEmployeeReqInfo>(
		`/WorkerRequests/getInfo?workerRequestId=${q?.queryKey[1]}`
	);
	return response;
};

interface IFindEmployee {
	objectNames: { [key: string]: any }[];
	jobTitles: { [key: string]: any }[];
	workSceduleTitles: { [key: string]: any }[];
	contractors: { [key: string]: any }[];
}

export const getEmployeeCreationTemplate = async () => {
	const response = instanceV2.get<IFindEmployee>(
		"/WorkerRequests/getCreationTemplate"
	);
	return response;
};

interface ISetWorkerStatus {
	workerId: number;
	workerStatusId: number;
	comment: string;
}

export const setWorkerStatus = async (fields: ISetWorkerStatus) => {
	const response = instanceV2.post(
		"/Workers/setWorkerStatus",
		JSON.stringify(fields),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

interface IPostWorkerRequestField {
	address: string;
	comment: string;
	contractorIds: string[];
	jobTitleId: string;
	objectId: string;
	sex: any;
	workEndDate: string;
	workScheduleId: string;
	workStartDate: string;
	deadline: string;
}
export const postWorkerRequest = async (fields: IPostWorkerRequestField) => {
	if (
		!isDateBeforeToday(fields.workStartDate) &&
		!isDateBeforeToday(fields.workEndDate) &&
		!isDateBeforeToday(fields.deadline)
	) {
		const response = instanceV2.post("WorkerRequests", JSON.stringify(fields), {
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response;
	} else {
		throw new Error();
	}
};

export const setApproval = async (approveParams: {
	workerRequestId: number | string;
	approval: boolean;
}) => {
	const response = await instanceV2.post(
		"WorkerRequests/setApproval",
		JSON.stringify({
			workerRequestId: approveParams.workerRequestId,
			approval: approveParams.approval,
		}),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

interface ITimeSheet {
	rootWorkerTimeSheetXML: {
		workers: {
			worker: {
				workerId: string;
				jobTitleId: string;
				position: string;
				objectId: string;
				objectName: string;
				customObjectId: string;
				numberOfEmployees: string;
				regionId: string;
				region: string;
				cityId: string;
				city: string;
				address: string;
				isObjectActive: string;
				objectResponsibleId: string;
				objectResponsibleFullName: string;
				companyId: string;
				contractorId: string;
				contractorName: string;
				workerFullname: string;
				hourlyRate: string;
				workerStatusId: string;
				workerStatusName: string;
				daysShowedUp: string;
				daysNotShowedUp: null;
				daysSick: null;
				workerStatusNameForTable: any;
			};
			days: {
				workdayStatusId: string;
				workDayStatus: string;
				dayWorkDate: string;
				workDate: string;
				workedHours: string;
			}[];
		}[];
	};
	workerFilterValues: {
		regions: any;
		cities: any;
		objectNames: any;
		jobTitles: any;
		workerStatuses: any;
		contractorNames: any;
		underChainManagers: any;
	};
	chosenFilters: {
		regions: any;
		cities: any;
		objectNames: any;
		jobTitles: any;
		contractorNames: any;
		underChainManagers: any;
		workerStatus: any;
	};
	totalWorkerStats: any;
}

interface IWorkerInfo {
	objectName: string;
	address: string;
	workerFullname: string;
	position: string;
	dismissalDate: any;
}

export const getWorkerInfo = async (q: QueryFunctionContext<any>) => {
	let workerId = "";
	if (q?.queryKey[1]?.workerId) {
		workerId = q?.queryKey[1]?.workerId;
	}
	const response = instanceV2.get<IWorkerInfo[]>(
		`Workers/getInfo?WorkerId=${workerId}`
	);
	return response;
};

// TIMESHEET
export const getWorkTimeSheet = async (q: QueryFunctionContext<any>) => {

	
	let page = "";
	if (q?.queryKey[2]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "&pageNumber=" + q.queryKey[2].pageNumber;
	}

	let objectId = "";
	if (q?.queryKey[1]?.objectId) {
		objectId = `&objectId=${q?.queryKey[1]?.objectId}`;
	}

	let region = "";
	if (q?.queryKey[1]?.region) {
		region = `&regionId=${q?.queryKey[1]?.region}`;
	}

	let city = "";
	if (q?.queryKey[1]?.city) {
		city = `&cityId=${q?.queryKey[1]?.city}`;
	}

	let contractorId = "";
	if (q?.queryKey[1]?.contractorId) {
		contractorId = `&contractorId=${q?.queryKey[1]?.contractorId}`;
	}

	let job = "";
	if (q?.queryKey[1]?.job) {
		job = `&jobTitleId=${q?.queryKey[1]?.job}`;
	}

	let workerId = "";
	if (q?.queryKey[1]?.workerId) {
		workerId = `&workerId=${q?.queryKey[1]?.workerId}`;
	}

	let WorkerFullname = "";
	if (q?.queryKey[1]?.WorkerFullname) {
		WorkerFullname = `&WorkerFullname=${q?.queryKey[1]?.WorkerFullname}`;
	}
	let address = "";
	if (q?.queryKey[1]?.address) {
		address = `&Address=${q?.queryKey[1]?.address}`;
	}
	let workerStatusId = "";
	if (q?.queryKey[1]?.workerStatusId) {
		workerStatusId = `&workerStatusId=${q?.queryKey[1]?.workerStatusId}`;
	}

	let UnderChainManagerId = "";
	if (q?.queryKey[1]?.UnderChainManagerId) {
		UnderChainManagerId = `&UnderChainManagerId=${q?.queryKey[1]?.UnderChainManagerId}`;
	}

	let ObjectResponsibleFullName = "";
	if (q?.queryKey[1]?.ObjectResponsibleFullName) {
		ObjectResponsibleFullName = `&ObjectResponsibleFullName=${q?.queryKey[1]?.ObjectResponsibleFullName}`;
	}

	let CustomObjectId = "";
	if (q?.queryKey[1]?.CustomObjectId) {
		CustomObjectId = `&CustomObjectId=${q?.queryKey[1]?.CustomObjectId}`;
	}

	//let CompanyId = "";
	//if (q?.queryKey[1]?.CompanyId) {
	//	CompanyId = `&CompanyId=${q?.queryKey[1]?.CompanyId}`;
	//}


	let year = "";
	if (q?.queryKey[1]?.year) {
		year = `&year=${q?.queryKey[1]?.year}`;
	}
	let searchInput = "";
	if (q?.queryKey[1]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[1]?.searchInput}`;
	}
	let month = "";
	if (q?.queryKey[1]?.month) {
		month = `&month=${q?.queryKey[1]?.month}`;
	}
	//<ITimeSheet>
	const response = await instanceV2.get(
		`Workers/getWorkTimeSheet?CompanyId=1&${objectId}${workerId}${year}${month}${region}${city}${contractorId}${job}${WorkerFullname}${page}${ObjectResponsibleFullName}${searchInput}${address}${CustomObjectId}${UnderChainManagerId}${workerStatusId}`
	);
	return response;
};

interface IgetWorkerRequestCreationTemplate {
	objectNames: any;
	jobTitles: any;
	workSceduleTitles: any;
	contractors: any;
}
export const getWorkerRequestCreationTemplate = async () => {
	const response = await instanceV2.get<IgetWorkerRequestCreationTemplate>(
		"WorkerRequests/getCreationTemplate"
	);
	return response;
};

interface IEmployeeReq {
	workerRequestInfo: {
		workerRequestId: number;
		objectId: number;
		customObjectId: string;
		companyId: number; // пятерочка
		objectName: string; // пятерочка петра
		objectResponsibleFullName: string;
		regionId: number;
		region: string;
		cityId: number;
		city: string;
		address: string;
		jobTitleId: number;
		position: string;
		workScheduleId: number;
		schedule: string;
		sex: true | false | null;
		sexString: string;
		deadline: any;
		workStartDate: string;
		workEndDate: string | null;
		comment: string;
		requestStatusId: number;
		requestStatus: string;
		isActive: boolean;
		// createdBy: "19ca01c8-f49d-4e6e-928e-af667ca7d7db";
		// createdOn: "2022-12-29T00:52:38.803";
		// createdByFullName: "Коптев Глеб Геннадьевич";
		numOfCandidates: number;
		candidateStatusId: any;
		workerStatusId: any;
	};
	contractors: any; // {3: "Подрядчик 1",}
	contractorIds: number[];
	workStartDateString: string;
	workEndDateString: string | null;
}

export const getWorkerRequestInfo = async (q: QueryFunctionContext<any>) => {
	let workerRequestId = "";
	if (q?.queryKey[1]?.workerRequestId) {
		workerRequestId = `?workerRequestId=${q?.queryKey[1]?.workerRequestId}`;
	}

	const response = await instanceV2.get<IEmployeeReq>(
		`/WorkerRequests/getinfo${workerRequestId}`
	);

	return response;
};

export const refreshWorkerRequest = async (
	workerRequestId: number | string
) => {
	const response = await instanceV2.post(
		"WorkerRequests/refresh",
		`${workerRequestId.toString()}`,
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

export const deleteWorkerRequest = async (workerRequestId: number | string) => {
	const response = await instanceV2.delete(
		`WorkerRequests?WorkerRequestId=${workerRequestId.toString()}`
	);
	return response;
};
export const editWorkerReq = async (edited: any) => {
	const response = await instanceV2.put(
		"/WorkerRequests",
		JSON.stringify(edited),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

export const setWorkerStats = async (fields: any) => {
	const response = instanceV2.post(
		"/Workers/setWorkerStats",
		JSON.stringify(fields),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

export const generateCandidate = async (workerRequestId: any) => {
	const response = await instanceV2.post(
		`Candidates/generateCandidate?workerRequestId=${workerRequestId}`
	);
	return response;
};
