import { instanceV3 } from "../instances";
import { QueryFunctionContext } from "react-query";

interface ICandidates {
	candidates: {
		workerRequestId: number;
		position: string;
		customObjectId: string;
		objectId: number;
		objectName: string;
		objectResponsibleFullName: string;
		candidateId: number;
		candidateFullName: string;
		hourlyRate: number;
		phoneNumber: string;
		citizenship: string;
		passport: string;
		visitDate: string;
		candidateStatusId: number;
		address: string;
		candidateStatus: string;
		isAvailableToSetStatus: boolean;
		requestDeadline: string;
		contractorId: number;
		contractorName: string;
		IsAvailableToDeleteCandidate: boolean;
		IsAvailableToEditCandidate: boolean;
		IsAvailableToRefreshCandidate: boolean;
		comment: string;
		candidateComment: string;
		candidateSexString: string;
		birthday: string;
		[key: string]: any;
	}[];

	filterValues: {
		regions: {
			[key: string]: string;
		};
		cities: {
			[key: string]: string;
		};
		objectNames: {
			[key: string]: string;
		};
		jobTitles: {
			[key: string]: string;
		};
		workerStatuses: {
			[key: string]: string;
		};
		contractors: {
			[key: string]: string;
		};
		companies: {
			[key: number]: string;
		};
	};

	chosenFilters: {
		regions: any;
		cities: any;
		objectNames: any;
		jobTitles: any;
		workerStatuses: any;
		contractors: any;
		companies: any;
	};
}
export const getCandidates = async (q?: QueryFunctionContext<any>) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let region = "";
	if (q?.queryKey[2]?.region) {
		region = `&RegionId=${q?.queryKey[2]?.region}`;
	}
	let city = "";
	if (q?.queryKey[2]?.city) {
		city = `&CityId=${q?.queryKey[2]?.city}`;
	}

	let job = "";
	if (q?.queryKey[2]?.job) {
		job = `&JobTitleId=${q?.queryKey[2]?.job}`;
	}

	let WorkerStatusId = "";
	if (q?.queryKey[2]?.WorkerStatusId) {
		WorkerStatusId = `&WorkerStatusId=${q?.queryKey[2]?.WorkerStatusId}`;
	}

	let CandidateFullName = "";
	if (q?.queryKey[2]?.CandidateFullName) {
		CandidateFullName = `&CandidateFullName=${q?.queryKey[2]?.CandidateFullName}`;
	}

	let ContractorId = "";
	if (q?.queryKey[2]?.ContractorId) {
		ContractorId = `&ContractorId=${q?.queryKey[2]?.ContractorId}`;
	}

	let StartVisitDate = "";
	if (q?.queryKey[2]?.StartVisitDate) {
		StartVisitDate = `&StartVisitDate=${q?.queryKey[2]?.StartVisitDate}`;
	}

	let EndVisitDate = "";
	if (q?.queryKey[2]?.EndVisitDate) {
		EndVisitDate = `&EndVisitDate=${q?.queryKey[2]?.EndVisitDate}`;
	}

	let companyId = "";
	if (q?.queryKey[2]?.companyId) {
		companyId = `&CompanyId=${q?.queryKey[2]?.companyId}`;
	}

	let outlet = "";
	if (q?.queryKey[2]?.outlet) {
		outlet = `&ObjectId=${q?.queryKey[2]?.outlet}`;
	}
	let customObjectId = "";
	if (q?.queryKey[2]?.index) {
		customObjectId = `&CustomObjectId=${q?.queryKey[2]?.index}`;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	// only number!
	function stringToNumber(str: string): number | null {
		const num = Number(str);
		return isNaN(num) ? null : num;
	}
	let workerRequestId = stringToNumber(q?.queryKey[2]?.workerRequestId);
	if (workerRequestId === 0) {
		workerRequestId = null;
	}

	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}
	const response = instanceV3.get<ICandidates>(
		`/Candidates${page}${region}${city}${job}${WorkerStatusId}${CandidateFullName}${ContractorId}${column}${order}${companyId}${outlet}${searchInput}${StartVisitDate}${EndVisitDate}${
			workerRequestId ? `&workerRequestId=${workerRequestId}` : ""
		}${customObjectId}`
	);
	return response;
};

export const postCandidates = async (candidate: any) => {
	const response = await instanceV3.post(
		"/Candidates",
		JSON.stringify(candidate),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

export const putCandidates = async (candidate: any) => {
	const response = await instanceV3.put(
		"/Candidates",
		JSON.stringify(candidate),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

interface ICandidate {
	workerRequestId: number;
	companyName: string;
	objectName: string;
	position: string;
	sex: true | false | null;
	workStartDate: string;
	workEndDate: string;
	hourlyRate: any;
	hourlyRateVAT: any;
	requestDeadline: any;
	comment: string;
	firstName: string;
	lastName: string;
	middleName: string;
	birthday: string;
	passport: string;
	candidateSex: true | false | null;
	phoneNumber: string;
	citizenship: string;
	visitDate: string;
	candidateSexString: string;
	isAvailableToDeleteCandidate: any;
	isAvailableToRefreshCandidate: any;
	isAvailableToEditCandidate: any;
	candidateStatus: any;
	candidateComment: string;
}
export const candidateGetInfo = async (q?: QueryFunctionContext<any>) => {
	let id = q?.queryKey[1];

	const response = await instanceV3.get<ICandidate>(
		`/Candidates/getInfo?candidateId=${id}`
	);
	return response;
};

export const deleteCandidate = async (candidateId: any) => {
	const response = await instanceV3.delete(
		`/Candidates?candidateId=${candidateId}`
	);
	return response;
};

export const refreshCandidate = async (candidateId: any) => {
	const response = await instanceV3.put(
		`/Candidates/refresh?candidateId=${candidateId}`
	);
	return response;
};

export const getAllCandidates = async () => {
	const response = await instanceV3.get("/Candidates/getAllCandidates");
	return response;
};
