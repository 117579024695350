import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState, useEffect } from "react";
import { createObject, getUnderChainManagers } from "../../api/objects";
import { getObjects } from "../../api/objects";
import { createArrayOptions } from "../../utils/createArrayOptionsFromObj";
import "../../styles/forms/FormNewEdit.css";
import { getGeopositions } from "../../api/objects";
import { useNavigate } from "react-router";
import InputControlled from "../utility/InputControlled";
import InputAutocomplete from "../utility/InputAutocomplete";
import CommonBtn from "../utility/Buttons/CommonBtn";
import useFeedback from "../../hooks/useFeedback";
import useLanguage from "../../hooks/useLanguage";

interface IProps {
	setModalCreateNew: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
		}>
	>;
}

function CreateOutlet(props: IProps) {
	const { L } = useLanguage();

	const [edited, setEdited] = useState({
		// region: "",
		cityId: "",
		address: "",
		email: "",
		numberOfEmployees: 0,
		firstName: "",
		lastName: "",
		middleName: "",
		phoneNumberWithoutCode: "",
		phoneNumber: "",
		objectName: "",
		customObjectId: "",
		reportedToId: "",
	});
	const queryClient = useQueryClient();

	const { openFeedback, closeFeedback } = useFeedback();
	const [adrreq, setadrreq] = useState(false);
	const [cityreq, setcityreq] = useState(false);
	const [namereq, setnamereq] = useState(false);
	const [lastreq, setlastreq] = useState(false);
	const [objnamereq, setobjnamereq] = useState(false);
	// const [underreq, setundereq] = useState(false);

	const { data: objects } = useQuery("outlets", getObjects);
	const { data: geo } = useQuery("geo", getGeopositions);

	const geoPositions = createArrayOptions(geo?.data);
	// console.log(geoPositions);

	// underchain query
	const { data: underManagersData } = useQuery(
		"getUnderChainManagers",
		getUnderChainManagers
	);

	// sending PUT

	const { mutate, isSuccess, isError, error, status } = useMutation<
		any,
		any,
		any
	>(createObject, {
		onError: () => {
			openFeedback(
				L(
					"Ошибка, пожалуйста, проверьте, заполнены ли все поля",
					"Error, please, check input fields"
				),
				"error"
			);
		},
		onSuccess: () => {
			openFeedback("Филиал успешно создан", "success");
			props.setModalCreateNew({
				opened: false,
			});
			setTimeout(() => {
				queryClient.invalidateQueries("outlets");
			}, 100);
		},
	});

	const change = () => {
		if (!edited.cityId) setcityreq(true);
		if (!edited.address) setadrreq(true);
		if (!edited.firstName) setnamereq(true);
		if (!edited.lastName) setlastreq(true);
		if (!edited.objectName) setobjnamereq(true);

		if (edited.cityId) setcityreq(false);
		if (edited.address) setadrreq(false);
		if (edited.firstName) setnamereq(false);
		if (edited.lastName) setlastreq(false);
		if (edited.objectName) setobjnamereq(false);
		if (edited.phoneNumberWithoutCode) {
			mutate({
				...edited,
				phoneNumber: L("+7", "+44") + edited.phoneNumberWithoutCode,
			});
		} else {
			mutate(edited);
		}
	};

	useEffect(() => {
		setcityreq(false);
		setadrreq(false);
		setnamereq(false);
		setlastreq(false);
		setobjnamereq(false);
	}, [isSuccess]);

	if (isSuccess) {
	}
	if (isError) {
	}
	const roleNotUnder = localStorage.getItem("role") != "UnderChainManager";

	return (
		<div className="">
			<div className="form-inner ">
				<div id="why" className="outlet-create-no-scroll hide-scrollbar">
					<InputControlled
						value={edited.customObjectId}
						setState={setEdited}
						stateName="customObjectId"
						label={L("Идентификатор", "ID")}
					/>

					{geoPositions && (
						<InputAutocomplete
							data={geo?.data}
							setState={setEdited}
							stateNames={["cityId"]}
							id="geo"
							label={L("Регион и город*", "Region and city*")}
							requiredState={cityreq}
						/>
					)}

					<InputControlled
						setState={setEdited}
						stateName="address"
						value={edited.address}
						requiredState={adrreq}
						label={L("Адрес*", "Address*")}
					/>

					<InputControlled
						setState={setEdited}
						stateName="objectName"
						value={edited.objectName}
						requiredState={objnamereq}
						label={L("Название филиала*", "Outlet name*")}
					/>

					{roleNotUnder && (
						<InputAutocomplete
							id="underChainManagers"
							data={underManagersData?.data}
							setState={setEdited}
							stateNames={["reportedToId"]}
							label={L("Менеджер сети", "Manager")}
						/>
					)}

					<InputControlled
						setState={setEdited}
						stateName="firstName"
						value={edited.firstName}
						requiredState={namereq}
						label={L("Имя директора*", "Director's name*")}
					/>

					<InputControlled
						setState={setEdited}
						stateName="lastName"
						value={edited.lastName}
						requiredState={lastreq}
						label={L("Фамилия директора*", "Director's lastname*")}
					/>

					<InputControlled
						setState={setEdited}
						stateName="middleName"
						value={edited.middleName}
						label={L("Отчество директора", "Director's middlename")}
					/>
					<InputControlled
						setState={setEdited}
						stateName="email"
						value={edited.email}
						label="E-mail"
						requiredState={error?.response?.status === 410}
						errorMessage={error?.response?.data?.error}
					/>

					<div className="mt-2">
						<label className="form-label" htmlFor="phoneNumber">
							{L("Телефон", "Phone")}
						</label>
						<div
							className={L("phonefield-container", "phonefield-container-gb")}
						>
							<input
								value={edited.phoneNumberWithoutCode}
								onChange={(e: any) =>
									setEdited((prev: any) => ({
										...prev,
										phoneNumberWithoutCode: e.target.value.toString(),
									}))
								}
								type="telephone"
								id="phoneNumber"
								className=" phonefield form-control"
							/>
						</div>
					</div>
				</div>

				<div className="mt-4">
					<div className="form-divider"></div>
					<button
						onClick={() => change()}
						className="btn-form btn-modal-right"
						style={{ cursor: "pointer", maxHeight: "42px", width: "185px" }}
						type="submit"
					>
						<p style={{ paddingBottom: "5px" }}>{L("Создать", "Create")}</p>
					</button>
					<CommonBtn
						styles={{ width: "175px" }}
						text={L("Отменить", "Cancel")}
						type="light"
						onClick={() => props.setModalCreateNew({ opened: false })}
						classes="btn-modal-left"
					/>
				</div>
			</div>
		</div>
	);
}

export default CreateOutlet;
