// consists of EmployeeRequestsCandidates.tsx (candidates query),
// EmployeeRequestsCandidate.tsx, EmployeeRequestEdit.tsx

import React, { useEffect, useState } from "react";
import InfoSwitch from "../../components/outlets/OutletInfo/InfoSwitch";
import MoreInfoItem from "../../components/outlets/OutletInfo/MoreInfoItem";
import CommonBtn from "../../components/utility/Buttons/CommonBtn";
import DeleteBtn from "../../components/utility/Buttons/DeleteBtn";
import ConfirmModal from "../../components/utility/confirmModal/ConfirmModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getInfoEmployeeReq, setApproval } from "../../api/employees";
import { refreshWorkerRequest } from "../../api/employees";
import { deleteWorkerRequest } from "../../api/employees";
import EmployeeRequestsCandidates from "./EmployeeRequestsCandidates";
import EmployeeRequestEdit from "../EmployeeInfo.tsx/EmployeeRequestEdit";
import useFeedback from "../../hooks/useFeedback";
import { sexCheck } from "../../utils/sexCheck";
import useLanguage from "../../hooks/useLanguage";

interface IProps {
	payload: {
		col: {
			workerRequestId: any;
			createdByFullName: any;
			region: any;
			city: string;
			position: any;
			numOfCandidates: any;
			objectName: any;
			sex: any;
			schedule: any;
			requestStatus: any;
			workStartDate: any;
			workEndDate: any;
			address: any;
			isAvailableToRefreshRequest: any;
			isAvailableToConfirmRequest: any;
			isAvailableToDeclineRequest: any;
			isAvailableToGenerateCandidate: any;
			isAvailableToDeleteRequest: any;
			customObjectId: any;
			createdOn: any;
			contractorName: any;
			comment: any;
		};
	};
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			confirmOpened?: boolean | undefined;
			secondaryOpened?: boolean | undefined;
		}>
	>;
}
function EmployeesRequestsInfo({ payload, setModal }: IProps) {
	const { L } = useLanguage();

	const client = useQueryClient();
	const { openFeedback, closeFeedback } = useFeedback();

	const [active, setActive] = useState<"request" | "candidates" | "edit">(
		"request"
	);

	const isAvaliableToDeclineAccept =
		!!payload.col.isAvailableToConfirmRequest &&
		!!payload.col.isAvailableToDeclineRequest;

	const border = () => {
		if (isAvaliableToDeclineAccept) return { border: "none" };
		return {};
	};

	// get data for workerReq
	const dataQuery = client.getQueryData<any>("employeesRequests", {
		exact: false,
	});

	const { data } = useQuery(
		["employeeReqGetInfo", payload.col.workerRequestId],
		(q) => getInfoEmployeeReq(q)
	);

	const getWorkerReqData = () => {
		const dataineed = dataQuery?.data?.workerRequests?.filter(
			(workerRequest: any) => {
				return workerRequest.workerRequestId == payload.col.workerRequestId;
			}
		)[0];

		return dataineed;
	};
	// console.log(getWorkerReqData());
	console.log(payload);
	// queries

	const { mutate: mutateApprovalApproved } = useMutation(setApproval, {
		onSuccess: () => {
			openFeedback(
				L("Заявка успешно подтверждена", "Request is approved"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("employeesRequests");
				closeConfirmation();
			}, 150);
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});
	const { mutate: mutateApprovalRejected } = useMutation(setApproval, {
		onSuccess: () => {
			openFeedback(
				L("Заявка успешно отклонена", "Request is dismissed"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("employeesRequests");
				closeConfirmation();
			}, 150);
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	const { mutate: mutateRestore } = useMutation(refreshWorkerRequest, {
		onSuccess: () => {
			openFeedback(
				L("Заявка успешно восстановлена", "Request is restored"),
				"success"
			);
			setTimeout(() => {
				client.invalidateQueries("employeesRequests");
				// closeConfirmation();
			}, 150);
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});
	const { mutate: mutateDelete } = useMutation(deleteWorkerRequest, {
		onSuccess: () => {
			openFeedback(L("Заявка успешно снята", "Request is removed"), "success");
			setTimeout(() => {
				client.invalidateQueries("employeesRequests");
				// closeConfirmation();
			}, 150);
		},
		onError: () => {
			openFeedback(L("Что-то пошло не так", "Something went wrong"), "error");
		},
	});

	// open for request conformation
	const openRejection = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Отклонение заявки", "Reject the request")}
					text={L(
						"Вы уверены, что хотите отклонить заявку?",
						"Are you sure you want to reject this request?"
					)}
				>
					<DeleteBtn
						text={L("Да, отклонить", "Yes, reject")}
						onClick={() => {
							mutateApprovalRejected({
								workerRequestId: payload.col.workerRequestId,
								approval: false,
							});
							closeConfirmation();
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};
	// open for request rejection
	const openConformation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Подтверждение заявки", "Approve the request")}
					text={L(
						"Вы уверены, что хотите подтвердить заявку?",
						"Are you sure you want to approve this request"
					)}
				>
					<CommonBtn
						text={L("Да, подтвердить", "Yes, approve")}
						onClick={() => {
							mutateApprovalApproved({
								workerRequestId: payload.col.workerRequestId,
								approval: true,
							});
							closeConfirmation();
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};
	// open for refresh request
	const openRestore = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Восстановление заявки", "Restore the request")}
					text={L(
						"Вы уверены, что хотите восстановить заявку?",
						"Are you sure you want to restore this request?"
					)}
				>
					<CommonBtn
						text={L("Да, восстановить", "Yes, restore")}
						onClick={() => {
							mutateRestore(payload.col.workerRequestId);
							closeConfirmation();
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	// open for delete request
	const openDelete = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: true,
			confirmComponent: (
				<ConfirmModal
					onClose={closeConfirmation}
					title={L("Удаление заявки", "Request deletion")}
					text={L(
						"Вы уверены, что хотите снять заявку?",
						"Are you sure you want to delete this request?"
					)}
				>
					<DeleteBtn
						text={L("Да, снять", "Yes, delete")}
						onClick={() => {
							mutateDelete(payload.col.workerRequestId);
							closeConfirmation();
						}}
					/>
					<CommonBtn
						text={L("Отменить", "Cancel")}
						onClick={closeConfirmation}
						type="light"
					/>
				</ConfirmModal>
			),
		}));
	};

	// close conformation modal
	const closeConfirmation = () => {
		setModal((prev: any) => ({
			...prev,
			confirmOpened: false,
		}));
	};

	// close modal
	const closeModal = () => {
		setModal((prev: any) => ({
			...prev,
			opened: false,
			confirmOpened: false,
		}));
	};

	// role check
	const roleNotDirector = localStorage.getItem("role") != "Director";
	const roleNotUnder = localStorage.getItem("role") != "UnderChainManager";

	if (active === "request") {
		return (
			<>
				<InfoSwitch
					options={["request", "candidates"]}
					optionsNames={L(["Заявка", "Кандидаты"], ["Request", "Candidates"])}
					active={active}
					setActive={setActive}
					// secondAvaliable={payload.col.isAvailableToGenerateCandidate}
				/>
				<div className="more-info-container">
					<MoreInfoItem
						styles={border()}
						name={L("Статус", "Status")}
						data={data?.data?.workerRequestInfo.requestStatus}
					/>
				</div>

				<div
					className="inline-styles"
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginTop: "-10px",

						marginBottom: "15px",
					}}
				>
					{getWorkerReqData()?.isAvailableToConfirmRequest && (
						<CommonBtn
							onClick={openConformation}
							type="light"
							styles={{ width: "183px", marginLeft: "5px" }}
							text={L("Подтвердить заявку", "Approve the request")}
						/>
					)}
					{getWorkerReqData()?.isAvailableToDeclineRequest && (
						<DeleteBtn
							onClick={openRejection}
							styles={{ width: "183px", marginRight: "5px" }}
							text={L("Отклонить заявку", "Reject the request")}
						/>
					)}
				</div>
				<div
					// style={{ maxHeight: "60vh", overflow: "scroll" }}
					className="more-info-container scrolled-3"
				>
					<MoreInfoItem
						name={L("№ Заявки", "Request ID")}
						data={payload.col.workerRequestId}
					/>
					{roleNotDirector && (
						<MoreInfoItem
							name={L("ID Филиала", "Outlet ID")}
							data={payload.col.customObjectId}
						/>
					)}
					{roleNotDirector && (
						<MoreInfoItem
							name={L("Филиал", "Outlet")}
							data={payload.col.objectName}
						/>
					)}
					{roleNotDirector && (
						<MoreInfoItem
							name={L("Регион", "Region")}
							data={payload.col.region}
						/>
					)}
					{roleNotDirector && (
						<MoreInfoItem name={L("Город", "City")} data={payload.col.city} />
					)}
					{roleNotDirector && (
						<MoreInfoItem
							name={L("Адрес", "Address")}
							data={payload.col.address}
						/>
					)}

					<MoreInfoItem
						name={L("Активные кандидаты", "Active candidates")}
						data={data?.data?.workerRequestInfo?.numOfCandidates}
					/>
					<MoreInfoItem
						name={L("Всего кандидатов", "Total candidates")}
						data={data?.data?.workerRequestInfo?.numOfTotalCandidates}
					/>
					<MoreInfoItem
						name={L("График", "Schedule")}
						data={data?.data?.workerRequestInfo?.schedule}
					/>
					<MoreInfoItem
						name={L("Пол", "Sex")}
						data={sexCheck(data?.data?.workerRequestInfo?.sex)}
					/>
					<MoreInfoItem
						name={L("Должность", "Job title")}
						data={data?.data?.workerRequestInfo?.position}
					/>
					<MoreInfoItem
						name={L("Комментарий", "Comment")}
						data={data?.data?.workerRequestInfo?.candidateStatusId}
					/>
					{/* <MoreInfoItem name="Подрядчик" data={payload.col.contractorName} /> */}
					<MoreInfoItem
						name={L("Создана", "Created")}
						data={
							payload.col.createdOn ? payload.col.createdOn.slice(0, 10) : ""
						}
					/>
					{roleNotDirector && (
						<MoreInfoItem
							name={L("Создавший", "Creator")}
							data={payload.col.createdByFullName}
						/>
					)}
					<MoreInfoItem
						name={L("Начало работы", "Work start")}
						data={
							payload.col.workStartDate
								? payload.col.workStartDate.slice(0, 10)
								: ""
						}
					/>
					<MoreInfoItem
						name={L("Завершение работы", "Work end")}
						data={
							payload.col.workEndDate
								? payload.col.workEndDate.slice(0, 10)
								: ""
						}
					/>
				</div>
				<div
					className="inline-styles"
					style={{
						display: "flex",
						justifyContent: "space-between",
						// marginTop: "-10px",

						marginBottom: "15px",
					}}
				>
					{getWorkerReqData()?.isAvailableToDeleteRequest && (
						<DeleteBtn
							onClick={openDelete}
							styles={{ height: "20px", width: "183px", marginLeft: "-10px" }}
							text={L("Снять заявку", "Delete request")}
							classes="btn-modal-left"
						/>
					)}
					{getWorkerReqData()?.isAvailableToRefreshRequest && (
						<CommonBtn
							onClick={openRestore}
							type="light"
							styles={{ width: "183px", marginLeft: "-10px" }}
							text={L("Восстановить заявку", "Restore the request")}
							classes="btn-modal-left"
						/>
					)}
				</div>
				<div
					className="inline-styles"
					style={{
						display: "flex",
						justifyContent: "space-between",

						marginBottom: "0px",
					}}
				>
					<CommonBtn
						onClick={() => setActive("edit")}
						styles={{ width: "183px", marginRight: "-10px" }}
						text={L("Редактировать", "Edit")}
						classes="btn-modal-right"
					/>
				</div>
			</>
		);
	}

	if (active === "candidates") {
		return (
			<>
				<InfoSwitch
					options={["request", "candidates"]}
					optionsNames={L(["Заявка", "Кандидаты"], ["Request", "Candidates"])}
					active={active}
					setActive={setActive}
				/>
				<EmployeeRequestsCandidates
					setModal={setModal}
					workerRequestId={payload.col.workerRequestId}
					active={active}
				/>
			</>
		);
	}

	if (active === "edit") {
		return (
			<>
				<EmployeeRequestEdit
					setModal={setModal}
					workerRequestId={payload.col.workerRequestId}
				/>
			</>
		);
	}

	return <div>{L("ошибка", "error")}</div>;
}

export default EmployeesRequestsInfo;
