import axios from "axios";
import { instanceV2 } from "./instances";
import { QueryFunctionContext } from "react-query";

interface ICandidates {
	candidates: {
		workerRequestId: number;
		position: string;
		customObjectId: string;
		objectId: number;
		objectName: string;
		objectResponsibleFullName: string;
		candidateId: number;
		candidateFullName: string;
		hourlyRate: number;
		phoneNumber: string;
		citizenship: string;
		passport: string;
		visitDate: string;
		candidateStatusId: number;
		address: string;
		candidateStatus: string;
		isAvailableToSetStatus: boolean;
		requestDeadline: string;
		contractorId: number;
		contractorName: string;
	}[];

	filterValues: {
		regions: {
			[key: string]: string;
		};
		cities: {
			[key: string]: string;
		};
		objectNames: {
			[key: string]: string;
		};
		jobTitles: {
			[key: string]: string;
		};
		workerStatuses: {
			[key: string]: string;
		};
		contractors: {
			[key: string]: string;
		};
	};

	chosenFilters: {
		regions: any;
		cities: any;
		objectNames: any;
		jobTitles: any;
		workerStatuses: any;
		contractors: any;
	};
}
export const getCandidates = async (q?: QueryFunctionContext<any>) => {
	let page = "";
	if (q?.queryKey[1]?.pageNumber) {
		// console.log(q.queryKey[1].pageNumber);
		page = "?pageNumber=" + q.queryKey[1].pageNumber;
	}

	let region = "";
	if (q?.queryKey[2]?.region) {
		region = `&RegionId=${q?.queryKey[2]?.region}`;
	}
	let city = "";
	if (q?.queryKey[2]?.city) {
		city = `&CityId=${q?.queryKey[2]?.city}`;
	}

	let job = "";
	if (q?.queryKey[2]?.job) {
		job = `&JobTitleId=${q?.queryKey[2]?.job}`;
	}

	let WorkerStatusId = "";
	if (q?.queryKey[2]?.WorkerStatusId) {
		WorkerStatusId = `&WorkerStatusId=${q?.queryKey[2]?.WorkerStatusId}`;
	}

	let CandidateFullName = "";
	if (q?.queryKey[2]?.CandidateFullName) {
		CandidateFullName = `&CandidateFullName=${q?.queryKey[2]?.CandidateFullName}`;
	}

	let workerRequestId = "";
	if (q?.queryKey[2]?.workerRequestId) {
		workerRequestId = `&workerRequestId=${q?.queryKey[2]?.workerRequestId}`;
	}

	let ContractorId = "";
	if (q?.queryKey[2]?.ContractorId) {
		ContractorId = `&ContractorId=${q?.queryKey[2]?.ContractorId}`;
	}

	let PageSize = "";
	if (q?.queryKey[2]?.PageSize) {
		PageSize = `&PageSize=${q?.queryKey[2]?.PageSize}`;
	}

	let outlet = "";
	if (q?.queryKey[2]?.outlet) {
		outlet = `&ObjectId=${q?.queryKey[2]?.outlet}`;
	}

	let searchInput = "";
	if (q?.queryKey[2]?.searchInput) {
		searchInput = `&SearchInput=${q?.queryKey[2]?.searchInput}`;
	}

	let column = "";
	let order = "";
	if (q?.queryKey[3]?.heading) {
		column = `&Column=${q?.queryKey[3]?.heading}`;
	}

	if (q?.queryKey[3]?.order) {
		order = `&SortType=${q?.queryKey[3]?.order}`;
	}
	const response = instanceV2.get<ICandidates>(
		`/Candidates${page}${region}${city}${job}${WorkerStatusId}${CandidateFullName}${workerRequestId}${ContractorId}${column}${order}${PageSize}${outlet}${searchInput}`
	);
	return response;
};

export const getCandidatesStatuses = async () => {
	const response = instanceV2.get(`/Candidates/getStatuses`);
	return response;
};

export const setCandidateStatus = async (newStatus: any) => {
	const response = instanceV2.post(
		"Candidates/setStatus",
		JSON.stringify(newStatus),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);
	return response;
};

export const getCandidatesSummary = async (q: QueryFunctionContext<any>) => {
	let workerRequestId = "";
	if (q?.queryKey[1]) {
		workerRequestId = q.queryKey[1];
	}

	const response = await instanceV2.get(
		`Candidates/getSummary?workerRequestId=${workerRequestId}`
	);
	return response;
};
