import React, { useState } from "react";
interface IProps {
	name: string;
	data: string | number | undefined | null;
	styles?: any;
}
function MoreInfoItem({ name, data, styles }: IProps) {
	const displayData = () => {
		if (data === undefined) return "-";
		if (data === null) return "-";
		if (data === "") return "-";
		if (typeof data === "string") return data;
		if (typeof data === "number") return data;
		return "Ошибка...";
	};

	return (
		<div style={styles} className="more-info-item">
			<p className="more-item-name">{name}:</p>
			<p  className="more-item-content">{displayData()}</p>
		</div>
	);
}

export default MoreInfoItem;
