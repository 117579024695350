import { useState } from "react";
import { useQuery } from "react-query";
import { getWorkerRequestsSupport } from "../../../api/for_support/support_worker_requests";
import Loading from "../../utility/Loading";
import TableHeadSort from "../../utility/Table/headings/TableHeadSort";
import { shortenSex } from "../../../utils/shortenSex";
import IconInputFilter from "../../utility/IconInputFilter";
import TableTotalCount from "../../utility/TableTotalCount";
import CommonBtn from "../../utility/Buttons/CommonBtn";
import ModalMain from "../../utility/ModalMain/ModalMain";
import EmployeeRequestFiltersS from "./modals/EmployeeRequestsFiltersS";
import FindEmployeeS from "./modals/FindEmployeeS";
import EmployeeReqInfoS from "./modals/EmployeeReqInfoS";
import UploadReqsS from "./modals/upload/UploadReqsS";
import TablePagination from "../../utility/TablePagination";

function WorkerRequests() {
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		index: "",
		companyId: "",
		region: "",
		city: "",
		outlet: "",
		address: "",
		// objectId: "",
		job: "",
		requestStatus: "",
		createdBy: "",
		StartCreatedOn: "",
		EndCreatedOn: "",
		workerRequestId: "", // must be integer
	});
	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc

	let headings = [
		{ name: "№ Заявки" },
		{ name: "Должность" },
		{ name: "Пол" },
		{ name: "Компания" },
		{ name: "Филиал", colname: "objectName " },
		{ name: "Локация" },

		{ name: "Начало работы" },
		{ name: "Ставка c НДС", colname: "hourlyRateVAT " },
		{ name: "Активные кандидаты", colname: "numOfCandidates" },
		{ name: "Всего кандидатов колонки", colname: "numOfTotalCandidates" },
	];
	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};

	const { data, isSuccess } = useQuery(
		["employeRequestsSupport", { pageNumber: page }, filters, sort],
		(q) => getWorkerRequestsSupport(q)
	);

	// modals

	// filters
	const [modalFilters, setModalFilters] = useState<{
		opened: boolean;
	}>({
		opened: false,
	});
	// create
	const [modalCreateNew, setModalCreateNew] = useState({
		opened: false,
	});

	// info
	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		confirmOpened?: boolean;
		secondaryOpened?: boolean;
	}>({
		opened: false,
		payload: null,
		confirmOpened: false,
		secondaryOpened: false,
	});
	const [modalUpload, setModalUpload] = useState<{
		opened: boolean;
		payload?: any;
	}>({
		opened: false,
		payload: null,
	});

	// modals

	return (
		<div className="container-fluid">
			<div className="upper-bar">
				<div className="upper-bar-first">
					<IconInputFilter
						setFilters={setFilters}
						placeholder="Искать заявку"
					/>
					<div
						onClick={() => setModalFilters({ opened: true })}
						className="filter-btn"
					>
						<img
							src="../../icons/setting-5.svg"
							alt=""
							width="20px"
							height="20px"
						/>
						<p>Фильтры</p>
					</div>

					{
						<TableTotalCount
							name="Заявок"
							headers={data?.headers["x-pagination"]}
						/>
					}
				</div>
				<div className="upper-bar-second">
					<img
						src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png"
						width="35px"
						style={{ marginRight: "30px", cursor: "pointer" }}
						alt=""
						onClick={() =>
							setModalUpload({
								opened: true,
							})
						}
					/>
					<CommonBtn
						onClick={() =>
							setModalCreateNew({
								opened: true,
							})
						}
						text=""
					>
						<img src="../../icons/add.svg" alt="powerpoint" />
						<p>Создать заявку</p>
					</CommonBtn>
				</div>
			</div>
			<ModalMain
				opened={modalFilters.opened}
				title="Фильтры"
				setModal={setModalFilters}
			>
				<EmployeeRequestFiltersS setFilters={setFilters} filters={filters} />
			</ModalMain>
			<ModalMain
				title="Создать заявку"
				setModal={setModalCreateNew}
				opened={modalCreateNew.opened}
			>
				<FindEmployeeS setModal={setModalCreateNew} />
			</ModalMain>
			<ModalMain
				modal={modalInfo}
				opened={modalInfo.opened}
				setModal={setModalInfo}
				title="Информация о заявке"
			>
				<EmployeeReqInfoS payload={modalInfo.payload} setModal={setModalInfo} />
			</ModalMain>
			{modalUpload.opened && (
				<ModalMain
					title="Загрузка ставок"
					setModal={setModalUpload}
					opened={modalUpload.opened}
				>
					<UploadReqsS />

					<CommonBtn
						text="Отменить"
						type="light"
						onClick={() => setModalUpload({ opened: false })}
						classes="btn-modal-left"
					></CommonBtn>
				</ModalMain>
			)}
			{isSuccess ? (
				<>
					<div className="table-container">
						<table cellSpacing={0} className="my-table">
							<TableHeadSort
								headings={headings}
								setSort={setSort}
								sort={sort}
							/>

							<tbody>
								{data.data.workerRequests.map((col) => {
									let workStartDate = col.workStartDate
										? col.workStartDate
												.replace("T", " ")
												.replace("-", ".")
												.replace("-", ".")
												.slice(0, -8)
										: "";
									return (
										<tr
											className={`${col.numOfCandidates > 0 && "active"}`}
											key={col.workerRequestId}
											onClick={() => {
												setModalInfo({
													opened: true,
													payload: {
														col,
													},
												});
											}}
										>
											<td className="size-item">{col.workerRequestId}</td>
											<td>{col.position}</td>
											<td>{shortenSex(col.sex)}</td>
											<td>{col.companyName}</td>
											<td>{col.objectName}</td>
											<td>
												{col.address && (
													<div
														title={`${col.region}, ${col.city}, ${col.address}`}
														className="with-icon"
													>
														<img src="../../icons/location.svg" alt="" />
														<p>{`${col.region}, ${
															col.city
														},  ${col.address.slice(0, 30)}...`}</p>
													</div>
												)}
											</td>

											<td>{workStartDate}</td>
											<td>{col.hourlyRateVAT}</td>
											<td>{col.numOfCandidates}</td>
											<td>{col.numOfTotalCandidates}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default WorkerRequests;
