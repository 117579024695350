import React from "react";
import MoreInfoItem from "../../outlets/OutletInfo/MoreInfoItem";
import CommonBtn from "../../utility/Buttons/CommonBtn";
// import EmployeeChangeStatus from "./EmployeeChangeStatus";
import { useQuery, useQueryClient } from "react-query";
import { sliceString } from "../../../utils/sliceString";
import { getEmployeeInfoContractor } from "../../../api/for_contractors/employees_contractors";

import useLanguage from "../../../hooks/useLanguage";

interface IEmployeeInfoProps {
	payload: any;
	setModal: React.Dispatch<
		React.SetStateAction<{
			opened: boolean;
			payload?: any;
			secondaryOpened?: boolean;
		}>
	>;
	modal: {
		opened: boolean;
		payload?: any;
		secondaryOpened?: boolean;
	};
	secondaryOpened: boolean;
}

function EmployeeInfoContractor({
	payload,
	setModal,
	modal,
	secondaryOpened = false,
}: IEmployeeInfoProps) {
	const { L } = useLanguage();

	const switchSecondary = () => {
		if (secondaryOpened) {
			setModal((prev) => ({ ...modal, secondaryOpened: false }));
		} else {
			setModal((prev) => ({ ...modal, secondaryOpened: true }));
		}
	};

	const client = useQueryClient();
	const dataQuery = client.getQueryData<any>("employeesContractors", {
		exact: false,
	});

	const getWorkerData = () => {
		const dataineed = dataQuery.data.workers.filter((worker: any) => {
			return worker.workerId == payload.workerId;
		})[0];
		return dataineed;
	};

	const { data: info, isSuccess } = useQuery(
		["getEmployeeInfo", payload.workerId, payload.datestart, payload.dateend],
		getEmployeeInfoContractor
	);

	// role check
	const roleNotDirector = localStorage.getItem("role") != "Director";
	return (
		<div>
			{/* company */}
			<div className="more-info-container scrolled-2">
				<div className="divider-sm"></div>
				<MoreInfoItem name={L("Компания", "Company")} data={payload.company} />

				{roleNotDirector && (
					<MoreInfoItem
						name={L("Филиал", "Outlet")}
						data={payload.objectName}
					/>
				)}
				{/* <MoreInfoItem name="ID филиала" data={payload.customObjectId} /> */}
				{roleNotDirector && (
					<MoreInfoItem name={L("Регион", "Region")} data={payload.region} />
				)}
				{roleNotDirector && (
					<MoreInfoItem name={L("Город", "City")} data={payload.city} />
				)}
				<MoreInfoItem name={L("Адрес", "Address")} data={payload.address} />
				<MoreInfoItem
					name={L("ФИО ответственного", "Responsible full name")}
					data={payload.objectResponsibleFullName}
				/>
				<MoreInfoItem
					name={L("Почта ответственного", "Responsible's email")}
					data={payload.objectResponsibleEmail}
				/>
				<MoreInfoItem
					name={L("Телефон ответственного", "Responsible's phone")}
					data={payload.ObjectResponsiblePhoneNumber}
				/>

				<MoreInfoItem
					name={L("ФИО рабочего", "Employee's full name")}
					data={payload.workerFullname}
				/>
				<MoreInfoItem name={L("Пол", "Sex")} data={payload.workerSexString} />

				<MoreInfoItem
					name={L("Дата рождения", "Birth date")}
					data={sliceString(payload.birthday)}
				/>
				<MoreInfoItem name={L("Телефон", "Phone")} data={payload.phoneNumber} />
				<MoreInfoItem
					name={L("Гражданство", "Citizenship")}
					data={payload.citizenship}
				/>
				<MoreInfoItem name={L("Паспорт", "Passport")} data={payload.passport} />
				<MoreInfoItem
					name={L("Должность", "Job title")}
					data={payload.position}
				/>

				<MoreInfoItem
					name={L("Начало работы", "Work start")}
					data={payload.workStartDate ? payload.workStartDate.slice(0, 10) : ""}
				/>
				<MoreInfoItem
					name={L("Завершение работы", "Work end")}
					data={payload.workEndDate}
				/>
				{info?.data?.workerInfo?.dismissalDate && (
					<MoreInfoItem
						name={L("Дата увольнения", "Dismissal date")}
						data={info.data?.data?.workerInfo?.dismissalDate}
					/>
				)}

				<MoreInfoItem name={L("Ставка", "Rate")} data={payload.externalRate} />
				<MoreInfoItem
					name={L("Ставка с НДС", "Rate with tax")}
					data={payload.externalRateVat}
				/>
				<MoreInfoItem
					name={L("Отработано часов", "Total working hours")}
					data={payload.externalRate}
				/>
				<MoreInfoItem
					name={L("Стоимость услуг без НДС", "Total costs")}
					data={getWorkerData().costs}
				/>
				<MoreInfoItem
					name={L("Стоимость услуг с НДС", "Total costs, with tax")}
					data={getWorkerData().costsWithVat}
				/>
			</div>
			<div className="form-divider"></div>

			<CommonBtn
				onClick={switchSecondary}
				text={
					secondaryOpened
						? L("Скрыть рабочие часы", "Hide working hours")
						: L("Показать рабочие часы", "Show working hours")
				}
				type="light"
				classes="btn-modal-center"
			/>
		</div>
	);
}

export default EmployeeInfoContractor;
