import { useState, useEffect } from "react";
import { getCandidates } from "../../../api/for_contractors/candidates_contractors";
import { useQuery } from "react-query";
import TablePagination from "../../utility/TablePagination";
import { useSearchParams } from "react-router-dom";
import SortArrow from "../../utility/SortArrow";
import Loading from "../../utility/Loading";
import CandidatesFiltersContractors from "./CandidatesFiltersContractors";
import IconInputFilter from "../../utility/IconInputFilter";
import TableTotalCount from "../../utility/TableTotalCount";
import ModalMain from "../../utility/ModalMain/ModalMain";
import CandidateInfoContractors from "./InfoCandidates/CandidatesInfoContractors";
import EditCandidate from "./EditCandidate";
import useLanguage from "../../../hooks/useLanguage";

function CandidatesContractors() {
	const { L } = useLanguage();

	const [searchParams] = useSearchParams();
	// console.log(searchParams.get("WorkerRequestId"));

	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		index: "", // custom object id
		region: "",
		city: "",
		outlet: "",
		job: "",
		ContractorId: "",
		WorkerStatusId: "",
		CandidateFullName: "",
		StartVisitDate: "",
		EndVisitDate: "",
		workerRequestId: "",
		companyId: "",
		searchInput: "",
	});
	//* modals
	const [modalFilters, setModalFilters] = useState<{
		opened: boolean;
	}>({
		opened: false,
	});

	const [modalInfo, setModalInfo] = useState<{
		opened: boolean;
		payload?: any;
		secondaryOpened?: boolean;
		confirmOpened?: boolean;
	}>({
		opened: false,
		payload: null,
		secondaryOpened: false,
	});

	// lifting the state up for the candidateInfo
	const [active, setActive] = useState<"candidate" | "request">("candidate");
	useEffect(() => {
		if (active === "request" && !modalInfo.opened) setActive("candidate");
	}, [active, modalInfo.opened]);

	const [modalCreateNew, setModalCreateNew] = useState({
		opened: false,
	});

	const [sort, setSort] = useState({
		heading: "",
		order: "",
	}); // order is asc or desc
	useEffect(() => {
		if (searchParams.get("workerRequestId")) {
			let wId: any = searchParams.get("workerRequestId");
			setFilters((prev) => ({ ...prev, workerRequestId: wId }));
		}

		if (searchParams.get("ContractorId")) {
			let contrctr: any = searchParams.get("ContractorId");
			setFilters((prev) => ({ ...prev, ContractorId: contrctr }));
		}
	}, [searchParams]);

	const { isSuccess, data, refetch } = useQuery(
		["candidates", { pageNumber: page }, filters, sort],

		(q) => getCandidates(q),

		{
			enabled: true,
			retry: 2,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 5000,
		}
	);

	const candidates = data?.data.candidates;

	let headings: any = [
		{ name: L("№ Заявки", "Request ID") },
		{ name: L("Кандидат", "Candidate") },
		{ name: L("Должность", "Job title") },
		{ name: L("Статус кандидата", "Candidate status") },
		{ name: L("Компания", "Company") },
		{ name: L("Филиал", "Outlet") },
		{ name: L("ID Филиала", "Outlet ID") },
		{ name: L("Адрес", "Address") },
		{ name: L("Дата визита", "Visit date") },
	];

	const getPaginationInfo = (data: any) => {
		if (data.headers["x-pagination"]) {
			return JSON.parse(data.headers["x-pagination"]);
		} else {
			return undefined;
		}
	};

	const roleNotDirector = localStorage.getItem("role") != "Director";

	return (
		<div className="container-fluid">
			<div className="upper-bar">
				<>
					<div className="upper-bar-first">
						<IconInputFilter
							setFilters={setFilters}
							placeholder={L("Искать кандидата", "Search candidate")}
						/>
						<div
							onClick={() => setModalFilters({ opened: true })}
							className="filter-btn"
						>
							<img
								src="../../icons/setting-5.svg"
								alt=""
								width="20px"
								height="20px"
							/>
							<p>{L("Фильтры", "Filters")}</p>
						</div>

						{
							<TableTotalCount
								name={L("Кандидатов", "Candidates")}
								headers={data?.headers["x-pagination"]}
							/>
						}
					</div>
				</>
			</div>
			<ModalMain
				opened={modalFilters.opened}
				setModal={setModalFilters}
				title={L("Фильтры", "Filters")}
			>
				<CandidatesFiltersContractors
					setFilters={setFilters}
					filters={filters}
				/>
			</ModalMain>

			<ModalMain
				modal={modalInfo}
				opened={modalInfo.opened}
				setModal={setModalInfo}
				title={L("Информация о кандидате", "Candidate information")}
				secondaryOpened={modalInfo.secondaryOpened}
				secondary={() => (
					<EditCandidate
						setActive={setActive}
						candidateId={modalInfo.payload.candidateId}
						setModal={setModalInfo}
					/>
				)}
			>
				<CandidateInfoContractors
					modal={modalInfo}
					setModal={setModalInfo}
					active={active}
					setActive={setActive}
					payload={modalInfo.payload}
				/>
			</ModalMain>

			<ModalMain
				opened={modalCreateNew.opened}
				setModal={setModalCreateNew}
				title={L("Создать кандидата", "Create candidate")}
			>
				{/* <CreateCandidate /> */}
			</ModalMain>
			{isSuccess ? (
				<>
					<div className="table-container">
						<table cellSpacing={0} className="my-table">
							<thead>
								<tr>
									{headings.map((heading: any) => (
										<th>
											<div className="d-flex align-items-end ">
												<div>{heading.name}</div>
												{heading.colname && (
													<div className="">
														<SortArrow
															sort={sort}
															heading={heading}
															setSort={setSort}
														/>
													</div>
												)}
											</div>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{candidates &&
									candidates.map((col) => {
										let visitDate = col.visitDate
											? col.visitDate
													.replace("T", " ")
													.replace("-", ".")
													.replace("-", ".")
													.slice(0, 10)
											: "";
										return (
											<tr
												onClick={() => {
													setModalInfo({
														opened: true,
														payload: col,
														secondaryOpened: false,
													});
												}}
											>
												<td className="size-item">{col.workerRequestId}</td>
												<td>{col.candidateFullName}</td>
												<td>{col.position}</td>
												<td>{col.candidateStatus}</td>
												<td>{col.companyName}</td>
												{roleNotDirector && <td>{col.objectName}</td>}
												<td>{col.customObjectId}</td>
												{roleNotDirector && <td>{col.address}</td>}
												<td>{visitDate}</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
					<TablePagination
						page={page}
						setPage={setPage}
						paginationInfo={getPaginationInfo(data)}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default CandidatesContractors;
