import React from "react";
import { useQuery } from "react-query";
import { workerRequestsGetInfo } from "../../../../api/for_contractors/worker_requests_contractors";
import MoreInfoItem from "../../../outlets/OutletInfo/MoreInfoItem";
import Loading from "../../../utility/Loading";
import { sliceString } from "../../../../utils/sliceString";
import useLanguage from "../../../../hooks/useLanguage";

interface IProps {
	workerRequestId: number;
}

function CandWorkerReqInfoContractors({ workerRequestId }: IProps) {
	const { L } = useLanguage();

	const {
		data,
		isSuccess: isReqDatam,
		isLoading,
	} = useQuery(
		["workerReqGetInfo", workerRequestId],
		(q) => workerRequestsGetInfo(q),
		{
			refetchInterval: false,
			refetchOnWindowFocus: true,
		}
	);

	if (isLoading) {
		return <Loading type="inside" />;
	}

	return (
		<div className="more-info-container">
			<div style={{ marginTop: "16px" }} className="divider-sm"></div>
			<MoreInfoItem
				name={L("№ Заявки", "Request ID")}
				data={data?.data.workerRequestId}
			/>
			<MoreInfoItem
				name={L("Компания", "Company")}
				data={data?.data.companyName}
			/>
			<MoreInfoItem name={L("Филиал", "Outlet")} data={data?.data.objectName} />
			<MoreInfoItem name={L("Регион", "Region")} data={data?.data.region} />
			<MoreInfoItem name={L("Город", "City")} data={data?.data.city} />
			<MoreInfoItem name={L("Адрес", "Address")} data={data?.data.address} />
			<MoreInfoItem
				name={L("ФИО ответственного", "Responsible full name")}
				data={data?.data.objectResponsibleFullName}
			/>
			<MoreInfoItem
				name={L("Почта ответственного", "Responsible's email")}
				data={data?.data?.objectResponsibleEmail}
			/>
			<MoreInfoItem
				name={L("Телефон ответственного", "Responsible's phone")}
				data={data?.data?.ObjectResponsiblePhoneNumber}
			/>

			{/* <MoreInfoItem name="График" data={data?.data.schedule} /> */}
			<MoreInfoItem name={L("Пол", "Sex")} data={data?.data.sexString} />
			<MoreInfoItem
				name={L("Должность", "Job title")}
				data={data?.data.position}
			/>
			<MoreInfoItem name={L("Ставка", "Rate")} data={data?.data.hourlyRate} />
			<MoreInfoItem
				name={L("Ставка с НДС", "Rate with tax")}
				data={data?.data.hourlyRateVAT}
			/>

			<MoreInfoItem
				name={L("Начало работы", "Work start")}
				data={sliceString(data?.data.workStartDate)}
			/>
			<MoreInfoItem
				name={L("Завершение работы", "Work end")}
				data={sliceString(data?.data.workEndDate)}
			/>
			<MoreInfoItem
				name={L("Дедлайн заявки", "Request deadline")}
				data={sliceString(data?.data.requestDeadline)}
			/>

			<MoreInfoItem
				name={L("Комментарий", "Comment")}
				data={data?.data.comment}
			/>
		</div>
	);
}

export default CandWorkerReqInfoContractors;
